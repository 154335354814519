// CustomResourceBreadcrumbItems.jsx
import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useResourceDefinitions, useTranslate, useDataProvider } from 'react-admin';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import { makeStyles } from '@mui/styles';

// Definizione delle etichette direttamente nel componente
const breadcrumbLabels = {
    anagen: 'Tutte le anagrafiche',
    automezzi: 'Automezzi',
    autisti: 'Autisti',
    sediaziendali: 'Sedie Aziendali',
    'tariffe-a': 'Tariffe A',
    'tariffa-servpax': 'Tariffe ServPax',
    servpax: 'ServPax',
    pacchetti: 'Pacchetti',
    'booking-0': 'Preventivi ( Booking_0 )',
    prenotazioni: 'Prenotazioni',
    listini: 'Listini',
    softwarecollegati: 'Software Collegati',
    statistiche: 'Statistiche',
    users: 'Utenti',
    gruppi: 'Gruppi',
    tabellealfa: 'Tabelle Alfa',
    notelist: 'Notifiche',
    preventivi: 'Lista Preventivi Pacchetti',
    scadenze: 'Lista Scadenze',
    settingspage: 'Impostazioni'
};

// Mappatura degli ID speciali per 'anagen'
const anagenSpecialLabels = {
    '1135': 'Clienti',
    '1136': 'Fornitori',
    '1148': 'Dipendenti e Collaboratori',
};

// Mappatura degli ID speciali per 'booking-0'
const booking_0SpecialLabels = {
    '0': 'Prenotazioni in Booking',
    '1': 'Prenotazioni Preventivate',
    '2': 'Prenotazioni Validate',
    '3': 'Prenotazioni Inviate',
    '4': 'Prenotazioni Non Confermate',
    '6': 'Prenotazioni Da Definire',
    '7': 'Prenotazioni con Servizio Approvato',
    '9': 'Prenotazioni Da Definire (9)',
    '10': 'Prenotazioni Da Definire (10)',
    '15': 'Prenotazioni Da Definire (15)',
    '18': 'Prenotazioni Da Definire (18)',
    '19': 'Prenotazioni Da Definire (19)',
    '100': 'Prenotazioni Da Definire (100)'
};

// Definizione delle azioni e delle loro etichette
const breadcrumbActionLabels = {
    create: 'Crea',
    edit: 'Modifica',
    show: 'Dettaglio',
    delete: 'Elimina', // Nuova azione
    duplicate: 'Duplica', // Un'altra nuova azione
    // Aggiungi altre azioni qui
};

// Stili personalizzati (opzionale)
// Aggiungiamo nuovi stili personalizzati
const useStyles = makeStyles({
    link: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: '#00796b',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    icon: {
        marginRight: '4px',
    },
    // Nuovo stile per il testo non cliccabile
    staticText: {
        fontWeight: '500',  // Testo leggermente più bold
        color: '#333333',   // Colore più scuro per il contrasto
        padding: '2px 4px', // Leggero padding per dare più spazio
        borderRadius: '3px' // Bordi arrotondati per un look moderno
    }
});


const CustomResourceBreadcrumbItems = () => {
    const location = useLocation();
    const resourceDefinitions = useResourceDefinitions();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const classes = useStyles();
    const [dynamicLabels, setDynamicLabels] = useState({});

    useEffect(() => {
        const fetchDynamicLabels = async () => {
            const newDynamicLabels = {};

            // Funzione per identificare e recuperare segmenti dinamici
            const fetchLabelForSegment = async (segment, resource) => {
                if (resourceDefinitions[resource] && resourceDefinitions[resource].recordRepresentation) {
                    // Verifica se il resource è 'anagen' e l'ID è uno di quelli speciali
                    if (resource === 'anagen' && anagenSpecialLabels[segment]) {
                        newDynamicLabels[`${resource}-${segment}`] = anagenSpecialLabels[segment];
                        return;
                    }

                    // Nella funzione fetchLabelForSegment
                    if (resource === 'booking-0' && booking_0SpecialLabels[segment]) {
                        newDynamicLabels[`${resource}-${segment}`] = booking_0SpecialLabels[segment];
                        return;
                    }

                    try {
                        const response = await dataProvider.getOne(resource, { id: segment });
                        const representation = typeof resourceDefinitions[resource].recordRepresentation === 'function'
                            ? resourceDefinitions[resource].recordRepresentation(response.data)
                            : response.data[resourceDefinitions[resource].recordRepresentation];
                        newDynamicLabels[`${resource}-${segment}`] = representation;
                    } catch (error) {
                        console.error(`Errore nel recupero della risorsa '${resource}' con id '${segment}':`, error);
                    }
                }
            };

            // Identifica i segmenti dinamici e recupera le etichette dinamiche
            const pathSegments = location.pathname.split('/').filter(seg => seg);
            for (let i = 0; i < pathSegments.length; i++) {
                const segment = pathSegments[i];
                // Verifica se il segmento corrente è un parametro dinamico (es. un ID)
                // Supponiamo che i parametri dinamici siano posizionati dopo il nome della risorsa
                // Es: "/anagen/1135/list" → "1135" è dopo "anagen"
                const resource = pathSegments[i - 1];
                if (resource && resourceDefinitions[resource] && pathSegments[i].match(/^\d+$/)) { // Supponiamo che gli ID siano numerici
                    await fetchLabelForSegment(pathSegments[i], resource);
                }
            }

            setDynamicLabels(newDynamicLabels);
        };

        fetchDynamicLabels();
    }, [location.pathname, resourceDefinitions, dataProvider]);

    const getLabel = (segment, index, pathSegments) => {
        // Identifica se il segmento è un parametro dinamico
        const resource = pathSegments[index - 1];
        if (resource && resourceDefinitions[resource]) {
            const dynamicLabel = dynamicLabels[`${resource}-${segment}`];
            if (dynamicLabel) {
                return dynamicLabel;
            }
        }

        // Controlla se il segmento è un'azione
        if (breadcrumbActionLabels[segment]) {
            return breadcrumbActionLabels[segment];
        }

        if (resourceDefinitions[segment]) {
            return breadcrumbLabels[segment] || resourceDefinitions[segment].options?.label || translate(`resources.${segment}.name`, { smart_count: 2 });
        }

        const customLabels = {
            'edit': 'Modifica',
            'create': 'Crea',
            'show': 'Visualizza',
            // Aggiungi altre mappature se necessario
        };

        return customLabels[segment] || segment.charAt(0).toUpperCase() + segment.slice(1);
    };

    const pathSegments = location.pathname.split('/').filter(seg => seg);

    const breadcrumbs = [];
    let i = 0;
    while (i < pathSegments.length) {
        const segment = pathSegments[i];
        const resource = pathSegments[i - 1];

        if (breadcrumbActionLabels[segment]) {
            // È un'azione, aggiungi l'azione al breadcrumb
            breadcrumbs.push({
                label: getLabel(segment, i, pathSegments),
                link: `/${pathSegments.slice(0, i + 1).join('/')}`,
                isLast: (i === pathSegments.length - 1) || (i + 1 === pathSegments.length - 1),
            });

            // Salta il prossimo segmento (parametro dell'azione)
            // Ad esempio, "/anagen/create/cliente" → salta "cliente"
            if (i + 1 < pathSegments.length) {
                i += 2;
            } else {
                i += 1;
            }
            continue;
        }

        if (resource && resourceDefinitions[resource] && segment.match(/^\d+$/)) { // Parametro dinamico
            // Aggiungi l'etichetta dinamica già recuperata
            const label = getLabel(segment, i, pathSegments);
            if (label) {
                breadcrumbs.push({
                    label: label,
                    link: `/${pathSegments.slice(0, i + 1).join('/')}`,
                    isLast: i === pathSegments.length - 1,
                });
            }
        } else if (resourceDefinitions[segment]) { // se il segmento è una risorsa
            breadcrumbs.push({
                label: getLabel(segment, i, pathSegments),
                link: `/${pathSegments.slice(0, i + 1).join('/')}`,
                isLast: i === pathSegments.length - 1,
            });
        } else { // altri segmenti
            breadcrumbs.push({
                label: getLabel(segment, i, pathSegments),
                link: `/${pathSegments.slice(0, i + 1).join('/')}`,
                isLast: i === pathSegments.length - 1,
            });
        }

        i++;
    }

    // Aggiungi "Home" come primo elemento
    const breadcrumbsWithHome = [
        {
            label: 'Home',
            link: '/',
            icon: <HomeIcon className={classes.icon} />,
        },
        ...breadcrumbs,
    ];


    // Nel componente, modifichiamo il rendering della Typography
    return (
        <Breadcrumbs separator=" > " aria-label="breadcrumb">
            {breadcrumbsWithHome.map((breadcrumb, index) => {
                const isDynamicRecord = breadcrumb.label &&
                    index > 1 &&
                    !breadcrumbActionLabels[breadcrumb.label] &&
                    !breadcrumbLabels[breadcrumb.label];

                if (isDynamicRecord || breadcrumb.isLast) {
                    return (
                        <Typography
                            key={index}
                            className={classes.staticText}  // Applichiamo il nuovo stile
                            component="span"  // Usiamo span per una migliore semantica
                        >
                            {breadcrumb.icon && <span>{breadcrumb.icon}</span>}
                            {breadcrumb.label}
                        </Typography>
                    );
                }

                return (
                    <Link key={index} to={breadcrumb.link} className={classes.link}>
                        {breadcrumb.icon && <span>{breadcrumb.icon}</span>}
                        {breadcrumb.label}
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
};

export default CustomResourceBreadcrumbItems;
