import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const CustomLoadingSimple = () => (
    <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start" // In cima
        alignItems="center"         // centro orizzontale
        height="100vh"
        sx={{
            // backgroundColor: '#f0f4f8',
            color: '#1a237e',
        }}
    >
        <CircularProgress
            size={80}
            thickness={5}
            sx={{ color: '#1a237e', mb: 3 }}
        />
    </Box>

);

export default CustomLoadingSimple;
