import React, { useEffect, useState } from 'react';
import { useDataProvider, useStore } from 'react-admin';
import {
    Box, Paper, Typography, Button, ButtonGroup, Tooltip, CircularProgress
} from '@mui/material';
import { InfoOutlined, CheckCircle } from '@mui/icons-material';

const AllBookingMessages = () => {
    const dataProvider = useDataProvider();
    const [loggedUserId] = useStore('profile.idUser');
    const [messages, setMessages] = useState([]);
    // 1. AGGIUNGIAMO UNO STATO DI CARICAMENTO
    const [loading, setLoading] = useState(false);

    const fetchMessages = async () => {
        try {
            const { data } = await dataProvider.getList('proc-messaggi-booking-0', {
                filter: {},
                pagination: { page: 1, perPage: 1000 }
            });
            setMessages(Array.isArray(data) ? data : [data]);
        } catch (error) {
            console.error('Errore caricamento messaggi:', error);
        }
    };

    useEffect(() => {
        fetchMessages();
        const interval = setInterval(fetchMessages, 5000);
        return () => clearInterval(interval);
    }, []);

    const getAuthorLabel = (message) => {
        if (message.usersId === loggedUserId) return 'Tu';
        return [1, 28].includes(message.gruppiId)
            ? 'Operatore Backoffice'
            : `Cliente #${message.usersId} - ${message.nome} ${message.cognome}`;
    };

    const handleReadStatus = async (messageId, status) => {
        try {
            // 2. QUANDO INIZIA L’AGGIORNAMENTO, settiamo loading = true
            setLoading(true);

            await dataProvider.getList('proc-set-messaggi-booking-0-letto', {
                filter: {
                    messaggio_id: messageId,
                    user_id: loggedUserId,
                    status: status
                },
                pagination: { page: 1, perPage: 1 }
            });

            // Ricarichiamo la lista dopo l'update
            await fetchMessages();
        } catch (error) {
            console.error('Errore aggiornamento stato:', error);
        } finally {
            // 3. Al termine, loading = false
            setLoading(false);
        }
    };

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
                Tutti i Messaggi di tutti i Booking
            </Typography>

            {/*
             4. Mostriamo un indicatore di caricamento 
                mentre loading = true
            */}
            {loading && (
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 1 }}>
                    <CircularProgress size={20} />
                    <Typography variant="body2">Aggiornamento in corso...</Typography>
                </Box>
            )}

            {messages.map((message) => (
                <MessageRow
                    key={message.id}
                    message={message}
                    getAuthorLabel={getAuthorLabel}
                    onReadStatusChange={handleReadStatus}
                    isMyMessage={message.usersId === loggedUserId}
                    disableButtons={loading} // <--- passiamo lo stato
                />
            ))}
        </Box>
    );
};

const MessageRow = ({ message, getAuthorLabel, onReadStatusChange, isMyMessage, disableButtons }) => {
    const timeFormatted = new Date(message.createdAt).toLocaleString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });

    return (
        <Paper
            sx={{
                p: 2,
                mb: 1,
                bgcolor: isMyMessage ? '#e3f2fd' : 'white',
                borderLeft: isMyMessage ? '4px solid #1976d2' : 'none'
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                <Tooltip
                    title={`Id ${message.id} | booking0Id: ${message.booking0Id} | bk0_idnumber: ${message.bk0_idnumber}`}
                >
                    <InfoOutlined sx={{ color: 'action.active', fontSize: 20 }} />
                </Tooltip>
                <a
                    href={`#/booking-0/${message.booking0Id}/show/messaggi`}
                    style={{ textDecoration: 'none', color: '#1976d2' }}
                >
                    Dettaglio Preventivo {message.bk0_idnumber}
                </a>
            </Box>
            <Typography
                variant="body2"
                sx={{
                    fontWeight: 'bold',
                    mb: 1,
                    color: isMyMessage ? '#1976d2' : 'text.primary'
                }}
            >
                {getAuthorLabel(message)}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                {message.testo}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Typography
                    variant="caption"
                    sx={{
                        color: 'text.secondary',
                        fontStyle: 'italic'
                    }}
                >
                    {timeFormatted}
                </Typography>
                {!isMyMessage && (
                    <ButtonGroup size="small">
                        <Button
                            startIcon={message.flLetto === 1 ? <CheckCircle /> : null}
                            onClick={() => onReadStatusChange(message.id, 1)}
                            variant={message.flLetto === 1 ? 'contained' : 'outlined'}
                            color="primary"
                            disabled={disableButtons}  // <--- disabilita se loading
                        >
                            Letto
                        </Button>
                        <Button
                            onClick={() => onReadStatusChange(message.id, 0)}
                            variant={message.flLetto === 0 ? 'contained' : 'outlined'}
                            color="primary"
                            disabled={disableButtons}  // <--- disabilita se loading
                        >
                            {message.flLetto === 0 ? (
                                'Non letto'
                            ) : (
                                <Typography sx={{ color: 'text.secondary' }}>
                                    Non letto
                                </Typography>
                            )}
                        </Button>
                    </ButtonGroup>
                )}
            </Box>
        </Paper>
    );
};

export default AllBookingMessages;
