import React, { useState } from 'react';
import { Card, CardContent, Grid, Typography, Box, CircularProgress, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAllStatuses, getStatusString } from './bookingStatus';
import { useBookingStats } from './useBookingStats';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { BookingCreateDialog } from '../BookingCreateDialog';

const StatusCard = ({ status, count, onCardClick }) => {
    const getBackgroundColor = (id) => {
        const colors = {
            0: '#4caf50',  // Booking - Verde
            1: '#2196f3',  // Preventivato - Blu
            2: '#9c27b0',  // Validato - Viola
            3: '#ff9800',  // Inviato - Arancione
            4: '#f44336',  // Non Confermato - Rosso
            7: '#009688',  // Servizio Approvato - Teal
        };
        return colors[id] || '#757575';
    };

    return (
        <Card
            sx={{
                cursor: 'pointer',
                transition: 'transform 0.2s, box-shadow 0.2s',
                height: '100%',
                '&:hover': {
                    transform: 'scale(1.02)',
                    boxShadow: 3
                }
            }}
            onClick={() => onCardClick(status)}
        >
            <CardContent>
                <Box
                    sx={{
                        backgroundColor: getBackgroundColor(status.id),
                        color: 'white',
                        padding: 2,
                        borderRadius: 1,
                        minHeight: '120px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center'
                    }}
                >
                    <Typography variant="h5" component="div" gutterBottom>
                        {status.descrizione}
                    </Typography>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', my: 1 }}>
                        {count?.toLocaleString() || '0'}
                    </Typography>
                    <Typography variant="body2">
                        preventivi
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

const ActionCard = ({ title, icon, onClick, backgroundColor }) => (
    <Card
        sx={{
            cursor: 'pointer',
            transition: 'transform 0.2s, box-shadow 0.2s',
            height: '100%',
            '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: 3
            }
        }}
        onClick={onClick}
    >
        <CardContent>
            <Box
                sx={{
                    backgroundColor,
                    color: 'white',
                    padding: 2,
                    borderRadius: 1,
                    minHeight: '120px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'
                }}
            >
                {icon}
                <Typography variant="h5" component="div" sx={{ mt: 2 }}>
                    {title}
                </Typography>
            </Box>
        </CardContent>
    </Card>
);

const BookingDashboard = () => {
    const navigate = useNavigate();
    const { data: statsData, loading, error } = useBookingStats();
    const [createDialogOpen, setCreateDialogOpen] = useState(false);

    const handleCardClick = (status) => {
        const statusString = getStatusString(status.id);
        navigate(`/booking-0/${statusString}/list`);
    };

    const handleCreateClick = () => {
        setCreateDialogOpen(true);
    };

    const handleDialogClose = () => {
        setCreateDialogOpen(false);
    };

    const handleCreateFromPackageClick = () => {
        navigate('/prenotazioni');
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height={400}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height={400}>
                <Typography color="error">
                    Si è verificato un errore nel caricamento dei dati.
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" gutterBottom sx={{ marginBottom: 4 }}>
                Dashboard Booking
            </Typography>

            {/* Sezione Azioni */}
            <Box sx={{ mb: 4 }}>
                <Typography variant="h5" gutterBottom color="primary">
                    Azioni
                </Typography>
                <Divider sx={{ mb: 3 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <ActionCard
                            title="Crea Preventivo"
                            icon={<AddCircleOutlineIcon sx={{ fontSize: 40 }} />}
                            onClick={handleCreateClick}
                            backgroundColor="#2196f3"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <ActionCard
                            title="Crea Preventivo da Pacchetto"
                            icon={<LocalOfferIcon sx={{ fontSize: 40 }} />}
                            onClick={handleCreateFromPackageClick}
                            backgroundColor="#ff9800"
                        />
                    </Grid>
                </Grid>
            </Box>

            {/* Divider e titolo per le statistiche */}
            <Box sx={{ mb: 4, mt: 6 }}>
                <Typography variant="h5" gutterBottom color="primary">
                    Link Rapidi ( Lista Preventivi divisi per STATUS )
                </Typography>
                <Divider />
            </Box>

            {/* Sezione Statistiche */}
            <Grid container spacing={3}>
                {statsData.map((item) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                        <StatusCard
                            status={item}
                            count={item.count}
                            onCardClick={handleCardClick}
                        />
                    </Grid>
                ))}
            </Grid>

            {/* Dialog di Creazione */}
            <BookingCreateDialog
                open={createDialogOpen}
                onClose={handleDialogClose}
            />
        </Box>
    );
};

export default BookingDashboard;