// colorMap.js (o all’interno dello stesso file se preferisci)
import { BookingStatus } from './bookingStatus';
// Scegli tu i colori
export const colorMap = {
    [BookingStatus.BOOKING]: '#f48fb1',         // rosa
    [BookingStatus.PREVENTIVATO]: '#26a69a',    // teal
    [BookingStatus.VALIDATO]: '#90caf9',        // blu chiaro
    [BookingStatus.INVIATO]: '#66bb6a',         // verde
    [BookingStatus.NON_CONFERMATO]: '#ffb74d',  // arancio
    [BookingStatus.DA_DEFINIRE_6]: '#ef5350',
    [BookingStatus.SERVIZIO_APPROVATO]: '#42a5f5',
    [BookingStatus.DA_DEFINIRE_9]: '#9c27b0',
    [BookingStatus.DA_DEFINIRE_10]: '#ab47bc',
    [BookingStatus.DA_DEFINIRE_15]: '#7e57c2',
    [BookingStatus.DA_DEFINIRE_18]: '#5c6bc0',
    [BookingStatus.DA_DEFINIRE_19]: '#26c6da',
    [BookingStatus.DA_DEFINIRE_100]: '#b0bec5',
};

// Esempio di mappa per i link
export const linkMap = {
    // Per ogni status definisci dove vuoi “puntare”.
    [BookingStatus.BOOKING]: '#/booking-0/booking/list',
    [BookingStatus.PREVENTIVATO]: '#/booking-0/preventivato/list',
    [BookingStatus.VALIDATO]: '#/booking-0/validato/list',
    [BookingStatus.INVIATO]: '#/booking-0/inviato/list',
    [BookingStatus.NON_CONFERMATO]: '#/booking-0/non-confermato/list',
    [BookingStatus.DA_DEFINIRE_6]: '#/booking-0/da-definire-6/list',
    [BookingStatus.SERVIZIO_APPROVATO]: '#/booking-0/servizio-approvato/list',
    [BookingStatus.DA_DEFINIRE_9]: '#/booking-0/da-definire-9/list',
    [BookingStatus.DA_DEFINIRE_10]: '#/booking-0/da-definire-10/list',
    [BookingStatus.DA_DEFINIRE_15]: '#/booking-0/da-definire-15/list',
    [BookingStatus.DA_DEFINIRE_18]: '#/booking-0/da-definire-18/list',
    [BookingStatus.DA_DEFINIRE_19]: '#/booking-0/da-definire-19/list',
    [BookingStatus.DA_DEFINIRE_100]: '#/booking-0/da-definire-100/list',
};
