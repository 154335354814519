import React, { useEffect, useState } from 'react';

const CountdownTimer = ({ record }) => {
    const { data: dueDate, statoScadenzaId, closedDate } = record;
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(dueDate));

    useEffect(() => {
        if (statoScadenzaId !== 1) return; // Avvia il timer solo se lo stato è "Aperto"

        const interval = setInterval(() => {
            setTimeLeft(calculateTimeLeft(dueDate));
        }, 1000);

        return () => clearInterval(interval);
    }, [dueDate, statoScadenzaId]);

    if (statoScadenzaId === 4) {
        // Stato "Scaduto"
        const elapsedTime = calculateElapsedTime(dueDate);
        return `Scaduto da ${elapsedTime.days}g ${elapsedTime.hours}h ${elapsedTime.minutes}m ${elapsedTime.seconds}s`;
    }

    if (statoScadenzaId === 2 && closedDate) {
        // Stato "Chiuso" (con anticipo)
        const advanceTime = calculateElapsedTime(closedDate, dueDate);
        return `Chiuso con ${advanceTime.days}g ${advanceTime.hours}h ${advanceTime.minutes}m ${advanceTime.seconds}s di anticipo`;
    }

    if (statoScadenzaId !== 1) {
        // Altri stati
        return <><strong>Non Attivo</strong></>;
    }

    if (timeLeft.total <= 0) {
        return <><strong>Scaduto!</strong></>;
    }

    // Conto alla rovescia per stato "Aperto"
    return `${timeLeft.days}g ${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`;
};

const calculateTimeLeft = (dueDate) => {
    const now = new Date();
    const due = new Date(dueDate);
    const diff = due - now;

    return {
        total: diff,
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((diff / (1000 * 60)) % 60),
        seconds: Math.floor((diff / 1000) % 60),
    };
};

const calculateElapsedTime = (startDate, endDate = new Date()) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diff = end - start;

    return {
        total: diff,
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((diff / (1000 * 60)) % 60),
        seconds: Math.floor((diff / 1000) % 60),
    };
};

export default CountdownTimer;
