import React from 'react';
import {
    Typography,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Paper,
    Divider
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MapIcon from '@mui/icons-material/Map';
import DirectionsIcon from '@mui/icons-material/Directions';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// Componente per formattare la data e l'ora
const formatDateTime = (dateString) => {
    return new Date(dateString).toLocaleString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
};

// Componente per formattare il tempo in secondi
const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
};

const RouteItem = ({ route }) => (
    <Box sx={{ py: 1 }}>
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={3}>
                <Typography variant="body2" color="textSecondary">
                    {formatDateTime(route.bk2_datastart)}
                </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <DirectionsIcon color="primary" sx={{ fontSize: 20 }} />
                    <Typography>{route.bk2_partenza}</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={3}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <DirectionsIcon color="primary" sx={{ fontSize: 20 }} />
                    <Typography>{route.bk2_arrivo}</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={3}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography>
                        <strong>{route.bk2_pkmt}</strong> km
                    </Typography>
                    <Typography>
                        <strong>{formatTime(route.bk2_tempoInSecondi)}</strong>
                    </Typography>
                </Box>
            </Grid>
        </Grid>
        <Divider sx={{ mt: 1 }} />
    </Box>
);

// Aggiungere questo componente all'interno di BookingCard
const RoutesAccordion = ({ routes }) => {
    if (!routes || routes.length === 0) return null;

    const totalKm = routes.reduce((sum, route) => sum + (parseFloat(route.bk2_pkmt) || 0), 0);
    const totalSeconds = routes.reduce((sum, route) => sum + (parseInt(route.bk2_tempoInSecondi) || 0), 0);

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ bgcolor: 'rgba(25, 118, 210, 0.05)' }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <MapIcon color="primary" />
                    <Typography variant="subtitle1">
                        Elenco delle Tratte ({routes.length})
                    </Typography>
                    <Typography variant="body2" sx={{ ml: 2, color: 'text.secondary' }}>
                        Totale: {totalKm.toFixed(1)} km - {formatTime(totalSeconds)}
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Paper elevation={0} sx={{ p: 2, bgcolor: 'background.default' }}>
                    {routes.map((route, index) => (
                        <RouteItem key={route.id || index} route={route} />
                    ))}
                </Paper>
            </AccordionDetails>
        </Accordion>
    );
};

export default RoutesAccordion;