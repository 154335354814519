import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, CircularProgress, Alert } from '@mui/material';
import { useDataProvider } from 'react-admin';

const AutomezzoStatisticheRifornimentiCard = ({ automezzoId }) => {
    const dataProvider = useDataProvider();
    const [automezzo, setAutomezzo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [noData, setNoData] = useState(false);

    useEffect(() => {
        const fetchAutomezzoData = async () => {
            try {
                setLoading(true);
                const { data } = await dataProvider.getOne('view-rifornimenti-automezzi', { id: automezzoId });
                setAutomezzo(data);
                setNoData(false);
            } catch (err) {
                if (err.message === `No record found for id '${automezzoId}'`) {
                    setNoData(true); // Trattalo come "nessun dato"
                } else {
                    setError(err);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchAutomezzoData();
    }, [automezzoId, dataProvider]);

    if (loading) return <CircularProgress />;
    if (error) {
        return (
            <Alert severity="error" sx={{ margin: 2 }}>
                Errore: {error.message}
            </Alert>
        );
    }
    if (noData) {
        return (
            <Alert severity="info" sx={{ margin: 2 }}>
                Statistiche Rifornimenti - Nessun dato disponibile per l'automezzo selezionato.
            </Alert>
        );
    }

    return (
        <Card sx={{ margin: 2 }}>
            <CardContent>
                <Typography variant="h5" component="div">
                    Statistiche Rifornimenti - Automezzo - {automezzo?.targa || 'N/A'}
                </Typography>
                <Typography variant="body2">
                    Numero Rifornimenti: {automezzo?.numRifornimenti || 'N/A'}
                </Typography>
                <Typography variant="body2">
                    Totale Litri Erogati: {automezzo?.totLitriErogati || 'N/A'}
                </Typography>
                <Typography variant="body2">
                    Chilometri Iniziali: {automezzo?.kmInizio || 'N/A'}
                </Typography>
                <Typography variant="body2">
                    Chilometri Finali: {automezzo?.kmFine || 'N/A'}
                </Typography>
                <Typography variant="body2">
                    Delta Km: {automezzo?.deltaKm || 'N/A'}
                </Typography>
                <Typography variant="body2">
                    Media Km/Litro: {automezzo?.mediaKm || 'N/A'}
                </Typography>
                <Typography variant="body2">
                    Data Primo Rifornimento: {automezzo?.dataPrimoRifornimento || 'N/A'}
                </Typography>
                <Typography variant="body2">
                    Data Ultimo Rifornimento: {automezzo?.dataUltimoRifornimento || 'N/A'}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default AutomezzoStatisticheRifornimentiCard;
