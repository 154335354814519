import React, { useState, useEffect } from "react";
import { useDataProvider } from "react-admin";
import { CircularProgress, Typography } from "@mui/material";

const RecordValueField = ({ record, source }) => {
    const dataProvider = useDataProvider();
    const [value, setValue] = useState("Caricamento...");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (!record || !record.tiporecordId || !record.recordId) {
                setValue("N/A");
                setLoading(false);
                return;
            }

            try {
                // Ottieni dettagli dal tipo record
                const { data: tiporecord } = await dataProvider.getOne("tiporecord", {
                    id: record.tiporecordId,
                });

                const archive = tiporecord.archivio;
                const field = tiporecord.campo;

                if (archive && field) {
                    // Effettua la chiamata dinamica all'archivio
                    const { data: relatedRecord } = await dataProvider.getOne(archive, {
                        id: record.recordId,
                    });

                    setValue(relatedRecord[field] || "Non trovato");
                } else {
                    setValue("Configurazione non valida");
                }
            } catch (error) {
                setValue("Errore durante il recupero");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [record, dataProvider]);

    if (loading) {
        return <CircularProgress size={16} />;
    }

    return <Typography variant="body2">{value}</Typography>;
};

export default RecordValueField;
