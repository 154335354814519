import * as React from 'react';
import { MenuItemLink, Menu, useTranslate, useGetList, useStore } from 'react-admin';
import { Box, ListItemIcon } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MailIcon from '@mui/icons-material/Mail';
import PersonIcon from '@mui/icons-material/Person';
import AnimatedBadge from './AnimatedBadge';
import { useEffect } from 'react';

const MyUserMenu = (props) => {
    const translate = useTranslate();
    const [loggedUserId] = useStore('profile.idUser');
    const { data: messages, refetch } = useGetList('proc-messaggi-booking-0', {
        filter: {},
        pagination: { page: 1, perPage: 1000 }
    });

    useEffect(() => {
        const interval = setInterval(() => {
            refetch();
        }, 5000); // Aggiorna ogni 5 secondi
        return () => clearInterval(interval);
    }, [refetch]);

    const unreadCount = messages?.filter(msg =>
        msg.flLetto === 0 && msg.usersId !== loggedUserId
    ).length || 0;

    return (
        <Box sx={{
            width: 240,
            height: '100%',
            backgroundImage: 'linear-gradient(to bottom, #01b5d0, #0077be)',
            color: 'white',
            pt: 2,
        }}>
            <Menu {...props} dense>
                <MenuItemLink
                    to="/booking-0"
                    primaryText={translate('Preventivi')}
                    leftIcon={
                        <ListItemIcon sx={{ color: 'white' }}>
                            <AssignmentIcon />
                        </ListItemIcon>
                    }
                    sx={{
                        color: 'white',
                        '&.RaMenuItem-active': {
                            backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        },
                    }}
                />
                <MenuItemLink
                    to="/messaggi"
                    primaryText={translate('Messaggi')}
                    leftIcon={
                        <ListItemIcon sx={{ color: 'white' }}>
                            <AnimatedBadge count={unreadCount} />
                        </ListItemIcon>
                    }
                    sx={{
                        color: 'white',
                        '&.RaMenuItem-active': {
                            backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        },
                    }}
                />
                <MenuItemLink
                    to="/dati-personali"
                    primaryText={translate('Dati Personali')}
                    leftIcon={
                        <ListItemIcon sx={{ color: 'white' }}>
                            <PersonIcon />
                        </ListItemIcon>
                    }
                    sx={{
                        color: 'white',
                        '&.RaMenuItem-active': {
                            backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        },
                    }}
                />
            </Menu>
        </Box>
    );
};

export default MyUserMenu;