import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    required,
    SaveButton,
    Toolbar,
} from 'react-admin';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
} from '@mui/material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import CustomAutocompleInput from '../shared/FormFields/CustomAutocompleInput';
import { checkPermission } from '../shared/Permissions/checkPermission';

const BookingCreateToolbar = props => (
    <Toolbar {...props} sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        marginTop: '20px'
    }}>
        <SaveButton
            label="Crea Preventivo"
            type="button"
            sx={{
                padding: '8px 24px',
                backgroundColor: '#4CAF50',
                '&:hover': {
                    backgroundColor: '#45a049',
                },
                color: 'white',
                fontWeight: 'bold'
            }}
        />
    </Toolbar>
);

export const BookingCreateDialog = ({ open, onClose }) => {

    const canRead = checkPermission("booking0", "create");

    if (!canRead) {
        return (
            <div style={{ color: "red", fontWeight: "bold", padding: 20 }}>
                Accesso negato
            </div>
        );
    }


    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            PaperProps={{
                style: { padding: '16px' }
            }}
        >
            <DialogTitle sx={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
                color: '#1976d2',
                borderBottom: '2px solid #1976d2',
                paddingBottom: '8px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px'
            }}>
                <DirectionsCarIcon />
                Nuovo Preventivo
            </DialogTitle>
            <DialogContent>
                <Create
                    resource="booking-0"
                    mutationOptions={{
                        onSuccess: () => {
                            onClose();
                        }
                    }}
                >
                    <SimpleForm toolbar={<BookingCreateToolbar />}>

                        {/* Sezione Cliente */}
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12}>
                                <CustomAutocompleInput
                                    key="client"
                                    source="bk0_clienteId"
                                    reference="anagen"
                                    optionProperty="gnfind"
                                    label="Seleziona Anagrafica"
                                    alwaysOn
                                />
                            </Grid>
                        </Grid>

                        {/* Sezione Servizio */}
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} md={6}>
                                <ReferenceInput
                                    source="bk0_servizio"
                                    reference="servizi"
                                    validate={[required()]}
                                >
                                    <SelectInput
                                        label="Tipo Servizio *"
                                        fullWidth
                                        validate={[required()]}
                                    />
                                </ReferenceInput>
                            </Grid>
                        </Grid>

                        {/* Sezione Tipo Richiesta */}
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            {/* <ReferenceField
                                link={null}
                                label="Tipo Richiesta"
                                source="bk0_richiestatipo"
                                reference="tabellealfa"
                                sortable={true}
                                emptyText="-"
                            /> */}

                            <Grid item xs={12}>
                                <ReferenceInput
                                    source="bk0_richiestatipo"
                                    reference="view-tabellaalfa-trp"
                                    validate={[required()]}
                                >
                                    <SelectInput
                                        label="Tipo di Richiesta *"
                                        fullWidth
                                        validate={[required()]}
                                    />
                                </ReferenceInput>
                            </Grid>
                        </Grid>

                        {/* Date */}
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} md={6}>
                                <DateInput
                                    source="bk0_datada"
                                    label="Data Inizio *"
                                    fullWidth
                                    validate={[required()]}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DateInput
                                    source="bk0_dataal"
                                    label="Data Fine *"
                                    fullWidth
                                    validate={[required()]}
                                />
                            </Grid>
                        </Grid>

                        {/* Passeggeri */}
                        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>Passeggeri</Typography>
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6} md={3}>
                                <NumberInput
                                    source="bk0_paxad"
                                    label="Adulti"
                                    fullWidth
                                    min={0}
                                    defaultValue={0}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <NumberInput
                                    source="bk0_paxch"
                                    label="Bambini"
                                    fullWidth
                                    min={0}
                                    defaultValue={0}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <NumberInput
                                    source="bk0_paxin"
                                    label="Infanti"
                                    fullWidth
                                    min={0}
                                    defaultValue={0}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <NumberInput
                                    source="bk0_nroseg"
                                    label="Seggiolini"
                                    fullWidth
                                    min={0}
                                    defaultValue={0}
                                />
                            </Grid>
                        </Grid>

                        {/* Note */}
                        <Typography variant="subtitle1" sx={{ mt: 3, mb: 1 }}>Note</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextInput
                                    source="bk0_ntmemo"
                                    label="Note per Booking"
                                    multiline
                                    rows={2}
                                    fullWidth
                                    sx={{ mb: 2 }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    source="bk0_ntcliente"
                                    label="Nota per Cliente"
                                    multiline
                                    rows={2}
                                    fullWidth
                                    sx={{ mb: 2 }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    source="bk0_ntautista"
                                    label="Nota per Autista"
                                    multiline
                                    rows={2}
                                    fullWidth
                                    sx={{ mb: 2 }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    source="bk0_ntresp"
                                    label="Nota per Responsabile"
                                    multiline
                                    rows={2}
                                    fullWidth
                                    sx={{ mb: 2 }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    source="bk0_contatto"
                                    label="Nota di Contatto"
                                    multiline
                                    rows={2}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </SimpleForm>
                </Create>
            </DialogContent>
        </Dialog>
    );
};