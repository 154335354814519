// src/components/middleware/MiddlewareToFly.js
import React, { useState } from "react";
import {
    Box,
    Tab,
    Tabs,
    Typography,
    Paper
} from '@mui/material';
import { Title } from 'react-admin';
import WialonStatus from './WialonStatus';
import SoftwareAmicoImport from './SoftwareAmicoImport';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`middleware-tabpanel-${index}`}
            aria-labelledby={`middleware-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `middleware-tab-${index}`,
        'aria-controls': `middleware-tabpanel-${index}`,
    };
}

const MiddlewareToFly = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Title title="Integrazione software: CHECK & UPLOAD" />

            <Paper sx={{ width: '100%', mb: 2 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="middleware tabs"
                        sx={{
                            backgroundColor: 'background.paper',
                            '& .MuiTab-root': {
                                minHeight: 64,
                                fontSize: '1rem'
                            }
                        }}
                    >
                        <Tab
                            sx={{
                                '&.Mui-selected': {
                                    borderBottom: '3px solid #01b5d0',
                                    backgroundColor: '#e8e8e8'
                                }
                            }}
                            label={
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography variant="body1">Topfly (Wialon Service)</Typography>
                                </Box>
                            }
                            {...a11yProps(0)}
                        />
                        <Tab
                            sx={{
                                '&.Mui-selected': {
                                    borderBottom: '3px solid #01b5d0',
                                    backgroundColor: '#e8e8e8'
                                }
                            }}
                            label={
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography variant="body1">Software Amico (import file .dat)</Typography>
                                </Box>
                            }
                            {...a11yProps(1)}
                        />
                    </Tabs>
                </Box>

                <TabPanel value={value} index={0}>
                    <WialonStatus />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SoftwareAmicoImport />
                </TabPanel>
            </Paper>
        </Box>
    );
};

export default MiddlewareToFly;