import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Alert,
    CircularProgress
} from '@mui/material';
import axios from 'axios';
import config from '../../../config/config.json';

const host = config.production.host;

const EditBookingStatusDialog = ({
    open,
    onClose,
    currentStatus,
    bookingId,
    onUpdate
}) => {
    const [status, setStatus] = useState(currentStatus);
    const [statusOptions, setStatusOptions] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingOptions, setLoadingOptions] = useState(false);

    useEffect(() => {
        setStatus(currentStatus);
        setError(null);

        if (open) {
            setLoadingOptions(true);
            fetchStatusOptions();
        }
    }, [currentStatus, open]);

    const fetchStatusOptions = async () => {
        try {
            const jwt = localStorage.getItem("feathers-jwt");
            const response = await axios.get(
                `${host}/booking-0-status`,  // Assumo questo endpoint per gli status
                {
                    headers: { 'Authorization': `Bearer ${jwt}` }
                }
            );
            const sortedOptions = response.data.data.sort((a, b) =>
                a.descrizione.localeCompare(b.descrizione)
            );
            setStatusOptions(sortedOptions);
        } catch (err) {
            console.error('Errore nel recupero degli status:', err);
            setError('Errore nel caricamento degli status disponibili');
        } finally {
            setLoadingOptions(false);
        }
    };

    const handleSave = async () => {
        setLoading(true);
        setError(null);

        try {
            const jwt = localStorage.getItem("feathers-jwt");

            await axios.patch(
                `${host}/booking-0/${bookingId}`,
                { bk0_idstatus: parseInt(status, 10) },
                {
                    headers: {
                        'Authorization': `Bearer ${jwt}`
                    }
                }
            );

            onUpdate(status);
            onClose();
        } catch (err) {
            setError(err.message || 'Errore durante il salvataggio dello status');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                Modifica Status Booking
            </DialogTitle>
            <DialogContent>
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel>Status</InputLabel>
                    <Select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        label="Status"
                        disabled={loadingOptions || loading}
                    >
                        {loadingOptions ? (
                            <MenuItem value="">
                                <CircularProgress size={20} />
                            </MenuItem>
                        ) : (
                            statusOptions.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.descrizione}
                                </MenuItem>
                            ))
                        )}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" disabled={loading}>
                    Annulla
                </Button>
                <Button
                    onClick={handleSave}
                    color="primary"
                    variant="contained"
                    disabled={loading || loadingOptions}
                >
                    {loading ? 'Salvataggio...' : 'Salva'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditBookingStatusDialog;