import React, { useState, useCallback } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import axios from 'axios';
import { debounce } from 'lodash';
import config from '../../../config/config.json';

const host = config.production.host;


const SimpleAddressAutocomplete = ({ value, onChange, label = "Indirizzo" }) => {
    const [options, setOptions] = useState([]);
    const jwt = localStorage.getItem("feathers-jwt");

    // Funzione per recuperare i suggerimenti da Google Places
    const fetchSuggestions = useCallback(
        debounce(async (input) => {
            if (input.length < 3) {
                setOptions([]);
                return;
            }

            try {
                const response = await axios.get(
                    `${host}/google-maps-proxy`, // Assicurati di configurare il tuo endpoint corretto
                    {
                        params: {
                            path: '/place/autocomplete/json',
                            input: input,
                        },
                        headers: { 'Authorization': `Bearer ${jwt}` }
                    }
                );
                setOptions(response.data.predictions || []);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
                setOptions([]);
            }
        }, 300),
        []
    );

    return (
        <Autocomplete
            freeSolo
            options={options}
            getOptionLabel={(option) => option.description || ''}
            filterOptions={(x) => x}
            onInputChange={(event, newInputValue) => {
                onChange(newInputValue);
                fetchSuggestions(newInputValue);
            }}
            inputValue={value}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    onChange(newValue);
                } else if (newValue && newValue.description) {
                    onChange(newValue.description);
                } else {
                    onChange('');
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    placeholder="Inserisci un indirizzo"
                    variant="outlined"
                    fullWidth
                />
            )}
        />
    );
};

export default SimpleAddressAutocomplete;