import {
    AutistiEdit,
} from './autisti';

import { AutistiList } from './AutistiList';
import { AutistiCreate } from './AutistiCreate';
import { AutistiShow } from './AutistiShow';

export default {
    list: AutistiList,
    create: AutistiCreate,
    edit: AutistiEdit,
    show: AutistiShow,
};