import { TariffeaList } from './TarrifeaList';
import { TariffeaCreate } from './TariffeaCreate';
import { TariffeaEdit } from './TariffeaEdit';
import { TariffeaShow } from './TariffeaShow';

export default {
    list: TariffeaList,
    create: TariffeaCreate,
    edit: TariffeaEdit,
    show: TariffeaShow,
};