import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    CardHeader,
    Button,
    Divider
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CalcolaComponent from '../calcoli/CalcolaComponent';
import EditBookingStatusDialog from './EditBookingStatusDialog';
import { PreventivoEmailButton } from './InvioEmailPreventivoDialog';
import { getStatusInfo } from './bookingStatus';

const PreventivoStatusCard = ({ statusDescription, booking0Id, bk0_idnumber, bk0_iddata }) => {
    const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);
    const [localStatusDescription, setLocalStatusDescription] = useState(statusDescription);

    const handleStatusUpdate = (newStatus) => {
        setLocalStatusDescription(newStatus);
    };

    return (
        <Card
            style={{
                maxWidth: 640,
                margin: '24px auto',
                borderRadius: '12px',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
                overflow: 'hidden'
            }}
        >
            <CardHeader
                title="Status ed Azioni"
                style={{
                    background: 'linear-gradient(45deg, #1976d2 30%, #42a5f5 90%)',
                    color: '#fff',
                    textAlign: 'center',
                    padding: '16px',
                    fontWeight: 'bold',
                    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)'
                }}
            />
            <CardContent style={{ padding: '20px' }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '16px'
                }}>
                    <Typography variant="h6" style={{ fontWeight: 600 }}>
                        Status Preventivo: {getStatusInfo(localStatusDescription).descrizione}
                    </Typography>
                    <Button
                        startIcon={<EditIcon />}
                        onClick={() => setIsStatusDialogOpen(true)}
                        variant="contained"
                        size="small"
                        style={{
                            backgroundColor: '#1976d2',
                            color: '#fff',
                            fontWeight: 'bold'
                        }}
                    >
                        Modifica
                    </Button>
                </div>

                <Divider style={{ margin: '16px 0' }} />

                {/* Testo prima del calcolo */}
                <Typography variant="body2" style={{ marginBottom: '8px' }}>
                    Determina i costi finali per il preventivo
                </Typography>
                <CalcolaComponent booking0Id={booking0Id} />

                <Divider style={{ margin: '16px 0' }} />

                <div style={{
                    display: 'flex',
                    gap: '12px',
                    alignItems: 'flex-start'
                }}>
                    <EditBookingStatusDialog
                        open={isStatusDialogOpen}
                        onClose={() => setIsStatusDialogOpen(false)}
                        currentStatus={statusDescription}
                        bookingId={booking0Id}
                        onUpdate={handleStatusUpdate}
                    />

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <PreventivoEmailButton
                            record={{
                                id: booking0Id,
                                bk0_idnumber: bk0_idnumber,
                                importoTotale: 123456, // TODO: Da IMPOSTARE I COSTI CORRETTI
                                bk0_iddata: bk0_iddata
                            }}
                        />
                        {/* Testo in corrispondenza del pulsante email */}
                        <Typography variant="body2" style={{ marginTop: '8px' }}>
                            Invia mail al cliente al fine di richiedere una verifica ed approvazione del preventivo.
                        </Typography>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default PreventivoStatusCard;
