import React, { useState, useEffect } from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    FunctionField,
    ReferenceField,
    ShowButton,
    EditButton,
    useDataProvider,
    useNotify,
    useRefresh,
    TopToolbar,
    CreateButton,
} from "react-admin";
import { Dialog, DialogContent, DialogActions, Button, Select, MenuItem, Typography, Box, IconButton, Tabs, Tab } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AlarmIcon from "@mui/icons-material/Alarm";
import EmptyListCustomNoCreate from "../shared/emptyPageCustom/empryPageCustomNoCreate";
import { ScadenzeSearchBar } from "./ScadenzeSearchBar";
import { ScadenzeActiveFilters } from "./ScadenzeActiveFilters";
import { ScadenzeAdvancedFiltersDialog } from "./ScadenzeAdvancedFiltersDialog";
import { createButtonCss, editButtonCss, showButtonCss } from "../shared/Styles/buttonStyles";
import CountdownTimer from "./CountdownTimer";
import RecordValueField from "./RecordValueField";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

const ListActions = () => (
    <TopToolbar>
        <CreateButton label="Crea" sx={createButtonCss} />
    </TopToolbar>
);

export const ScadenzeListWithTabs = (props) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [tabLabels, setTabLabels] = useState([]);
    const [recordCounts, setRecordCounts] = useState([]);
    const [filters, setFilters] = useState({});
    const [openAdvanced, setOpenAdvanced] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    useEffect(() => {
        const fetchStates = async () => {
            try {
                const { data } = await dataProvider.getList("stato-scadenza", {
                    pagination: { page: 1, perPage: 100 },
                    sort: { field: "id", order: "ASC" },
                });
                setTabLabels(data);

                const counts = await Promise.all(
                    data.map(async (tab) => {
                        const { total } = await dataProvider.getList("scadenze", {
                            pagination: { page: 1, perPage: 1 },
                            filter: { statoScadenzaId: tab.id },
                        });
                        return total;
                    })
                );
                setRecordCounts(counts);
            } catch (error) {
                console.error("Errore durante il caricamento degli stati o conteggi:", error);
            }
        };
        fetchStates();
    }, [dataProvider]);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
        refresh();
    };

    const handleSearch = (searchValues) => {
        setFilters(searchValues);
        notify("Ricerca completata", { type: "success" });
    };

    const handleRemoveFilter = (key) => {
        const newFilters = { ...filters };
        delete newFilters[key];
        setFilters(newFilters);
        notify("Filtro rimosso", { type: "info" });
    };

    const handleReset = () => {
        setFilters({});
        notify("Filtri resettati", { type: "info" });
        refresh();
    };

    const handleOpenDialog = (record) => {
        setSelectedRecord(record);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedRecord(null);
    };

    const handleSaveDialog = async () => {
        try {
            await dataProvider.update("scadenze", {
                id: selectedRecord.id,
                data: { statoScadenzaId: selectedRecord.statoScadenzaId },
            });
            notify("Stato aggiornato con successo", { type: "success" });
            refresh();
            handleCloseDialog();
        } catch (error) {
            notify("Errore durante l'aggiornamento dello stato", { type: "error" });
        }
    };

    return (
        <Box>
            <Tabs
                value={currentTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                sx={{ borderBottom: 1, borderColor: "divider" }}
            >
                <Tab
                    key="all"
                    label={`Tutte (${recordCounts.reduce((acc, count) => acc + count, 0) || 0})`}
                    icon={<AlarmIcon />}
                    iconPosition="start"
                />
                {tabLabels.map((tab, index) => (
                    <Tab
                        key={tab.id}
                        label={`${tab.nome} (${recordCounts[index] || 0})`}
                        style={{ color: tab.colore || "#000" }}
                    />
                ))}
            </Tabs>

            <Box mt={2}>
                <ScadenzeSearchBar
                    onSearch={handleSearch}
                    onReset={handleReset}
                    onOpenAdvanced={() => setOpenAdvanced(true)}
                />
                <ScadenzeActiveFilters filters={filters} onRemove={handleRemoveFilter} />
            </Box>

            <ScadenzeAdvancedFiltersDialog
                open={openAdvanced}
                onClose={() => setOpenAdvanced(false)}
                onApply={(advancedFilters) => {
                    setFilters(advancedFilters);
                    notify("Filtri applicati con successo", { type: "success" });
                    setOpenAdvanced(false);
                }}
                onReset={handleReset}
                currentFilters={filters}
            />

            <Box mt={2}>
                <List
                    title={<><AlarmIcon /> Lista delle Scadenze</>}
                    actions={<ListActions />}
                    empty={<EmptyListCustomNoCreate titolo="Lista" />}
                    {...props}
                    filter={{
                        ...(currentTab === 0 ? {} : { statoScadenzaId: tabLabels[currentTab - 1]?.id }),
                        ...filters,
                    }}
                    sort={{ field: "data", order: "DESC" }}
                >
                    <Datagrid bulkActionButtons={false}>
                        <ReferenceField source="tipoScadenzaId" reference="tipo-scadenza" link={false}>
                            <TextField source="nome" label="Tipo" />
                        </ReferenceField>
                        <ReferenceField source="tiporecordId" reference="tiporecord" label="Archivio" link={false}>
                            <TextField source="descrizione" label="Tipo Record" />
                        </ReferenceField>
                        <FunctionField
                            label="Dato Associato"
                            render={(record) => <RecordValueField record={record} source="recordId" />}
                        />
                        <FunctionField
                            source="recordId"
                            label="Show Archivio"
                            render={(recordScadenze) =>
                                recordScadenze && (
                                    <>
                                        <ReferenceField
                                            source="tiporecordId"
                                            reference="tiporecord"
                                            link={false}
                                        >
                                            <FunctionField
                                                render={(record) => {
                                                    if (record && record.archivio && recordScadenze.recordId) {
                                                        const url = `/${record.archivio}/${recordScadenze.recordId}/show`;
                                                        return (
                                                            <Box display="flex" alignItems="center" gap={1}>
                                                                <IconButton
                                                                    component={Link}
                                                                    to={url}
                                                                    title="Visualizza Dettaglio"
                                                                    color="primary"
                                                                >
                                                                    <VisibilityIcon />
                                                                </IconButton>
                                                            </Box>
                                                        );
                                                    }
                                                    return "Dati non disponibili";
                                                }}
                                            />
                                        </ReferenceField>
                                    </>
                                )
                            }
                        />
                        <TextField source="note" label="Descrizione" />
                        <DateField source="data" label="Data Scadenza" showTime />
                        {/* <FunctionField
                            label="Tempo Rimanente"
                            render={(record) => {
                                const today = new Date();
                                const dueDate = new Date(record.data);
                                const diffTime = dueDate - today;
                                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                return diffDays >= 0
                                    ? `${diffDays} giorni rimanenti`
                                    : `Scaduto da ${Math.abs(diffDays)} giorni`;
                            }}
                        /> */}
                        <FunctionField
                            label="Conto alla Rovescia / Stato"
                            render={(record) => <CountdownTimer record={record} />}
                        />
                        <FunctionField
                            label="Cambia Stato"
                            render={(record) => (
                                <Box display="flex" alignItems="center">
                                    <ReferenceField
                                        source="statoScadenzaId"
                                        reference="stato-scadenza"
                                        link={false}
                                    >
                                        <TextField source="nome" label="Stato" />
                                    </ReferenceField>
                                    <IconButton size="small" onClick={() => handleOpenDialog(record)}>
                                        <EditIcon />
                                    </IconButton>
                                </Box>
                            )}
                        />
                        <ReferenceField source="userId" reference="users" link={false}>
                            <TextField source="nome" label="Assegnato a" />
                        </ReferenceField>
                        <ShowButton sx={showButtonCss} label="Dettaglio" />
                        <EditButton sx={editButtonCss} label="Modifica" />
                    </Datagrid>
                </List>
            </Box>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="h6">Cambia Stato</Typography>
                    <Select
                        fullWidth
                        value={selectedRecord?.statoScadenzaId || ""}
                        onChange={(e) =>
                            setSelectedRecord((prev) => ({
                                ...prev,
                                statoScadenzaId: e.target.value,
                            }))
                        }
                    >
                        {tabLabels.map((tab) => (
                            <MenuItem key={tab.id} value={tab.id}>
                                {tab.nome}
                            </MenuItem>
                        ))}
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary">
                        Annulla
                    </Button>
                    <Button onClick={handleSaveDialog} color="primary">
                        Salva
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
