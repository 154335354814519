// GetListTabelleb.js
import React, { useState } from 'react';
import {
    ReferenceManyField,
    TextField,
    FunctionField,
    useRecordContext,
    useUpdate,
    useNotify,
    useRefresh,
    Datagrid,
    NumberField
} from 'react-admin';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { TariffeBEditDialog } from './TariffeBEditDialog';
import Servpax_simulator from './servpax-simulator';
import Simulatore from './simulatore/simulatore';

export const GetListTabelleb = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const recordTariffea = useRecordContext();
    const [update] = useUpdate();
    const notify = useNotify();
    const refresh = useRefresh();

    if (!recordTariffea) return null;

    const handleEdit = (record) => {
        setSelectedRecord(record);
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
        setSelectedRecord(null);
    };

    const handleSave = async (values) => {
        try {
            await update(
                'tariffe-b',
                {
                    id: values.id,
                    data: values,
                },
                {
                    mutationMode: 'pessimistic'
                }
            );
            notify('Tariffa aggiornata con successo', { type: 'success' });
            refresh();
            return true;
        } catch (error) {
            console.error('Errore durante il salvataggio:', error);
            notify(error.message || 'Errore durante il salvataggio', { type: 'error' });
            throw error;
        }
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
        }).format(value);
    };

    const formatPercentage = (value) => {
        return new Intl.NumberFormat('it-IT', {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value / 100);
    };

    return (
        <>
            <ReferenceManyField
                reference="tariffe-b"
                target="tfa_id"
                sort={{ field: 'tfb_gruppo', order: 'ASC' }}
            >
                <Datagrid bulkActionButtons={false}>
                    <TextField
                        source="tfb_gruppo"
                        label="Gruppo"
                    />
                    <TextField
                        source="tfb_des"
                        label="Descrizione"
                    />
                    <FunctionField
                        label="Costo Km di Base"
                        render={record => formatCurrency(record.tfb_ckm)}
                    />
                    <FunctionField
                        label="Costo Extra Km"
                        render={record => formatCurrency(record.tfb_ekm)}
                    />
                    <FunctionField
                        label="Costo Minuto"
                        render={record => formatCurrency(record.tfb_chh)}
                    />
                    <FunctionField
                        label="Costo Extra Min"
                        render={record => formatCurrency(record.tfb_ehh)}
                    />
                    <FunctionField
                        label="Suppl. Notturno"
                        render={record => formatPercentage(record.tfb_snotturno)}
                    />
                    <FunctionField
                        label="PAX A"
                        render={record => formatCurrency(record.tfb_cpaxa)}
                    />
                    <FunctionField
                        label="PAX C"
                        render={record => formatCurrency(record.tfb_cpaxc)}
                    />
                    <FunctionField
                        label="PAX I"
                        render={record => formatCurrency(record.tfb_cpaxi)}
                    />
                    <NumberField
                        source="tfb_npaxmin"
                        label="Min PAX"
                    />
                    <NumberField
                        source="tfb_vkm"
                        label="Km Vuoto"
                    />
                    <NumberField
                        source="tfb_vhh"
                        label="Min Vuoto"
                    />
                    <FunctionField
                        label="Extra Km Vuoto"
                        render={record => formatCurrency(record.tfb_evkm)}
                    />
                    <FunctionField
                        label="% Km Vuoto"
                        render={record => formatPercentage(record.tfb_vkmperc)}
                    />
                    <FunctionField
                        label="% Min Vuoto"
                        render={record => formatPercentage(record.tfb_vhhperc)}
                    />
                    <FunctionField
                        label="ServPax"
                        render={record => (
                            <Servpax_simulator
                                record={record}
                            />
                        )}
                    />
                    <FunctionField
                        label="Simulatore"
                        render={record => (
                            <Simulatore
                                record={{
                                    ...record,
                                    tfa_bdgkm: recordTariffea.tfa_bdgkm,
                                    tfa_bdghh: recordTariffea.tfa_bdghh,
                                    tfa_frgmmec: recordTariffea.tfa_frgmmec,
                                    tfa_frgmmnt: recordTariffea.tfa_frgmmnt
                                }}
                            />
                        )}
                    />
                    <FunctionField
                        label="Azioni"
                        render={record => (
                            <IconButton
                                onClick={() => handleEdit(record)}
                                color="primary"
                                size="small"
                                title="Modifica tariffa"
                            >
                                <EditIcon />
                            </IconButton>
                        )}
                    />
                </Datagrid>
            </ReferenceManyField>

            <TariffeBEditDialog
                open={openDialog}
                onClose={handleClose}
                record={selectedRecord}
                save={handleSave}
            />
        </>
    );
};

export default GetListTabelleb;