import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Alert,
    CircularProgress
} from '@mui/material';
import axios from 'axios';
import config from '../../../config/config.json';

const host = config.production.host;

const EditFieldDialog = ({
    open,
    onClose,
    fieldName,
    currentValue,
    bookingHId,
    onUpdate,
    fieldType,
    label
}) => {
    const [value, setValue] = useState(currentValue);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [loadingOptions, setLoadingOptions] = useState(false);

    useEffect(() => {
        setValue(currentValue);
        setError(null);

        if (fieldType === 'payment' && open) {
            setLoadingOptions(true);
            fetchPaymentOptions();
        }
    }, [currentValue, open, fieldType]);

    const fetchPaymentOptions = async () => {
        try {
            const jwt = localStorage.getItem("feathers-jwt");
            const response = await axios.get(
                `${host}/view-tabellaalfa-pag`,
                {
                    headers: { 'Authorization': `Bearer ${jwt}` }
                }
            );
            const sortedOptions = response.data.data.sort((a, b) =>
                a.descrizione.localeCompare(b.descrizione)
            );
            setPaymentOptions(sortedOptions);
        } catch (err) {
            console.error('Errore nel recupero delle opzioni di pagamento:', err);
            setError('Errore nel caricamento delle opzioni di pagamento');
        } finally {
            setLoadingOptions(false);
        }
    };

    const handleSave = async () => {
        setLoading(true);
        setError(null);

        try {
            const jwt = localStorage.getItem("feathers-jwt");
            let valueToSave = value;

            // Gestione dei diversi tipi di valore
            if (fieldType === 'number') {
                valueToSave = Number(value);
            } else if (fieldType === 'boolean') {
                valueToSave = parseInt(value, 10); // Assicuriamoci che sia un numero
            }

            const updateData = {
                [fieldName]: valueToSave
            };

            await axios.patch(
                `${host}/booking-h/${bookingHId}`,
                updateData,
                {
                    headers: {
                        'Authorization': `Bearer ${jwt}`
                    }
                }
            );

            onUpdate(valueToSave);
            onClose();
        } catch (err) {
            setError(err.message || 'Errore durante il salvataggio');
        } finally {
            setLoading(false);
        }
    };

    const handleNumberChange = (e) => {
        const newValue = e.target.value;
        if (newValue === '' || !isNaN(newValue)) {
            setValue(newValue);
        }
    };

    const renderField = () => {
        switch (fieldType) {
            case 'documentType':
                return (
                    <FormControl fullWidth>
                        <InputLabel>Tipo Documento</InputLabel>
                        <Select
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            label="Tipo Documento"
                        >
                            <MenuItem value="F">Fattura</MenuItem>
                            <MenuItem value="R">Ricevuta</MenuItem>
                        </Select>
                    </FormControl>
                );

            case 'payment':
                return (
                    <FormControl fullWidth>
                        <InputLabel>Modalità Pagamento</InputLabel>
                        <Select
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            label="Modalità Pagamento"
                            disabled={loadingOptions}
                        >
                            {loadingOptions ? (
                                <MenuItem value="">
                                    <CircularProgress size={20} />
                                </MenuItem>
                            ) : (
                                paymentOptions.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.descrizione}
                                    </MenuItem>
                                ))
                            )}
                        </Select>
                    </FormControl>
                );

            case 'boolean':
                return (
                    <FormControl fullWidth>
                        <InputLabel>{label || fieldName}</InputLabel>
                        <Select
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            label={label || fieldName}
                        >
                            <MenuItem value={1}>Si</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </Select>
                    </FormControl>
                );

            case 'number':
                return (
                    <TextField
                        fullWidth
                        value={value}
                        onChange={handleNumberChange}
                        label={label || fieldName}
                        variant="outlined"
                        type="number"
                        inputProps={{
                            step: "any"
                        }}
                    />
                );

            case 'text':
                return (
                    <TextField
                        fullWidth
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        label={label || fieldName}
                        variant="outlined"
                        multiline
                        rows={4}
                    />
                );

            default:
                return (
                    <TextField
                        fullWidth
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        label={label || fieldName}
                        variant="outlined"
                    />
                );
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                Modifica {label} - <strong>{fieldName}</strong>
            </DialogTitle>
            <DialogContent>
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}
                {renderField()}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" disabled={loading}>
                    Annulla
                </Button>
                <Button
                    onClick={handleSave}
                    color="primary"
                    variant="contained"
                    disabled={loading || loadingOptions}
                >
                    {loading ? 'Salvataggio...' : 'Salva'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditFieldDialog;