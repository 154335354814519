import {
    useRecordContext,
    ReferenceField,
    TextField,
    FunctionField,
    TextInput
} from 'react-admin';
import {
    Box,
    Typography,
    Avatar,
    Button,
    Paper,
    Chip
} from '@mui/material';
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import CustomAutocompleInputUsers from '../shared/FormFields/CustomAutocompleInputUsers';
import { showButtonCss } from '../shared/Styles/buttonStyles';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';

const NavigateButton = ({ recordId, service, message }) => {
    const handleNavigate = () => {
        const url = `#/${service}/${recordId}/show`;
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <Button
            size="small"
            variant="outlined"
            sx={{
                ...showButtonCss,
                minWidth: 'auto',
                py: 0.5,
                borderRadius: 2
            }}
            onClick={handleNavigate}
        >
            {message}
        </Button>
    );
};

const UsersShowDisponibiliRowForm = ({ id, ...props }) => (
    <RowForm {...props}>
        <TextInput
            source="gruppiId"
            defaultValue={parseInt(id)}
            disabled
        />
        <CustomAutocompleInputUsers
            isFiltered={true}
            filterField="roles"
            filterValue="gruppi"
            source="usersId"
            reference="users"
            optionProperty="email"
            label=""
        />
    </RowForm>
);

const UserInfo = () => {
    const record = useRecordContext();

    const getInitials = (nome, cognome) => {
        return `${nome?.charAt(0) || ''}${cognome?.charAt(0) || ''}`.toUpperCase();
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                width: '100%',
                p: 1.5,
                transition: 'background-color 0.2s',
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                }
            }}
        >
            <Avatar
                sx={{
                    width: 45,
                    height: 45,
                    bgcolor: 'primary.main',
                    fontSize: '1.2rem',
                    fontWeight: 500
                }}
            >
                {getInitials(record.nome, record.cognome)}
            </Avatar>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
                flex: '0 0 80px'
            }}>
                <Chip
                    size="small"
                    label={<TextField source="id" />}
                    variant="outlined"
                    sx={{ width: 'fit-content' }}
                />
            </Box>

            <Box sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                }}>
                    <EmailIcon sx={{ fontSize: 18, color: 'primary.main' }} />
                    <Typography variant="body2">
                        <TextField source="email" />
                    </Typography>
                </Box>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                }}>
                    <PersonIcon sx={{ fontSize: 18, color: 'primary.main' }} />
                    <Typography variant="body2" color="textSecondary">
                        <FunctionField
                            source=""
                            render={record => record && `${record.nome || ''} ${record.cognome || ''}`}
                        />
                    </Typography>
                </Box>
            </Box>

            <Box sx={{
                flex: '0 0 auto',
                display: 'flex',
                alignItems: 'center'
            }}>
                <FunctionField
                    source="usersId"
                    render={record => (
                        <NavigateButton
                            recordId={record.id}
                            service="users"
                            message="Dettagli"
                        />
                    )}
                />
            </Box>
        </Box>
    );
};

const GestioneUsersInShow = ({ id, ...props }) => {
    const record = useRecordContext();
    if (!record) return null;

    return (
        <EditableDatagrid
            bulkActionButtons={false}
            editForm={<UsersShowDisponibiliRowForm />}
            createForm={<UsersShowDisponibiliRowForm id={record.id} />}
        >
            <Paper
                elevation={0}
                sx={{
                    mb: 1,
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 2,
                    overflow: 'hidden',
                    '&:hover': {
                        borderColor: 'primary.main',
                        boxShadow: '0 0 0 1px rgba(1, 181, 208, 0.1)'
                    }
                }}
            >
                <ReferenceField
                    source="usersId"
                    reference="users"
                    link={false}
                >
                    <UserInfo />
                </ReferenceField>
            </Paper>
        </EditableDatagrid>
    );
};

export default GestioneUsersInShow;