import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { Card, CardContent, Typography, CircularProgress, Box } from '@mui/material';
import Alert from '@mui/material/Alert';
import config from '../../config/config.json';

const AutomezziStatisticsChart = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const jwt = localStorage.getItem("feathers-jwt");

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                // Prima chiamata per ottenere il numero totale di record
                const initialResponse = await fetch(`${config.production.host}/view-rifornimenti-automezzi`, {
                    headers: {
                        'Authorization': `Bearer ${jwt}`,
                    },
                });

                if (!initialResponse.ok) {
                    throw new Error('Errore nel caricamento dei dati');
                }

                const initialData = await initialResponse.json();
                const totalRecords = initialData.total;

                // Seconda chiamata per ottenere tutti i record
                const fullResponse = await fetch(
                    `${config.production.host}/view-rifornimenti-automezzi?$limit=${totalRecords}`, {
                    headers: {
                        'Authorization': `Bearer ${jwt}`,
                    },
                });

                if (!fullResponse.ok) {
                    throw new Error('Errore nel caricamento dei dati completi');
                }

                const fullData = await fullResponse.json();
                setData(fullData.data.filter(item => item.targa !== "")); // Filtra gli automezzi senza targa
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchAllData();
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight={400}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error" sx={{ margin: 2 }}>
                Errore: {error}
            </Alert>
        );
    }

    // Preparazione dati per i grafici
    const targhe = data.map(item => item.targa);
    const numRifornimenti = data.map(item => item.numRifornimenti);
    const totLitri = data.map(item => item.totLitriErogati);
    const mediaKm = data.map(item => item.mediaKm);
    const deltaKm = data.map(item => item.deltaKm);

    // Configurazione comune per i grafici
    const commonLayout = {
        barmode: 'group',
        xaxis: {
            tickangle: -45,
            automargin: true,
            tickfont: {
                size: 10
            }
        },
        margin: {
            b: 100,
            t: 50,
            r: 100, // Aumentato per accomodare i valori sull'asse y destro
            l: 100  // Aumentato per accomodare i valori sull'asse y sinistro
        },
        showlegend: true,
        legend: {
            orientation: 'h',
            y: -0.2
        }
    };

    return (
        <Card sx={{ margin: 2, mt: 4 }}>
            <CardContent>
                <Typography variant="h6" gutterBottom component="div">
                    Statistiche Comparate Automezzi
                </Typography>

                {/* Primo grafico - Rifornimenti e Consumi */}
                <Box sx={{ width: '100%', height: '500px' }}>
                    <Plot
                        data={[
                            {
                                type: 'bar',
                                x: targhe,
                                y: numRifornimenti,
                                name: 'Numero Rifornimenti',
                                marker: { color: '#1976d2' }
                            },
                            {
                                type: 'bar',
                                x: targhe,
                                y: totLitri,
                                name: 'Totale Litri',
                                marker: { color: '#2e7d32' }
                            },
                            {
                                type: 'bar',
                                x: targhe,
                                y: mediaKm,
                                name: 'Media Km/L',
                                marker: { color: '#ed6c02' },
                                yaxis: 'y2'
                            }
                        ]}
                        layout={{
                            ...commonLayout,
                            title: 'Rifornimenti e Consumi',
                            yaxis: {
                                title: 'Numero/Litri',
                                side: 'left',
                                tickformat: ',.0f'
                            },
                            yaxis2: {
                                title: 'Media Km/L',
                                overlaying: 'y',
                                side: 'right',
                                tickformat: ',.1f'
                            },
                            height: 450
                        }}
                        config={{
                            responsive: true,
                            displayModeBar: true,
                            displaylogo: false,
                            modeBarButtonsToRemove: ['lasso2d', 'select2d']
                        }}
                        style={{ width: '100%', height: '100%' }}
                    />
                </Box>

                {/* Secondo grafico - Chilometri Percorsi */}
                <Box sx={{ width: '100%', height: '500px', mt: 4 }}>
                    <Typography variant="h6" gutterBottom component="div">
                        Chilometri Percorsi per Automezzo
                    </Typography>
                    <Plot
                        data={[
                            {
                                type: 'bar',
                                x: targhe,
                                y: deltaKm,
                                name: 'Km Percorsi',
                                marker: { color: '#d32f2f' },
                                text: deltaKm.map(val => val.toLocaleString()),
                                textposition: 'auto'
                            }
                        ]}
                        layout={{
                            ...commonLayout,
                            yaxis: {
                                title: 'Chilometri',
                                tickformat: ',.0f'
                            },
                            height: 450
                        }}
                        config={{
                            responsive: true,
                            displayModeBar: true,
                            displaylogo: false,
                            modeBarButtonsToRemove: ['lasso2d', 'select2d']
                        }}
                        style={{ width: '100%', height: '100%' }}
                    />
                </Box>
            </CardContent>
        </Card>
    );
};

export default AutomezziStatisticsChart;