import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Alert,
    CardHeader,
    Grid,
    IconButton,
    Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import config from '../../../config/config.json';
import { fetchCustomServiceHelp } from '../../../utils/helpers/fetchCustomServiceHelp';
import EditFieldDialog from '../utils/EditFieldDialog';
import formatDateForDisplay from '../../shared/CustomFields/LocalDateField';
import InfoIcon from '@mui/icons-material/Info';

const host = config.production.host;

const BookingHDetails = ({ bookingId }) => {
    // Stati per i dati del booking
    const [bookingData, setBookingData] = useState(null);
    const [bookingLoading, setBookingLoading] = useState(true);
    const [bookingError, setBookingError] = useState(null);

    // Stati per i dati di tabellealfa e servizi
    const [gruppoData, setGruppoData] = useState(null);
    const [pagData, setPagData] = useState(null);
    const [serviziData, setServiziData] = useState(null);
    const [usersData, setUsersData] = useState(null);

    // Stati di caricamento
    const [gruppoLoading, setGruppoLoading] = useState(true);
    const [pagLoading, setPagLoading] = useState(true);
    const [serviziLoading, setServiziLoading] = useState(true);
    const [usersLoading, setUsersLoading] = useState(true);

    // Stati di errore
    const [gruppoError, setGruppoError] = useState(null);
    const [pagError, setPagError] = useState(null);
    const [serviziError, setServiziError] = useState(null);
    const [usersError, setUsersError] = useState(null);

    // Stato per il dialog di modifica
    const [editDialog, setEditDialog] = useState({
        label: '',
        open: false,
        fieldName: '',
        currentValue: '',
        fieldType: '',
        paymentOptions: []
    });

    // Effetto per recuperare i dati del booking
    useEffect(() => {
        if (!bookingId) return;

        const fetchBooking = async () => {
            try {
                const jwt = localStorage.getItem("feathers-jwt");
                const response = await axios.get(
                    `${host}/booking-h?booking0Id=${bookingId}`,
                    { headers: { 'Authorization': `Bearer ${jwt}` } }
                );
                const booking = response.data.data?.[0] || null;
                setBookingData(booking);
            } catch (err) {
                setBookingError(err);
                console.error('Errore nel recupero dei dati di booking:', err);
            } finally {
                setBookingLoading(false);
            }
        };

        fetchBooking();
    }, [bookingId]);

    // Effetto per recuperare i dati di tabellealfa per bkh_gruppo
    useEffect(() => {
        const fetchGruppo = async () => {
            if (!bookingData || !bookingData.bkh_gruppo) {
                setGruppoLoading(false);
                return;
            }

            try {
                const data = await fetchCustomServiceHelp(bookingData.bkh_gruppo, "tabellealfa");
                setGruppoData(data);
            } catch (err) {
                setGruppoError(err);
            } finally {
                setGruppoLoading(false);
            }
        };

        fetchGruppo();
    }, [bookingData]);

    // Effetto per recuperare i dati di tabellealfa per bkh_pag
    useEffect(() => {
        const fetchPag = async () => {
            if (!bookingData || !bookingData.bkh_pag) {
                setPagLoading(false);
                return;
            }

            try {
                const data = await fetchCustomServiceHelp(bookingData.bkh_pag, "tabellealfa");
                setPagData(data);
            } catch (err) {
                setPagError(err);
            } finally {
                setPagLoading(false);
            }
        };

        fetchPag();
    }, [bookingData]);

    // Effetto per recuperare i dati di servizi per bkh_servizio
    useEffect(() => {
        const fetchServizi = async () => {
            if (!bookingData || !bookingData.bkh_servizio) {
                setServiziLoading(false);
                return;
            }

            try {
                const data = await fetchCustomServiceHelp(bookingData.bkh_servizio, "servizi");
                setServiziData(data);
            } catch (err) {
                setServiziError(err);
            } finally {
                setServiziLoading(false);
            }
        };

        fetchServizi();
    }, [bookingData]);

    // Effetto per recuperare i dati di users per bkh_redattoreId
    useEffect(() => {
        const fetchUsers = async () => {
            if (!bookingData || !bookingData.bkh_redattoreId) {
                setUsersLoading(false);
                return;
            }

            try {
                const data = await fetchCustomServiceHelp(bookingData.bkh_redattoreId, "users");
                setUsersData(data);
            } catch (err) {
                setUsersError(err);
            } finally {
                setUsersLoading(false);
            }
        };

        fetchUsers();
    }, [bookingData]);

    // Funzione per aprire il dialog di modifica
    const handleEdit = (label, fieldName, currentValue, fieldType = 'text') => {
        setEditDialog({
            label,
            open: true,
            fieldName,
            currentValue,
            fieldType,
            paymentOptions: fieldType === 'payment' ? pagData : []
        });
    };

    // Funzione per gestire l'aggiornamento dopo la modifica
    const handleUpdate = (newValue) => {
        setBookingData(prev => ({
            ...prev,
            [editDialog.fieldName]: newValue
        }));
    };

    // Stato complessivo di caricamento ed errore
    const isLoading = bookingLoading || gruppoLoading || pagLoading || serviziLoading || usersLoading;
    const hasError = bookingError || gruppoError || pagError || serviziError || usersError;

    if (isLoading) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '400px',
            }}>
                <CircularProgress />
            </div>
        );
    }

    if (hasError) {
        return (
            <Alert severity="error" style={{ margin: '20px' }}>
                <span>
                    Errore nel caricamento dei dati:{' '}
                    {bookingError?.message ||
                        gruppoError?.message ||
                        pagError?.message ||
                        serviziError?.message ||
                        usersError?.message}
                </span>
            </Alert>
        );
    }

    if (!bookingData) {
        return (
            <Alert severity="warning" style={{ margin: '20px' }}>
                <span>Nessun dato disponibile per questo booking</span>
            </Alert>
        );
    }

    return (
        <Card
            style={{
                maxWidth: '1200px',
                margin: '40px auto',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: '10px',
            }}
        >
            <CardHeader
                title={
                    <Typography variant="h5" component="div">
                        Dettagli Preventivo #{bookingData.bkh_idnumber}
                    </Typography>
                }
                style={{
                    backgroundColor: '#1976d2',
                    color: '#fff',
                    textAlign: 'center',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                }}
            />
            <CardContent>
                <Grid container spacing={3}>
                    {/* Prima Colonna */}
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1" style={{ marginBottom: '10px' }}>
                            <span style={{ fontWeight: 'bold' }}>Booking-h ID:</span>{' '}
                            <Tooltip title={`Booking-h ID: ${bookingData.id}`} arrow>
                                <IconButton size="small" aria-label="Mostra Booking-h ID">
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </Typography>

                        <Typography variant="subtitle1" style={{ marginBottom: '10px' }}>
                            <span style={{ fontWeight: 'bold' }}>Data:</span>{' '}
                            {formatDateForDisplay(bookingData.bkh_data)}
                        </Typography>
                    </Grid>

                    {/* Seconda Colonna */}
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1" style={{ marginBottom: '10px' }}>
                            <span style={{ fontWeight: 'bold' }}>Importo Base:</span>{' '}
                            {bookingData?.bkh_importobase != null
                                ? `€${bookingData.bkh_importobase.toFixed(2)}`
                                : ''
                            }
                        </Typography>


                        <Typography variant="subtitle1" style={{ marginBottom: '10px' }}>
                            <span style={{ fontWeight: 'bold' }}>Importo Notturno:</span>{' '}

                            {bookingData?.bkh_importonotturno != null
                                ? `€${bookingData.bkh_importonotturno.toFixed(2)}`
                                : ''
                            }
                        </Typography>

                        <Typography variant="subtitle1" style={{ marginBottom: '10px' }}>
                            <span style={{ fontWeight: 'bold' }}>Sconto:</span>{' '}
                            {bookingData.bkh_sconto}
                            {bookingData.bkh_scontobit_i === 0 ? '%' : '€'}
                        </Typography>

                        <Typography variant="subtitle1" style={{ marginBottom: '10px' }}>
                            <span style={{ fontWeight: 'bold' }}>Totale IVA:</span>{' '}
                            {bookingData?.bkh_totaleivato != null
                                ? `€${bookingData.bkh_totaleivato.toFixed(2)}`
                                : ''
                            }
                        </Typography>

                    </Grid>
                </Grid>

                {/* Dialog di modifica */}
                <EditFieldDialog
                    label={editDialog.label}
                    open={editDialog.open}
                    onClose={() => setEditDialog({ ...editDialog, open: false })}
                    fieldName={editDialog.fieldName}
                    currentValue={editDialog.currentValue}
                    bookingHId={bookingData.id}
                    onUpdate={handleUpdate}
                    fieldType={editDialog.fieldType}
                    paymentOptions={editDialog.paymentOptions}
                />
            </CardContent>
        </Card>
    );
};

export default BookingHDetails;