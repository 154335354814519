import * as React from "react";
import {
    List,
    TextField,
    Edit,
    Create,
    SimpleForm,
    required,
    SimpleShowLayout,
    Show,
    localStorageStore,
    EditButton,
    ShowButton,
    DatagridConfigurable,
    SelectColumnsButton,
    TopToolbar,
    Toolbar,
    ExportButton,
    CreateButton,
    TabbedForm,
    SaveButton,
    BooleanInput,
    SavedQueriesList,
    FilterList,
    FilterListItem,
    useRefresh,
} from 'react-admin';

// import { useState, useEffect, Fragment } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import Timeline from '@mui/lab/Timeline';
// import TimelineItem from '@mui/lab/TimelineItem';
// import TimelineSeparator from '@mui/lab/TimelineSeparator';
// import TimelineConnector from '@mui/lab/TimelineConnector';
// import TimelineContent from '@mui/lab/TimelineContent';
// import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
/* import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat'; */
import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import { Tabs, Tab, Divider } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { CardActionArea, CardActions, CardHeader } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { green } from '@mui/material/colors';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";

// import axiosInstance from "../../utils/axios"; // TODO: Necessario per l'upload
// Gestione lista note
import GestioneNote from "../note/GestioneNote";

// Importo il css per i Bottoni
import { editButtonCss, saveButtonCss, showButtonCss, exportButtonCss, columnsButtonCss, filterButtonCss } from '../shared/Styles/buttonStyles';
import { listCss } from '../shared/Styles/listStyles';
import { formCss, formCssTextInput, formCssDropdownMenu } from "../shared/Styles/formCss";
import { ToBoolean } from '../shared/ToBoolean';

import Box from '@mui/material/Box';
import PeopleIcon from '@mui/icons-material/People';

// Importo componenti input custom
import CustomTextInput from '../shared/FormFields/CustomTextInput';
import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";
import CustomTextFieldDataOra from "../shared/FormFields/CustomTextFieldDataOra";
import CustomNumberInput from '../shared/FormFields/CustomNumberInput';
// Necessari per il form
import { /* Container, Row, Col, Form, */ ProgressBar /* , Button */ } from "react-bootstrap";
import MySimpleMap from "../shared/OpenStreet/MySimpleMap";
import { useHasPermission } from "../../utils/helpers/hasPermissionHelp";

/* CSS - BASE - FORM - START */
const styles = {

    TextInput: {
        // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        // background: 'linear-gradient(45deg, #01b5d0 10%, #ffffff 90%)',
        border: 0,
        borderRadius: 1,
        // boxShadow: '0px 0px 3px 2px #01b5d0',
        color: 'green',
        height: 50,
        padding: '0 0px',
    },
};

const useStyles = makeStyles(styles);

// Init store
const storeGenerale = localStorageStore();

// Validazione
const validateOperatoreCreation = (values) => {
    const errors = {};
    if (!values.nome) {
        errors.nome = 'Il nome è necessario';
    }
    if (!values.cognome) {
        errors.cognome = 'Il cognome è necessario';
    }
    /* if (!values.age) {
        // You can return translation keys
        errors.age = 'ra.validation.required';
    } else if (values.age < 18) {
        // Or an object if the translation messages need parameters
        errors.age = {
            message: 'ra.validation.minValue',
            args: { min: 18 }
        };
    } */
    return errors
};

const ListActions = () => (
    <TopToolbar>
        <CreateButton label="Crea" sx={exportButtonCss} />
        <ExportButton label="Esporta" sx={exportButtonCss} />
        {/* <ExportButton sx={columnsButtonCss} /> */}
        <SelectColumnsButton sx={exportButtonCss} />
    </TopToolbar>
);

/* FILTRI */
const AutistiFilters = [
    <CustomNumberInput source="id" label="id" alwaysOn ></CustomNumberInput>,
    <CustomTextInput source="nominativo" label="Nominativo" alwaysOn ></CustomTextInput>,

];


/* BLOCCO FILTRI LATERALI */
const AutistiFilterSidebar = () => (
    <Card sx={{ order: -1, mr: 2, mt: 9, width: 200 }}>
        <CardContent>
            <SavedQueriesList label="Salva Filtro" icon={<AssignmentIcon />} />
            <FilterList label="Stato record" icon={<AssignmentIcon />}>
                <FilterListItem label="Attivi" value={{ flAttivo: 1 }} />
                <FilterListItem label="Visibili" value={{ fiVisibile: 1 }} />
                <FilterListItem label="Annullati" value={{ flAnnullato: 1 }} />
            </FilterList>
            {/*<FilterList label="Altro" icon={<AssignmentIcon />}>
                ...
            </FilterList> */}
        </CardContent>
    </Card>
);

export const AutistiList = props => {

    const sort = { field: 'id', order: 'DESC' };

    return (
        <List title={<>
            <Button>
                <AirlineSeatReclineNormalIcon></AirlineSeatReclineNormalIcon>
                &nbsp;Anagrafiche Autisti
            </Button>
        </>}
            sx={listCss}
            perPage={50}
            actions={<ListActions />}
            filters={AutistiFilters}
            aside={<AutistiFilterSidebar />}
            sort={sort}
        >
            {/* <Datagrid> */}
            <DatagridConfigurable
                omit={['note'/* , 'indirizzo', 'città', 'cap', 'prov' */]}
                bulkActionButtons={false}
                filterDefaultValues={{ id: { $ne: 0 } }}
            >

                {<TextField label="id" source="id" />}
                {<TextField label="Nominativo" source="nominativo" />}
                {<TextField label="Indirizzo" source="indirizzo" />}
                {<TextField label="Città" source="città" />}
                {<TextField label="Cap" source="cap" />}
                {<TextField label="Prov" source="prov" />}
                {<TextField label="Telefono" source="telefono" />}
                {<TextField label="Cellulare" source="cellulare" />}
                {<TextField label="Tel. Aziendale" source="telaziendale" />}
                {<TextField label="Email" source="email" />}
                {<TextField label="PEC" source="pec" />}
                {<TextField label="Patente" source="patente" />}
                {<ToBoolean label="Attivo" source="flAttivo"></ToBoolean>}
                {<ToBoolean label="Visibile" source="fiVisibile"></ToBoolean>}
                {<ToBoolean label="Annullato" source="flAnnullato"></ToBoolean>}
                {<EditButton sx={editButtonCss} label="Modifica" />}
                {<ShowButton sx={showButtonCss} label="Dettaglio" />}
            </DatagridConfigurable>
        </List >
    );
}

const AutistiListForm = (props) => (
    <RowForm {...props}>
        {< TextField label="Autista Id" source="id" validate={[required()]} />}
        {<TextField label="Nominativo" source="nominativo" validate={[required()]} />}
        {<TextField label="Indirizzo" source="indirizzo" validate={[required()]} />}
        {<TextField label="Città" source="città" validate={[required()]} />}
        {<TextField label="Cap" source="cap" />}
        {<TextField label="Prov" source="prov" />}
        {<TextField label="Telefono" source="telefono" />}
        {<TextField label="Cellulare" source="cellulare" />}
        {<TextField label="fax" source="fax" />}
        {<TextField label="Tel. Aziendale" source="telaziendale" />}
        {<TextField label="Email" source="email" />}
        {<TextField label="PEC" source="pec" />}
        {<TextField label="Patente" source="patente" />}
        {<TextField label="Att" source="flAttivo" />}
        {<TextField label="Vis" source="fiVisibile" />}
        {<TextField label="Ann" source="flAnnullato" />}
    </RowForm>
);

/* const AutistiName = ({ record }) => {
    return <span>Operatore {record ? `"${record.name} ${record.cognome}"` : ''}</span>;
};
 */
const MyToolbarEdit = () => (
    <Toolbar>
        <SaveButton sx={saveButtonCss} /* sx={{
            border: 1,
            borderColor: 'black',
            borderRadius: '20px',
            backgroundColor: '#01b5d0'
        }} */ label="Salva parametri" alwaysEnable />
    </Toolbar>
);

function canDo(modules, name, action) {
    // Trova il modulo con il nome specificato
    const module = modules.find(module => module.name === name);

    // Se il modulo non esiste, ritorna false
    if (!module) {
        console.log(`Modulo "${name}" non trovato`);
        return false;
    }

    // Controlla se il permesso specificato esiste
    if (!module.permissions.hasOwnProperty(action)) {
        console.log(`Permesso "${action}" non trovato per il modulo "${name}"`);
        return false;
    }

    // Ritorna true se il permesso è 1, altrimenti false
    return module.permissions[action] === 1;
}


export const AutistiCreate = ({ id, ...props }) => {
    const canCreateAutisti = useHasPermission("autisti", "create");

    if (!canCreateAutisti) {
        return (
            <div style={{ textAlign: 'center', padding: '20px' }}>
                <h2>Accesso negato</h2>
                <p>Non hai i permessi necessari per eseguire questa azione.</p>
            </div>
        );
    }

    // Se c'è il permesso di creazione, mostra il form
    return (
        <Create title={
            <>
                <Button>
                    <PeopleIcon sx={{ color: "yellow" }}></PeopleIcon>
                    &nbsp;Aggiungi Autista
                </Button>
            </>
        } redirect="show" {...props}>
            <SimpleForm sx={formCss} toolbar={<MyToolbarEdit />}>
                <Divider variant="middle" />
                <CustomTextInput source="nominativo" label="Nominativo" />
                <Divider variant="middle" />
                <CustomTextInput label="Indirizzo" source="indirizzo" />
                <Divider variant="middle" />
                <CustomTextInput label="Città" source="città" />
                <Divider variant="middle" />
                <CustomTextInput label="Cap" source="cap" />
                <Divider variant="middle" />
                <CustomTextInput label="Prov" source="prov" />
                <Divider variant="middle" />
                <CustomTextInput label="Telefono" source="telefono" />
                <Divider variant="middle" />
                <CustomTextInput label="Cellulare" source="cellulare" />
                <Divider variant="middle" />
                <CustomTextInput label="fax" source="fax" />
                <Divider variant="middle" />
                <CustomTextInput label="Tel. Aziendale" source="telaziendale" />
                <Divider variant="middle" />
                <CustomTextInput label="Email" source="email" />
                <Divider variant="middle" />
                <CustomTextInput label="PEC" source="pec" />
                <Divider variant="middle" />
                <CustomTextInput label="Patente" source="patente" />
                <Divider variant="middle" />
                {<ToBoolean label="Attivo" source="flAttivo" />}
                {<ToBoolean label="Visibile" source="fiVisibile" />}
                {<ToBoolean label="Annullato" source="flAnnullato" />}
            </SimpleForm>
        </Create>
    );
};


const ShowActions = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica" />
    </TopToolbar>
);

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <ShowButton sx={showButtonCss} label="Dettaglio" />
    </TopToolbar>
);

const ShowActionsForEdit = () => (
    <ShowTopToolbarDettaglio></ShowTopToolbarDettaglio>
);

export const AutistiShow = (props) => {

    // Coordinate Base di Budoni
    // Latitudine: 40.7095, Longitude: 9.69923
    // const latitude = 40.7095;
    // const longitude = 9.69923;

    const refresh = useRefresh();

    return (
        <Show title={<>
            <Button>
                <PeopleIcon></PeopleIcon>
                &nbsp;Dettaglio Autista
            </Button>
        </>} actions={<ShowActions />}>
            <Card sx={{ minWidth: 275 }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: green[500] }}>
                            <AssignmentIcon />
                        </Avatar>
                    }
                    title={<><strong>ID Autista :</strong> {< TextField label="Autista Id" source="id" />}</>}
                    subheader={
                        <>
                            <strong>Nominativo :</strong> {<TextField label="Nominativo" source="nominativo" />}
                            <br />
                        </>}
                />
            </Card>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 2, width: '30ch' },
                }}
            >
                <TabbedForm sx={formCss} toolbar={null}>
                    <TabbedForm.Tab label="Anagrafica">
                        <CustomTextInput label="Nominativo" source="nominativo" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Nominativo" source="nominativo" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Indirizzo" source="indirizzo" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Città" source="città" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Cap" source="cap" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Prov" source="prov" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Telefono" source="telefono" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Cellulare" source="cellulare" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Fax" source="fax" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Tel. Aziendale" source="telaziendale" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Email" source="email" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="PEC" source="pec" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Patente" source="patente" readOnly={true} />
                        <Divider variant="middle" />

                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Proprieta" path="proprieta">
                        <div>
                            <div>
                                {<CustomTextFieldDataOra label="Data creazione" source="createdAt" />}
                                <br></br>
                                {<CustomTextFieldDataOra label="Data ultima modifica" source="updatedAt" />}
                            </div>
                        </div>
                        <div>
                            {<ToBoolean label="Attivo" source="flAttivo" labelOn={true}></ToBoolean>}
                            {<ToBoolean label="Visibile" source="fiVisibile" labelOn={true}></ToBoolean>}
                            {<ToBoolean label="Annullato" source="flAnnullato" labelOn={true}></ToBoolean>}
                        </div>
                        <Divider variant="middle" />
                        <ConfirmDialogDeleteButtonCustom
                            titolo={"Cancella Autista"}
                            messaggio={"Sei sicuro di vole cancellare questo Autista ?"}
                            servizio={"autisti"} />
                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Annotazioni" path="annotazioni">
                        <p><strong>Annotazioni</strong></p>
                        <GestioneNote tiporecordId="3" refresh={refresh} ></GestioneNote>
                    </TabbedForm.Tab>
                    {/*                     <TabbedForm.Tab label="Geolocalizza" path="posizione">
                        <MySimpleMap latitude={latitude} longitude={longitude} />
                    </TabbedForm.Tab> */}
                </TabbedForm>
            </Box>
        </Show>
    );
}

export const AutistiEdit = ({ id, ...props }) => {

    const classes = useStyles();

    return (
        <Edit actions={<ShowActionsForEdit />} title={<>
            <Button>
                <PeopleIcon sx={{ color: "yellow" }}></PeopleIcon>
                &nbsp;Modifica Autista
            </Button>
        </>} redirect="show" {...props}>
            <SimpleForm sx={formCss} toolbar={<MyToolbarEdit />}>
                <Divider variant="middle" />
                <CustomTextInput source="nominativo" label="Nominativo"></CustomTextInput>
                <Divider variant="middle" />

                <Divider variant="middle" />
                <CustomTextInput label="Indirizzo" source="indirizzo"></CustomTextInput>
                <Divider variant="middle" />

                <Divider variant="middle" />
                <CustomTextInput label="Città" source="città"></CustomTextInput>
                <Divider variant="middle" />

                <Divider variant="middle" />
                <CustomTextInput label="Cap" source="cap"></CustomTextInput>
                <Divider variant="middle" />

                <Divider variant="middle" />
                <CustomTextInput label="Prov" source="prov"></CustomTextInput>
                <Divider variant="middle" />

                <Divider variant="middle" />
                <CustomTextInput label="Telefono" source="telefono"></CustomTextInput>
                <Divider variant="middle" />

                <Divider variant="middle" />
                <CustomTextInput label="Cellulare" source="cellulare"></CustomTextInput>
                <Divider variant="middle" />

                <Divider variant="middle" />
                <CustomTextInput label="fax" source="fax"></CustomTextInput>
                <Divider variant="middle" />

                <Divider variant="middle" />
                <CustomTextInput label="Tel. Aziendale" source="telaziendale"></CustomTextInput>
                <Divider variant="middle" />

                <Divider variant="middle" />
                <CustomTextInput label="Email" source="email"></CustomTextInput>
                <Divider variant="middle" />

                <Divider variant="middle" />
                <CustomTextInput label="PEC" source="pec"></CustomTextInput>
                <Divider variant="middle" />

                <Divider variant="middle" />
                <CustomTextInput label="Patente" source="patente"></CustomTextInput>
                <Divider variant="middle" />

                <p><strong>Proprietà</strong></p>
                {<BooleanInput color="success" className={classes.TextInput} variant="outlined" label="Attivo" source="flAttivo" />}
                {<BooleanInput color="success" className={classes.TextInput} variant="outlined" label="Visibile" source="fiVisibile" />}
                {<BooleanInput color="success" className={classes.TextInput} variant="outlined" label="Annullato" source="flAnnullato" />}

            </SimpleForm>
        </Edit>
    );
}

export const AutistiExpand = ({ id, ...props }) => {

    return (
        <Show {...props} id={id} >

            <SimpleShowLayout divider={<Divider flexItem />}>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={6}>

                        <Card>
                            <CardContent>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
                                    <Avatar>
                                        <AccountCircleIcon />
                                    </Avatar>
                                    <Typography variant="h6" style={{ marginLeft: 16 }}>
                                        Dettagli <TextField label="Nominativo" source="nominativo" />
                                    </Typography>
                                </div>
                                <ListItem>
                                    <TextField label="Indirizzo" source="indirizzo" />
                                </ListItem>
                                <ListItem>
                                    <TextField label="Città" source="città" />
                                </ListItem>
                                <ListItem>
                                    <TextField label="Cap" source="cap" />
                                </ListItem>
                                <ListItem>
                                    <TextField label="Prov" source="prov" />
                                </ListItem>
                                <ListItem>
                                    <TextField label="Telefono" source="telefono" />
                                </ListItem>
                                <ListItem>
                                    <TextField label="Cellulare" source="cellulare" />
                                </ListItem>
                                <ListItem>
                                    <TextField label="Fax" source="fax" />
                                </ListItem>
                                <ListItem>
                                    <TextField label="Tel. Aziendale" source="telaziendale" />
                                </ListItem>
                                <ListItem>
                                    EMAIL : <TextField label="Email" source="email" />
                                </ListItem>
                                <ListItem>
                                    PEC: <TextField label="PEC" source="pec" />
                                </ListItem>
                                <ListItem>
                                    PATENTI : <TextField label="Patente" source="patente" />
                                </ListItem>

                            </CardContent>
                            <CardActions>
                                <Button size="small">Scrivi</Button>
                                {<EditButton sx={editButtonCss} label="Modifica" />}
                                {<ShowButton sx={showButtonCss} label="Dettaglio" />}

                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </SimpleShowLayout>

        </Show>
    );
}

