// src/components/automezzi/AutomezziList.js
import React, { useState } from 'react';
import {
    List,
    TextField,
    EditButton,
    ShowButton,
    DatagridConfigurable,
    SelectColumnsButton,
    TopToolbar,
    ExportButton,
    WrapperField,
    CreateButton,
    useNotify,
    useDataProvider
} from 'react-admin';

import {
    Card,
    CardContent,
    Typography,
    Box,
    Alert,
    AlertTitle,
    Button
} from '@mui/material';

import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";
import { editButtonCss, showButtonCss, exportButtonCss, columnsButtonCss } from '../shared/Styles/buttonStyles';
import { listCss } from '../shared/Styles/listStyles';
import { ToBoolean } from '../shared/ToBoolean';
import GetValoreCampo from '../shared/FormFields/GetValoreCampo';

import AutomezziSearchBar from './AutomezziSearchBar';
import { AUTOMEZZI_SEARCH_FIELDS } from './AutomezziSearchBar';
import ActiveFiltersChips from './ActiveFiltersChips';

const ListActions = () => (
    <Box width="100%">
        <TopToolbar>
            <CreateButton label="Crea" sx={exportButtonCss} />
            <ExportButton label="Esporta" sx={exportButtonCss} />
            <SelectColumnsButton sx={columnsButtonCss} />
        </TopToolbar>
    </Box>
);

const NoResultsFound = ({ onReset }) => (
    <Box sx={{ mt: 2, mb: 2 }}>
        <Alert
            severity="info"
            action={
                <Button color="inherit" size="small" onClick={onReset}>
                    Resetta Filtri
                </Button>
            }
        >
            <AlertTitle>Nessun risultato trovato</AlertTitle>
            La ricerca non ha prodotto risultati. Prova a modificare i criteri di ricerca.
        </Alert>
    </Box>
);

export const AutomezziList = props => {
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const [filters, setFilters] = useState({});
    const [showNoResults, setShowNoResults] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSearch = async (searchValues) => {
        try {
            setLoading(true);
            setShowNoResults(false);

            const searchConfig = {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'amid', order: 'DESC' },
                filter: searchValues
            };

            console.log('Configurazione ricerca:', searchConfig);

            // Prima facciamo la ricerca per verificare i risultati
            const { total, data } = await dataProvider.getList('automezzi', searchConfig);

            console.log('Risultati trovati:', { total, data });

            if (total === 0) {
                setShowNoResults(true);
                setFilters({});
                notify('Nessun risultato trovato', { type: 'info' });
            } else {
                setShowNoResults(false);
                // Impostiamo i filtri con gli stessi valori usati nella ricerca
                const newFilters = { ...searchValues };
                console.log('Impostazione filtri:', newFilters);
                setFilters(newFilters);
                notify(`Trovati ${total} risultati`, { type: 'success' });
            }

        } catch (error) {
            console.error('Errore durante la ricerca:', error);
            notify('Errore durante la ricerca', { type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleReset = () => {
        setFilters({});
        setShowNoResults(false);
        notify('Filtri resettati', { type: 'info' });
    };

    const handleRemoveFilter = (filterKey) => {
        if (filterKey === 'all') {
            handleReset();
            return;
        }

        const newFilters = { ...filters };
        delete newFilters[filterKey];
        setFilters(newFilters);

        // Se non ci sono più filtri, resettiamo tutto
        if (Object.keys(newFilters).length === 0) {
            handleReset();
        } else {
            // Altrimenti, eseguiamo una nuova ricerca con i filtri rimanenti
            dataProvider.getList('automezzi', {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'amid', order: 'DESC' },
                filter: newFilters
            }).then(({ total }) => {
                if (total === 0) {
                    setShowNoResults(true);
                    setFilters({});
                    notify('Nessun risultato trovato', { type: 'info' });
                } else {
                    setShowNoResults(false);
                    notify(`Trovati ${total} risultati`, { type: 'success' });
                }
            }).catch(error => {
                console.error('Errore durante la ricerca:', error);
                notify('Errore durante la ricerca', { type: 'error' });
            });
        }
    };

    return (
        <>
            <List
                title="Anagrafiche Automezzi"
                sx={listCss}
                perPage={50}
                actions={<ListActions />}
                filter={filters}
                empty={false}
                queryOptions={{
                    refetchOnWindowFocus: false,
                    staleTime: 0,
                    meta: { filters }
                }}
            >
                <Card sx={{ mb: 2 }}>
                    <CardContent>
                        <Typography variant="h6">Filtri Automezzi</Typography>
                        <AutomezziSearchBar
                            onSearch={handleSearch}
                            onReset={handleReset}
                            disabled={loading}
                        />
                        <ActiveFiltersChips
                            filters={filters}
                            onRemoveFilter={handleRemoveFilter}
                            searchFields={AUTOMEZZI_SEARCH_FIELDS}
                        />
                        {showNoResults && (
                            <NoResultsFound
                                onReset={handleReset}
                            />
                        )}
                    </CardContent>
                </Card>

                {!showNoResults && (
                    <DatagridConfigurable
                        sx={listCss}
                        bulkActionButtons={false}
                    >
                        <TextField label="amid" source="amid" />
                        <TextField label="Targa" source="amtarga" />
                        <TextField label="Posti a sedere" source="ampaxnrdw_s1" />
                        <TextField label="Posti Assistente" source="ampaxnrdw_ass" />
                        <TextField label="Posti in piedi" source="ampaxnrup_s2" />
                        <TextField label="Posti Totali" source="ampaxnrtot" />
                        <TextField label="Posti a sedere Totali" source="ampaxnrdw_tot" />
                        <GetValoreCampo label="Gruppo" source="amtgruppo" reference="tabellealfa" />
                        <GetValoreCampo label="Marca" source="amtmarca_d1" reference="tabellealfa" />
                        <WrapperField label="Organizzazione">
                            <GetValoreCampo label="Organizzazione" source="amtintest_c2" reference="tabellealfa" />
                        </WrapperField>
                        <WrapperField label="Categoria">
                            <GetValoreCampo label="Categoria" source="amtcategoria_j" reference="tabellealfa" />
                        </WrapperField>
                        <ToBoolean label="Attivo" source="flAttivo" />
                        <ToBoolean label="Visibile" source="fiVisibile" />
                        <ToBoolean label="Annullato" source="flAnnullato" />
                        <EditButton sx={editButtonCss} label="Modifica" />
                        <ShowButton sx={showButtonCss} label="Dettaglio" />
                        <WrapperField label="Elimina">
                            <ConfirmDialogDeleteButtonCustom
                                titolo="Cancella Automezzo"
                                messaggio="Sei sicuro di voler cancellare questo Automezzo?"
                                servizio="automezzi"
                            />
                        </WrapperField>
                    </DatagridConfigurable>
                )}
            </List>
        </>
    );
};