


import {
    List,
    Datagrid,
    DatagridConfigurable, TextField, DateField, BooleanField,
    EditButton, ShowButton, CreateButton,
    SelectColumnsButton,
    TopToolbar, ExportButton,
    ReferenceField
} from 'react-admin';
import {
    numberFilter,
    StackedFilters,
} from '@react-admin/ra-form-layout';
import { ToBoolean } from '../shared/ToBoolean';
import { listCss } from '../shared/Styles/listStyles';
import { editButtonCss, showButtonCss, exportButtonCss, columnsButtonCss, filterButtonCss } from '../shared/Styles/buttonStyles';
import PeopleIcon from '@mui/icons-material/People';
import Button from '@mui/material/Button';

import { checkPermission } from '../shared/Permissions/checkPermission';

const ListActions = () => (
    <TopToolbar>

        <CreateButton label="Crea" sx={exportButtonCss} />
        <ExportButton label="Esporta" sx={exportButtonCss} />
        <SelectColumnsButton sx={columnsButtonCss} />
        <StackedFilters sx={filterButtonCss} config={listFilterspacchetti} />

    </TopToolbar>
);

const listFilterspacchetti = {
    'id': numberFilter({ operators: ["eq", "neq", "gt", "lt"] }),
};

export const pacchettiList = () => {

    const canRead = checkPermission("pacchetti", "read");

    if (!canRead) {
        return (
            <div style={{ color: "red", fontWeight: "bold", padding: 20 }}>
                Accesso negato
            </div>
        );
    }

    return (

        <List mutationMode="pessimistic" title={<>
            <Button>
                <PeopleIcon></PeopleIcon>
                &nbsp;Lista pacchetti
            </Button>
        </>} perPage={50} actions={<ListActions />} >
            <DatagridConfigurable sx={listCss} bulkActionButtons={false}>
                <TextField source="id" />
                <TextField source="descrizione" />
                <ReferenceField link={null} label="Anagrafica" source="anagenId" reference="anagen" />
                <TextField label="Nome" source="nome" />
                <ToBoolean label="Attivo" source="flAttivo"></ToBoolean>
                <ToBoolean label="Visibile" source="fiVisibile"></ToBoolean>
                <ToBoolean label="Annullato" source="flAnnullato"></ToBoolean>
                <DateField source="createdAt" />
                <EditButton sx={editButtonCss} label="Modifica" />
                <ShowButton sx={showButtonCss} label="Dettaglio" />
            </DatagridConfigurable>
        </List>
    );
}