import React from 'react';
import { useStore } from 'react-admin';
import { Booking0List } from './Booking0List';
import { ClienteBookingList } from './cliente/ClienteBookingList';

export const Booking0ListWrapper = props => {
    // Recupera l'ID del gruppo dall'utente autenticato
    const [gruppiId] = useStore('profile.gruppiId');

    // Se l'utente è identificato come Cliente (gruppiId === 2), mostra la lista dedicata
    if (gruppiId === 2) {
        return <ClienteBookingList gruppiId={gruppiId} {...props} />;
    }

    // Per gli altri utenti, mostra la lista standard
    return <Booking0List {...props} />;
};