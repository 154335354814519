import { usePermissions, useStore } from 'react-admin';

// Custom Hook per verificare i permessi
export function useHasPermission(moduleName, action) {
    // Recupera permissions e accessibleResources
    const { permissions } = usePermissions();
    const [accessibleResources] = useStore('profile.accessibleResources');

    // Verifica se l'utente è admin
    const isAdmin = permissions === "admin";

    // Se admin, ha accesso automaticamente
    if (isAdmin) return true;

    // Controlla i permessi specifici per il modulo
    return canDo(accessibleResources, moduleName, action);
}

function canDo(modules, name, action) {
    // Trova il modulo con il nome specificato
    const module = modules.find(module => module.name === name);

    // Se il modulo non esiste, ritorna false
    if (!module) {
        console.error(`Modulo "${name}" non trovato`);
        return false;
    }

    // Controlla se il permesso specificato esiste
    if (!module.permissions.hasOwnProperty(action)) {
        console.error(`Permesso "${action}" non trovato per il modulo "${name}"`);
        return false;
    }

    // Ritorna true se il permesso è 1, altrimenti false
    return module.permissions[action] === 1;
}
