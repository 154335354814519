import React, { useState, useEffect } from 'react';
import {
    useRecordContext,
    useNotify,
    useRefresh,
    useDataProvider,
    useListContext
} from 'react-admin';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    Tooltip,
    Switch,
    Paper,
    Chip,
    Zoom,
    Stack,
    Fade,
    CircularProgress,
    alpha
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';
import SecurityIcon from '@mui/icons-material/Security';
import { motion } from 'framer-motion';

// Componente per il toggle dei permessi
const PermissionToggle = ({ label, checked, onChange, disabled }) => (
    <Paper
        elevation={2}
        sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bgcolor: checked ? alpha('#4CAF50', 0.1) : 'background.paper',
            transition: 'all 0.3s ease',
            borderRadius: 2,
            border: checked ? '1px solid #4CAF50' : '1px solid transparent'
        }}
    >
        <Typography
            variant="body2"
            sx={{
                textTransform: 'capitalize',
                color: checked ? 'success.main' : 'text.primary'
            }}
        >
            {label}
        </Typography>
        <Switch
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            color="success"
        />
    </Paper>
);

// Dialog di modifica
const EditDialog = ({ open, onClose, record, onSave }) => {
    const [permissions, setPermissions] = useState({});
    const [saving, setSaving] = useState(false);

    // Reset permissions quando cambia il record
    useEffect(() => {
        if (record) {
            setPermissions({
                id: record.id,
                nome: record.nome,
                descrizione: record.descrizione,
                read_permission: record.read_permission || false,
                create_permission: record.create_permission || false,
                update_permission: record.update_permission || false,
                patch_permission: record.patch_permission || false,
                remove_permission: record.remove_permission || false,
                gruppiId: record.gruppiId
            });
        }
    }, [record]);

    const handleToggle = (permission) => {
        setPermissions(prev => ({
            ...prev,
            [`${permission}_permission`]: !prev[`${permission}_permission`]
        }));
    };

    const handleSave = async () => {
        setSaving(true);
        await onSave(permissions);
        setSaving(false);
    };

    if (!record) return null;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            TransitionComponent={Zoom}
            PaperProps={{
                elevation: 24,
                sx: { borderRadius: 3 }
            }}
        >
            <DialogTitle
                sx={{
                    bgcolor: 'primary.main',
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    py: 2
                }}
            >
                <Stack direction="row" spacing={2} alignItems="center">
                    <SecurityIcon />
                    <Typography variant="h6">
                        {record.nome}
                    </Typography>
                </Stack>
                <IconButton
                    onClick={onClose}
                    sx={{ color: 'white' }}
                    size="small"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ mt: 3 }}>
                <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ mb: 4 }}
                >
                    {record.descrizione}
                </Typography>

                <Grid container spacing={2}>
                    {['read', 'create', 'update', 'patch', 'remove'].map((permission, index) => (
                        <Grid item xs={12} sm={6} key={permission}>
                            <Fade in={true} style={{ transitionDelay: `${index * 100}ms` }}>
                                <Box>
                                    <PermissionToggle
                                        label={permission}
                                        checked={!!permissions[`${permission}_permission`]}
                                        onChange={() => handleToggle(permission)}
                                        disabled={saving}
                                    />
                                </Box>
                            </Fade>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>

            <DialogActions sx={{ p: 3 }}>
                <Button
                    onClick={onClose}
                    disabled={saving}
                    variant="outlined"
                    startIcon={<CloseIcon />}
                >
                    Annulla
                </Button>
                <Button
                    onClick={handleSave}
                    disabled={saving}
                    variant="contained"
                    startIcon={saving ? <CircularProgress size={20} /> : <SaveIcon />}
                    color="primary"
                >
                    {saving ? 'Salvataggio...' : 'Salva Modifiche'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// Card del servizio con animazione
const ServiceCard = ({ record, onEdit, index }) => {
    const cardVariants = {
        hidden: {
            opacity: 0,
            y: 50
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                delay: index * 0.1,
                duration: 0.5,
                ease: "easeOut"
            }
        }
    };

    return (
        <motion.div
            variants={cardVariants}
            initial="hidden"
            animate="visible"
        >
            <Card
                elevation={3}
                sx={{
                    height: '100%',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: 8
                    },
                    borderRadius: 3,
                    position: 'relative',
                    overflow: 'visible'
                }}
            >
                <CardContent>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        mb={3}
                    >
                        <Stack spacing={1}>
                            <Typography variant="h6" component="div" fontWeight="bold">
                                {record.nome}
                            </Typography>
                            <Tooltip title={record.descrizione || 'Nessuna descrizione'} placement="bottom-start">
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        maxWidth: '300px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        cursor: 'help'
                                    }}
                                >
                                    {record.descrizione}
                                </Typography>
                            </Tooltip>
                        </Stack>
                        <IconButton
                            onClick={() => onEdit(record)}
                            sx={{
                                bgcolor: 'primary.main',
                                color: 'white',
                                '&:hover': {
                                    bgcolor: 'primary.dark',
                                    transform: 'scale(1.1)',
                                },
                                transition: 'all 0.2s ease',
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    </Stack>

                    <Stack spacing={1}>
                        {['read', 'create', 'update', 'patch', 'remove'].map((permission) => (
                            <Chip
                                key={permission}
                                icon={record[`${permission}_permission`] ? <DoneIcon /> : <BlockIcon />}
                                label={permission}
                                color={record[`${permission}_permission`] ? "success" : "default"}
                                variant={record[`${permission}_permission`] ? "filled" : "outlined"}
                                sx={{
                                    width: '100%',
                                    py: 2,
                                    textTransform: 'capitalize',
                                    transition: 'all 0.2s ease',
                                    '&:hover': {
                                        transform: 'scale(1.02)'
                                    }
                                }}
                            />
                        ))}
                    </Stack>
                </CardContent>
            </Card>
        </motion.div>
    );
};

// Componente principale
const ServicePermissionsManager = () => {
    const [editingRecord, setEditingRecord] = useState(null);
    const { data } = useListContext();
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const handleEdit = (record) => {
        setEditingRecord(record);
    };

    const handleClose = () => {
        setEditingRecord(null);
        // Resetta lo stato quando si chiude il dialog
        setTimeout(() => {
            notify('Modifiche annullate', { type: 'info' });
        }, 100);
    };

    const handleSave = async (updatedRecord) => {
        try {
            await dataProvider.update('servicegroup', {
                id: updatedRecord.id,
                data: updatedRecord,
            });
            notify('Permessi aggiornati con successo', { type: 'success' });
            refresh();
            handleClose();
        } catch (error) {
            notify('Errore durante il salvataggio', { type: 'error' });
        }
    };

    return (
        <Box sx={{ p: 3 }}>
            <Paper
                elevation={0}
                sx={{
                    p: 3,
                    bgcolor: alpha('#000', 0.02),
                    borderRadius: 4
                }}
            >
                <Grid container spacing={3}>
                    {data?.map((record, index) => (
                        <Grid item xs={12} sm={6} md={4} key={record.id}>
                            <ServiceCard
                                record={record}
                                onEdit={handleEdit}
                                index={index}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Paper>

            <EditDialog
                open={!!editingRecord}
                onClose={handleClose}
                record={editingRecord}
                onSave={handleSave}
            />
        </Box>
    );
};

export default ServicePermissionsManager;