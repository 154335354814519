import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    Box,
    Typography,
    Paper,
    Snackbar,
    Alert,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    Card,
    CardContent,
    Button,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TimelineIcon from '@mui/icons-material/Timeline';
import SpeedIcon from '@mui/icons-material/Speed';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import axios from "axios";
import { useAuthenticated } from 'react-admin';
import config from '../../config/config.json';

const DriverReport = ({ id, from, to }) => {
    useAuthenticated();
    const [reportData, setReportData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [summary, setSummary] = useState({
        totalKm: 0,
        totalTime: 0,
        totalTrips: 0
    });

    const jwt = localStorage.getItem("feathers-jwt");
    const host = config.production.host; // Cambia con il tuo endpoint

    const performCleanup = async () => {
        try {
            const response = await axios.post(
                `${host}/wialon/report/cleanup_result/`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: jwt
                    }
                }
            );
            if (response.data.error !== 0) {
                throw new Error("Problemi con il cleanup_result di wialon");
            }
        } catch (error) {
            console.error("Errore durante il cleanup:", error);
            setErrorMessage("Problemi con il cleanup_result di wialon");
            throw error;
        }
    };

    useEffect(() => {
        const fetchReportData = async () => {
            try {
                setLoading(true);
                setErrorMessage(null);

                await performCleanup();

                const execReportPayload = {
                    reportResourceId: 27691204,
                    reportTemplateId: 2,
                    reportObjectId: 27691204,
                    reportObjectSecId: id,
                    interval: {
                        flags: 0,
                        from: from,
                        to: to
                    }
                };

                const execReportResponse = await axios.post(
                    `${host}/wialon/report/exec_report/`,
                    execReportPayload,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: jwt
                        }
                    }
                );

                const reportResult = execReportResponse.data?.reportResult;
                if (!reportResult || !reportResult.tables || reportResult.tables.length === 0) {
                    throw new Error("Nessun dato disponibile dal report exec_report.");
                }

                const tableInfo = reportResult.tables[0];
                const { rows, level, header } = tableInfo || {};

                if (!rows || rows === 0) {
                    setReportData({ reportResult, header: header || [], rowsData: [] });
                    setLoading(false);
                    return;
                }

                const selectRowsPayload = {
                    tableIndex: 0,
                    config: {
                        type: "range",
                        data: {
                            from: 0,
                            to: rows - 1,
                            level: level
                        }
                    }
                };

                const selectRowsResponse = await axios.post(
                    `${host}/wialon/report/select_result_rows`,
                    selectRowsPayload,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: jwt
                        }
                    }
                );

                if (selectRowsResponse.data && Array.isArray(selectRowsResponse.data)) {
                    // Facciamo un piccolo calcolo delle totalizzazioni (km e tempo)
                    const totals = selectRowsResponse.data.reduce((acc, row) => {
                        // Chilometraggio (colonna 6) -> "X.XX km"
                        const kmCell = row.c?.[6];
                        let km = 0;
                        if (typeof kmCell === 'string') {
                            km = parseFloat(kmCell.replace(' km', '').replace(',', '.')) || 0;
                        }

                        // Durata (colonna 1) -> "HH:MM:SS"
                        const durationCell = row.c?.[1];
                        let minutes = 0;
                        if (typeof durationCell === 'string' && durationCell.includes(':')) {
                            const [hh, mm, ss] = durationCell.split(':').map(Number);
                            minutes = (hh * 60) + mm + (ss ? ss / 60 : 0);
                        }

                        return {
                            totalKm: acc.totalKm + km,
                            totalTime: acc.totalTime + minutes,
                            totalTrips: acc.totalTrips + 1,
                        };
                    }, { totalKm: 0, totalTime: 0, totalTrips: 0 });

                    setSummary(totals);

                    setReportData({
                        reportResult,
                        header: header || [],
                        rowsData: selectRowsResponse.data
                    });
                } else {
                    setReportData({
                        reportResult,
                        header: header || [],
                        rowsData: []
                    });
                }
            } catch (error) {
                console.error("Errore nel caricamento dei dati dell'autista:", error);
                setErrorMessage(error.message || 'Errore nel caricamento dei dati.');
            } finally {
                setLoading(false);
            }
        };

        fetchReportData();
    }, [id, from, to, jwt, host]);

    // Copia l'intero JSON in appunti
    const handleCopyJson = () => {
        if (reportData) {
            navigator.clipboard.writeText(JSON.stringify(reportData, null, 2))
                .then(() => {
                    setOpenSnackbar(true);
                })
                .catch((error) => {
                    console.error('Errore durante la copia:', error);
                });
        }
    };

    // Formatta il tempo in h e m, partendo dai minuti
    const formatTime = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const mins = Math.round(minutes % 60);
        return `${hours}h ${mins}m`;
    };

    // Formatta i campi delle celle
    const formatCell = (value) => {
        if (value === null || value === undefined) return '';
        if (typeof value === 'object') {
            // Se è un oggetto, tentiamo di leggere la proprietà 't'
            return value.t ? value.t : JSON.stringify(value);
        }
        return String(value);
    };

    /**
     * Funzione per ricavare tutti i row (anche i sottolivelli) e generare un CSV unico.
     * Se vuoi includere i sub-livelli nel CSV, devi “flatten” i dati ricorsivamente.
     */
    const flattenRows = (rows) => {
        let flat = [];
        rows.forEach((row) => {
            flat.push(row);
            if (row.r && row.r.length > 0) {
                flat = flat.concat(flattenRows(row.r));
            }
        });
        return flat;
    };

    /**
     * Esporta CSV di *tutte* le tratte, inclusi i sottolivelli.
     * Se non vuoi i sottolivelli, puoi semplicemente rimuovere la `flattenRows`.
     */
    const exportAllRoutesCSV = () => {
        if (!reportData || !reportData.header || !reportData.rowsData) return;

        const { header, rowsData } = reportData;

        // Flatten dell'intera gerarchia (in modo da prendere i sottolivelli)
        const allRows = flattenRows(rowsData);

        // Prepara l'array di righe del CSV
        const lines = [];

        // Riga di intestazione
        lines.push(header.join(';'));

        // Righe dati
        allRows.forEach((row) => {
            const rowValues = (row.c || []).map((col) => {
                // Sostituiamo ";" con "," per non rompere il CSV
                return formatCell(col).replace(/;/g, ',');
            });
            lines.push(rowValues.join(';'));
        });

        const csvContent = lines.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report_autista_${id}_all_routes.csv`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    /**
     * Render ricorsivo dei row.
     * level: per gestire un eventuale indentazione o stili diversi
     */
    const renderRowsRecursively = (rows, level = 0) => {
        return rows.map((row, idx) => {
            // La "riga" top ha c come array di colonne
            const columns = row.c || [];

            // Attenzione: i sub-level stanno in row.r
            const hasSubLevels = Array.isArray(row.r) && row.r.length > 0;

            return (
                <React.Fragment key={`${level}-${idx}`}>
                    <TableRow>
                        {columns.map((colValue, colIndex) => (
                            <TableCell key={colIndex}>
                                {formatCell(colValue)}
                            </TableCell>
                        ))}
                    </TableRow>

                    {/* Se ci sono sottolivelli, mostriamo un accordion annidato */}
                    {hasSubLevels && (
                        <TableRow>
                            <TableCell colSpan={columns.length} sx={{ p: 0 }}>
                                <Accordion disableGutters>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>
                                            Sottolivello (Level {level + 1})
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ p: 0 }}>
                                        <Table size="small">
                                            <TableBody>
                                                {renderRowsRecursively(row.r, level + 1)}
                                            </TableBody>
                                        </Table>
                                    </AccordionDetails>
                                </Accordion>
                            </TableCell>
                        </TableRow>
                    )}
                </React.Fragment>
            );
        });
    };

    if (loading) {
        return (
            <Box sx={{ p: 3, textAlign: 'center' }}>
                <CircularProgress />
                <Typography>Caricamento dati report autista...</Typography>
            </Box>
        );
    }

    if (errorMessage) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography color="error">{errorMessage}</Typography>
            </Box>
        );
    }

    if (!reportData) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography>Nessun dato disponibile per l'autista richiesto.</Typography>
            </Box>
        );
    }

    const { rowsData, header } = reportData;
    const isRowsDataArray = Array.isArray(rowsData);
    const isHeaderArray = Array.isArray(header);

    return (
        <Box sx={{ width: '100%', p: 2 }}>
            <Paper
                elevation={3}
                sx={{
                    p: 3,
                    mb: 3,
                    borderRadius: 2,
                    backgroundColor: 'background.paper'
                }}
            >
                <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
                    Report Autista {id}
                </Typography>
                <Typography variant="body1">
                    Periodo: {new Date(from * 1000).toLocaleString('it-IT')} - {new Date(to * 1000).toLocaleString('it-IT')}
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                    Righe trovate: {isRowsDataArray ? rowsData.length : 0}
                </Typography>
            </Paper>

            {/* Sommario */}
            <Grid container spacing={3} sx={{ mb: 3 }}>
                <Grid item xs={12} md={4}>
                    <Card raised>
                        <CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <TimelineIcon sx={{ mr: 1, color: 'primary.main' }} />
                                <Typography variant="h6">Viaggi Totali</Typography>
                            </Box>
                            <Typography variant="h4">{summary.totalTrips}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card raised>
                        <CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <SpeedIcon sx={{ mr: 1, color: 'primary.main' }} />
                                <Typography variant="h6">Km Totali</Typography>
                            </Box>
                            <Typography variant="h4">{summary.totalKm.toFixed(2)} km</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card raised>
                        <CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <AccessTimeIcon sx={{ mr: 1, color: 'primary.main' }} />
                                <Typography variant="h6">Tempo Totale</Typography>
                            </Box>
                            <Typography variant="h4">{formatTime(summary.totalTime)}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* Dettaglio Tratte + Bottone Export CSV */}
            {isHeaderArray && isRowsDataArray && rowsData.length > 0 ? (
                <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Dettaglio Tratte (con sottolivelli)
                        </Typography>
                        <Button variant="contained" onClick={exportAllRoutesCSV}>
                            Esporta CSV
                        </Button>
                    </Box>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {header.map((col, i) => (
                                        <TableCell key={i} sx={{ fontWeight: 'bold' }}>
                                            {col}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {renderRowsRecursively(rowsData)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            ) : (
                <Typography>Non ci sono tratte da mostrare.</Typography>
            )}

            {/* Accordion JSON completo */}
            <Accordion
                sx={{
                    borderRadius: '8px !important',
                    '&:before': {
                        display: 'none',
                    }
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                        backgroundColor: 'background.paper',
                        borderRadius: '8px',
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}>
                        <Typography>Dati JSON Completi</Typography>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCopyJson();
                            }}
                            size="small"
                            sx={{ mr: 2 }}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ bgcolor: 'background.default' }}>
                    <pre style={{
                        overflow: 'auto',
                        maxHeight: '400px',
                        backgroundColor: '#f5f5f5',
                        padding: '16px',
                        borderRadius: '4px',
                        margin: 0
                    }}>
                        {JSON.stringify(reportData, null, 2)}
                    </pre>
                </AccordionDetails>
            </Accordion>

            {/* Snackbar per notifica copia */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    JSON copiato negli appunti!
                </Alert>
            </Snackbar>
        </Box>
    );
};

DriverReport.propTypes = {
    id: PropTypes.string.isRequired,
    from: PropTypes.number.isRequired,
    to: PropTypes.number.isRequired
};

export default DriverReport;
