import * as React from 'react';
import { useGetOne } from 'react-admin';
import {
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Alert,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Collapse,
    Box,
    Tooltip,
    Paper,
} from '@mui/material';
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
    Info as InfoIcon,
} from '@mui/icons-material';

const SelectedTariffBInfo = ({ tariff, totals }) => {
    const [detailsOpen, setDetailsOpen] = React.useState(false);
    const { data: tariffBData, isLoading, error } = useGetOne(
        'tariffe-b',
        { id: tariff.id }
    );

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" p={2}>
                <CircularProgress size={24} />
            </Box>
        );
    }

    if (error) {
        return <Alert severity="error">Errore nel caricamento della tariffa</Alert>;
    }

    if (!tariffBData) {
        return <Alert severity="info">Nessuna tariffa selezionata</Alert>;
    }

    const calculateCosts = () => {
        if (!totals) return null;

        const kmCost = totals.kilometers * tariffBData.tfb_ckm;
        const hoursCost = (totals.totalTimeInSeconds / 3600) * tariffBData.tfb_chh;
        const totalCost = kmCost + hoursCost;

        return {
            kmCost,
            hoursCost,
            totalCost
        };
    };

    const costs = calculateCosts();

    return (
        <Card sx={{ marginBottom: 2 }}>
            <CardContent>
                {/* Header con titolo e info */}
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" component="h2" sx={{ flexGrow: 1 }}>
                        Tariffa B - {tariff.tfb_des}
                    </Typography>
                    <Tooltip title={`ID Tariffa: ${tariff.id}`}>
                        <InfoIcon color="action" sx={{ fontSize: 20 }} />
                    </Tooltip>
                </Box>

                {/* Tabella principale dei costi */}
                <Paper variant="outlined" sx={{ mb: 2 }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Tipo</TableCell>
                                <TableCell align="right">Tariffa</TableCell>
                                <TableCell align="right">Costo</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Kilometri</TableCell>
                                <TableCell align="right">{tariffBData.tfb_ckm}€/km</TableCell>
                                <TableCell align="right">
                                    {costs?.kmCost.toFixed(2)}€
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Ore</TableCell>
                                <TableCell align="right">{tariffBData.tfb_chh}€/h</TableCell>
                                <TableCell align="right">
                                    {costs?.hoursCost.toFixed(2)}€
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ backgroundColor: 'action.hover' }}>
                                <TableCell colSpan={2}>
                                    <Typography variant="subtitle2">Totale</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="subtitle2" color="primary">
                                        {costs?.totalCost.toFixed(2)}€
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>

                {/* Sezione dettagli supplementari collassabile */}
                <Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        py: 1
                    }}
                        onClick={() => setDetailsOpen(!detailsOpen)}>
                        <IconButton size="small">
                            {detailsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                        <Typography variant="subtitle2" sx={{ ml: 1 }}>
                            Dettagli Supplementari
                        </Typography>
                        <Typography variant="subtitle2" sx={{ ml: 1 }}>
                            {/* <SimulatoreCalcoli /> */}
                        </Typography>
                    </Box>
                    <Collapse in={detailsOpen}>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell>Supplemento Notturno</TableCell>
                                    <TableCell align="right">{tariff.tfb_snotturno}€</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Tariffa Km Vuoto</TableCell>
                                    <TableCell align="right">{tariff.tfb_vkm}€/km</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Tariffa Ora Vuoto</TableCell>
                                    <TableCell align="right">{tariff.tfb_vhh}€/h</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Minuti Eccedenti</TableCell>
                                    <TableCell align="right">{tariffBData.tfb_ehh}€/min</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Collapse>
                </Box>
            </CardContent>
        </Card>
    );
};

export default SelectedTariffBInfo;