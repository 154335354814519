import React from "react";
import { Box, Chip } from "@mui/material";

export const TariffeaActiveFilters = ({ filters, onRemove }) => {
    if (!filters || Object.keys(filters).length === 0) return null;

    const getFilterLabel = (key, value) => {
        // Rimuove gli operatori dal nome del campo (es: "tfa_des.like" -> "tfa_des")
        const baseKey = key.split('.')[0];

        // Formatta il valore in base al tipo di filtro
        let displayValue = value;
        if (typeof value === 'object' && value !== null) {
            if (Array.isArray(value)) {
                displayValue = `${value[0]} - ${value[1]}`;
            } else if (value.min !== undefined || value.max !== undefined) {
                displayValue = `${value.min || 0} - ${value.max || '∞'}`;
            }
        }

        // Mappa i nomi dei campi a etichette più leggibili
        const fieldLabels = {
            tfa_des: "Descrizione",
            tfa_kmtratta0: "Km Start",
            tfa_kmtratta1: "Km Stop",
            tfa_tipo: "Tipo",
            tfa_isresday: "Resoconto giornaliero",
            tfa_clienteId: "Cliente",
            tfa_servizio: "Servizio",
            tfa_bdgkm: "Budget km",
            tfa_bdghh: "Budget ore",
            tfa_maxkmvuoto: "Max Km Vuoto",
            tfa_maxmmvuoto: "Max Minuti Vuoto",
            tfa_frgmmec: "Franchigia Extra",
            tfa_frgmmnt: "Franchigia Notturno"
        };

        // Formatta i valori per campi specifici
        switch (baseKey) {
            case 'tfa_tipo':
                displayValue = value === 1 ? 'TKm' : 'PAX';
                break;
            case 'tfa_isresday':
                displayValue = value === 1 ? 'SI' : 'NO';
                break;
        }

        return `${fieldLabels[baseKey] || baseKey}: ${displayValue}`;
    };

    return (
        <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {Object.entries(filters).map(([key, value]) => {
                if (value === undefined || value === null || value === '') return null;

                return (
                    <Chip
                        key={key}
                        label={getFilterLabel(key, value)}
                        onDelete={() => onRemove(key)}
                        color="primary"
                        variant="outlined"
                        size="medium"
                    />
                );
            })}
        </Box>
    );
};