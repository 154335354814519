import * as React from "react";
import {
    Create,
    SimpleForm,
    Toolbar,
    SaveButton,
} from 'react-admin';

import Button from '@mui/material/Button';
import { Divider } from '@mui/material';

// Importo il css per i Bottoni
import { saveButtonCss } from '../shared/Styles/buttonStyles';
import { formCss } from "../shared/Styles/formCss";
import { ToBoolean } from '../shared/ToBoolean';

import PeopleIcon from '@mui/icons-material/People';

// Importo componenti input custom
import CustomTextInput from '../shared/FormFields/CustomTextInput';
import { useHasPermission } from "../../utils/helpers/hasPermissionHelp";

const MyToolbarEdit = () => (
    <Toolbar>
        <SaveButton sx={saveButtonCss}
            label="Salva parametri" alwaysEnable />
    </Toolbar>
);

export const AutistiCreate = ({ id, ...props }) => {
    const canCreateAutisti = useHasPermission("autisti", "create");

    if (!canCreateAutisti) {
        return (
            <div style={{ textAlign: 'center', padding: '20px' }}>
                <h2>Accesso negato</h2>
                <p>Non hai i permessi necessari per eseguire questa azione.</p>
            </div>
        );
    }

    // Se c'è il permesso di creazione, mostra il form
    return (
        <Create title={
            <>
                <Button>
                    <PeopleIcon sx={{ color: "yellow" }}></PeopleIcon>
                    &nbsp;Aggiungi Autista
                </Button>
            </>
        } redirect="show" {...props}>
            <SimpleForm sx={formCss} toolbar={<MyToolbarEdit />}>
                <Divider variant="middle" />
                <CustomTextInput source="nominativo" label="Nominativo" />
                <Divider variant="middle" />
                <CustomTextInput label="Indirizzo" source="indirizzo" />
                <Divider variant="middle" />
                <CustomTextInput label="Città" source="città" />
                <Divider variant="middle" />
                <CustomTextInput label="Cap" source="cap" />
                <Divider variant="middle" />
                <CustomTextInput label="Prov" source="prov" />
                <Divider variant="middle" />
                <CustomTextInput label="Telefono" source="telefono" />
                <Divider variant="middle" />
                <CustomTextInput label="Cellulare" source="cellulare" />
                <Divider variant="middle" />
                <CustomTextInput label="fax" source="fax" />
                <Divider variant="middle" />
                <CustomTextInput label="Tel. Aziendale" source="telaziendale" />
                <Divider variant="middle" />
                <CustomTextInput label="Email" source="email" />
                <Divider variant="middle" />
                <CustomTextInput label="PEC" source="pec" />
                <Divider variant="middle" />
                <CustomTextInput label="Patente" source="patente" />
                <Divider variant="middle" />
                {<ToBoolean label="Attivo" source="flAttivo" />}
                {<ToBoolean label="Visibile" source="fiVisibile" />}
                {<ToBoolean label="Annullato" source="flAnnullato" />}
            </SimpleForm>
        </Create>
    );
};