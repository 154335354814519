import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    required,
    Toolbar,
    SaveButton,
    SelectInput,
    FormDataConsumer,
} from 'react-admin';
import {
    Grid,
    Box,
    Typography,
    Card,
    CardContent,
} from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import { createButtonCss } from '../shared/Styles/buttonStyles';
import PasswordInputCustom from '../shared/CustomFields/PasswordInputCustom';
import { checkPermission } from '../shared/Permissions/checkPermission';
import axios from 'axios';

// Host backend
import config from '../../config/config.json';

const host = config.production.host;

const MyToolbarCreate = () => (
    <Toolbar>
        <SaveButton sx={createButtonCss} label="Crea User" alwaysEnable />
    </Toolbar>
);

const UsersCreate = () => {

    const [data, setData] = useState([]); // Inizializza come array vuoto
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const jwt = localStorage.getItem("feathers-jwt");
                const response = await axios.get(`${host}/gruppi`, {
                    headers: { 'Authorization': `Bearer ${jwt}` },
                });
                setData(response.data.data || []);
            } catch (err) {
                setError(err);
                console.log('Error fetching data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []); // Aggiungi un array di dipendenze vuoto per evitare loop infiniti

    const canRead = checkPermission("users", "create");

    if (!canRead) {
        return (
            <div style={{ color: "red", fontWeight: "bold", padding: 20 }}>
                Accesso negato
            </div>
        );
    }

    return (
        <Create
            title={
                <Box display="flex" alignItems="center">
                    <PeopleIcon color="primary" />
                    <Typography variant="h6" component="span" sx={{ marginLeft: 1 }}>
                        Crea Nuovo User
                    </Typography>
                </Box>
            }
            redirect="show"
        >
            <SimpleForm toolbar={<MyToolbarCreate />}>
                <Grid container spacing={3}>
                    {/* Colonna sinistra */}
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    User e tipologia
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextInput
                                            label="Email"
                                            source="email"
                                            validate={[required()]}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PasswordInputCustom
                                            source="password"
                                            validate={[required()]}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectInput
                                            label="Ruolo"
                                            source="roles"
                                            validate={[required()]}
                                            choices={[
                                                { id: 'admin', name: 'Admin' },
                                                { id: 'gruppi', name: 'Gruppi' },
                                            ]}
                                        />
                                    </Grid>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) =>
                                            formData.roles === 'gruppi' ? (
                                                <Grid item xs={12}>
                                                    {loading ? (
                                                        <Typography>Caricamento gruppi...</Typography>
                                                    ) : error ? (
                                                        <Typography>Errore nel caricamento dei gruppi</Typography>
                                                    ) : (
                                                        <SelectInput
                                                            label="Gruppi"
                                                            source="gruppiId"
                                                            validate={[required()]}
                                                            choices={data.map(item => ({
                                                                id: item.id,
                                                                name: item.descrizione,
                                                            }))}
                                                            {...rest}
                                                        />
                                                    )}
                                                </Grid>
                                            ) : null
                                        }
                                    </FormDataConsumer>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Colonna destra */}
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Dettagli di contatto
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextInput
                                            label="Nome"
                                            source="nome"
                                            validate={[required()]}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextInput
                                            label="Cognome"
                                            source="cognome"
                                            validate={[required()]}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <NumberInput
                                            label="Telefono"
                                            source="telefono"
                                            validate={[required()]}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
}

export default UsersCreate;
