import React, { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { Card, CardContent, Typography, Grid, Box, CircularProgress, IconButton } from '@mui/material';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { makeStyles } from '@mui/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.default,
    },
    card: {
        backgroundColor: '#FFA726',
        color: theme.palette.common.white,
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        borderRadius: theme.shape.borderRadius,
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
    },
    icon: {
        fontSize: 50,
        marginRight: theme.spacing(2),
        color: theme.palette.common.white,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    note: {
        fontWeight: 'bold',
    },
    date: {
        fontStyle: 'italic',
    },
    gridContainer: {
        marginTop: theme.spacing(2),
    },
    noData: {
        textAlign: 'center',
        color: '#FF7043',
    },
    title: {
        color: '#FB8C00',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

const ScadenzeDelMese = () => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [scadenze, setScadenze] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchScadenze = async () => {
            const today = new Date();
            const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1).toISOString();
            const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString();

            try {
                const { data } = await dataProvider.getList('scadenze', {
                    filter: {
                        'data.gte': today.toISOString(),
                        'data.lte': endOfMonth,
                        flAttivo: 1,
                    },
                    pagination: { page: 1, perPage: 10 },
                    sort: { field: 'data', order: 'ASC' },
                });
                setScadenze(data);
            } catch (error) {
                console.error('Errore nel caricamento delle scadenze del mese:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchScadenze();
    }, [dataProvider]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress style={{ color: '#FF7043' }} />
            </Box>
        );
    }

    return (
        <Box className={classes.root}>
            <Typography variant="h5" gutterBottom className={classes.title}>
                Scadenze del Mese Corrente (Attive e Non Scadute)
                <IconButton href="/#/scadenze" color="inherit">
                    <ArrowForwardIcon />
                </IconButton>
            </Typography>
            {scadenze.length > 0 ? (
                <Grid container spacing={2} className={classes.gridContainer}>
                    {scadenze.map((scadenza) => (
                        <Grid item xs={12} sm={6} md={4} key={scadenza.id}>
                            <Card className={classes.card}>
                                <EventNoteIcon className={classes.icon} />
                                <Box className={classes.content}>
                                    <Typography variant="subtitle1" className={classes.note}>
                                        {scadenza.note || 'Nessuna descrizione'}
                                    </Typography>
                                    <Typography variant="body2" className={classes.date}>
                                        {new Date(scadenza.data).toLocaleDateString()}
                                    </Typography>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Typography className={classes.noData}>
                    Nessuna scadenza attiva e non scaduta per questo mese.
                </Typography>
            )}
        </Box>
    );
};

export default ScadenzeDelMese;
