import React, { useState } from 'react';
import axios from 'axios';
import config from '../../../config/config.json';

const host = config.production.host;

const CalcolaComponent = ({ booking0Id }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const styles = {
        button: {
            backgroundColor: '#1976d2',
            color: 'white',
            padding: '10px 16px',
            border: 'none',
            borderRadius: '4px',
            fontSize: '14px',
            textTransform: 'uppercase',
            cursor: 'pointer',
            boxShadow: '0 3px 1px -2px rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12)',
            transition: 'background-color 0.2s ease'
        },
        dialog: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '24px',
            borderRadius: '4px',
            boxShadow: '0 11px 15px -7px rgba(0,0,0,0.2), 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12)',
            width: '90%',
            maxWidth: '500px',
            zIndex: 1000
        },
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999
        },
        dialogTitle: {
            margin: '0 0 20px 0',
            fontSize: '20px',
            fontWeight: '500',
            color: error ? '#d32f2f' : '#000'
        },
        dialogContent: {
            marginBottom: '24px'
        },
        dialogActions: {
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '8px'
        },
        dialogButton: {
            backgroundColor: '#1976d2',
            color: 'white',
            padding: '6px 16px',
            border: 'none',
            borderRadius: '4px',
            fontSize: '14px',
            cursor: 'pointer',
            textTransform: 'uppercase'
        },
        cancelButton: {
            backgroundColor: '#grey',
            color: '#000',
            padding: '6px 16px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            fontSize: '14px',
            cursor: 'pointer',
            textTransform: 'uppercase'
        },
        pre: {
            backgroundColor: '#f5f5f5',
            padding: '16px',
            borderRadius: '4px',
            overflow: 'auto',
            maxHeight: '300px'
        },
        confirmMessage: {
            fontSize: '16px',
            marginBottom: '20px',
            lineHeight: '1.5',
            color: '#d32f2f'
        }
    };

    const handleCalcolaClick = () => {
        setIsConfirmOpen(true);
    };

    const handleConfirm = async () => {
        setIsConfirmOpen(false);
        setIsLoading(true);
        setError(null);
        setResult(null);
        setIsOpen(true);

        try {
            const jwt = localStorage.getItem("feathers-jwt");
            const response = await axios.post(
                `${host}/booking-calc`,
                { booking0Id },
                {
                    headers: {
                        'Authorization': `Bearer ${jwt}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            setResult(response.data);
            // Dopo il successo dell'operazione, attendi un breve momento e poi aggiorna
            setTimeout(() => {
                window.location.reload();
            }, 1500); // Attendi 1.5 secondi per permettere all'utente di vedere il risultato
        } catch (err) {
            setError(err.message || 'Si è verificato un errore durante il calcolo');
            console.error('Errore nella chiamata booking-calc:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseResult = () => {
        setIsOpen(false);
        // Refresh immediato quando si chiude il dialog dei risultati
        window.location.reload();
    };

    return (
        <>
            <button
                onClick={handleCalcolaClick}
                style={{
                    ...styles.button,
                    opacity: isLoading ? 0.7 : 1,
                    cursor: isLoading ? 'not-allowed' : 'pointer'
                }}
                disabled={isLoading}
            >
                {isLoading ? 'Calcolo in corso...' : 'Calcola'}
            </button>

            {/* Dialog di conferma */}
            {isConfirmOpen && (
                <>
                    <div style={styles.overlay} onClick={() => setIsConfirmOpen(false)} />
                    <div style={styles.dialog}>
                        <h2 style={styles.dialogTitle}>Conferma operazione</h2>
                        <div style={styles.dialogContent}>
                            <p style={styles.confirmMessage}>
                                Sei sicuro di voler procedere? Se dai l'ok andrai a cancellare il precedente calcolo.
                            </p>
                        </div>
                        <div style={styles.dialogActions}>
                            <button
                                onClick={() => setIsConfirmOpen(false)}
                                style={styles.cancelButton}
                            >
                                Annulla
                            </button>
                            <button
                                onClick={handleConfirm}
                                style={styles.dialogButton}
                            >
                                Procedi
                            </button>
                        </div>
                    </div>
                </>
            )}

            {/* Dialog risultati */}
            {isOpen && (
                <>
                    <div style={styles.overlay} onClick={handleCloseResult} />
                    <div style={styles.dialog}>
                        <h2 style={styles.dialogTitle}>
                            {error ? 'Errore' : 'Risultato del calcolo'}
                        </h2>
                        <div style={styles.dialogContent}>
                            <pre style={styles.pre}>
                                {error
                                    ? error
                                    : JSON.stringify(result, null, 2)}
                            </pre>
                        </div>
                        <div style={styles.dialogActions}>
                            <button
                                onClick={handleCloseResult}
                                style={styles.dialogButton}
                            >
                                Chiudi
                            </button>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default CalcolaComponent;