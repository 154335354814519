import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useStore, useNotify, useGetList } from 'react-admin';
import {
    Message as MessageIcon
} from '@mui/icons-material';
import axios from 'axios';
import config from '../../../config/config.json';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Button,
    IconButton,
    Typography,
    Box,
    CircularProgress,
    TextField,
    Grid,
    Card,
    CardContent,
    Divider,
    useTheme,
    useMediaQuery,
    Chip,
    MenuItem
} from '@mui/material';

import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import CopyToClipboardButtonWithLabel from '../../shared/FormFields/CopyToClipboardButtonWithLabel';
import { GetNameFromResourceById } from '../../shared/CustomFields/GetNameFromResourceById';
import { getStatusId } from '../utils/bookingStatus';
import { ClienteBookingCreateDialog } from './ClienteBookingCreateDialog';

const formatDate = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    return date.toLocaleDateString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
};

const formatDateTime = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    return date.toLocaleDateString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
};

const fetchPreventivi = async (clientiId, page = 0, limit = 10, filters = {}, sortConfig = {}) => {
    try {
        const jwt = localStorage.getItem("feathers-jwt");
        if (!jwt) {
            throw new Error('JWT non trovato');
        }

        const skip = page * limit;
        const queryParams = new URLSearchParams({
            'bk0_clienteId': clientiId,
            '$limit': limit,
            '$skip': skip,
        });

        // Gestione ordinamento
        if (sortConfig.field && sortConfig.direction) {
            const sortDirection = sortConfig.direction === 'asc' ? 1 : -1;
            if (sortConfig.field === 'bk0_idnumber') {
                queryParams.append('$sort[bk0_idnumber]', sortDirection);
            } else if (sortConfig.field === 'bk0_idstatus') {
                queryParams.append('$sort[bk0_idstatus]', sortDirection);
            } else if (sortConfig.field === 'period') {
                queryParams.append('$sort[bk0_datada]', sortDirection);
            } else if (sortConfig.field === 'createdAt') {
                queryParams.append('$sort[createdAt]', sortDirection);
            }
        } else {
            queryParams.append('$sort[id]', -1);
        }

        // Gestione filtri
        if (filters.bk0_datada) {
            const dataInizio = new Date(filters.bk0_datada);
            dataInizio.setHours(0, 0, 0, 0);
            queryParams.append('bk0_datada', dataInizio.toISOString().split('T')[0]);
        }
        if (filters.bk0_dataal) {
            const dataFine = new Date(filters.bk0_dataal);
            dataFine.setHours(23, 59, 59, 999);
            queryParams.append('bk0_dataal', dataFine.toISOString().split('T')[0]);
        }
        if (filters.bk0_idnumber) {
            queryParams.append('bk0_idnumber', filters.bk0_idnumber);
        }
        if (filters.bk0_idstatus) {
            queryParams.append('bk0_idstatus', filters.bk0_idstatus);
        }

        const response = await axios.get(
            `${config.production.host}/booking-0?${queryParams}`,
            {
                headers: { 'Authorization': `Bearer ${jwt}` }
            }
        );

        if (response.data) {
            return {
                data: Array.isArray(response.data.data) ? response.data.data : [],
                total: response.data.total || 0
            };
        }
        return { data: [], total: 0 };
    } catch (error) {
        if (error.response?.status === 400) {
            const errorMessage = error.response.data.message || 'Errore di validazione';
            console.error('Errore di validazione:', errorMessage);
            throw new Error(errorMessage);
        }
        if (error.response?.data?.error) {
            console.error('Errore dal server:', error.response.data.error);
            throw new Error(error.response.data.error.message || 'Errore dal server');
        }
        console.error('Errore nel recupero preventivi:', error);
        throw error;
    }
};

const TableHeader = ({ sortConfig, onSortChange }) => {
    const theme = useTheme();

    const handleSortClick = (field) => {
        let direction = 'asc';
        if (sortConfig.field === field) {
            direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
        }
        onSortChange({ field, direction });
    };

    const getSortIcon = (field) => {
        if (sortConfig.field !== field) return '↕️';
        return sortConfig.direction === 'asc' ? '↑' : '↓';
    };

    const sortableHeaderStyle = {
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell
                    onClick={() => handleSortClick('bk0_idnumber')}
                    sx={sortableHeaderStyle}
                >
                    <Box component="span" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        Numero
                        <Typography variant="caption" sx={{ opacity: 0.7 }}>
                            {getSortIcon('bk0_idnumber')}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell
                    onClick={() => handleSortClick('bk0_idstatus')}
                    sx={sortableHeaderStyle}
                >
                    <Box component="span" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        Status
                        <Typography variant="caption" sx={{ opacity: 0.7 }}>
                            {getSortIcon('bk0_idstatus')}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell
                    onClick={() => handleSortClick('period')}
                    sx={sortableHeaderStyle}
                >
                    <Box component="span" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        Periodo
                        <Typography variant="caption" sx={{ opacity: 0.7 }}>
                            {getSortIcon('period')}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell>Passeggeri</TableCell>
                <TableCell
                    onClick={() => handleSortClick('createdAt')}
                    sx={sortableHeaderStyle}
                >
                    <Box component="span" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        Data Creazione
                        <Typography variant="caption" sx={{ opacity: 0.7 }}>
                            {getSortIcon('createdAt')}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell align="right">Azioni</TableCell>
            </TableRow>
        </TableHead>
    );
};

const BookingFilters = ({ filters, onFilterChange, onSearch, onReset }) => {
    const [errors, setErrors] = useState({});
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Aggiungiamo useGetList per ottenere la lista degli status
    const { data: statusList, isLoading: statusLoading } = useGetList(
        'view-booking-0-status-riepilogo',
        {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'descrizione', order: 'ASC' }
        }
    );

    const validateDates = () => {
        const newErrors = {};

        if (filters.bk0_datada && filters.bk0_dataal) {
            const dataInizio = new Date(filters.bk0_datada);
            const dataFine = new Date(filters.bk0_dataal);

            if (isNaN(dataInizio.getTime())) {
                newErrors.bk0_datada = 'Data non valida';
            }
            if (isNaN(dataFine.getTime())) {
                newErrors.bk0_dataal = 'Data non valida';
            }
            if (dataInizio > dataFine) {
                newErrors.bk0_dataal = 'La data di fine non può essere precedente alla data di inizio';
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSearch = () => {
        if (validateDates()) {
            onSearch();
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setErrors(prev => ({ ...prev, [name]: '' }));
        onFilterChange({
            ...filters,
            [name]: value
        });
    };

    const handleReset = () => {
        setErrors({});
        onReset();
    };

    return (
        <Card elevation={3} sx={{ mb: 3, backgroundColor: 'background.paper' }}>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            fullWidth
                            name="bk0_datada"
                            label="Data Inizio"
                            type="date"
                            value={filters.bk0_datada || ''}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            error={!!errors.bk0_datada}
                            helperText={errors.bk0_datada}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            fullWidth
                            name="bk0_dataal"
                            label="Data Fine"
                            type="date"
                            value={filters.bk0_dataal || ''}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            error={!!errors.bk0_dataal}
                            helperText={errors.bk0_dataal}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            fullWidth
                            name="bk0_idnumber"
                            label="Numero Preventivo"
                            value={filters.bk0_idnumber || ''}
                            onChange={handleChange}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            select
                            fullWidth
                            name="bk0_idstatus"
                            label="Status"
                            value={filters.bk0_idstatus || ''}
                            onChange={handleChange}
                            disabled={statusLoading}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    }
                                }
                            }}
                        >
                            <MenuItem value="">
                                <em>Tutti</em>
                            </MenuItem>
                            {statusList?.map((status) => (
                                <MenuItem key={status.id} value={status.id}>
                                    {status.descrizione}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                        <Button
                            fullWidth={isMobile}
                            variant="contained"
                            onClick={handleSearch}
                            startIcon={<SearchIcon />}
                            sx={{
                                height: '56px',
                                backgroundColor: theme.palette.primary.main,
                                '&:hover': {
                                    backgroundColor: theme.palette.primary.dark,
                                }
                            }}
                        >
                            Cerca
                        </Button>
                        <Button
                            fullWidth={isMobile}
                            onClick={handleReset}
                            startIcon={<RestartAltIcon />}
                            sx={{
                                height: '56px',
                                backgroundColor: theme.palette.warning.main,
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: theme.palette.warning.dark,
                                },
                            }}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

const BookingListItem = ({ record }) => {
    const theme = useTheme();

    return (
        <TableRow
            hover
            sx={{
                '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                    transition: 'background-color 0.2s',
                }
            }}
        >
            <TableCell>
                <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                    <CopyToClipboardButtonWithLabel
                        text={record.bk0_idnumber}
                        label={record.bk0_idnumber}
                    />
                </Typography>
            </TableCell>
            <TableCell>
                <Chip
                    label={<GetNameFromResourceById
                        id={record.bk0_idstatus}
                        resource="view-booking-0-status-riepilogo"
                        fieldName="descrizione" >
                    </GetNameFromResourceById>
                    }
                    size="small"
                    sx={{
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.contrastText,
                    }}
                />
            </TableCell>
            <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CalendarTodayIcon sx={{ fontSize: '1rem', color: theme.palette.text.secondary }} />
                    <Typography variant="body2">
                        {formatDate(record.bk0_datada)} - {formatDate(record.bk0_dataal)}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <PeopleAltIcon sx={{ fontSize: '1rem', color: theme.palette.text.secondary }} />
                    <Typography variant="body2">
                        {record.bk0_paxad || '0'} adulti, {record.bk0_paxch || '0'} bambini, {record.bk0_paxin || '0'} infanti
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Typography variant="body2" color="text.secondary">
                    {formatDateTime(record.createdAt)}
                </Typography>
            </TableCell>
            <TableCell>
                <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                    <IconButton
                        component={Link}
                        to={`/booking-0/${record.id}/show`}
                        size="small"
                        sx={{
                            color: theme.palette.success.main,
                            '&:hover': {
                                backgroundColor: theme.palette.success.light,
                            }
                        }}
                    >
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton
                        component={Link}
                        to={`/booking-0/${record.id}/show/riepilogoTratte`}
                        size="small"
                        sx={{
                            color: theme.palette.info.main,
                            '&:hover': {
                                backgroundColor: theme.palette.info.light,
                            }
                        }}
                    >
                        <DirectionsCarIcon />
                    </IconButton>
                    <IconButton
                        component={Link}
                        to={`/booking-0/${record.id}/show/messaggi`}
                        size="small"
                        sx={{
                            color: theme.palette.info.main,
                            '&:hover': {
                                backgroundColor: theme.palette.info.light,
                            }
                        }}
                    >
                        <MessageIcon />
                    </IconButton>
                </Box>
            </TableCell>
        </TableRow>
    );
};


export const ClienteBookingList = () => {
    var { bk0_idstatus } = useParams();
    // Converti la stringa in numero
    const numericStatus = (bk0_idstatus !== undefined) ? getStatusId(bk0_idstatus) : "";
    bk0_idstatus = numericStatus;

    // console.log("bk0_idstatus param (stringa): ", bk0_idstatus);
    // console.log("bk0_idstatus convertito in numero: ", numericStatus);

    const [clientiId] = useStore('profile.clientiId');
    const notify = useNotify();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [preventivi, setPreventivi] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({
        bk0_datada: '',
        bk0_dataal: '',
        bk0_idnumber: '',
        bk0_idstatus: ''
    });
    const [activeFilters, setActiveFilters] = useState({});
    const [error, setError] = useState(null);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [sortConfig, setSortConfig] = useState({ field: null, direction: null });

    const [page, setPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const limit = 10;

    const loadPreventivi = useCallback(async () => {
        if (!clientiId) {
            setLoading(false);
            return;
        }

        setLoading(true);
        setError(null);
        try {
            const result = await fetchPreventivi(clientiId, page, limit, {
                ...activeFilters,
                ...(bk0_idstatus ? { bk0_idstatus } : {})
            }, sortConfig);
            setPreventivi(result.data);
            setTotalRecords(result.total);
        } catch (error) {
            setError(error.message || 'Errore nel caricamento dei dati');
            notify(error.message || 'Errore nel caricamento dei preventivi', { type: 'error' });
            setPreventivi([]);
            setTotalRecords(0);
        } finally {
            setLoading(false);
        }
    }, [clientiId, page, activeFilters, bk0_idstatus, notify, sortConfig]);

    useEffect(() => {
        loadPreventivi();
    }, [loadPreventivi]);

    const handleSearch = () => {
        setPage(0);
        setActiveFilters(filters);
    };

    const handleReset = () => {
        const emptyFilters = {
            bk0_datada: '',
            bk0_dataal: '',
            bk0_idnumber: '',
            bk0_idstatus: ''
        };
        setFilters(emptyFilters);
        setActiveFilters(emptyFilters);
        setSortConfig({ field: null, direction: null });
        setPage(0);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleCreateClick = () => setCreateDialogOpen(true);
    const handleClose = () => {
        setCreateDialogOpen(false);
        loadPreventivi();
    };

    const handleSortChange = (newSortConfig) => {
        setSortConfig(newSortConfig);
        setPage(0);
    };

    if (loading) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh'
            }}>
                <CircularProgress size={40} thickness={4} />
            </Box>
        );
    }

    if (error) {
        return (
            <Card sx={{
                p: 3,
                textAlign: 'center',
                backgroundColor: theme.palette.error.light,
                color: theme.palette.error.contrastText
            }}>
                <Typography variant="h6" sx={{ mb: 2 }}>{error}</Typography>
                <Button
                    onClick={loadPreventivi}
                    variant="contained"
                    sx={{
                        backgroundColor: theme.palette.error.dark,
                        '&:hover': {
                            backgroundColor: theme.palette.error.main,
                        }
                    }}
                >
                    Riprova
                </Button>
            </Card>
        );
    }

    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            <Card elevation={3} sx={{ mb: 4, overflow: 'hidden', width: '100%' }}>
                <Box sx={{
                    p: 3,
                    color: 'green'
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: isMobile ? 'column' : 'row',
                        gap: 2
                    }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <InsertInvitationIcon sx={{ fontSize: '2rem' }} />
                            <Typography variant="h5" component="h1">
                                Lista Preventivi
                            </Typography>
                        </Box>
                        <Button
                            startIcon={<AddIcon />}
                            onClick={handleCreateClick}
                            variant="contained"
                            sx={{
                                backgroundColor: 'white',
                                color: 'green',
                                '&:hover': {
                                    backgroundColor: theme.palette.grey[200],
                                }
                            }}
                        >
                            Nuovo Preventivo
                        </Button>
                    </Box>
                </Box>

                <BookingFilters
                    filters={filters}
                    onFilterChange={setFilters}
                    onSearch={handleSearch}
                    onReset={handleReset}
                />

                {!preventivi || preventivi.length === 0 ? (
                    <Box sx={{
                        p: 4,
                        textAlign: 'center',
                        backgroundColor: theme.palette.grey[50]
                    }}>
                        <Typography variant="h6" color="text.secondary">
                            Nessun preventivo trovato
                        </Typography>
                    </Box>
                ) : (
                    <>
                        <TableContainer sx={{
                            overflowX: 'auto',
                            '&::-webkit-scrollbar': {
                                height: 8,
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: theme.palette.grey[100],
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: theme.palette.grey[400],
                                borderRadius: 4,
                            },
                        }}>
                            <Table sx={{ minWidth: 800 }}>
                                <TableHeader
                                    sortConfig={sortConfig}
                                    onSortChange={handleSortChange}
                                />
                                <TableBody>
                                    {preventivi.map((record) => (
                                        <BookingListItem key={record.id} record={record} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Divider />

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2,
                            backgroundColor: theme.palette.grey[50]
                        }}>
                            <TablePagination
                                component="div"
                                count={totalRecords}
                                page={page}
                                onPageChange={handlePageChange}
                                rowsPerPage={limit}
                                rowsPerPageOptions={[10]}
                                labelDisplayedRows={({ from, to, count }) =>
                                    `${from}-${to} di ${count !== -1 ? count : `più di ${to}`}`
                                }
                                labelRowsPerPage="Righe per pagina:"
                                sx={{
                                    '.MuiTablePagination-toolbar': {
                                        pl: 2
                                    },
                                    '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                                        color: theme.palette.text.secondary
                                    }
                                }}
                            />
                        </Box>
                    </>
                )}
            </Card>

            <ClienteBookingCreateDialog
                bk0_clienteId={clientiId}
                open={createDialogOpen}
                onClose={handleClose}
            />
        </Box>
    );
};

export default ClienteBookingList;