import React, { useState } from 'react';
import {
    Button,
    useRecordContext,
    useRefresh,
    useNotify,
    useDataProvider
} from 'react-admin';

import { Dialog, DialogTitle, DialogContent, DialogActions, TextField as MuiTextField } from '@mui/material';
import { Save, Edit } from '@mui/icons-material';

export const EditNoteButton = () => {
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const [note, setNote] = useState(record ? record.note : '');
    const refresh = useRefresh();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    if (!record) return null;

    const handleClickOpen = () => {
        setNote(record.note || '');
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        // Eseguo l'update sul campo "note"
        dataProvider.update('rifornimenti', {
            id: record.id,
            data: { note },
            previousData: record
        })
            .then(() => {
                notify('Note aggiornate correttamente', { type: 'info' });
                refresh();
                handleClose();
            })
            .catch(error => {
                notify(`Errore nell'aggiornamento delle note: ${error.message}`, { type: 'error' });
            });
    };

    return (
        <>
            <Button onClick={handleClickOpen} label="Modifica Note">
                <Edit />
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Modifica Note</DialogTitle>
                <DialogContent>
                    <MuiTextField
                        label="Note"
                        value={note}
                        onChange={e => setNote(e.target.value)}
                        fullWidth
                        multiline
                        rows={4}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annulla</Button>
                    <Button onClick={handleSave} startIcon={<Save />}>Salva</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};