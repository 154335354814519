import React, { useState } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    required,
    DateTimeInput,
    useNotify,
    useRedirect,
    useDataProvider,
} from 'react-admin';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
    Box,
    Tooltip,
    IconButton,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { GetNameFromResourceById } from '../../shared/CustomFields/GetNameFromResourceById';
import SimpleAddressAutocomplete from '../helpers/SimpleAddressAutocomplete';
import BookingCreateToolbar from './BookingCreateToolbar';
import CustomAutocompleInputWithOnChange from '../../shared/FormFields/CustomAutocompleInputWithOnChange';

// Validators
const validateDateTime = (value) => {
    if (!value) return 'Campo obbligatorio';
    const date = new Date(value);
    if (isNaN(date.getTime())) return 'Data non valida';
    return undefined;
};

const validatePassengers = (values) => {
    const errors = {};
    const totalPassengers =
        (parseInt(values.bk0_paxad) || 0) +
        (parseInt(values.bk0_paxch) || 0) +
        (parseInt(values.bk0_paxin) || 0);

    if (totalPassengers === 0) {
        errors.bk0_paxad = 'Inserire almeno un passeggero';
    }
    return errors;
};

export const ClienteBookingCreateDialog = ({ bk0_clienteId, open, onClose }) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const [addressPartenza, setAddressPartenza] = useState('');
    const [addressArrivo, setAddressArrivo] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        bk0_clienteId: bk0_clienteId,
        bk0_richiestatipo: 46,
        bk0_servizio: null,
        bk0_gruppo: null,
        bk0_datada: null,
        bk0_dataal: null,
        bk0_paxad: 0,
        bk0_paxch: 0,
        bk0_paxin: 0,
        bk0_nroseg: 0,
        bk2_nominativo: '',
        bk2_vettipo: '',
        bk2_vetora: null,
        bk2_partenza: null,
        bk2_arrivo: null,
        bk0_ntextra: '',
        bk0_ntmemo: '',
        bk0_ntcliente: '',
        bk0_ntautista: '',
        bk0_ntresp: '',
        bk0_contatto: ''
    });

    const handleInputChange = (fieldName) => (event) => {
        const value = event.target.value;
        setFormData(prev => ({
            ...prev,
            [fieldName]: value,
        }));
    };

    const handleInputChangeDate = (fieldName) => (event) => {
        const value = event.target.value;

        console.log(`Campo aggiornato: ${fieldName}`);
        console.log(`Valore ricevuto: ${value}`);

        // Parsing del valore come data locale
        const localDate = new Date(value);

        console.log("Valore interpretato come locale:", localDate);

        // Salva la data come stringa nel formato locale
        setFormData((prev) => ({
            ...prev,
            [fieldName]: value, // Mantieni il valore locale
        }));
    };




    const handleSubmit = async (values) => {
        if (submitting) return;

        try {
            setSubmitting(true);

            // Validate addresses
            if (!addressPartenza || !addressArrivo) {
                notify('Inserire sia l\'indirizzo di partenza che di arrivo', { type: 'error' });
                return;
            }

            // Validate dates
            // Parsing delle date direttamente dai valori inseriti
            const startDate = new Date(values.bk0_datada);
            const endDate = new Date(values.bk0_dataal);

            console.log("Data di inizio (locale):", startDate);
            console.log("Data di fine (locale):", endDate);

            // Controllo se la data di fine è successiva a quella di inizio
            if (endDate <= startDate) {
                notify('La data di fine deve essere successiva alla data di inizio', { type: 'error' });
                return;
            }

            // Funzione helper per formattare le date nel formato corretto per MySQL
            const formatDateForMySQL = (dateString) => {
                const localDate = new Date(dateString); // Interpreta la data come locale

                // Formatto in 'YYYY-MM-DD HH:mm:ss' mantenendo il fuso orario locale
                const year = localDate.getFullYear();
                const month = String(localDate.getMonth() + 1).padStart(2, '0');
                const day = String(localDate.getDate()).padStart(2, '0');
                const hours = String(localDate.getHours()).padStart(2, '0');
                const minutes = String(localDate.getMinutes()).padStart(2, '0');
                const seconds = String(localDate.getSeconds()).padStart(2, '0');

                return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            };


            // Prepare transfer payload con date formattate
            const transferPayload = {
                bk0_clienteId: parseInt(bk0_clienteId),
                bk0_richiestatipo: 46,
                bk0_servizio: values.bk0_servizio,
                bk0_gruppo: values.bk0_gruppo,
                bk0_datada: formatDateForMySQL(values.bk0_datada),
                bk0_dataal: formatDateForMySQL(values.bk0_dataal),
                bk0_paxad: parseInt(values.bk0_paxad || 0),
                bk0_paxch: parseInt(values.bk0_paxch || 0),
                bk0_paxin: parseInt(values.bk0_paxin || 0),
                bk0_nroseg: parseInt(values.bk0_nroseg || 0),
                bk2_nominativo: values.bk2_nominativo || '',
                bk2_vettipo: values.bk2_vettipo || '',
                bk2_vetora: formatDateForMySQL(values.bk2_vetora),
                bk2_partenza: addressPartenza,
                bk2_arrivo: addressArrivo,
                bk0_ntextra: values.bk0_ntextra || '',
                bk0_ntmemo: values.bk0_ntmemo || '',
                bk0_ntcliente: values.bk0_ntcliente || '',
                bk0_ntautista: values.bk0_ntautista || '',
                bk0_ntresp: values.bk0_ntresp || '',
                bk0_contatto: values.bk0_contatto || ''
            };

            // Invio a booking-transfer
            const response = await dataProvider.create('booking-transfer', { data: transferPayload });

            // Gestione della risposta più accurata
            if (response?.data?.error) {
                // Se c'è un errore nella risposta, lo trattiamo come un errore
                throw new Error(response.data.error);
            }

            if (response?.data?.success && !response?.data?.warning) {
                // Solo se success è true e non ci sono warning procediamo
                notify('Preventivo creato con successo', { type: 'success' });
                onClose();
                // Redirect solo se abbiamo l'ID del booking creato
                if (response.data?.booking0?.id) {
                    redirect('show', 'booking-0', response.data.booking0.id);
                }
            } else {
                // Se c'è un warning o non c'è success, lo trattiamo come un errore
                throw new Error(response.data?.warning || 'Errore durante la creazione del preventivo');
            }

        } catch (error) {
            console.error('Error creating booking:', error);
            notify(
                error.message || 'Errore durante la creazione del preventivo',
                { type: 'error' }
            );
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            PaperProps={{
                style: { padding: '16px' }
            }}
        >
            <DialogTitle
                sx={{
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    color: '#1976d2',
                    borderBottom: '2px solid #1976d2',
                    paddingBottom: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px'
                }}
            >
                <DirectionsCarIcon />
                Crea un nuovo Preventivo
            </DialogTitle>
            <DialogContent>
                <Create
                    resource="booking-transfer"
                    mutationOptions={{
                        onSuccess: () => {
                            notify('Preventivo creato con successo', { type: 'success' });
                            onClose();
                        }
                    }}
                >
                    <SimpleForm
                        toolbar={
                            <BookingCreateToolbar
                                formData={formData}
                                addressPartenza={addressPartenza}
                                addressArrivo={addressArrivo}
                                submitting={submitting}
                            />
                        }
                        validate={validatePassengers}
                        onSubmit={handleSubmit}
                    >
                        {/* Cliente Info */}
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <TextInput
                                        source="bk0_clienteId"
                                        defaultValue={bk0_clienteId}
                                        disabled
                                        sx={{ display: 'none' }}
                                    />
                                    <Box component="span" sx={{ fontWeight: 500, color: '#333' }}>
                                        Anagrafica Cliente:{' '}
                                        <GetNameFromResourceById
                                            id={bk0_clienteId}
                                            resource="anagen"
                                            fieldName="gnfind"
                                        />
                                    </Box>
                                    <Tooltip title={`Codice cliente: ${bk0_clienteId}`}>
                                        <IconButton size="small">
                                            <InfoIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </Grid>

                        {/* Tipo Richiesta */}
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <TextInput
                                        source="bk0_richiestatipo"
                                        defaultValue={46}
                                        disabled
                                        sx={{ display: 'none' }}
                                    />
                                    Tipo Richiesta:{' '}
                                    <GetNameFromResourceById
                                        id={46}
                                        resource="view-tabellaalfa-trp"
                                        fieldName="descrizione"
                                    />
                                    <Tooltip title="Codice: 46">
                                        <IconButton size="small">
                                            <InfoIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </Grid>

                        {/* Servizio e Automezzo */}
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} md={6}>
                                <ReferenceInput
                                    source="bk0_servizio"
                                    reference="servizi"
                                    validate={[required()]}
                                >
                                    <SelectInput
                                        label="Tipo Servizio *"
                                        fullWidth
                                        validate={[required()]}
                                        onChange={handleInputChange('bk0_servizio')}
                                    />
                                </ReferenceInput>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomAutocompleInputWithOnChange
                                    source="bk0_gruppo"
                                    reference="view-tabellaalfa-grp"
                                    optionProperty="descrizione"
                                    label="Seleziona Automezzo"
                                    validate={[required()]}
                                    onChange={(value) => {
                                        if (value?.target?.value) {
                                            setFormData(prev => ({
                                                ...prev,
                                                bk0_gruppo: value.target.value
                                            }));
                                        } else {
                                            setFormData(prev => ({
                                                ...prev,
                                                bk0_gruppo: value
                                            }));
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>

                        {/* Date */}
                        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                            Data e Ora Inizio/Fine Servizio
                        </Typography>
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} md={6}>
                                <DateTimeInput
                                    source="bk0_datada"
                                    label="Data e Ora Inizio Servizio *"
                                    validate={[validateDateTime]}
                                    onChange={handleInputChangeDate('bk0_datada')}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DateTimeInput
                                    source="bk0_dataal"
                                    label="Data e Ora Fine Servizio *"
                                    validate={[validateDateTime]}
                                    onChange={handleInputChangeDate('bk0_dataal')}
                                />
                            </Grid>
                        </Grid>

                        {/* Passeggeri */}
                        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                            Numero Passeggeri
                        </Typography>
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6} md={3}>
                                <NumberInput
                                    source="bk0_paxad"
                                    label="Adulti"
                                    fullWidth
                                    min={0}
                                    defaultValue={0}
                                    onChange={handleInputChange('bk0_paxad')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <NumberInput
                                    source="bk0_paxch"
                                    label="Bambini"
                                    fullWidth
                                    min={0}
                                    defaultValue={0}
                                    onChange={handleInputChange('bk0_paxch')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <NumberInput
                                    source="bk0_paxin"
                                    label="Infanti"
                                    fullWidth
                                    min={0}
                                    defaultValue={0}
                                    onChange={handleInputChange('bk0_paxin')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <NumberInput
                                    source="bk0_nroseg"
                                    label="Seggiolini"
                                    fullWidth
                                    min={0}
                                    defaultValue={0}
                                    onChange={handleInputChange('bk0_nroseg')}
                                />
                            </Grid>
                        </Grid>

                        {/* Riferimenti Passeggeri */}
                        <Typography variant="subtitle1" sx={{ mt: 3, mb: 1 }}>
                            Riferimenti Passeggeri
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextInput
                                    source="bk2_nominativo"
                                    label="Nota per indicare riferimenti passeggeri (Nominativo, Telefono, etc...)"
                                    multiline
                                    rows={2}
                                    fullWidth
                                    onChange={handleInputChange('bk2_nominativo')}
                                />
                            </Grid>
                        </Grid>

                        {/* Riferimenti Vettore */}
                        <Typography variant="subtitle1" sx={{ mt: 3, mb: 1 }}>
                            Riferimenti Vettore - Aereo/Nave
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextInput
                                    source="bk2_vettipo"
                                    label="Codice Vettore (es: Numero Volo)"
                                    fullWidth
                                    onChange={handleInputChange('bk2_vettipo')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DateTimeInput
                                    source="bk2_vetora"
                                    label="Data e Ora Arrivo / Partenza Previsto *"
                                    validate={[required()]}
                                    onChange={handleInputChangeDate('bk2_vetora')}
                                />
                            </Grid>
                        </Grid>

                        {/* Indirizzi */}
                        <Typography variant="subtitle1" sx={{ mt: 3, mb: 1 }}>
                            Indirizzi Partenza e Arrivo
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <SimpleAddressAutocomplete
                                    value={addressPartenza}
                                    onChange={setAddressPartenza}
                                    label="Indirizzo Partenza *"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SimpleAddressAutocomplete
                                    value={addressArrivo}
                                    onChange={setAddressArrivo}
                                    label="Indirizzo Arrivo *"
                                />
                            </Grid>
                        </Grid>

                        {/* Note */}
                        <Typography variant="subtitle1" sx={{ mt: 3, mb: 1 }}>
                            Note
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextInput
                                    source="bk0_ntextra"
                                    label="Nota per particolari esigenze"
                                    multiline
                                    rows={2}
                                    fullWidth
                                    onChange={handleInputChange('bk0_ntextra')}
                                />
                            </Grid>
                        </Grid>

                        {/* Hidden fields */}
                        <TextInput
                            source="bk0_ntmemo"
                            defaultValue=""
                            disabled
                            sx={{ display: 'none' }}
                        />
                        <TextInput
                            source="bk0_ntcliente"
                            defaultValue=""
                            disabled
                            sx={{ display: 'none' }}
                        />
                        <TextInput
                            source="bk0_ntautista"
                            defaultValue=""
                            disabled
                            sx={{ display: 'none' }}
                        />
                        <TextInput
                            source="bk0_ntresp"
                            defaultValue=""
                            disabled
                            sx={{ display: 'none' }}
                        />
                        <TextInput
                            source="bk0_contatto"
                            defaultValue=""
                            disabled
                            sx={{ display: 'none' }}
                        />
                    </SimpleForm>
                </Create>
            </DialogContent>
        </Dialog>
    );
};

export default ClienteBookingCreateDialog;