import * as React from "react";
import {
    List,
    Datagrid,
    EditButton,
    TextInput,
    TextField,
    ReferenceManyField,
    Show,
    useRecordContext,
    ShowButton,
    TopToolbar,
    CreateButton,
    TabbedForm,
    FunctionField,
    NumberInput,
    Button
} from 'react-admin';
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import EuroIcon from '@mui/icons-material/Euro';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import NoteIcon from '@mui/icons-material/Note';
import TopicIcon from '@mui/icons-material/Topic';
import PeopleIcon from '@mui/icons-material/People';
import { List as ListMaterial, ListItem, ListItemIcon, ListItemText } from '@mui/material';

// Importo il css per i Bottoni
import { listCss } from '../shared/Styles/listStyles';
import { editButtonCss, showButtonCss, createButtonCss } from '../shared/Styles/buttonStyles';
import Simulatore from "./simulatore/simulatore";

// Simulatore Servpax
// Versione 1
import Servpax_simulator from './servpax-simulator';
import { GetListTabelleb } from "./GetListTabelleb";

const ShowActions = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica Tariffa" />
    </TopToolbar>
);

/* const GetListTabelleb = (props) => {

    const recordTariffea = useRecordContext();
    if (!recordTariffea) return null;

    return (
        <ReferenceManyField
            reference="tariffe-b"
            target="tfa_id"
        // sort={{ field: 'id', order: 'ASC' }}
        >
            <EditableDatagrid noDelete
                bulkActionButtons={false}
                editForm={<TariffeBRowForm />}
            >
                <TextField fullWidth label="Gruppo" source="tfb_gruppo" />
                <TextField fullWidth label="Descrizione" source="tfb_des" />
                <TextField fullWidth label="Costo Km di Base" source="tfb_ckm" />
                <TextField fullWidth label="Costo Supplemento Km ExtraBudget" source="tfb_ekm" />
                <TextField fullWidth label="Costo Minuto di Budget" source="tfb_chh" />
                <TextField fullWidth label="Costo Supplemento Min ExtraBudget" source="tfb_ehh" />
                <TextField fullWidth label="Supplemento Notturno (%)" source="tfb_snotturno" />
                <TextField fullWidth label="tfb_cpaxa" source="tfb_cpaxa" />
                <TextField fullWidth label="tfb_cpaxc" source="tfb_cpaxc" />
                <TextField fullWidth label="tfb_cpaxc" source="tfb_cpaxi" />
                <TextField fullWidth label="tfb_npaxmin" source="tfb_npaxmin" />
                <TextField fullWidth label="KM a vuoto" source="tfb_vkm" />
                <TextField fullWidth label="Minuti a vuoto" source="tfb_vhh" />
                <TextField fullWidth label="Costi Extra Km a vuoto" source="tfb_evkm" />
                <TextField fullWidth label="Max % Km a Vuoto Fatt" source="tfb_vkmperc" />
                <TextField fullWidth label="Max % Min a Vuoto Fatt" source="tfb_vhhperc" />
                <FunctionField
                    label="ServPax Sim"
                    render={record => {
                        return record && <Servpax_simulator record={record} ></Servpax_simulator>;
                        // return record && <ServpaxSimulator record={record}></ServpaxSimulator>;
                    }}
                />

                <FunctionField
                    label="Simulatore"
                    render={record => {

                        // console.log(recordTariffea);

                        record.tfa_bdgkm = recordTariffea.tfa_bdgkm;
                        record.tfa_bdghh = recordTariffea.tfa_bdghh;
                        record.tfa_frgmmec = recordTariffea.tfa_frgmmec;
                        record.tfa_frgmmnt = recordTariffea.tfa_frgmmnt;

                        return record && <Simulatore record={record}></Simulatore>;
                    }}
                />



            </EditableDatagrid>
        </ReferenceManyField>
    );
}
 */

const TariffeBRowForm = (props) => (
    <RowForm {...props}>
        <TextInput fullWidth label="Gruppo" source="tfb_gruppo" />
        <TextInput fullWidth label="Descrizione" source="tfb_des" />
        <NumberInput min={0.000} fullWidth label="Costo Km di Base" source="tfb_ckm" options={{ style: "currency", currency: "EUR" }} />
        <NumberInput min={0.000} fullWidth label="Costo Supplemento Km ExtraBudget" source="tfb_ekm" options={{ style: "currency", currency: "EUR" }} />
        <NumberInput min={0.000} fullWidth label="Costo Minuto di Budget" source="tfb_chh" options={{ style: "currency", currency: "EUR" }} />
        <NumberInput min={0.000} fullWidth label="Costo Supplemento Min ExtraBudget" source="tfb_ehh" />
        <NumberInput min={0} fullWidth label="Supplemento Notturno (%)" source="tfb_snotturno" />
        <NumberInput min={0.000} fullWidth label="tfb_cpaxa" source="tfb_cpaxa" />
        <NumberInput min={0.000} fullWidth label="tfb_cpaxc" source="tfb_cpaxc" />
        <NumberInput min={0.000} fullWidth label="tfb_cpaxi" source="tfb_cpaxi" />
        <NumberInput min={0.000} fullWidth label="tfb_npaxmin" source="tfb_npaxmin" />
        <NumberInput min={0} fullWidth label="Km a vuoto" source="tfb_vkm" />
        <NumberInput min={0} fullWidth label="Minuti a vuoto" source="tfb_vhh" />
        <NumberInput min={0.000} fullWidth label="Costi Extra Km a vuoto" source="tfb_evkm" />
        <NumberInput min={0} fullWidth label="Max % Km a Vuoto Fatt" source="tfb_vkmperc" />
        <NumberInput min={0} fullWidth label="Max % Min a Vuoto Fatt" source="tfb_vhhperc" />


    </RowForm>
);

export const TariffeaShow = (props) => {

    return (
        <Show title={<>
            <Button>&nbsp;Dettaglio Tariffa</Button>
        </>} actions={<ShowActions />}>

            <TabbedForm toolbar={null}>
                <TabbedForm.Tab label="Dettagli Tariffa" icon={<TopicIcon />}>
                    <ListMaterial>
                        <Grid container spacing={2}>
                            {[
                                { label: "Descrizione", source: "tfa_des", Icon: NoteIcon },
                                { label: "Tipo", source: "tfa_tipo", Icon: NoteIcon }, // 1 = Tempo km ; 2 = A passeggero
                                { label: "Cliente", source: "tfa_clienteId", Icon: PeopleIcon },
                                { label: "Servizio", source: "tfa_servizio", Icon: NoteIcon },
                                // Escludi i campi "f Km Start", "f Km Stop" e "Sconto"
                            ].filter(field => !["tfa_kmtratta0", "tfa_kmtratta1"].includes(field.source)).map(({ label, source, Icon }, index) => (
                                <Grid item xs={12} sm={6} key={`field-${index}`}>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon />
                                        </ListItemIcon>
                                        <ListItemText primary={label} secondary={<TextField label="" source={source} fullWidth />} />
                                    </ListItem>
                                </Grid>
                            ))}
                            {/* Raggruppa "f Km Start" e "f Km Stop" in una Card separata */}
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            {[
                                                { label: "f Km Start", source: "tfa_kmtratta0", Icon: NoteIcon },
                                                { label: "f Km Stop", source: "tfa_kmtratta1", Icon: NoteIcon },
                                            ].map(({ label, source, Icon }, index) => (
                                                <Grid item xs={12} sm={6} key={`km-${index}`}>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <Icon />
                                                        </ListItemIcon>
                                                        <ListItemText primary={label} secondary={<TextField label="" source={source} fullWidth />} />
                                                    </ListItem>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            {/* Raggruppa i campi "Sconto" in una Card separata */}
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            {Array.from({ length: 10 }, (_, i) => ({ label: `Sconto ${i + 1}`, source: `tfa_maxsc${i + 1}`, Icon: EuroIcon })).map(({ label, source, Icon }, index) => (
                                                <Grid item xs={6} sm={3} key={`discount-${index}`}>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <Icon />
                                                        </ListItemIcon>
                                                        <ListItemText primary={label} secondary={<TextField label="" source={source} fullWidth />} />
                                                    </ListItem>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </ListMaterial>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Gruppi tariffe" icon={<TopicIcon />}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <GetListTabelleb></GetListTabelleb>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
            </TabbedForm>
        </Show>
    );
}