import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Typography,
    CircularProgress,
    Alert,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Paper
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import InfoIcon from '@mui/icons-material/Info';
import EuroIcon from '@mui/icons-material/Euro';
import MapIcon from '@mui/icons-material/Map';
import config from '../../../config/config.json';
import { fetchCustomServiceHelp } from '../../../utils/helpers/fetchCustomServiceHelp';
import ClienteRoutesAccordion from './ClienteRoutesAccordion';

const host = config.production.host;

// Componente per visualizzare un singolo campo dati
const DataField = ({ label, value, type = 'text' }) => {
    let displayValue = value;

    if (value === null || value === undefined) {
        displayValue = '-';
    } else if (type === 'date' && value) {
        try {
            displayValue = new Date(value).toLocaleString('it-IT');
        } catch {
            displayValue = value;
        }
    } else if (type === 'currency') {
        displayValue = `€${Number(value || 0).toFixed(2)}`;
    } else if (type === 'percentage') {
        displayValue = `${value}%`;
    } else if (type === 'boolean') {
        displayValue = value ? 'Sì' : 'No';
    }

    return (
        <Box sx={{ py: 0.5 }}>
            <Typography variant="body2">
                <span style={{ fontWeight: 'bold', color: '#666' }}>{label}:</span>{' '}
                <span style={{ marginLeft: '8px' }}>{displayValue}</span>
            </Typography>
        </Box>
    );
};

// Componente principale per i dettagli del booking
const BookingCard = ({ booking, routes = [] }) => {

    return (
        <Paper elevation={3} sx={{ mb: 3, borderRadius: 2, overflow: 'hidden' }}>
            {/* Header della Card */}
            <Box sx={{ bgcolor: '#1976d2', color: 'white', p: 2 }}>
                <Typography variant="h6">
                    Tratta ID #{booking.booking0AutomezziId}
                </Typography>
            </Box>

            {/* Contenuto della Card */}
            <Box sx={{ p: 2 }}>
                {/* Sezione Informazioni Principali */}
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{ bgcolor: 'rgba(25, 118, 210, 0.05)' }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <InfoIcon color="primary" />
                            <Typography variant="subtitle1">Informazioni Principali</Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <DataField label="Id Tratta" value={booking.booking0AutomezziId} />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                {/* Sezione Itinerari */}
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{
                            bgcolor: 'rgba(66, 165, 245, 0.05)',
                            '&:hover': {
                                bgcolor: 'rgba(66, 165, 245, 0.1)',
                            }
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <MapIcon sx={{ color: '#42A5F5' }} />
                            <Typography variant="subtitle1">Dettagli Itinerario</Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" color="primary" gutterBottom>
                                    Descrizione
                                </Typography>
                                <Typography variant="body2" paragraph>
                                    {booking.bkh_itbreve || '-'}
                                </Typography>

                                <Typography variant="subtitle2" color="primary" gutterBottom sx={{ mt: 2 }}>
                                    Punti Geografici
                                </Typography>
                                <Box sx={{
                                    bgcolor: 'rgba(66, 165, 245, 0.05)',
                                    p: 2,
                                    borderRadius: 1,
                                    border: '1px solid rgba(66, 165, 245, 0.2)'
                                }}>
                                    {console.log("Routes passate a ClienteRoutesAccordion:", routes)}
                                    <ClienteRoutesAccordion routes={routes} />
                                    {(!routes || routes.length === 0) && (
                                        <Typography variant="body2" color="text.secondary">
                                            Nessun itinerario disponibile per questo gruppo
                                        </Typography>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Paper>
    );
};

// Componente principale
const ClienteBookingHDetailsAutomezzi = ({ bookingId }) => {
    const [bookingsData, setBookingsData] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [relationData, setRelationData] = useState({
        gruppoData: null,
        pagData: null,
        serviziData: null,
        usersData: null
    });

    useEffect(() => {
        const fetchData = async () => {
            if (!bookingId) {
                setLoading(false);
                return;
            }

            try {
                const jwt = localStorage.getItem("feathers-jwt");

                // 1. Recupera i booking-h-automezzi
                const bookingsResponse = await axios.get(
                    `${host}/booking-h-automezzi?booking0Id=${bookingId}`,
                    { headers: { 'Authorization': `Bearer ${jwt}` } }
                );

                const bookings = bookingsResponse.data.data || [];
                setBookingsData(bookings);
                console.log("Bookings trovati:", bookings);

                // 2. Per ogni booking, recupera le relative tratte
                if (bookings.length > 0) {
                    const allRoutes = [];

                    // Recupera le tratte per ogni booking0AutomezziId
                    for (const booking of bookings) {
                        try {
                            console.log("Cercando tratte per booking0AutomezziId:", booking.booking0AutomezziId);
                            const routesResponse = await axios.get(
                                `${host}/booking-2?booking0_automezziId=${booking.booking0AutomezziId}`,
                                { headers: { 'Authorization': `Bearer ${jwt}` } }
                            );

                            if (routesResponse.data.data) {
                                console.log(`Trovate ${routesResponse.data.data.length} tratte per booking ${booking.booking0AutomezziId}:`, routesResponse.data.data);
                                allRoutes.push(...routesResponse.data.data);
                            }
                        } catch (err) {
                            console.error('Errore nel recupero delle tratte per booking:', booking.booking0AutomezziId, err);
                        }
                    }

                    console.log("Totale tratte recuperate:", allRoutes);
                    setRoutes(allRoutes);

                    // 3. Recupera i dati relazionati per il primo booking
                    const firstBooking = bookings[0];
                    const [gruppo, pag, servizi, users] = await Promise.all([
                        firstBooking.bkh_gruppo ? fetchCustomServiceHelp(firstBooking.bkh_gruppo, "tabellealfa") : null,
                        firstBooking.bkh_pag ? fetchCustomServiceHelp(firstBooking.bkh_pag, "tabellealfa") : null,
                        firstBooking.bkh_servizio ? fetchCustomServiceHelp(firstBooking.bkh_servizio, "servizi") : null,
                        firstBooking.bkh_redattoreId ? fetchCustomServiceHelp(firstBooking.bkh_redattoreId, "users") : null
                    ]);

                    setRelationData({
                        gruppoData: gruppo,
                        pagData: pag,
                        serviziData: servizi,
                        usersData: users
                    });
                }
            } catch (err) {
                setError(err);
                console.error('Errore nel recupero dei dati:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [bookingId]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                <span>Errore nel caricamento dei dati: {error.message}</span>
            </Alert>
        );
    }

    if (!bookingsData.length) {
        return (
            <Alert severity="warning" sx={{ m: 2 }}>
                <span>Nessun dato disponibile per questo booking</span>
            </Alert>
        );
    }

    return (
        <Box sx={{ maxWidth: '1400px', margin: '40px auto', px: 2 }}>
            {bookingsData.map((booking, index) => {
                console.log("Filtrando routes per booking:", booking.booking0AutomezziId);
                // Filtra le tratte per questo specifico booking0AutomezziId
                const bookingRoutes = routes.filter(route => {
                    console.log("Confronto:", {
                        routeId: route.booking0_automezziId,
                        bookingId: booking.booking0AutomezziId,
                        match: Number(route.booking0_automezziId) === Number(booking.booking0AutomezziId)
                    });
                    return Number(route.booking0_automezziId) === Number(booking.booking0AutomezziId);
                });

                console.log("Routes filtrate per questo booking:", bookingRoutes);

                return (
                    <BookingCard
                        key={booking.id}
                        booking={booking}
                        routes={bookingRoutes}
                        index={index}
                        total={bookingsData.length}
                        relationData={relationData}
                    />
                );
            })}
        </Box>
    );
};

export default ClienteBookingHDetailsAutomezzi;