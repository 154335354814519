
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    TopToolbar,
    ExportButton,
    EditButton,
    ShowButton,
    ReferenceField,
    FunctionField,
} from 'react-admin';
import DettaglioButton from './DettaglioButton';

// Icone
import PeopleIcon from '@mui/icons-material/People';
import Button from '@mui/material/Button';

// Importa CSS
import { listCss } from '../shared/Styles/listStyles';
import { editButtonCss, showButtonCss, exportButtonCss } from '../shared/Styles/buttonStyles';
import { ToBoolean } from '../shared/ToBoolean';
import { checkPermission } from '../shared/Permissions/checkPermission';

const ListActions = () => (
    <TopToolbar>
        <ExportButton label="Esporta" sx={exportButtonCss} />
    </TopToolbar>
);

export const TariffaServpaxList = props => {

    const canRead = checkPermission("tariffa-servpax", "read");

    if (!canRead) {
        return (
            <div style={{ color: "red", fontWeight: "bold", padding: 20 }}>
                Accesso negato
            </div>
        );
    }

    return (
        <List
            title={<>
                <Button>
                    <PeopleIcon></PeopleIcon>
                    &nbsp;Lista delle Tariffe Servpax
                </Button>
            </>}
            sx={listCss}

            actions={<ListActions />}
            filterDefaultValues={{ id: { $ne: 0 } }}
            {...props}>
            <Datagrid bulkActionButtons={false} >
                <NumberField source="id" />
                <>
                    <ReferenceField link={false} source='anagenId' reference='anagen' label='Fornitore' ></ReferenceField>

                    <FunctionField
                        source="anagenId"
                        label=""
                        render={record => record && <DettaglioButton codice={record.anagenId} />}
                    />
                </>
                <ReferenceField link={false} source='servpaxId' reference='servpax' label='Servizio' />
                <DateField source="dataFrom" />
                <DateField source="dataTo" />
                <TextField source="costo" />
                <TextField source="costo2" />
                <TextField source="costo3" />
                <ToBoolean label="Attivo" source="flAttivo" />
                <ToBoolean label="Visibile" source="fiVisibile" />
                <ToBoolean label="Annullato" source="flAnnullato" />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
                <EditButton sx={editButtonCss} label="Modifica" />
                <ShowButton sx={showButtonCss} label="Dettaglio" />
            </Datagrid>
        </List>
    );
}
