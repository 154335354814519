import React, { useEffect, useState, useMemo } from 'react';
import {
    Grid,
    Typography,
    Box,
    Divider,
    Card,
    CardHeader,
    CardContent
} from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useStore } from 'react-admin';

// Import dal tuo bookingStatus.js
import { getAllStatuses } from './../../../booking0/utils/bookingStatus';
// Importa mappa colori e link
import { colorMap, linkMap } from './../../../booking0/utils/colorMap';
import CustomLoadingSimple from '../../../shared/CustomLoading/CustomLoadingSimple';

// Se usi il Link di react-router-dom
// import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    serviceCard: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        padding: theme.spacing(2),
        color: '#fff',
        minHeight: '120px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
            transform: 'scale(1.03)',
        },
    },
    iconContainer: {
        marginBottom: theme.spacing(1),
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        borderRadius: '50%',
        padding: theme.spacing(1),
    },
}));

const DashboardCliente = () => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [serviceCounts, setServiceCounts] = useState({});
    const [serviceErrors, setServiceErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [clientiId] = useStore('profile.clientiId');

    /**
     * Usiamo useMemo per costruire "statusCards" una sola volta.
     * Se la lista degli status è statica, potresti anche dichiararla fuori dal componente.
     */
    const statusCards = useMemo(() => {
        return getAllStatuses().map((statusObj) => {
            const { id, descrizione } = statusObj;
            return {
                bk0_idstatus: id,
                name: descrizione,
                color: colorMap[id] ?? '#999', // Fallback
                icon: PeopleIcon,
                link: linkMap[id] ? linkMap[id] : '#/booking-0/generic/list',
            };
        });
    }, []);

    useEffect(() => {
        const fetchServiceData = async () => {
            setLoading(true);

            // Facciamo la getList su ogni status
            const promises = statusCards.map(async (card) => {
                try {
                    const result = await dataProvider.getList('booking-0', {
                        pagination: { page: 1, perPage: 1 },
                        sort: { field: 'id', order: 'ASC' },
                        filter: {
                            bk0_clienteId: clientiId,
                            bk0_idstatus: card.bk0_idstatus,
                        },
                    });
                    return {
                        bk0_idstatus: card.bk0_idstatus,
                        count: result.total,
                    };
                } catch (error) {
                    console.error(
                        `Errore nel fetch per status ${card.bk0_idstatus}:`,
                        error
                    );
                    return {
                        bk0_idstatus: card.bk0_idstatus,
                        error: true,
                    };
                }
            });

            const results = await Promise.all(promises);
            const counts = {};
            const errors = {};

            results.forEach((res) => {
                if (res.error) {
                    errors[res.bk0_idstatus] = true;
                } else {
                    counts[res.bk0_idstatus] = res.count;
                }
            });

            setServiceCounts(counts);
            setServiceErrors(errors);
            setLoading(false);
        };

        fetchServiceData();
        // ** NON ** mettiamo "statusCards" tra le dipendenze
        // se non cambia in base a qualche altro stato!
    }, [dataProvider, clientiId /*, statusCards */]);

    if (loading) return <CustomLoadingSimple />;


    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom sx={{ marginBottom: 4 }}>
                    Dashboard Wrapper
                </Typography>

                <Box sx={{ mb: 4, mt: 6 }}>
                    <Typography variant="h5" gutterBottom color="primary">
                        Link Rapidi
                    </Typography>
                    <Divider />
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        className={classes.paper}
                        title="Classificazione Preventivi"
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            {statusCards.map((card) => {
                                const IconComp = card.icon;
                                const count = serviceCounts[card.bk0_idstatus] || 0;
                                const isError = serviceErrors[card.bk0_idstatus];

                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        key={`status-${card.bk0_idstatus}`}
                                    >
                                        {/* Tag <a> che rende cliccabile l'intera card. */}
                                        <a
                                            href={`http://localhost:3000/${card.link}`}
                                            style={{
                                                textDecoration: 'none',
                                                color: 'inherit',
                                            }}
                                        >
                                            <Box
                                                className={classes.serviceCard}
                                                style={{
                                                    backgroundColor: card.color,
                                                }}
                                            >
                                                <Box className={classes.iconContainer}>
                                                    <IconComp
                                                        style={{
                                                            fontSize: 32,
                                                            color: '#fff',
                                                        }}
                                                    />
                                                </Box>
                                                <Typography variant="h6">
                                                    {card.name}
                                                </Typography>
                                                {isError ? (
                                                    <Typography
                                                        variant="body2"
                                                        color="inherit"
                                                    >
                                                        Errore nel caricamento
                                                    </Typography>
                                                ) : (
                                                    <Typography variant="body1">
                                                        {count}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </a>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
};

export default DashboardCliente;
