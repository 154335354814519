


import { Show, SimpleShowLayout, TopToolbar, TextField, EditButton } from 'react-admin';
import { ToBoolean } from '../shared/ToBoolean';
import { editButtonCss } from '../shared/Styles/buttonStyles';
import PeopleIcon from '@mui/icons-material/People';
import Button from '@mui/material/Button';
import { Breadcrumb } from '@react-admin/ra-navigation';
import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";
import { formCss } from "../shared/Styles/formCss";

import { checkPermission } from '../shared/Permissions/checkPermission';

const ShowActions = () => (
    <ShowTopToolbarDettaglio></ShowTopToolbarDettaglio>
);

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica" />
        <ConfirmDialogDeleteButtonCustom
            titolo={"Cancella record"}
            messaggio={"Sei sicuro di vole cancellare questo record ?"}
            servizio={"tabellealfa"} />
    </TopToolbar>
);


export const tabellealfaShow = () => {
    const canRead = checkPermission("tabellealfa", "read");

    if (!canRead) {
        return (
            <div style={{ color: "red", fontWeight: "bold", padding: 20 }}>
                Accesso negato
            </div>
        );
    }

    return (
        <Show title={<>
            <Button>
                <PeopleIcon></PeopleIcon>
                &nbsp;Dettaglio record di tabellealfa
            </Button>
        </>}
            actions={<ShowActions />}
        >
            <Breadcrumb />
            <SimpleShowLayout sx={formCss} >

                <TextField source="tbpref" />
                <TextField source="tbdes" />
                <TextField source="tbmy" />
                <ToBoolean source="flAttivo" />
                <ToBoolean source="flVisibile" />
                <ToBoolean source="flAnnullato" />

                <EditButton sx={editButtonCss} label="Modifica" />
            </SimpleShowLayout>
        </Show>
    );
}