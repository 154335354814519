// utils/fetchCustomServiceHelp.js.js
import axios from 'axios';
import config from '../../config/config.json';

const host = config.production.host;

// Questa funzinoe esegue il fetch di singolo record
export const fetchCustomServiceHelp = async (id, service) => {
    if (!id) return null;

    try {
        const jwt = localStorage.getItem("feathers-jwt");
        const response = await axios.get(
            `${host}/${service}/${id}`,
            { headers: { 'Authorization': `Bearer ${jwt}` } }
        );
        return response.data;
    } catch (error) {
        console.error(`Errore nel recupero dei dati per ${service} ID ${id}:`, error);
        throw error;
    }
};
