// Booking0Show.js
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    TextField,
    ReferenceField,
    DateField,
    Show,
    NumberField,
    TabbedShowLayout,
    useRecordContext,
    FunctionField,
    useRefresh,
} from 'react-admin';
import {
    Card,
    CardContent,
    Typography,
    Box,
    Grid,
    CircularProgress,
    useTheme,
} from '@mui/material';
import {
    EventNote as EventNoteIcon,
    Person as PersonIcon,
    Group as GroupIcon,
    Map as MapIcon,
    CarRentalRounded,
    Message as MessageIcon,
} from '@mui/icons-material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import axios from 'axios';

// Host backend
import config from '../../../config/config.json';

// Gestione lista documenti
import GestioneDocumenti from "../../documenti/GestioneDocumenti";

// Import dei componenti di dialogo
import ImpegnoCard from '../impegno/ImpegnoCard';
import ImpegnoNonServizioCard from '../impegno/ImpegnoNonServizioCard';
import ImpegnoNotturnoCard from '../impegno/ImpegnoNotturnoCard';
import ImpegnoVuotoCard from '../impegno/ImpegnoVuotoCard';
import ImpegnoServizioCard from '../impegno/ImpegnoServizioCard';
import ClienteBookingHDetails from './ClienteBookingHDetails';
import ClienteBookingHDetailsAutomezzi from './ClienteBookingHDetailsAutomezzi';
import formatDateForDisplay from '../../shared/CustomFields/LocalDateField';
import MessagesBookingTab from './MessagesBookingTab';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import VisibilityIcon from '@mui/icons-material/Visibility';
const host = config.production.host;

// Componente per il titolo del booking
const BookingTitle = () => {
    const record = useRecordContext();
    if (!record) return null;
    return (
        <Typography variant="h5" component="h1">
            Preventivo #{record.bk0_idnumber}{/*  - Serie: {record.bk0_idserie} */}
        </Typography>
    );
};

// Componente per le informazioni del cliente
const ClientInfo = () => {
    const record = useRecordContext();

    if (!record) return null;

    return (
        <>
            <Card>
                <CardContent>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '2px solid #1976d2',
                        paddingBottom: 1,
                        marginBottom: 2
                    }}>
                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                            <PersonIcon sx={{ mr: 1 }} />
                            Informazioni Cliente
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box mb={2}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Cliente
                                </Typography>
                                <ReferenceField
                                    source="bk0_clienteId"
                                    reference="anagen"
                                    link={false}
                                >
                                    <TextField source="gnfind" />
                                </ReferenceField>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box mb={2}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Tipo Servizio
                                </Typography>
                                <ReferenceField
                                    source="bk0_servizio"
                                    reference="servizi"
                                    link={false}
                                >
                                    <TextField source="ss_des" />
                                </ReferenceField>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box mb={2}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Tipo Richiesta
                                </Typography>
                                <ReferenceField
                                    source="bk0_richiestatipo"
                                    reference="view-tabellaalfa-trp"
                                    link={false}
                                >
                                    <TextField source="descrizione" />
                                </ReferenceField>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

// Componente per le date con layout migliorato
const DateInfo = () => {
    const record = useRecordContext();

    if (!record) return null;

    return (
        <>
            <Card
                sx={{
                    backgroundColor: '#fff',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    borderRadius: '8px'
                }}
            >
                <CardContent>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '2px solid #1976d2',
                        paddingBottom: 1,
                        marginBottom: 2
                    }}>
                        <Typography variant="h6" sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#1976d2'
                        }}>
                            <EventNoteIcon sx={{ mr: 1 }} />
                            Date del Servizio
                        </Typography>
                    </Box>

                    <Grid container spacing={3}>
                        {/* Prima riga con le date principali */}
                        <Grid item xs={12} md={6}>
                            <Card variant="outlined" sx={{
                                height: '100%',
                                backgroundColor: '#f8f9fa',
                                border: '1px solid #e0e0e0'
                            }}>
                                <CardContent>
                                    <Typography variant="subtitle1"
                                        sx={{
                                            color: '#1976d2',
                                            fontWeight: 500,
                                            marginBottom: 1
                                        }}>
                                        Periodo del Servizio
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2" color="textSecondary">
                                                Data Inizio
                                            </Typography>
                                            {/* <DateField
                                                source="bk0_datada"
                                                showTime
                                                sx={{
                                                    fontSize: '1.1rem',
                                                    fontWeight: 500
                                                }}
                                            /> */}
                                            <FunctionField
                                                label="Data di Inizio"
                                                render={(record) => {
                                                    console.log("Date ricevuta:", record.bk0_datada);
                                                    return formatDateForDisplay(record.bk0_datada);
                                                }}
                                                sx={{
                                                    fontSize: '1.1rem',
                                                    fontWeight: 500,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2" color="textSecondary">
                                                Data Fine
                                            </Typography>
                                            {/* <DateField
                                                source="bk0_dataal"
                                                showTime
                                                sx={{
                                                    fontSize: '1.1rem',
                                                    fontWeight: 500
                                                }}
                                            /> */}
                                            <FunctionField
                                                label="Data Fine"
                                                render={(record) => {
                                                    console.log("Date ricevuta:", record.bk0_dataal);
                                                    return formatDateForDisplay(record.bk0_dataal);
                                                }}
                                                sx={{
                                                    fontSize: '1.1rem',
                                                    fontWeight: 500,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Seconda riga con informazioni aggiuntive */}
                        <Grid item xs={12} md={6}>
                            <Card variant="outlined" sx={{
                                height: '100%',
                                backgroundColor: '#f8f9fa',
                                border: '1px solid #e0e0e0'
                            }}>
                                <CardContent>
                                    <Typography variant="subtitle1"
                                        sx={{
                                            color: '#1976d2',
                                            fontWeight: 500,
                                            marginBottom: 1
                                        }}>
                                        Dettagli Amministrativi
                                    </Typography>
                                    <Box>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Data Creazione Preventivo
                                        </Typography>
                                        {<DateField
                                            source="createdAt"
                                            showTime
                                            sx={{
                                                fontSize: '1.1rem',
                                                fontWeight: 500
                                            }}
                                        />}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

// Componente per le informazioni dei passeggeri con layout migliorato
const PassengersInfo = () => {
    const record = useRecordContext();

    if (!record) return null;

    return (
        <>
            <Card sx={{
                backgroundColor: '#fff',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                borderRadius: '8px',
                mt: 2
            }}>
                <CardContent>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '2px solid #1976d2',
                        paddingBottom: 1,
                        marginBottom: 2
                    }}>
                        <Typography variant="h6" sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#1976d2'
                        }}>
                            <GroupIcon sx={{ mr: 1 }} />
                            Composizione del Gruppo
                        </Typography>
                    </Box>

                    <Grid container spacing={3}>
                        {/* Card per il totale passeggeri */}
                        <Grid item xs={12} md={6}>
                            <Card variant="outlined" sx={{
                                backgroundColor: '#f8f9fa',
                                border: '1px solid #e0e0e0'
                            }}>
                                <CardContent>
                                    <Typography variant="subtitle1" sx={{
                                        color: '#1976d2',
                                        fontWeight: 500,
                                        marginBottom: 2
                                    }}>
                                        Passeggeri
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <Box sx={{
                                                textAlign: 'center',
                                                padding: '10px',
                                                backgroundColor: '#fff',
                                                borderRadius: '8px',
                                                border: '1px solid #e0e0e0'
                                            }}>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    Adulti
                                                </Typography>
                                                <NumberField
                                                    source="bk0_paxad"
                                                    sx={{
                                                        fontSize: '1.5rem',
                                                        fontWeight: 'bold',
                                                        color: '#1976d2'
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box sx={{
                                                textAlign: 'center',
                                                padding: '10px',
                                                backgroundColor: '#fff',
                                                borderRadius: '8px',
                                                border: '1px solid #e0e0e0'
                                            }}>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    Bambini
                                                </Typography>
                                                <NumberField
                                                    source="bk0_paxch"
                                                    sx={{
                                                        fontSize: '1.5rem',
                                                        fontWeight: 'bold',
                                                        color: '#1976d2'
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box sx={{
                                                textAlign: 'center',
                                                padding: '10px',
                                                backgroundColor: '#fff',
                                                borderRadius: '8px',
                                                border: '1px solid #e0e0e0'
                                            }}>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    Infanti
                                                </Typography>
                                                <NumberField
                                                    source="bk0_paxin"
                                                    sx={{
                                                        fontSize: '1.5rem',
                                                        fontWeight: 'bold',
                                                        color: '#1976d2'
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Card per i seggiolini */}
                        <Grid item xs={12} md={6}>
                            <Card variant="outlined" sx={{
                                backgroundColor: '#f8f9fa',
                                border: '1px solid #e0e0e0',
                                height: '100%'
                            }}>
                                <CardContent>
                                    <Typography variant="subtitle1" sx={{
                                        color: '#1976d2',
                                        fontWeight: 500,
                                        marginBottom: 2
                                    }}>
                                        Attrezzatura Richiesta
                                    </Typography>
                                    <Box sx={{
                                        textAlign: 'center',
                                        padding: '10px',
                                        backgroundColor: '#fff',
                                        borderRadius: '8px',
                                        border: '1px solid #e0e0e0'
                                    }}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Seggiolini
                                        </Typography>
                                        <NumberField
                                            source="bk0_nroseg"
                                            sx={{
                                                fontSize: '1.5rem',
                                                fontWeight: 'bold',
                                                color: '#1976d2'
                                            }}
                                        />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

const NotePerEsigenzeParrticolariInfo = () => {
    const record = useRecordContext();

    if (!record) return null;

    return (
        <>
            <Card>
                <CardContent>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '2px solid #1976d2',
                        paddingBottom: 1,
                        marginBottom: 2
                    }}>
                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                            <PersonIcon sx={{ mr: 1 }} />
                            Note per particolari esigenze
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box mb={2}>
                                <TextField
                                    source="bk0_ntextra"
                                    fullWidth
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

// Componenti per il fetch ------------------------------------ START

const useFetchImpegno = (id) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!id) return;

        const fetchData = async () => {
            try {
                const jwt = localStorage.getItem("feathers-jwt");
                const response = await axios.get(`${host}/view-booking-0-impegno-servizio?id=${id}`, {
                    headers: { 'Authorization': `Bearer ${jwt}` },
                });
                setData(response.data.data || []);
            } catch (err) {
                setError(err);
                console.error('Error fetching data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    return { data, loading, error };
};

const useFetchImpegnoNonServizio = (id) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!id) return;

        const fetchData = async () => {
            try {
                const jwt = localStorage.getItem("feathers-jwt");
                const response = await axios.get(`${host}/view-booking-2-impegno-non-servizio?id=${id}`, {
                    headers: { 'Authorization': `Bearer ${jwt}` },
                });
                setData(response.data.data || []);
            } catch (err) {
                setError(err);
                console.error('Error fetching data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    return { data, loading, error };
};

const useFetchImpegnoNotturno = (id) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!id) return;

        const fetchData = async () => {
            try {
                const jwt = localStorage.getItem("feathers-jwt");
                const response = await axios.get(`${host}/view-booking-2-impegno-notturno?id=${id}`, {
                    headers: { 'Authorization': `Bearer ${jwt}` },
                });
                setData(response.data.data || []);
            } catch (err) {
                setError(err);
                console.error('Error fetching data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    return { data, loading, error };
};

const useFetchImpegnoVuoto = (id) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!id) return;

        const fetchData = async () => {
            try {
                const jwt = localStorage.getItem("feathers-jwt");
                const response = await axios.get(`${host}/view-booking-2-impegno-vuoto?id=${id}`, {
                    headers: { 'Authorization': `Bearer ${jwt}` },
                });
                setData(response.data.data || []);
            } catch (err) {
                setError(err);
                console.error('Error fetching data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    return { data, loading, error };
};

const useFetchImpegnoServizio = (id) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!id) return;

        const fetchData = async () => {
            try {
                const jwt = localStorage.getItem("feathers-jwt");
                const response = await axios.get(`${host}/view-booking-2-impegno-servizio?id=${id}`, {
                    headers: { 'Authorization': `Bearer ${jwt}` },
                });
                setData(response.data.data || []);
            } catch (err) {
                setError(err);
                console.error('Error fetching data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    return { data, loading, error };
};

// Componenti per il fetch ------------------------------------ FINE //

const ImpegnoComplessivoInfo = () => {
    const record = useRecordContext(); // Context fornito da React-Admin
    const { data, loading, error } = useFetchImpegno(record?.id);

    if (!record) return null;

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Errore nel caricamento dei dati.</Typography>;

    return (
        <>
            {data.length > 0 ? (
                data.map((impegno) => <ImpegnoCard key={impegno.id} impegno={impegno} />)
            ) : (
                <Typography variant="body2" color="textSecondary">
                    {/* NESSUN IMPEGNO TROVATO */}
                </Typography>
            )}
        </>
    );
};

const ImpegnoNonServizioInfo = () => {
    const record = useRecordContext(); // Context fornito da React-Admin
    const { data, loading, error } = useFetchImpegnoNonServizio(record?.id);

    if (!record) return null;

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Errore nel caricamento dei dati.</Typography>;

    return (
        <>
            {data.length > 0 ? (
                data.map((impegno) => <ImpegnoNonServizioCard key={impegno.id} impegno={impegno} />)
            ) : (
                <Typography variant="body2" color="textSecondary">
                    {/* NESSUN IMPEGNO TROVATO */}
                </Typography>
            )}
        </>
    );
};

const ImpegnoNotturnoInfo = () => {
    const record = useRecordContext(); // Context fornito da React-Admin
    const { data, loading, error } = useFetchImpegnoNotturno(record?.id);

    if (!record) return null;

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Errore nel caricamento dei dati.</Typography>;

    return (
        <>
            {data.length > 0 ? (
                data.map((impegno) => <ImpegnoNotturnoCard key={impegno.id} impegno={impegno} />)
            ) : (
                <Typography variant="body2" color="textSecondary">
                    {/* NESSUN IMPEGNO TROVATO */}
                </Typography>
            )}
        </>
    );
};

const ImpegnoVuotoInfo = () => {
    const record = useRecordContext(); // Context fornito da React-Admin
    const { data, loading, error } = useFetchImpegnoVuoto(record?.id);

    if (!record) return null;

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Errore nel caricamento dei dati.</Typography>;

    return (
        <>
            {data.length > 0 ? (
                data.map((impegno) => <ImpegnoVuotoCard key={impegno.id} impegno={impegno} />)
            ) : (
                <Typography variant="body2" color="textSecondary">
                    {/* NESSUN IMPEGNO TROVATO */}
                </Typography>
            )}
        </>
    );
};

const ImpegnoServizioInfo = () => {
    const record = useRecordContext(); // Context fornito da React-Admin
    const { data, loading, error } = useFetchImpegnoServizio(record?.id);

    if (!record) return null;

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Errore nel caricamento dei dati.</Typography>;

    return (
        <>
            {data.length > 0 ? (
                data.map((impegno) => <ImpegnoServizioCard key={impegno.id} impegno={impegno} />)
            ) : (
                <Typography variant="body2" color="textSecondary">
                    {/* NESSUN IMPEGNO TROVATO */}
                </Typography>
            )}
        </>
    );
};

// Componenti per Visualizzazione Impegno ------------------------------------ FINE //

// Componente principale
const ClienteBooking0Show = () => {
    const theme = useTheme();
    const refresh = useRefresh();

    return (
        <Show title={<BookingTitle />}>
            <Box sx={{ marginX: theme => theme.spacing(2) }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <FunctionField
                            source=""
                            label=""
                            render={record =>
                                record && <ClienteBookingHDetails bookingId={record.id} />
                            }
                        />
                    </Grid>
                </Grid>

                <Box sx={{ marginTop: 4 }}>
                    <TabbedShowLayout>
                        <TabbedShowLayout.Tab
                            label="Informazioni Generali"
                            icon={<VisibilityIcon />}
                            path=""
                            sx={{
                                '&.Mui-selected': {
                                    borderBottom: `3px solid ${theme.palette.primary.main}`,
                                    backgroundColor: theme.palette.action.selected
                                }
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <ClientInfo />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                                        gap: 3
                                    }}>
                                        <ImpegnoComplessivoInfo />
                                        <ImpegnoNonServizioInfo />
                                        <ImpegnoNotturnoInfo />
                                        <ImpegnoVuotoInfo />
                                        <ImpegnoServizioInfo />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <DateInfo />
                                </Grid>
                                <Grid item xs={12}>
                                    <PassengersInfo />
                                </Grid>
                                <Grid item xs={12}>
                                    <NotePerEsigenzeParrticolariInfo></NotePerEsigenzeParrticolariInfo>
                                </Grid>
                            </Grid>
                        </TabbedShowLayout.Tab>

                        <TabbedShowLayout.Tab
                            label="Riepilogo Tratte"
                            icon={<DirectionsCarIcon />}
                            path="riepilogoTratte"
                            sx={{
                                '&.Mui-selected': {
                                    borderBottom: `3px solid ${theme.palette.primary.main}`,
                                    backgroundColor: theme.palette.action.selected
                                }
                            }}
                        >
                            <FunctionField
                                source=""
                                label=""
                                render={record => record &&
                                    <ClienteBookingHDetailsAutomezzi bookingId={record.id} />
                                }
                            />
                        </TabbedShowLayout.Tab>

                        <TabbedShowLayout.Tab
                            label="Messaggi"
                            icon={<MessageIcon />}
                            path="messaggi"
                            sx={{
                                '&.Mui-selected': {
                                    borderBottom: `3px solid ${theme.palette.primary.main}`,
                                    backgroundColor: theme.palette.action.selected
                                }
                            }}
                        >
                            {/* <Box sx={{
                                padding: 3,
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: '12px',
                                minHeight: 200,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Typography variant="h6" color="textSecondary">
                                    Sezione messaggi in sviluppo
                                </Typography>
                            </Box> */}
                            <FunctionField
                                // Non ci serve definire la "source" 
                                label=""
                                render={record =>
                                    record ? (
                                        <MessagesBookingTab booking0Id={record.id} bk0_idnumber={record.bk0_idnumber} />
                                    ) : (
                                        <Typography variant="body2">Nessun record</Typography>
                                    )
                                }
                            />
                        </TabbedShowLayout.Tab>
                        <TabbedShowLayout.Tab
                            sx={{
                                '&.Mui-selected': {
                                    borderBottom: '3px solid #01b5d0',
                                    backgroundColor: '#e8e8e8'
                                }
                            }}
                            icon={<AttachFileIcon />}
                            label="Documenti"
                            path="documenti"
                        >
                            <p><strong>Documenti</strong></p>
                            <GestioneDocumenti tipoDocumentoFisso={true} tipodocumentoIdFisso="7" tiporecordId="5" refresh={refresh} ></GestioneDocumenti>
                        </TabbedShowLayout.Tab>
                    </TabbedShowLayout>
                </Box>
            </Box>
        </Show>
    );
};

export default ClienteBooking0Show;