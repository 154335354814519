
import * as React from "react";
import {
    EditButton,
    TextInput,
    TextField,
    TopToolbar,
    Show,
    TabbedForm,
    ReferenceManyCount,
    ReferenceManyField,
    useRecordContext,
    FunctionField,
    useRefresh,
    RichTextField,
    BooleanInput
} from 'react-admin';

import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";

import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";

import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import HomeIcon from '@mui/icons-material/Home';
import Alert from '@mui/material/Alert';
import { ToBoolean } from '../shared/ToBoolean';
import { Divider } from '@mui/material';

// Impost Custom List Page 
import EmptyListCustom from '../shared/emptyPageCustom/empryPageCustom';
import EmptyListCustomNoCreate from '../shared/emptyPageCustom/empryPageCustomNoCreate';

// Importo il css per i Bottoni
import { editButtonCss, saveButtonCss, showButtonCss, exportButtonCss, columnsButtonCss, createButtonCss, filterButtonCss } from '../shared/Styles/buttonStyles';
import { listCss, listItemDefault } from '../shared/Styles/listStyles';

import CustomTextFieldDataOra from "../shared/FormFields/CustomTextFieldDataOra";

// Gestione lista note
import GestioneNote from "../note/GestioneNote";
import GetDescrizioneVoce from "./GetDescrizioneVoce";
import GestioneUsersInShow from "./UserInfo";
import ServicePermissionsManager from "./ServicePermissionsManager";

const ShowActions = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica" />
    </TopToolbar>
);


const GruppiShow = (props) => {

    const refresh = useRefresh();

    return (
        <Show title={<>
            &nbsp;Dettaglio Gruppo&nbsp;
            <TextField label="Nome" source="descrizione" />
        </>} actions={<ShowActions />}>

            <TabbedForm toolbar={null}>
                <TabbedForm.Tab label="Dati Gruppo"
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                                <HomeIcon />
                                                Nome Gruppo: <TextField label="" source="descrizione" />
                                            </Box>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 1 }}>
                                                <HomeIcon />
                                                Specifiche di gruppo: <RichTextField label="" source="specifiche" />
                                            </Box>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                    label="Users" count={<ReferenceManyCount reference="users-gruppi"
                        target="gruppiId" />}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Alert severity="info">Attenzione, in questo tab puoi selezionare solo user con ruolo <strong>Gruppi</strong></Alert>
                            <ReferenceManyField
                                reference="users-gruppi"
                                target="gruppiId"
                                sort={{ field: 'id', order: 'ASC' }}
                            >
                                <GestioneUsersInShow></GestioneUsersInShow>
                            </ReferenceManyField>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                    label="Permessi Voci Menu" count={<ReferenceManyCount reference="permessi"
                        target="usersGroupId" />}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Alert severity="info">Gestione permessi di gruppi</Alert>
                        </Grid>
                        <ReferenceManyField
                            reference="permessi"
                            target="usersGroupId"
                            sort={{ field: 'menuprogId', order: 'ASC' }}
                            filter={{ tipoPermesso: 'G' }}
                        >
                            <GestionePermessiInShow></GestionePermessiInShow>
                        </ReferenceManyField>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                    label="Permessi Servizi">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Alert severity="info">Gestione permessi dei singoli servizi</Alert>
                        </Grid>
                        <Box sx={{ margin: 2 }} >
                            <ReferenceManyField
                                reference="servicegroup"
                                target="gruppiId"
                                sort={{ field: 'id', order: 'ASC' }}
                                perPage={100}
                            >
                                <ServicePermissionsManager></ServicePermissionsManager>
                            </ReferenceManyField>
                        </Box>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                    label="Messaggi" path="annotazioni">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <p><strong>Comunicazioni interne</strong></p>
                            <GestioneNote tiporecordId="4" refresh={refresh} ></GestioneNote>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab
                    sx={{
                        '&.Mui-selected': {
                            borderBottom: '3px solid #01b5d0',
                            backgroundColor: '#e8e8e8'
                        }
                    }}
                    label="Proprieta" path="proprieta">
                    <div>
                        <div>
                            {<CustomTextFieldDataOra label="Data creazione" source="createdAt" />}
                            <br></br>
                            {<CustomTextFieldDataOra label="Data ultima modifica" source="updatedAt" />}
                        </div>
                    </div>
                    <div>
                        {<ToBoolean label="Attivo" source="flAttivo" labelOn={true}></ToBoolean>}
                        {<ToBoolean label="Visibile" source="flVisibile" labelOn={true}></ToBoolean>}
                        {<ToBoolean label="Annullato" source="flAnnullato" labelOn={true}></ToBoolean>}
                    </div>
                    <Divider variant="middle" />
                    <ConfirmDialogDeleteButtonCustom
                        titolo={"Cancella Gruppo"}
                        messaggio={"Sei sicuro di vole cancellare questo gruppo ?"}
                        servizio={"gruppi"} />
                </TabbedForm.Tab>
            </TabbedForm>

        </Show >
    );
}

const GestionePermessiInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<EditableDatagrid noDelete
        bulkActionButtons={false}
        editForm={<PermessiShowDisponibiliRowForm />}
    >
        {/* <Card variant="outlined"
            sx={{ margin: 1, borderRadius: 2 }}
        >
            <CardContent> */}
        {/* <TextField source="id" ></TextField> */}
        <ReferenceManyField sortable={false} label="" reference="permessi" target="usersGroupId">
            {/* <TextField source="menuprogId" /> */}
            <FunctionField
                sortable={false}
                source="menuprogId" // used for sorting
                label="Voce menu"
                render={record => record && <GetDescrizioneVoce id={record.menuprogId} ></GetDescrizioneVoce>}
            />
        </ReferenceManyField>



        {/* </CardContent>
        </Card> */}

        {<ToBoolean sortable={false} label="Attivo" source="flAttivo" labelOn={true}></ToBoolean>}
        {<ToBoolean sortable={false} label="Visibile" source="flVisibile" labelOn={true}></ToBoolean>}
        {<ToBoolean sortable={false} label="Annullato" source="flAnnullato" labelOn={true}></ToBoolean>}
        {<ToBoolean sortable={false} label="Abilitato" source="flAbilitato" labelOn={true}></ToBoolean>}

    </EditableDatagrid>);

}

const GestionePermessiServicesInShow = ({ id, ...props }) => {

    const record = useRecordContext();
    if (!record) return null;

    return (<EditableDatagrid noDelete
        bulkActionButtons={false}
        editForm={<PermessiServicesShowDisponibiliRowForm />}
    >
        <TextField sx={listItemDefault} source="nome" ></TextField>
        <TextField source="descrizione" ></TextField>
        <ToBoolean label="Read" source="read_permission" labelOn={true} />
        <ToBoolean label="Create" source="create_permission" labelOn={true} />
        <ToBoolean label="Update" source="update_permission" labelOn={true} />
        <ToBoolean label="Patch" source="patch_permission" labelOn={true} />
        <ToBoolean label="Remove" source="remove_permission" labelOn={true} />
    </EditableDatagrid>);

}

const PermessiShowDisponibiliRowForm = ({ id, ...props }) => {
    return (
        <RowForm  {...props}>
            <ReferenceManyField label="" reference="permessi" target="usersGroupId">
                {/* <TextField source="menuprogId" /> */}
                <FunctionField
                    source="menuprogId" // used for sorting
                    label="Voce menu"
                    render={record => record && <GetDescrizioneVoce id={record.menuprogId} ></GetDescrizioneVoce>}
                />
            </ReferenceManyField>
            <BooleanInput label="Attivo" source="flAttivo" />
            <BooleanInput label="Visibile" source="flVisibile" />
            <BooleanInput label="Annullato" source="flAnnullato" />
            <BooleanInput label="Abilitato" source="flAbilitato" />
            <TextInput source="usersGroupId" defaultValue={parseInt(id)} disabled ></TextInput>
        </RowForm>
    );

}

const PermessiServicesShowDisponibiliRowForm = ({ id, ...props }) => {
    return (
        <RowForm  {...props}>
            <TextField source="nome" ></TextField>
            <TextField source="descrizione" ></TextField>
            <BooleanInput label="Read" source="read_permission" />
            <BooleanInput label="Create" source="create_permission" />
            <BooleanInput label="Update" source="update_permission" />
            <BooleanInput label="Patch" source="patch_permission" />
            <BooleanInput label="Remove" source="remove_permission" />
            <TextInput source="gruppiId" defaultValue={parseInt(id)} disabled ></TextInput>
        </RowForm>
    );

}

export default GruppiShow;