import React, { useState } from "react";
import {
    Grid,
    Box,
    Button,
    IconButton,
    InputAdornment,
    TextField,
    Switch,
    FormControlLabel,
    Paper,
    Typography,
    Divider
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import ClearIcon from "@mui/icons-material/Clear";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

export const SearchBar = ({ onSearch, onReset, onOpenAdvanced }) => {
    const [searchValues, setSearchValues] = useState({});
    const [useWildcard, setUseWildcard] = useState({
        id: false
    });

    const handleChange = (field, value) => {
        if (value === '') {
            const newValues = { ...searchValues };
            delete newValues[field];
            setSearchValues(newValues);
            return;
        }

        setSearchValues((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleWildcardToggle = (field) => {
        setUseWildcard(prev => ({
            ...prev,
            [field]: !prev[field]
        }));
        handleChange(field, '');
    };

    const handleClear = (field) => {
        const newValues = { ...searchValues };
        delete newValues[field];
        setSearchValues(newValues);
    };

    const handleSearch = () => {
        const filteredValues = {};

        Object.entries(searchValues).forEach(([field, value]) => {
            if (!value || value.toString().trim() === '') return;

            if (field === 'id' && useWildcard[field]) {
                if (value.includes('*')) {
                    filteredValues[`${field}.like`] = value;
                } else {
                    filteredValues[`${field}.eq`] = value;
                }
            } else if (field === 'id') {
                filteredValues[`${field}.eq`] = value;
            } else {
                filteredValues[field] = value;
            }
        });

        onSearch(filteredValues);
    };

    // Stile personalizzato per i TextField
    const textFieldStyle = {
        '& .MuiOutlinedInput-root': {
            backgroundColor: '#ffffff',
            '&:hover': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#1976d2',
                }
            }
        },
        '& .MuiInputLabel-root': {
            color: '#666',
            '&.Mui-focused': {
                color: '#1976d2'
            }
        }
    };

    return (
        <Paper elevation={3} sx={{ p: 3, backgroundColor: '#f8f9fa' }}>
            <Box sx={{ mb: 2 }}>
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1, color: '#1976d2' }}>
                    <FilterAltIcon />
                    Filtri Autisti
                </Typography>
            </Box>
            <Divider sx={{ mb: 3 }} />

            <Grid container spacing={3} alignItems="flex-start">
                {/* Campo ID con Wildcard */}
                <Grid item xs={12} sm={6} md={2}>
                    <Box sx={{
                        p: 2,
                        borderRadius: 1,
                        backgroundColor: 'white',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                    }}>
                        <TextField
                            label="ID"
                            type={useWildcard.id ? 'text' : 'number'}
                            value={searchValues["id"] || ""}
                            onChange={(e) => handleChange("id", e.target.value)}
                            placeholder={useWildcard.id ? 'Usa * come wildcard' : 'Inserisci ID'}
                            fullWidth
                            size="small"
                            sx={textFieldStyle}
                            InputProps={{
                                endAdornment: searchValues["id"] && (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => handleClear("id")} size="small">
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    size="small"
                                    checked={useWildcard.id || false}
                                    onChange={() => handleWildcardToggle("id")}
                                />
                            }
                            label={
                                <Typography variant="body2" sx={{ fontSize: '0.875rem', color: '#666' }}>
                                    Usa wildcard
                                </Typography>
                            }
                        />
                    </Box>
                </Grid>

                {/* Campo Nominativo */}
                <Grid item xs={12} sm={6} md={3}>
                    <Box sx={{
                        p: 2,
                        borderRadius: 1,
                        backgroundColor: 'white',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                    }}>
                        <TextField
                            label="Nominativo"
                            value={searchValues["nominativo"] || ""}
                            onChange={(e) => handleChange("nominativo", e.target.value)}
                            fullWidth
                            size="small"
                            sx={textFieldStyle}
                            InputProps={{
                                endAdornment: searchValues["nominativo"] && (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => handleClear("nominativo")} size="small">
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>

                {/* Campo Patente */}
                <Grid item xs={12} sm={6} md={2}>
                    <Box sx={{
                        p: 2,
                        borderRadius: 1,
                        backgroundColor: 'white',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                    }}>
                        <TextField
                            label="Patente"
                            value={searchValues["patente"] || ""}
                            onChange={(e) => handleChange("patente", e.target.value)}
                            fullWidth
                            size="small"
                            sx={textFieldStyle}
                            InputProps={{
                                endAdornment: searchValues["patente"] && (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => handleClear("patente")} size="small">
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>

                {/* Campo Città */}
                <Grid item xs={12} sm={6} md={2}>
                    <Box sx={{
                        p: 2,
                        borderRadius: 1,
                        backgroundColor: 'white',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                    }}>
                        <TextField
                            label="Città"
                            value={searchValues["città"] || ""}
                            onChange={(e) => handleChange("città", e.target.value)}
                            fullWidth
                            size="small"
                            sx={textFieldStyle}
                            InputProps={{
                                endAdornment: searchValues["città"] && (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => handleClear("città")} size="small">
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>

                {/* Pulsanti */}
                <Grid item xs={12} sm={6} md={3}>
                    <Box sx={{
                        p: 2,
                        borderRadius: 1,
                        backgroundColor: 'white',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1
                    }}>
                        <Button
                            startIcon={<SearchIcon />}
                            variant="contained"
                            onClick={handleSearch}
                            fullWidth
                            sx={{
                                textTransform: 'none',
                                boxShadow: '0 2px 4px rgba(25,118,210,0.2)',
                                '&:hover': {
                                    boxShadow: '0 4px 8px rgba(25,118,210,0.3)'
                                }
                            }}
                        >
                            Cerca
                        </Button>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button
                                startIcon={<TuneIcon />}
                                variant="outlined"
                                onClick={onOpenAdvanced}
                                fullWidth
                                sx={{ textTransform: 'none' }}
                            >
                                Filtri Autisti
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={onReset}
                                fullWidth
                                sx={{ textTransform: 'none' }}
                            >
                                Reset
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
};