import React, { useState, useEffect } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    ReferenceInput,
    SelectInput,
    useDataProvider,
    useNotify,
    useStore,
    useCreate,
    useRedirect,
    required
} from 'react-admin';
import { Card, CardContent } from '@mui/material';
import CustomAutocompleInput from '../shared/FormFields/CustomAutocompleInput';

export const ScadenzeCreate = (props) => {
    const notify = useNotify();
    const [create] = useCreate();
    const redirect = useRedirect();
    const [idUser] = useStore('profile.idUser');
    const dataProvider = useDataProvider();

    const [selectedTiporecordId, setSelectedTiporecordId] = useState(null);
    const [selectedArchive, setSelectedArchive] = useState(null);
    const [selectedOptionText, setSelectedOptionText] = useState(null);

    const handleSubmit = async (values) => {
        if (!idUser) {
            notify("Errore: utente non autenticato", { type: 'error' });
            return;
        }

        // Verifica che recordId sia popolato correttamente
        if (!values.recordId) {
            notify("Errore: il campo recordId è obbligatorio", { type: 'error' });
            return;
        }

        const data = {
            ...values,
            userId: idUser,
            flChiusa: false,
            flPromemoria: false,
            flAttivo: true,
            flVisibile: true,
            flAnnullato: false,
            ordinamento: 0,
            promemoriaId: 0,
            notePromemoria: '',
            ggPromemoria: 0
        };

        try {
            await create(
                'scadenze',
                { data },
                {
                    onSuccess: () => {
                        notify('Scadenza creata con successo', { type: 'success' });
                        redirect('list', 'scadenze');
                    },
                    onError: (error) => {
                        notify(`Errore durante la creazione: ${error.message}`, { type: 'error' });
                    },
                }
            );
        } catch (error) {
            notify(`Errore: ${error.message}`, { type: 'error' });
        }
    };


    const loadAutocompleteSettings = async (tiporecordId) => {
        try {
            const { data } = await dataProvider.getOne('tiporecord', { id: tiporecordId });
            const archive = data.archivio;
            const optionTextField = data.campo;

            if (archive && optionTextField) {
                setSelectedArchive(archive);
                setSelectedOptionText(optionTextField);
            } else {
                setSelectedArchive(null);
                setSelectedOptionText(null);
                notify(`Errore: il tipo record selezionato non ha un archivio o un campo associato`, { type: 'error' });
            }
        } catch (error) {
            notify(`Errore durante il caricamento del tipo record: ${error.message}`, { type: 'error' });
        }
    };

    useEffect(() => {
        if (selectedTiporecordId) {
            loadAutocompleteSettings(selectedTiporecordId);
        } else {
            setSelectedArchive(null);
            setSelectedOptionText(null);
        }
    }, [selectedTiporecordId]);

    if (!idUser) {
        return (
            <Card>
                <CardContent>
                    <p>Errore: utente non autenticato</p>
                </CardContent>
            </Card>
        );
    }

    return (
        <Create {...props}>
            <SimpleForm onSubmit={handleSubmit}>
                {/* Tipo Scadenza */}
                <ReferenceInput source="tipoScadenzaId" reference="tipo-scadenza">
                    <SelectInput optionText="nome" label="Tipo di Scadenza" />
                </ReferenceInput>

                {/* Tipo Record */}
                <ReferenceInput source="tiporecordId" reference="tiporecord">
                    <SelectInput
                        optionText="descrizione"
                        label="Tipo di Record"
                        onChange={(e) => setSelectedTiporecordId(e.target.value)} // Usa lo stato per tracciare il valore selezionato
                    />
                </ReferenceInput>

                {/* Autocomplete dinamico */}
                {selectedArchive && selectedOptionText && (
                    <CustomAutocompleInput
                        source="recordId"
                        reference={selectedArchive}
                        optionProperty={selectedOptionText} // Campo dinamico
                        label={`Seleziona da ${selectedArchive}`}
                        readOnly={false}
                        filterToQuery={(searchText) => ({ [selectedOptionText]: searchText })} // Filtro dinamico
                    />
                )}

                {/* Stato Scadenza */}
                <ReferenceInput source="statoScadenzaId" reference="stato-scadenza">
                    <SelectInput optionText="nome" label="Stato della Scadenza" />
                </ReferenceInput>

                <DateInput source="data" label="Data Scadenza" validate={required()} />

                <TextInput
                    source="note"
                    label="Descrizione"
                    fullWidth
                    multiline
                    validate={required()}
                />

                <TextInput
                    source="noteAggiuntive"
                    label="Note Aggiuntive"
                    fullWidth
                    multiline
                />
            </SimpleForm>
        </Create>
    );
};
