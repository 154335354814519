import React from 'react';
import { useNotify } from 'react-admin';
import Button from '@mui/material/Button';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const CopyToClipboardButtonWithLabel = ({ text, label = "" }) => {
    const notify = useNotify();

    const copyToClipboard = async (textToCopy) => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            notify('Copiato negli appunti!', 'info');
        } catch (err) {
            notify('Errore durante la copia!', 'warning');
        }
    };

    return (
        <Button
            onClick={() => copyToClipboard(text)}
            startIcon={<FileCopyIcon />}
            variant="outlined"
        >
            {label}
        </Button>
    );
};

export default CopyToClipboardButtonWithLabel;
