import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
    MenuItem,
    FormControlLabel,
    Switch,
    Typography,
    Divider,
    Autocomplete
} from "@mui/material";
import { useGetList } from 'react-admin';

export const TariffeaAdvancedFiltersDialog = ({ open, onClose, onApply, onReset, currentFilters }) => {
    const [filters, setFilters] = useState(currentFilters || {});

    // Fetch delle liste per gli autocomplete
    const { data: clienti } = useGetList('anagen', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' }
    });

    const { data: servizi } = useGetList('servizi', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' }
    });

    useEffect(() => {
        setFilters(currentFilters || {});
    }, [currentFilters]);

    const handleApply = () => {
        const formattedFilters = {};

        Object.entries(filters).forEach(([key, value]) => {
            if (value === "" || value === null || value === undefined) return;

            switch (key) {
                case "tfa_des":
                    formattedFilters[`${key}.like`] = `*${value}*`;
                    break;
                case "tfa_kmtratta0":
                case "tfa_kmtratta1":
                case "tfa_bdgkm":
                case "tfa_bdghh":
                case "tfa_maxkmvuoto":
                case "tfa_maxmmvuoto":
                case "tfa_frgmmec":
                case "tfa_frgmmnt":
                    if (value.min !== undefined && value.max !== undefined) {
                        formattedFilters[`${key}.between`] = [value.min, value.max];
                    } else if (value.min !== undefined) {
                        formattedFilters[`${key}.gte`] = value.min;
                    } else if (value.max !== undefined) {
                        formattedFilters[`${key}.lte`] = value.max;
                    }
                    break;
                case "tfa_clienteId":
                case "tfa_servizio":
                case "tfa_tipo":
                case "tfa_isresday":
                    formattedFilters[key] = value;
                    break;
                default:
                    formattedFilters[key] = value;
            }
        });

        console.log('Filtri formattati:', formattedFilters);
        onApply(formattedFilters);
        onClose();
    };

    const handleChange = (field, value) => {
        setFilters(prev => ({ ...prev, [field]: value }));
    };

    const handleRangeChange = (field, type, value) => {
        setFilters((prev) => ({
            ...prev,
            [field]: {
                ...prev[field],
                [type]: value === "" ? undefined : Number(value)
            }
        }));
    };

    const RangeFields = ({ title, minField, maxField, minLabel, maxLabel }) => (
        <>
            <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>{title}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label={minLabel}
                    type="number"
                    fullWidth
                    value={filters[minField]?.min || ""}
                    onChange={(e) => handleRangeChange(minField, "min", e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label={maxLabel}
                    type="number"
                    fullWidth
                    value={filters[maxField]?.max || ""}
                    onChange={(e) => handleRangeChange(maxField, "max", e.target.value)}
                />
            </Grid>
        </>
    );

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogContent>
                <Grid container spacing={2}>
                    {/* Filtri testuali */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Descrizione"
                            fullWidth
                            value={filters["tfa_des"] || ""}
                            onChange={(e) => handleChange("tfa_des", e.target.value)}
                        />
                    </Grid>

                    {/* Cliente Autocomplete */}
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            options={clienti || []}
                            getOptionLabel={(option) =>
                                typeof option === 'object' ? `${option.gnfind || ''} (${option.id})` : ''
                            }
                            isOptionEqualToValue={(option, value) => {
                                if (!option || !value) return false;
                                return option.id === value.id;
                            }}
                            value={clienti?.find(c => c.id === filters["tfa_clienteId"]) || null}
                            onChange={(e, newValue) => {
                                handleChange("tfa_clienteId", newValue ? newValue.id : null)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Cliente"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>

                    {/* Servizio Autocomplete */}
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            options={servizi || []}
                            getOptionLabel={(option) =>
                                typeof option === 'object' ? `${option.ss_des || ''} (${option.id})` : ''
                            }
                            isOptionEqualToValue={(option, value) => {
                                if (!option || !value) return false;
                                return option.id === value.id;
                            }}
                            value={servizi?.find(s => s.id === filters["tfa_servizio"]) || null}
                            onChange={(e, newValue) => {
                                handleChange("tfa_servizio", newValue ? newValue.id : null)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Servizio"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}><Divider sx={{ my: 2 }} /></Grid>

                    {/* Kilometri */}
                    <RangeFields
                        title="Range Kilometri"
                        minField="tfa_kmtratta0"
                        maxField="tfa_kmtratta1"
                        minLabel="Km Start Min"
                        maxLabel="Km Stop Max"
                    />

                    <Grid item xs={12}><Divider sx={{ my: 2 }} /></Grid>

                    {/* Budget */}
                    <RangeFields
                        title="Budget"
                        minField="tfa_bdgkm"
                        maxField="tfa_bdghh"
                        minLabel="Budget Km Min"
                        maxLabel="Budget Ore Max"
                    />

                    <Grid item xs={12}><Divider sx={{ my: 2 }} /></Grid>

                    {/* Vuoto MAX Fatturabile */}
                    <RangeFields
                        title="Vuoto Massimo Fatturabile"
                        minField="tfa_maxkmvuoto"
                        maxField="tfa_maxmmvuoto"
                        minLabel="Km Min"
                        maxLabel="Minuti Max"
                    />

                    <Grid item xs={12}><Divider sx={{ my: 2 }} /></Grid>

                    {/* Franchigie */}
                    <RangeFields
                        title="Minuti Franchigia"
                        minField="tfa_frgmmec"
                        maxField="tfa_frgmmnt"
                        minLabel="Extra Budget Min"
                        maxLabel="Notturno Max"
                    />

                    <Grid item xs={12}><Divider sx={{ my: 2 }} /></Grid>

                    {/* Tipo */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            label="Tipo"
                            fullWidth
                            value={filters["tfa_tipo"] || ""}
                            onChange={(e) => handleChange("tfa_tipo", e.target.value)}
                        >
                            <MenuItem value="">Tutti</MenuItem>
                            <MenuItem value="1">TKm</MenuItem>
                            <MenuItem value="2">PAX</MenuItem>
                        </TextField>
                    </Grid>

                    {/* Switch */}
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={filters["tfa_isresday"] === 1}
                                    onChange={(e) => handleChange("tfa_isresday", e.target.checked ? 1 : 0)}
                                />
                            }
                            label="Resoconto giornaliero"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onReset} color="secondary">
                    Reset
                </Button>
                <Button onClick={handleApply} variant="contained">
                    Applica
                </Button>
            </DialogActions>
        </Dialog>
    );
};