import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Switch,
    FormControlLabel,
} from '@mui/material';
import {
    useGetList,
    useUpdate,
    useNotify,
    ListBase,
    Datagrid,
    TextField,
    NumberField,
    FunctionField,
} from 'react-admin';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { GetNameFromResourceById } from '../../shared/CustomFields/GetNameFromResourceById';

const TariffSelectionDialog = ({ open, onClose, record }) => {
    const notify = useNotify();
    const [selectedTariff, setSelectedTariff] = useState(null);
    const [useZeroClienteId, setUseZeroClienteId] = useState(false); // Flag per il filtro
    const [update] = useUpdate();

    const filter = {
        tfa_clienteId: useZeroClienteId ? 0 : record?.bk0_clienteId,
        tfa_servizio: record?.bk0_servizio,
    };

    const { data } = useGetList('tariffe-a', { filter, pagination: { page: 1, perPage: 200 }, });

    const handleUpdate = () => {
        if (!selectedTariff) return;

        update(
            'booking-0',
            {
                id: record.id,
                data: { tariffeAId: selectedTariff.id },
            },
            {
                onSuccess: () => {
                    notify('Tariffa aggiornata con successo', { type: 'success' });
                    onClose();
                },
                onError: (error) => {
                    notify('Errore durante l\'aggiornamento della tariffa', { type: 'error' });
                },
            }
        );
    };

    const ActionButtons = ({ record }) => (
        <Button
            variant="contained"
            size="small"
            onClick={(e) => {
                e.stopPropagation();
                setSelectedTariff(record);
            }}
            sx={{ mx: 1 }}
        >
            Seleziona
        </Button>
    );

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Seleziona Tariffa - bk0_clienteId: {record?.bk0_clienteId} - bk0_servizio: {record?.bk0_servizio}
            </DialogTitle>
            <DialogContent>
                <Box sx={{ mb: 2 }}>
                    {/* Switch per attivare/disattivare il filtro */}
                    <FormControlLabel
                        control={
                            <Switch
                                checked={useZeroClienteId}
                                onChange={(e) => setUseZeroClienteId(e.target.checked)}
                            />
                        }
                        label="Abilita Generale/Contratto (On/Off)"
                    />
                </Box>

                <Box sx={{ height: 400, width: '100%', mt: 2, overflow: 'auto' }}>
                    <ListBase
                        resource="tariffe-a"
                        filter={filter}
                        sort={{ field: 'id', order: 'DESC' }}
                    >
                        <Datagrid
                            bulkActionButtons={false}
                            rowStyle={(record) => ({
                                backgroundColor: record.id === selectedTariff?.id ? '#eef' : undefined,
                            })}
                        >
                            <TextField source="id" />
                            <FunctionField
                                source="id"
                                label=""
                                render={(record) => (
                                    <GetNameFromResourceById
                                        id={record.tfa_servizio}
                                        resource="servizi"
                                        fieldName="ss_des"
                                    />
                                )}
                            />
                            <TextField source="tfa_des" label="Descrizione" />
                            <NumberField source="tfa_bdgkm" label="Budget KM" />
                            <NumberField source="tfa_bdghh" label="Budget Ore" />
                            <NumberField source="tfa_frgmmec" label="Franchigia Mec." />
                            <FunctionField
                                label="Azioni"
                                render={(record) => <ActionButtons record={record} />}
                            />
                        </Datagrid>
                    </ListBase>
                </Box>

                {/* Blocco JSON in Accordion */}
                <Accordion sx={{ mt: 2 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="subtitle1">Debug JSON</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <pre
                            style={{
                                backgroundColor: '#f4f4f4',
                                padding: '10px',
                                borderRadius: '5px',
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                            }}
                        >
                            {JSON.stringify(
                                {
                                    filter,
                                    responseData: data,
                                    recordId: record?.id,
                                    selectedTariff: selectedTariff,
                                },
                                null,
                                2
                            )}
                        </pre>
                    </AccordionDetails>
                </Accordion>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button onClick={onClose} sx={{ mr: 1 }}>
                        Annulla
                    </Button>
                    <Button variant="contained" onClick={handleUpdate} disabled={!selectedTariff}>
                        Conferma
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default TariffSelectionDialog;
