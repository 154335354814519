// TariffeBEditDialog.js
import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    CircularProgress,
} from '@mui/material';
import {
    Form,
    TextInput,
    NumberInput,
    useNotify,
    useRefresh,
    required,
    minValue,
    maxValue,
} from 'react-admin';

const validatePercentage = [required(), minValue(0), maxValue(100)];
const validateCurrency = [required(), minValue(0)];
const validatePositiveNumber = [required(), minValue(0)];

export const TariffeBEditDialog = ({ open, onClose, record, save }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [saving, setSaving] = useState(false);

    const handleSubmit = async (values) => {
        setSaving(true);
        try {
            // Validazione aggiuntiva dei dati
            if (values.tfb_vkmperc > 100 || values.tfb_vhhperc > 100) {
                throw new Error('Le percentuali non possono superare il 100%');
            }

            // Formattazione dei valori numerici
            const formattedValues = {
                ...values,
                id: record.id,
                tfa_id: record.tfa_id,
                tfb_gruppo: record.tfb_gruppo,
                tfb_ckm: Number(values.tfb_ckm || 0).toFixed(3),
                tfb_ekm: Number(values.tfb_ekm || 0).toFixed(3),
                tfb_chh: Number(values.tfb_chh || 0).toFixed(3),
                tfb_ehh: Number(values.tfb_ehh || 0).toFixed(3),
                tfb_snotturno: Number(values.tfb_snotturno || 0).toFixed(2),
                tfb_cpaxa: Number(values.tfb_cpaxa || 0).toFixed(3),
                tfb_cpaxc: Number(values.tfb_cpaxc || 0).toFixed(3),
                tfb_cpaxi: Number(values.tfb_cpaxi || 0).toFixed(3),
                tfb_npaxmin: Number(values.tfb_npaxmin || 0),
                tfb_vkm: Number(values.tfb_vkm || 0),
                tfb_vhh: Number(values.tfb_vhh || 0),
                tfb_evkm: Number(values.tfb_evkm || 0).toFixed(3),
                tfb_vkmperc: Number(values.tfb_vkmperc || 0).toFixed(2),
                tfb_vhhperc: Number(values.tfb_vhhperc || 0).toFixed(2),
            };

            await save(formattedValues);
            notify('Tariffa aggiornata con successo', { type: 'success' });
            refresh();
            onClose();
        } catch (error) {
            console.error('Errore durante il salvataggio:', error);
            notify(error.message || 'Errore durante il salvataggio', { type: 'error' });
        } finally {
            setSaving(false);
        }
    };

    if (!record) return null;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    maxHeight: '90vh',
                }
            }}
        >
            <Form
                onSubmit={handleSubmit}
                record={record}
            >
                <DialogTitle>
                    Modifica Tariffa - {record.tfb_des || `Gruppo ${record.tfb_gruppo}`}
                </DialogTitle>

                <DialogContent>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        {/* Dati Base */}
                        <Grid item xs={12} md={12}>
                            <TextInput disabled fullWidth label="Gruppo" source="tfb_gruppo" />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextInput
                                fullWidth
                                label="Descrizione"
                                source="tfb_des"
                                validate={required()}
                            />
                        </Grid>

                        {/* Costi Base */}
                        <Grid item xs={12} md={6}>
                            <NumberInput
                                fullWidth
                                label="Costo Km di Base"
                                source="tfb_ckm"
                                validate={validateCurrency}
                                options={{
                                    style: "currency",
                                    currency: "EUR",
                                    minimumFractionDigits: 3,
                                    maximumFractionDigits: 3
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <NumberInput
                                fullWidth
                                label="Costo Minuto di Budget"
                                source="tfb_chh"
                                validate={validateCurrency}
                                options={{
                                    style: "currency",
                                    currency: "EUR",
                                    minimumFractionDigits: 3,
                                    maximumFractionDigits: 3
                                }}
                            />
                        </Grid>

                        {/* Costi Extra */}
                        <Grid item xs={12} md={6}>
                            <NumberInput
                                fullWidth
                                label="Costo Supplemento Km ExtraBudget"
                                source="tfb_ekm"
                                validate={validateCurrency}
                                options={{
                                    style: "currency",
                                    currency: "EUR",
                                    minimumFractionDigits: 3,
                                    maximumFractionDigits: 3
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <NumberInput
                                fullWidth
                                label="Costo Supplemento Min ExtraBudget"
                                source="tfb_ehh"
                                validate={validateCurrency}
                                options={{
                                    style: "currency",
                                    currency: "EUR",
                                    minimumFractionDigits: 3,
                                    maximumFractionDigits: 3
                                }}
                            />
                        </Grid>

                        {/* Supplementi */}
                        <Grid item xs={12} md={6}>
                            <NumberInput
                                fullWidth
                                label="Supplemento Notturno (%)"
                                source="tfb_snotturno"
                                validate={validatePercentage}
                                helperText="Inserire un valore tra 0 e 100"
                            />
                        </Grid>

                        {/* Costi PAX */}
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <NumberInput
                                        fullWidth
                                        label="Costo PAX A"
                                        source="tfb_cpaxa"
                                        validate={validateCurrency}
                                        options={{
                                            style: "currency",
                                            currency: "EUR",
                                            minimumFractionDigits: 3,
                                            maximumFractionDigits: 3
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <NumberInput
                                        fullWidth
                                        label="Costo PAX C"
                                        source="tfb_cpaxc"
                                        validate={validateCurrency}
                                        options={{
                                            style: "currency",
                                            currency: "EUR",
                                            minimumFractionDigits: 3,
                                            maximumFractionDigits: 3
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <NumberInput
                                        fullWidth
                                        label="Costo PAX I"
                                        source="tfb_cpaxi"
                                        validate={validateCurrency}
                                        options={{
                                            style: "currency",
                                            currency: "EUR",
                                            minimumFractionDigits: 3,
                                            maximumFractionDigits: 3
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <NumberInput
                                        fullWidth
                                        label="N. PAX Minimo"
                                        source="tfb_npaxmin"
                                        validate={validatePositiveNumber}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Gestione vuoto */}
                        <Grid item xs={12} md={6}>
                            <NumberInput
                                fullWidth
                                label="Km a vuoto"
                                source="tfb_vkm"
                                validate={validatePositiveNumber}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <NumberInput
                                fullWidth
                                label="Minuti a vuoto"
                                source="tfb_vhh"
                                validate={validatePositiveNumber}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <NumberInput
                                fullWidth
                                label="Costi Extra Km a vuoto"
                                source="tfb_evkm"
                                validate={validateCurrency}
                                options={{
                                    style: "currency",
                                    currency: "EUR",
                                    minimumFractionDigits: 3,
                                    maximumFractionDigits: 3
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <NumberInput
                                fullWidth
                                label="Max % Km a Vuoto Fatt"
                                source="tfb_vkmperc"
                                validate={validatePercentage}
                                helperText="Inserire un valore tra 0 e 100"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <NumberInput
                                fullWidth
                                label="Max % Min a Vuoto Fatt"
                                source="tfb_vhhperc"
                                validate={validatePercentage}
                                helperText="Inserire un valore tra 0 e 100"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={onClose}
                        disabled={saving}
                    >
                        Annulla
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={saving}
                        startIcon={saving ? <CircularProgress size={20} /> : null}
                    >
                        {saving ? 'Salvataggio...' : 'Salva'}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export default TariffeBEditDialog;