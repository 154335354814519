// bookingStatus.js

// Object.freeze() rende l'oggetto immutabile, simile a un enum
export const BookingStatus = Object.freeze({
    BOOKING: 0,
    PREVENTIVATO: 1,
    VALIDATO: 2,
    INVIATO: 3,
    NON_CONFERMATO: 4,
    DA_DEFINIRE_6: 6,
    SERVIZIO_APPROVATO: 7,
    DA_DEFINIRE_9: 9,
    DA_DEFINIRE_10: 10,
    DA_DEFINIRE_15: 15,
    DA_DEFINIRE_18: 18,
    DA_DEFINIRE_19: 19,
    DA_DEFINIRE_100: 100
});

// Mappa delle descrizioni
export const STATUS_DESCRIPTIONS = {
    [BookingStatus.BOOKING]: 'Booking',
    [BookingStatus.PREVENTIVATO]: 'Preventivato',
    [BookingStatus.VALIDATO]: 'Validato',
    [BookingStatus.INVIATO]: 'Inviato',
    [BookingStatus.NON_CONFERMATO]: 'Non Confermato',
    [BookingStatus.DA_DEFINIRE_6]: 'Da definire 6',
    [BookingStatus.SERVIZIO_APPROVATO]: 'Servizio Approvato',
    [BookingStatus.DA_DEFINIRE_9]: 'Da definire 9',
    [BookingStatus.DA_DEFINIRE_10]: 'Da definire 10',
    [BookingStatus.DA_DEFINIRE_15]: 'Da definire 15',
    [BookingStatus.DA_DEFINIRE_18]: 'Da definire 18',
    [BookingStatus.DA_DEFINIRE_19]: 'Da definire 19',
    [BookingStatus.DA_DEFINIRE_100]: 'Da definire 100'
};

// Mappa inversa per convertire stringhe in ID
export const STATUS_STRINGS = {
    'booking': BookingStatus.BOOKING,
    'preventivato': BookingStatus.PREVENTIVATO,
    'validato': BookingStatus.VALIDATO,
    'inviato': BookingStatus.INVIATO,
    'non-confermato': BookingStatus.NON_CONFERMATO,
    'da-definire-6': BookingStatus.DA_DEFINIRE_6,
    'servizio-approvato': BookingStatus.SERVIZIO_APPROVATO,
    'da-definire-9': BookingStatus.DA_DEFINIRE_9,
    'da-definire-10': BookingStatus.DA_DEFINIRE_10,
    'da-definire-15': BookingStatus.DA_DEFINIRE_15,
    'da-definire-18': BookingStatus.DA_DEFINIRE_18,
    'da-definire-19': BookingStatus.DA_DEFINIRE_19,
    'da-definire-100': BookingStatus.DA_DEFINIRE_100
};

/**
 * Ottiene le informazioni dello status dal parametro (ID o stringa)
 * @param {string|undefined} bk0_idstatus - ID dello status o nome dello status
 * @returns {Object} Oggetto con id e descrizione dello status
 */
export const getStatusInfo = (bk0_idstatus) => {
    if (bk0_idstatus === undefined || bk0_idstatus === null || bk0_idstatus === '') {
        return { id: -1, descrizione: 'Status non valido' };
    }


    let statusId;

    // Prima verifica se è una stringa valida nella mappa STATUS_STRINGS
    if (isNaN(bk0_idstatus)) {
        const normalizedString = bk0_idstatus.toLowerCase().trim();
        statusId = STATUS_STRINGS[normalizedString];
    } else {
        // Se non è una stringa, prova a convertire in numero
        statusId = parseInt(bk0_idstatus);
    }

    // Verifica se l'ID esiste tra i valori validi
    if (!Object.values(BookingStatus).includes(statusId)) {
        return { id: -1, descrizione: 'Status non valido' };
    }

    return {
        id: statusId,
        descrizione: STATUS_DESCRIPTIONS[statusId]
    };
};

/**
 * Verifica se uno status è valido (sia come ID che come stringa)
 * @param {number|string} status - ID o stringa dello status da verificare
 * @returns {boolean} true se lo status è valido
 */
export const isValidStatus = (status) => {
    if (typeof status === 'string') {
        return status.toLowerCase().trim() in STATUS_STRINGS;
    }
    return Object.values(BookingStatus).includes(status);
};

/**
 * Ottiene la lista di tutti gli stati disponibili
 * @returns {Array} Array di oggetti con id e descrizione di ogni status
 */
export const getAllStatuses = () => {
    return Object.values(BookingStatus).map(id => ({
        id,
        descrizione: STATUS_DESCRIPTIONS[id],
        urlString: Object.keys(STATUS_STRINGS).find(key => STATUS_STRINGS[key] === id)
    }));
};

/**
 * Converte uno status ID in stringa URL-friendly
 * @param {number} statusId - ID dello status
 * @returns {string} Stringa URL-friendly dello status
 */
export const getStatusString = (statusId) => {
    return Object.keys(STATUS_STRINGS).find(key => STATUS_STRINGS[key] === statusId) || '';
};

/**
 * Converte una stringa URL-friendly in status ID
 * @param {string} statusString - Stringa dello status
 * @returns {number} ID dello status o -1 se non valido
 */
export const getStatusId = (statusString) => {
    return STATUS_STRINGS[statusString.toLowerCase().trim()] ?? -1;
};