import React, { useState } from 'react';
import { useUpdate, useNotify, useRefresh, useRecordContext } from 'react-admin';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Typography,
    Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import { EventNote as EventNoteIcon } from '@mui/icons-material';
import formatDateForDisplay from '../shared/CustomFields/LocalDateField';

const EditDateDialog = ({ open, onClose, record }) => {
    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(
        record?.bk2_datastart ? formatDateForInput(record.bk2_datastart) : ''
    );
    const [error, setError] = useState('');
    const [update] = useUpdate();
    const notify = useNotify();
    const refresh = useRefresh();

    function formatDateForInput(dateString) {
        if (!dateString) return '';
        try {
            const utcDate = new Date(dateString);
            const timezoneOffset = utcDate.getTimezoneOffset();
            const localDate = new Date(utcDate.getTime() - timezoneOffset * 60000);

            const year = localDate.getFullYear();
            const month = String(localDate.getMonth() + 1).padStart(2, '0');
            const day = String(localDate.getDate()).padStart(2, '0');
            const hours = String(localDate.getHours()).padStart(2, '0');
            const minutes = String(localDate.getMinutes()).padStart(2, '0');

            return `${year}-${month}-${day}T${hours}:${minutes}`;
        } catch (error) {
            console.error('Date formatting error:', error);
            return '';
        }
    }

    function formatDateForSave(dateString) {
        if (!dateString) return null;
        try {
            const date = new Date(dateString);
            return date.toISOString();
        } catch (error) {
            console.error('Date save formatting error:', error);
            return null;
        }
    }

    const handleSubmit = async () => {
        if (!selectedDate) {
            setError('La data è obbligatoria');
            return;
        }

        setLoading(true);
        try {
            const localDate = new Date(selectedDate);
            const timezoneOffset = localDate.getTimezoneOffset();
            const utcDate = new Date(localDate.getTime() + timezoneOffset * 60000);

            await update('booking-2', {
                id: record.id,
                data: {
                    bk2_datastart: utcDate.toISOString()
                },
            });
            notify('Data aggiornata con successo', { type: 'success' });
            refresh();
            onClose();
        } catch (error) {
            notify('Errore durante l\'aggiornamento della data', { type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor: '#f5f5f5',
                borderBottom: '1px solid #ddd'
            }}>
                <EventNoteIcon color="primary" />
                Modifica Data e Ora
            </DialogTitle>
            <DialogContent sx={{ pt: 2 }}>
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}
                <Typography variant="subtitle2" gutterBottom>
                    Data e Ora
                </Typography>
                <TextField
                    type="datetime-local"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                    fullWidth
                    required
                    error={!!error}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ step: 300 }}
                />
            </DialogContent>
            <DialogActions sx={{
                padding: 2,
                borderTop: '1px solid #ddd',
                backgroundColor: '#f5f5f5'
            }}>
                <Button onClick={onClose} disabled={loading}>
                    Annulla
                </Button>
                <LoadingButton
                    loading={loading}
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                >
                    Salva
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

const DateDisplayWithEdit = ({ source, label }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const record = useRecordContext();

    if (!record?.[source]) return null;

    return (
        <>
            <div onClick={() => setDialogOpen(true)} style={{ cursor: 'pointer' }}>
                {formatDateForDisplay(record[source])}
            </div>
            <EditDateDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                record={record}
            />
        </>
    );
};

export default DateDisplayWithEdit;