
// import { Booking0List } from './Booking0List';
import { Booking0ListWrapper } from './Booking0ListWrapper';
// import Booking0Show from './Booking0Show';
import { Booking0ShowWrapper } from './Booking0ShowWrapper';

const booking0 = {
    // list: Booking0List,
    list: Booking0ListWrapper,
    show: Booking0ShowWrapper
    /*         edit: usersEdit,
            show: usersShow, */
};

export default booking0;
