import * as React from "react";
import {
    TextField,
    Show,
    EditButton,
    TopToolbar,
    TabbedForm,
    useRefresh,
    Datagrid,
    ReferenceField,
    List,
    useRecordContext,
    DateField,
    BooleanField,
    FunctionField,
    ReferenceManyField,
} from 'react-admin';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { Alert, Divider } from '@mui/material';
import { CardHeader } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { green } from '@mui/material/colors';

// Gestione lista note
import GestioneNote from "../note/GestioneNote";

// Importo il css per i Bottoni
import { editButtonCss } from '../shared/Styles/buttonStyles';
import { listCss } from '../shared/Styles/listStyles';
import { ToBoolean } from '../shared/ToBoolean';

import Box from '@mui/material/Box';
import PeopleIcon from '@mui/icons-material/People';

// Importo componenti input custom
import CustomTextInput from '../shared/FormFields/CustomTextInput';
import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";
import CustomTextFieldDataOra from "../shared/FormFields/CustomTextFieldDataOra";
import { formCss } from "../shared/Styles/formCss";
import DriverReport from "./DriverReport";
import DriversReport from "./DriversReport";

const ShowActions = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica" />
    </TopToolbar>
);

export const AutistiShow = (props) => {
    const refresh = useRefresh();

    // Stati separati per ogni tab di reportistica
    const [driverReportDates, setDriverReportDates] = React.useState({
        fromDate: new Date(),
        toDate: new Date(),
        error: ''
    });

    const [driversReportDates, setDriversReportDates] = React.useState({
        fromDate: new Date(),
        toDate: new Date(),
        error: ''
    });

    // Handler per il primo report
    const handleDriverReportFromDateChange = (e) => {
        const newFromDate = new Date(e.target.value);
        setDriverReportDates(prev => {
            const newError = prev.toDate < newFromDate ?
                'La data di fine non può essere precedente alla data di inizio' : '';
            return {
                ...prev,
                fromDate: newFromDate,
                error: newError
            };
        });
    };

    const handleDriverReportToDateChange = (e) => {
        const newToDate = new Date(e.target.value);
        setDriverReportDates(prev => {
            const newError = newToDate < prev.fromDate ?
                'La data di fine non può essere precedente alla data di inizio' : '';
            return {
                ...prev,
                toDate: newToDate,
                error: newError
            };
        });
    };

    // Handler per il secondo report
    const handleDriversReportFromDateChange = (e) => {
        const newFromDate = new Date(e.target.value);
        setDriversReportDates(prev => {
            const newError = prev.toDate < newFromDate ?
                'La data di fine non può essere precedente alla data di inizio' : '';
            return {
                ...prev,
                fromDate: newFromDate,
                error: newError
            };
        });
    };

    const handleDriversReportToDateChange = (e) => {
        const newToDate = new Date(e.target.value);
        setDriversReportDates(prev => {
            const newError = newToDate < prev.fromDate ?
                'La data di fine non può essere precedente alla data di inizio' : '';
            return {
                ...prev,
                toDate: newToDate,
                error: newError
            };
        });
    };

    // Conversione date in epoch
    const getEpochTimestamp = (date) => {
        return Math.floor(date.getTime() / 1000);
    };

    return (
        <Show title={<>
            <Button>
                <PeopleIcon></PeopleIcon>
                &nbsp;Dettaglio Autista
            </Button>
        </>} actions={<ShowActions />}>
            <Card sx={{ minWidth: 275 }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: green[500] }}>
                            <AssignmentIcon />
                        </Avatar>
                    }
                    title={<><strong>ID Autista :</strong> {< TextField label="Autista Id" source="id" />}</>}
                    subheader={
                        <>
                            <strong>Nominativo :</strong> {<TextField label="Nominativo" source="nominativo" />}
                            <br />
                        </>}
                />
            </Card>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 2, width: '30ch' },
                }}
            >
                <TabbedForm sx={formCss} toolbar={null}>
                    <TabbedForm.Tab
                        sx={{
                            '&.Mui-selected': {
                                borderBottom: '3px solid #01b5d0',
                                backgroundColor: '#e8e8e8'
                            }
                        }}
                        label="Anagrafica">
                        <CustomTextInput label="Nominativo" source="nominativo" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Nominativo" source="nominativo" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Indirizzo" source="indirizzo" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Città" source="città" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Cap" source="cap" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Prov" source="prov" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Telefono" source="telefono" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Cellulare" source="cellulare" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Fax" source="fax" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Tel. Aziendale" source="telaziendale" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Email" source="email" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="PEC" source="pec" readOnly={true} />
                        <Divider variant="middle" />
                        <CustomTextInput label="Patente" source="patente" readOnly={true} />
                        <Divider variant="middle" />
                    </TabbedForm.Tab>

                    <TabbedForm.Tab
                        sx={{
                            '&.Mui-selected': {
                                borderBottom: '3px solid #01b5d0',
                                backgroundColor: '#e8e8e8'
                            }
                        }}
                        label="Proprieta" path="proprieta">
                        <div>
                            <div>
                                {<CustomTextFieldDataOra label="Data creazione" source="createdAt" />}
                                <br></br>
                                {<CustomTextFieldDataOra label="Data ultima modifica" source="updatedAt" />}
                            </div>
                        </div>
                        <div>
                            {<ToBoolean label="Attivo" source="flAttivo" labelOn={true}></ToBoolean>}
                            {<ToBoolean label="Visibile" source="fiVisibile" labelOn={true}></ToBoolean>}
                            {<ToBoolean label="Annullato" source="flAnnullato" labelOn={true}></ToBoolean>}
                        </div>
                        <Divider variant="middle" />
                        <ConfirmDialogDeleteButtonCustom
                            titolo={"Cancella Autista"}
                            messaggio={"Sei sicuro di vole cancellare questo Autista ?"}
                            servizio={"autisti"} />
                    </TabbedForm.Tab>

                    <TabbedForm.Tab
                        sx={{
                            '&.Mui-selected': {
                                borderBottom: '3px solid #01b5d0',
                                backgroundColor: '#e8e8e8'
                            }
                        }}
                        label="Annotazioni" path="annotazioni">
                        <p><strong>Annotazioni</strong></p>
                        <GestioneNote tiporecordId="3" refresh={refresh} ></GestioneNote>
                    </TabbedForm.Tab>

                    <TabbedForm.Tab
                        sx={{
                            '&.Mui-selected': {
                                borderBottom: '3px solid #01b5d0',
                                backgroundColor: '#e8e8e8'
                            }
                        }}
                        label="Scadenzario" path="scadenze">
                        <ReferenceManyField
                            reference="scadenze"
                            target="recordId"
                            filter={{ tiporecordId: 3 }}
                            sort={{ field: "data", order: "DESC" }}
                        >
                            <Datagrid bulkActionButtons={false}>
                                <TextField source="note" label="Descrizione" />
                                <TextField source="recordId" label="Record" />
                                <DateField
                                    source="data"
                                    label="Data Scadenza"
                                    options={{ year: "numeric", month: "2-digit", day: "2-digit" }}
                                />
                                <ReferenceField
                                    source="tipoScadenzaId"
                                    reference="tipo-scadenza"
                                    label="Tipo Scadenza"
                                >
                                    <TextField source="nome" />
                                </ReferenceField>
                                <ReferenceField
                                    source="statoScadenzaId"
                                    reference="stato-scadenza"
                                    label="Stato"
                                >
                                    <TextField source="nome" />
                                </ReferenceField>
                                <BooleanField
                                    source="flPromemoria"
                                    label="Promemoria"
                                    valueLabelTrue="Attivo"
                                    valueLabelFalse="Non Attivo"
                                />
                                <FunctionField
                                    label="Giorni Rimanenti"
                                    render={(record) => {
                                        const today = new Date();
                                        const dueDate = new Date(record.data);
                                        const diffTime = dueDate - today;
                                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                        return diffDays >= 0
                                            ? `${diffDays} giorni rimanenti`
                                            : `Scaduto da ${Math.abs(diffDays)} giorni`;
                                    }}
                                />
                            </Datagrid>
                        </ReferenceManyField>
                    </TabbedForm.Tab>

                    <TabbedForm.Tab
                        sx={{
                            '&.Mui-selected': {
                                borderBottom: '3px solid #01b5d0',
                                backgroundColor: '#e8e8e8'
                            }
                        }}
                        label="Reportistica(TopFly) Autista Singolo"
                        path="reportistica">
                        <p>Codice TopFly : <TextField source="topflyId"></TextField></p>

                        <div className="mb-4">
                            <div className="flex flex-col space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Data Inizio</label>
                                    <input
                                        type="date"
                                        className="mt-1 block w-64 rounded-md border-gray-300 shadow-sm"
                                        onChange={handleDriverReportFromDateChange}
                                        value={driverReportDates.fromDate.toISOString().split('T')[0]}
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Data Fine</label>
                                    <input
                                        type="date"
                                        className="mt-1 block w-64 rounded-md border-gray-300 shadow-sm"
                                        onChange={handleDriverReportToDateChange}
                                        value={driverReportDates.toDate.toISOString().split('T')[0]}
                                    />
                                </div>

                                {driverReportDates.error && (
                                    <p className="text-red-500 text-sm">{driverReportDates.error}</p>
                                )}
                            </div>
                        </div>
                        <FunctionField
                            source="id"
                            label=""
                            render={record => <DriverReport
                                id={record.topflyId}
                                from={getEpochTimestamp(driverReportDates.fromDate)}
                                to={getEpochTimestamp(driverReportDates.toDate)}
                            />}
                        />
                    </TabbedForm.Tab>

                    <TabbedForm.Tab
                        sx={{
                            '&.Mui-selected': {
                                borderBottom: '3px solid #01b5d0',
                                backgroundColor: '#e8e8e8'
                            }
                        }}
                        label="Reportistica(TopFly) Badge Autisti"
                        path="reportistica2">
                        <Alert severity="info">N.B: I conteggi di TopFLy per il report "Badge Autista" partono dal 09/12/2024</Alert>

                        <div className="mb-4">
                            <div className="flex flex-col space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Data Inizio</label>
                                    <input
                                        type="date"
                                        className="mt-1 block w-64 rounded-md border-gray-300shadow-sm"
                                        onChange={handleDriversReportFromDateChange}
                                        value={driversReportDates.fromDate.toISOString().split('T')[0]}
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Data Fine</label>
                                    <input
                                        type="date"
                                        className="mt-1 block w-64 rounded-md border-gray-300 shadow-sm"
                                        onChange={handleDriversReportToDateChange}
                                        value={driversReportDates.toDate.toISOString().split('T')[0]}
                                    />
                                </div>

                                {driversReportDates.error && (
                                    <p className="text-red-500 text-sm">{driversReportDates.error}</p>
                                )}
                            </div>
                        </div>
                        <FunctionField
                            source="id"
                            label=""
                            render={record => <DriversReport
                                id={2}
                                from={getEpochTimestamp(driversReportDates.fromDate)}
                                to={getEpochTimestamp(driversReportDates.toDate)}
                            />}
                        />
                    </TabbedForm.Tab>
                </TabbedForm>
            </Box>
        </Show>
    );
};