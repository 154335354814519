import * as React from 'react';
import { AppBar, useStore, TitlePortal, RefreshIconButton, Button, UserMenu, useLogout } from 'react-admin';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import SettingsIcon from '@mui/icons-material/Settings';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { urlLogo } from '../../../utils/urlBase';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import Avatar from '@mui/material/Avatar';
import MessageBadge from './MessageBadge';
import PersonIcon from '@mui/icons-material/Person';

const SettingsButton = () => (
    <Button
        sx={{
            color: 'white',
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
        }}
        component={Link}
        to="/settingspage"
    >
        <SettingsIcon />
    </Button>
);

const MyLogoutButton = forwardRef((props, ref) => {
    const logout = useLogout();

    const handleLogout = () => {
        // Clear cache
        if ('caches' in window) {
            caches.keys().then(function (cacheNames) {
                cacheNames.forEach(function (cacheName) {
                    caches.delete(cacheName);
                });
            });
        }

        // Clear localStorage and sessionStorage
        localStorage.clear();
        sessionStorage.clear();

        // Unregister Service Workers
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                registrations.forEach(function (registration) {
                    registration.unregister();
                });
            });
        }

        // Reset React-Admin store
        const event = new Event('RA/RESET'); // Dispatch Redux reset action
        document.dispatchEvent(event);

        // Perform logout
        logout();

        // Force page reload to ensure no data is cached
        window.location.reload();
    };

    return (
        <MenuItem
            onClick={handleLogout}
            ref={ref}
            {...props}
        >
            <ListItemIcon>
                <ExitIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Esci</ListItemText>
        </MenuItem>
    );
});


const AvatarCustom = () => (
    <Avatar
        sx={{
            height: 40,
            width: 40,
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            color: 'white',
        }}
    />
);

export const MyAppBar = props => {
    const [nomeUtente] = useStore('profile.email');
    const [gruppiId] = useStore('profile.gruppiId');

    return (
        <AppBar
            sx={{
                backgroundColor: 'primary.main',
                backgroundImage: 'linear-gradient(to right, #01b5d0, #0077be)',
                color: 'white',
                boxShadow: 3,
                borderBottom: '2px solid rgba(255,255,255,0.3)', // bordo inferiore aggiunto
                '& .RaAppBar-title': {
                    color: 'white',
                },
            }}
            toolbar={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>

                    {gruppiId && (
                        <Typography variant="body2" sx={{ color: 'white', fontWeight: 'bold', mt: 1 }}>
                            {gruppiId === 1
                                ? <SettingsButton />
                                : <></>
                            }
                        </Typography>
                    )}
                    <RefreshIconButton sx={{ color: 'white' }} />

                    {gruppiId && (
                        <Typography variant="body2" sx={{ color: 'white', fontWeight: 'bold', mt: 1 }}>
                            {(gruppiId === 1 || gruppiId === 28)
                                ? <Link to="/notelist" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    {<MessageBadge />}
                                </Link>
                                : <></>
                            }
                        </Typography>
                    )}


                </Box>
            }
            userMenu={
                <UserMenu icon={<AvatarCustom />}>
                    <MenuItem component={Link} to="/dati-personali">
                        <ListItemIcon>
                            <PersonIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Dati Personali</ListItemText>
                    </MenuItem>
                    <MyLogoutButton />
                </UserMenu>
            }
            {...props}
        >
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                px: 2,
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                    <Box component="span" sx={{
                        mr: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'white',
                        borderRadius: '8px',
                        p: 1,
                    }}>
                        <img src={urlLogo} alt="Gruppo Tuvoni Logo" style={{ height: '30px' }} />
                    </Box>
                    <Typography variant="h6" sx={{ color: 'white', display: { xs: 'none', sm: 'block' }, mr: 2 }}>
                        Gestione Trasporti Tuvoni
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{ mx: 2, bgcolor: 'white' }} />
                    <Box sx={{ color: 'white' }}>
                        <TitlePortal />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {gruppiId && (
                        <Typography variant="body2" sx={{ color: 'white', fontWeight: 'bold', mt: 1 }}>
                            {gruppiId === 1
                                ? <Typography variant="body2" sx={{ color: 'white', mr: 2, display: { xs: 'none', md: 'block' } }}>
                                    v1.3.14
                                </Typography>
                                : <></>
                            }
                        </Typography>
                    )}
                    <Divider orientation="vertical" flexItem sx={{ mx: 2, bgcolor: 'white' }} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography variant="body2" sx={{ color: 'white', fontWeight: 'bold' }}>
                            USER: {nomeUtente}
                        </Typography>
                        {gruppiId && (
                            <Typography variant="body2" sx={{ color: 'white', fontWeight: 'bold', mt: 1 }}>
                                {gruppiId === 1
                                    ? <strong>Administrator</strong>
                                    : gruppiId === 2
                                        ? <strong>Cliente</strong>
                                        : gruppiId === 28
                                            ? <strong>Booking</strong>
                                            : gruppiId === 25
                                                ? <strong>Fornitori</strong>
                                                : `Gruppo: ${gruppiId}`
                                }
                            </Typography>
                        )}
                    </Box>

                </Box>
            </Box>
        </AppBar >
    );
};
