import React from 'react';
import { Box, Paper, Grid, Chip } from '@mui/material';
import {
    NumberInput,
    ReferenceInput,
    SelectInput,
    useRecordContext,
} from "react-admin";
import CustomDateInputNoMargini from '../shared/FormFields/CustomDateInputNoMargini';
import CustomAutocompleInput from '../shared/FormFields/CustomAutocompleInput';

export const BookingFilters = (bk0_idstatus) => {
    console.log("BookingFilters > bk0_idstatus :", bk0_idstatus);

    // Array base dei filtri sempre presenti
    const baseFilters = [
        <CustomDateInputNoMargini
            key="date-from"
            source="bk0_datada"
            label="Data Inizio"
            alwaysOn
        />,
        <CustomDateInputNoMargini
            key="date-to"
            source="bk0_dataal"
            label="Data Fine"
            alwaysOn
        />,
        <CustomAutocompleInput
            key="client"
            source="bk0_clienteId"
            reference="anagen"
            optionProperty="gnfind"
            label="Seleziona Anagrafica"
            alwaysOn
        />,
        <NumberInput
            key="booking-number"
            source="bk0_idnumber"
            label="Numero Preventivo"
            alwaysOn
            sx={{ minWidth: 200 }}
        />,
        <ReferenceInput
            key="service"
            source="bk0_servizio"
            reference="servizi"
            alwaysOn
        >
            <SelectInput
                label="Tipo Servizio"
                sx={{ minWidth: 200 }}
            />
        </ReferenceInput>,
        <ReferenceInput
            key="request-type"
            source="bk0_richiestatipo"
            reference="view-tabellaalfa-trp"
            alwaysOn
        >
            <SelectInput
                label="Tipo Richiesta"
                sx={{ minWidth: 200 }}
            />
        </ReferenceInput>
    ];

    // Aggiunge il filtro status solo se bk0_idstatus è definito
    if (bk0_idstatus === undefined) {
        baseFilters.push(
            <ReferenceInput
                key="status"
                source="bk0_idstatus"
                reference="view-booking-0-status-riepilogo"
                alwaysOn
            >
                <SelectInput
                    optionText={<StatusComposito />}
                    label="Status"
                    sx={{ minWidth: 200 }}
                />
            </ReferenceInput>
        );
    }

    return baseFilters;
};

const StatusComposito = () => {
    const record = useRecordContext();
    return <span>{record.descrizione} - <Chip label={`${record.count}`} /></span>;
};

export default BookingFilters;