import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
    Typography,
    Box,
} from "@mui/material";

const HtmlEditorDialog = ({ open, onClose, onSave, initialHtml, placeholders }) => {
    const [html, setHtml] = useState(initialHtml || "");

    const handleInsertPlaceholder = (placeholder) => {
        setHtml((prevHtml) => `${prevHtml}{{${placeholder}}}`);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>Modifica HTML</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {/* Editor HTML */}
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>
                            Editor HTML
                        </Typography>
                        <TextField
                            value={html}
                            onChange={(e) => setHtml(e.target.value)}
                            multiline
                            rows={20}
                            fullWidth
                            variant="outlined"
                            label="Codice HTML"
                        />
                    </Grid>

                    {/* Anteprima HTML */}
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>
                            Anteprima
                        </Typography>
                        <Box
                            sx={{
                                border: "1px solid #ddd",
                                padding: 2,
                                borderRadius: 2,
                                backgroundColor: "#f9f9f9",
                                minHeight: "400px",
                                overflowY: "auto",
                            }}
                            dangerouslySetInnerHTML={{ __html: html }}
                        />
                    </Grid>

                    {/* Lista di Segnaposto */}
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            Segnaposto Disponibili
                        </Typography>
                        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                            {placeholders.map((placeholder) => (
                                <Button
                                    key={placeholder}
                                    variant="outlined"
                                    size="small"
                                    onClick={() => handleInsertPlaceholder(placeholder)}
                                >
                                    {`{{${placeholder}}}`}
                                </Button>
                            ))}
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Annulla
                </Button>
                <Button
                    onClick={() => onSave(html)}
                    color="primary"
                    variant="contained"
                >
                    Salva
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default HtmlEditorDialog;
