import * as React from 'react';
import {
    Create,
    SimpleForm,
    Toolbar,
    TextInput,
    BooleanInput,
    SaveButton,
    required
} from 'react-admin';
import { Divider } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import Button from '@mui/material/Button';
import CustomAutocompleInput from '../shared/FormFields/CustomAutocompleInput';
import { saveButtonCss } from '../shared/Styles/buttonStyles';
import { formCss } from "../shared/Styles/formCss";

import { checkPermission } from '../shared/Permissions/checkPermission';

const MyToolbarEdit = () => (
    <Toolbar>
        <SaveButton
            sx={saveButtonCss}
            label="Salva parametri"
            alwaysEnable
        />
    </Toolbar>
);

export const tabellealfaCreate = () => {
    const canCreate = checkPermission("tabellealfa", "create");

    if (!canCreate) {
        return (
            <div style={{ color: "red", fontWeight: "bold", padding: 20 }}>
                Accesso negato
            </div>
        );
    }

    return (
        <Create
            title={
                <Button>
                    <PeopleIcon />
                    &nbsp;Crea record su tabellealfa
                </Button>
            }
            redirect="show"
        >
            <SimpleForm sx={formCss} toolbar={<MyToolbarEdit />}>
                <Divider variant="middle" />
                <CustomAutocompleInput
                    source="tbpref"
                    reference="tipotabellealfa"
                    optionProperty="descrizione"
                    label="Tipologia"
                />
                <Divider variant="middle" />
                <TextInput source="tbdes" validate={required()} />
                <BooleanInput source="flAttivo" validate={required()} />
                <BooleanInput source="flVisibile" validate={required()} />
                <BooleanInput source="flAnnullato" validate={required()} />
            </SimpleForm>
        </Create>
    );
};
