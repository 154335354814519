import * as React from "react";
import {
    TextInput,
    SimpleForm,
    Edit,
    ShowButton,
    TopToolbar,
    Toolbar,
    SaveButton,
    NumberInput,
    Button
} from 'react-admin';

import { RowForm } from "@react-admin/ra-editable-datagrid";
import EuroIcon from '@mui/icons-material/Euro';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import NoteIcon from '@mui/icons-material/Note';

import { List as ListMaterial, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { editButtonCss, saveButtonCss, showButtonCss, createButtonCss } from '../shared/Styles/buttonStyles';


const ShowActionsForEdit = () => (
    <ShowTopToolbarDettaglio></ShowTopToolbarDettaglio>
);

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <ShowButton sx={showButtonCss} label="Dettaglio" />
    </TopToolbar>
);

const MyToolbarEdit = () => (
    <Toolbar>
        <SaveButton sx={saveButtonCss}
            label="Salva parametri"
        />
    </Toolbar>
);

// Componente personalizzato che simula un NumberInput ma accetta la virgola
const EuroNumberInput = ({ source, label, ...rest }) => {
    // Gestore per l'input dell'utente
    const handleBlur = (event) => {
        let value = event.target.value;
        // Sostituisci la virgola con il punto per la conversione
        value = value.replace(',', '.');
        // Controlla che il valore sia un numero e limita a tre cifre decimali
        if (!isNaN(value) && value.trim() !== '') {
            value = parseFloat(value);
            // Tronca a tre cifre decimali senza arrotondamento
            value = Math.floor(value * 1000) / 1000;
            // Converti di nuovo in stringa sostituendo il punto con la virgola
            value = value.toString().replace('.', ',');
            // Aggiorna il valore del campo con la versione corretta
            event.target.value = value;
        }
    };

    return (
        <NumberInput
            min={0.000}
            label={label}
            source={source}
            onBlur={handleBlur}
            {...rest}
        />
    );
};

export const TariffeaEdit = (props) => {
    return (
        <Edit redirect="show" title={<><Button>&nbsp;Dettaglio Tariffa</Button></>} actions={<ShowActionsForEdit />} {...props}>
            <SimpleForm toolbar={<MyToolbarEdit />}  >
                <Grid container spacing={2}>
                    {/* I campi standard */}
                    {[
                        { label: "Descrizione", source: "tfa_des", Icon: NoteIcon },
                        { label: "Tipo", source: "tfa_tipo", Icon: NoteIcon }, // 1 = Tempo km ; 2 = A passeggero
                        // { label: "Cliente", source: "tfa_clienteId", Icon: NoteIcon },
                        { label: "Servizio", source: "tfa_servizio", Icon: NoteIcon },
                    ].map(({ label, source, Icon }, index) => (
                        <Grid item xs={12} sm={6} key={`field-edit-${index}`}>
                            <ListItem>
                                <ListItemIcon>
                                    <Icon />
                                </ListItemIcon>
                                <TextInput label={label} source={source} fullWidth />
                            </ListItem>
                        </Grid>
                    ))}
                    {/* "f Km Start" e "f Km Stop" raggruppati in una Card */}
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    {[
                                        { label: "f Km Start", source: "tfa_kmtratta0", Icon: NoteIcon },
                                        { label: "f Km Stop", source: "tfa_kmtratta1", Icon: NoteIcon },
                                    ].map(({ label, source, Icon }, index) => (
                                        <Grid item xs={12} sm={6} key={`km-edit-${index}`}>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <Icon />
                                                </ListItemIcon>
                                                <NumberInput min={0} label={label} source={source} fullWidth />
                                            </ListItem>
                                        </Grid>
                                    ))}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* Campi "Sconto" raggruppati in una Card, ora con NumberInput */}
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            {Array.from({ length: 10 }, (_, i) => ({
                                                label: `Sconto ${i + 1} (€)`,
                                                source: `tfa_maxsc${i + 1}`,
                                                Icon: EuroIcon,
                                            })).map(({ label, source, Icon }, index) => (
                                                <Grid item xs={6} sm={3} key={`discount-edit-${index}`}>
                                                    <ListItem>
                                                        <ListItemIcon><Icon /></ListItemIcon>
                                                        {/* <NumberInput
                                                            label={label}
                                                            source={source}
                                                            fullWidth
                                                            min={0}
                                                        /> */}<EuroNumberInput label={label}
                                                            source={source} />
                                                    </ListItem>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
}