// useBookingStats.js
import { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../../config/config.json';

export const useBookingStats = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const jwt = localStorage.getItem("feathers-jwt");

            try {
                const configBackend = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${config.production.host}/view-booking-0-status-riepilogo`,
                    headers: {
                        'Authorization': `Bearer ${jwt}`,
                    },
                };

                const response = await axios.request(configBackend);
                setData(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Errore durante il fetch dei dati:', error);
                setError(error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return { data, loading, error };
};