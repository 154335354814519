import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReferenceInput, AutocompleteInput, required } from 'react-admin';
import { formCssDropdownMenu } from '../Styles/formCss';

const CustomAutocompleInputWithOnChange = ({
    source,
    reference,
    optionProperty,
    label,
    readOnly,
    // Qui raccogliamo explicitamente onChange
    // e qualsiasi altra prop extra voglia passare il componente genitore
    onChange,
    validate,
    ...rest
}) => {
    // Renderizza il testo dell'opzione
    const optionRenderer = choice => `${choice[optionProperty]}`;

    // Aggiungiamo una funzione "wrapper" che fa debug
    // e richiama eventuale onChange
    const handleChange = (eventOrValue) => {
        console.log('** DEBUG AutocompleteInput onChange:', eventOrValue);

        // Se arriva un SyntheticEvent, possiamo catturare eventOrValue.target.value;
        // se è un valore "puro", dipende da come AutocompleteInput lo gestisce.
        if (onChange) {
            onChange(eventOrValue);
        }
    };

    // Se readOnly è true, usiamo un AutocompleteInput in sola lettura;
    // altrimenti uno "normale".
    // Importante: passare onChange (o readOnly) nel componente interno.
    return (
        <div>
            <Box display="flex" alignItems="center" sx={{ width: 700 }}>
                <Typography variant="body1" style={{ marginRight: 8 }}>
                    {label}:
                </Typography>
                {readOnly ? (
                    <ReferenceInput
                        perPage={100}
                        source={source}
                        reference={reference}
                        validate={validate || [required()]}
                        {...rest}
                    >
                        <AutocompleteInput
                            readOnly
                            fullWidth
                            label={label}
                            optionText={optionRenderer}
                            suggestionLimit={100}
                            sx={formCssDropdownMenu}
                        />
                    </ReferenceInput>
                ) : (
                    <ReferenceInput
                        perPage={100}
                        source={source}
                        reference={reference}
                        validate={validate || [required()]}
                        {...rest}
                    >
                        <AutocompleteInput
                            fullWidth
                            label={label}
                            optionText={optionRenderer}
                            suggestionLimit={100}
                            sx={formCssDropdownMenu}
                            onChange={handleChange}
                        />
                    </ReferenceInput>
                )}
            </Box>
        </div>
    );
};

export default CustomAutocompleInputWithOnChange;
