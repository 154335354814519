import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
    MenuItem,
    Autocomplete,
    Typography,
    Divider
} from "@mui/material";
import { useGetList } from 'react-admin';

export const ScadenzeAdvancedFiltersDialog = ({ open, onClose, onApply, onReset, currentFilters }) => {
    const [filters, setFilters] = useState(currentFilters || {});

    // Fetch delle liste per gli autocomplete
    const { data: utenti } = useGetList('users', { pagination: { page: 1, perPage: 100 } });
    const { data: tipiScadenza } = useGetList('tipo-scadenza', { pagination: { page: 1, perPage: 100 } });
    const { data: tipiRecord } = useGetList('tiporecord', { pagination: { page: 1, perPage: 100 } });

    useEffect(() => {
        setFilters(currentFilters || {});
    }, [currentFilters]);

    const handleApply = () => {
        const formattedFilters = {};
        if (filters["note"]) {
            formattedFilters["note.like"] = `*${filters["note"]}*`;
        }
        if (filters["userId"]) {
            formattedFilters["userId"] = filters["userId"];
        }
        if (filters["tipoScadenzaId"]) {
            formattedFilters["tipoScadenzaId"] = filters["tipoScadenzaId"];
        }
        if (filters["statoScadenzaId"]) {
            formattedFilters["statoScadenzaId"] = filters["statoScadenzaId"];
        }
        if (filters["tiporecordId"]) {
            formattedFilters["tiporecordId"] = filters["tiporecordId"];
        }
        onApply(formattedFilters);
    };

    const handleChange = (field, value) => {
        setFilters(prev => ({ ...prev, [field]: value }));
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogContent>
                <Grid container spacing={2}>
                    {/* Descrizione */}
                    <Grid item xs={12}>
                        <TextField
                            label="Descrizione"
                            fullWidth
                            value={filters["note"] || ""}
                            onChange={(e) => handleChange("note", e.target.value)}
                        />
                    </Grid>

                    {/* Utente assegnato */}
                    {/* <Grid item xs={12}>
                        <Autocomplete
                            options={utenti || []}
                            getOptionLabel={(option) => option.nome || ''}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={utenti?.find(u => u.id === filters["userId"]) || null}
                            onChange={(e, newValue) => handleChange("userId", newValue ? newValue.id : null)}
                            renderInput={(params) => (
                                <TextField {...params} label="Caricato da" fullWidth />
                            )}
                        />
                    </Grid> */}

                    {/* Tipo Scadenza */}
                    <Grid item xs={12}>
                        <Autocomplete
                            options={tipiScadenza || []}
                            getOptionLabel={(option) => option.nome || ''}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={tipiScadenza?.find(t => t.id === filters["tipoScadenzaId"]) || null}
                            onChange={(e, newValue) => handleChange("tipoScadenzaId", newValue ? newValue.id : null)}
                            renderInput={(params) => (
                                <TextField {...params} label="Tipo Scadenza" fullWidth />
                            )}
                        />
                    </Grid>

                    {/* Tipo Record */}
                    <Grid item xs={12}>
                        <Autocomplete
                            options={tipiRecord || []}
                            getOptionLabel={(option) => option.descrizione || ''}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={tipiRecord?.find(t => t.id === filters["tiporecordId"]) || null}
                            onChange={(e, newValue) => handleChange("tiporecordId", newValue ? newValue.id : null)}
                            renderInput={(params) => (
                                <TextField {...params} label="Tipo Record" fullWidth />
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onReset} color="secondary">
                    Reset
                </Button>
                <Button onClick={handleApply} variant="contained">
                    Applica
                </Button>
            </DialogActions>
        </Dialog>
    );
};
