import React, { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Grid,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { Title, useDataProvider, useNotify } from "react-admin";
import { blue } from "@mui/material/colors";
import HtmlEditorDialog from "./HtmlEditorDialog"; // Importa il dialog per l'editing HTML

function TimezoneCard() {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(interval); // Pulisce l'intervallo al momento dello smontaggio
    }, []);

    return (
        <Card sx={{ mt: 5, backgroundColor: "#e8f5e9", textAlign: "center" }}>
            <CardContent>
                <Typography variant="h5" sx={{ color: "#388e3c" }}>
                    Data e Ora Attuale
                </Typography>
                <Typography variant="h6" sx={{ mt: 2 }}>
                    {currentTime.toLocaleString()} ({Intl.DateTimeFormat().resolvedOptions().timeZone})
                </Typography>
            </CardContent>
        </Card>
    );
}


function SettingsPage() {
    const [settings, setSettings] = useState(null); // Stato per i parametri SMTP
    const [templates, setTemplates] = useState([]); // Stato per i template
    const [loading, setLoading] = useState(true); // Stato di caricamento
    const [dialogOpen, setDialogOpen] = useState(false); // Stato per dialog generica
    const [htmlDialogOpen, setHtmlDialogOpen] = useState(false); // Stato per dialog HTML
    const [fieldToEdit, setFieldToEdit] = useState(null); // Campo corrente da modificare
    const [templateToEdit, setTemplateToEdit] = useState(null); // Template corrente per editing HTML
    const [fieldValue, setFieldValue] = useState(""); // Valore corrente del campo
    const dataProvider = useDataProvider();
    const notify = useNotify();

    useEffect(() => {
        const fetchSettingsAndTemplates = async () => {
            try {
                const [settingsResponse, templatesResponse] = await Promise.all([
                    dataProvider.getList("settings", { pagination: { page: 1, perPage: 1 } }),
                    dataProvider.getList("tipo-servizio-mail", {
                        pagination: { page: 1, perPage: 10 }
                    }),
                ]);
                setSettings(settingsResponse.data[0] || {});
                setTemplates(templatesResponse.data || []);
            } catch (error) {
                notify(`Errore nel caricamento dei dati: ${error.message}`, { type: "error" });
            } finally {
                setLoading(false);
            }
        };
        fetchSettingsAndTemplates();
    }, [dataProvider, notify]);

    const handleOpenDialog = (field, value) => {
        setFieldToEdit(field);
        setFieldValue(value);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setFieldToEdit(null);
        setFieldValue("");
    };

    const handleSaveField = async () => {
        try {
            const updatedSettings = { ...settings, [fieldToEdit]: fieldValue };
            await dataProvider.update("settings", {
                id: settings.id,
                data: { [fieldToEdit]: fieldValue }
            });
            setSettings(updatedSettings);
            notify(`${fieldToEdit} aggiornato con successo`, { type: "success" });
        } catch (error) {
            notify(`Errore durante l'aggiornamento: ${error.message}`, { type: "error" });
        } finally {
            handleCloseDialog();
        }
    };

    const handleOpenHtmlDialog = (template) => {
        setTemplateToEdit(template);
        setHtmlDialogOpen(true);
    };

    const handleCloseHtmlDialog = () => {
        setHtmlDialogOpen(false);
        setTemplateToEdit(null);
    };

    const handleSaveHtml = async (updatedHtml) => {
        try {
            const updatedTemplates = templates.map((template) =>
                template.id === templateToEdit.id
                    ? { ...template, html: updatedHtml }
                    : template
            );
            setTemplates(updatedTemplates);

            await dataProvider.update("tipo-servizio-mail", {
                id: templateToEdit.id,
                data: { html: updatedHtml }
            });

            notify(`Template aggiornato con successo`, { type: "success" });
        } catch (error) {
            notify(`Errore durante il salvataggio: ${error.message}`, { type: "error" });
        } finally {
            handleCloseHtmlDialog();
        }
    };

    const handleTestEmail = async () => {
        try {
            await dataProvider.create('generatore-mail', {
                data: {
                    payload: {
                        to: settings.destinatarioNotifiche,
                        tipoServizioMailId: 4, // ID del template di test
                        html: {
                            /* BLOCCO DOVE DFINIRE I PLACEHOLDERS */
                            email: settings.destinatarioNotifiche,
                            azienda: "Gruppo Tuvoni",
                        },
                    }
                }
            });
            notify('Email di test inviata con successo!', { type: 'success' });
        } catch (error) {
            notify(`Errore durante l'invio dell'email di test: ${error.message}`, { type: 'error' });
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Card sx={{ mt: 10, textAlign: "center", backgroundColor: "#f3f6f9" }}>
            <Title title="Impostazioni" />
            <CardContent>
                <SettingsIcon sx={{ color: blue[500] }} />
                <Typography variant="h4" sx={{ margin: "20px 0", color: "#01b5d0" }}>
                    Impostazioni Generali
                </Typography>

                {/* Sezione check Timezone */}
                <TimezoneCard />

                {/* Sezione SMTP */}
                <Typography variant="h5" sx={{ marginTop: "30px", color: "#333" }}>
                    Parametri SMTP
                </Typography>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    {["smtphost", "smtpusername", "smtppassword", "smtpport", "destinatarioNotifiche"].map(
                        (field) => (
                            <Grid item xs={12} md={6} key={field}>
                                <TextField
                                    label={field}
                                    value={settings[field] || ""}
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <Button onClick={() => handleOpenDialog(field, settings[field])}>
                                                Modifica
                                            </Button>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                        )
                    )}
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleTestEmail}
                            sx={{ mt: 3 }}
                        >
                            Test Email
                        </Button>
                    </Grid>
                </Grid>

                {/* Sezione Templates */}
                <Typography variant="h5" sx={{ marginTop: "40px", color: "#333" }}>
                    Gestione Templates Email
                </Typography>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    {templates.map((template) => (
                        <Grid item xs={12} md={6} key={template.id}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6">{template.nome}</Typography>
                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                        <strong>Subject:</strong> {template.subject}
                                    </Typography>
                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                        <strong>HTML:</strong>
                                        <Button
                                            onClick={() => handleOpenHtmlDialog(template)}
                                            sx={{ ml: 2 }}
                                        >
                                            Modifica HTML
                                        </Button>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </CardContent>

            {/* Dialog per modifica parametri SMTP */}
            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Modifica {fieldToEdit}</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        label={fieldToEdit}
                        value={fieldValue}
                        onChange={(e) => setFieldValue(e.target.value)}
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary">
                        Annulla
                    </Button>
                    <Button onClick={handleSaveField} color="primary">
                        Salva
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog per modifica HTML */}
            {templateToEdit && (
                <HtmlEditorDialog
                    open={htmlDialogOpen}
                    onClose={handleCloseHtmlDialog}
                    onSave={handleSaveHtml}
                    initialHtml={templateToEdit.html}
                    placeholders={["nome", "cognome", "email", "link", "azienda", "resetLink", "descrizioneScadenza", "dataScadenza"]}
                />
            )}
        </Card>
    );
}

export default SettingsPage;
