import React, { useState, useEffect } from 'react';
import { useNotify, useDataProvider, useUpdate } from 'react-admin';
import { TextField, Button } from '@mui/material';
import { Select, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import axios from 'axios';
import { uploadButtonCss } from '../shared/Styles/buttonStyles';
import config from '../../config/config.json';

const CustomCreateDocumenti = ({
    tipoDocumentoFisso,
    tipodocumentoIdFisso,
    tiporecordId,
    recordId,
    userId,
    refresh
}) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [update] = useUpdate();

    const [file, setFile] = useState(null);
    const [descrizione, setDescrizione] = useState('');

    // Se `tipoDocumentoFisso` è true, usiamo direttamente tipodocumentoIdFisso;
    // altrimenti iniziamo da '' (o null) così da mostrare poi la Select.
    const [tipodocumentoId, setTipodocumentoId] = useState(
        tipoDocumentoFisso ? parseInt(tipodocumentoIdFisso) : ''
    );

    // Stato per memorizzare le opzioni della dropdown
    const [tipoDocumentoOptions, setTipoDocumentoOptions] = useState([]);

    // Carica le opzioni solo se `tipoDocumentoFisso` è false
    const fetchTipoDocumento = async () => {
        try {
            const { data } = await dataProvider.getList('tipodocumento', {
                pagination: { page: 1, perPage: 50 },
                sort: { field: 'id', order: 'ASC' }
            });
            setTipoDocumentoOptions(data);
        } catch (error) {
            console.error("Errore nel recupero dei tipi documento:", error);
            notify("Errore nel recupero dei tipi documento", 'warning');
        }
    };

    useEffect(() => {
        if (!tipoDocumentoFisso) {
            fetchTipoDocumento();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipoDocumentoFisso]);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const uploadFile = async (documentoId) => {
        let formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios({
                method: 'post',
                url: `${config.production.hostUploads}/uploads/documenti/${tiporecordId}/${recordId}/${documentoId}`,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            return response.data;
        } catch (error) {
            console.error("Errore nell'upload del file:", error);
            throw error;
        }
    };

    const handleClick = async () => {
        try {
            const data = {
                tiporecordId,
                tipodocumentoId: tipodocumentoId, // qui abbiamo già o la fissa (se true) o quella selezionata
                recordId,
                descrizione,
                userId
            };

            dataProvider.create('documenti', { data })
                .then(response => {
                    notify('Riferimento file su DB creato con successo');
                    setDescrizione('');

                    // Faccio l'upload fisico del file
                    uploadFile(response.data.id).then(rispostaDopoUpload => {
                        console.log(rispostaDopoUpload);

                        const dataAgg = {
                            nomeFile: rispostaDopoUpload.metadati.nomeFile,
                            metadati: JSON.stringify(rispostaDopoUpload.metadati),
                            estensioneFile: rispostaDopoUpload.metadati.tipoMIME,
                            dimensione: rispostaDopoUpload.metadati.dimensione
                        };

                        update(
                            'documenti',
                            { id: rispostaDopoUpload.documentiId, data: dataAgg },
                            {
                                onSuccess: () => {
                                    notify('Upload e metadati aggiornati con successo');
                                },
                                onFailure: (error) => {
                                    notify(error.message, 'warning');
                                }
                            }
                        );
                    });
                    refresh();
                })
                .catch(error => {
                    notify(error.message, 'warning');
                });
        } catch (error) {
            notify(error.message, 'warning');
        }
    };

    return (
        <Box>
            <TextField
                id="descrizione"
                label="Descrizione"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={descrizione}
                onChange={(e) => setDescrizione(e.target.value)}
            />

            {/* Input file */}
            <input
                type="file"
                onChange={handleFileChange}
                style={{
                    display: 'block',
                    marginTop: '10px',
                    backgroundColor: 'green',
                    color: 'white'
                }}
            />

            {/* Mostra la Select solo se tipoDocumentoFisso è false */}
            {!tipoDocumentoFisso && (
                <Select
                    labelId="dropdown-tipo-documento-label"
                    id="dropdown-tipo-documento"
                    value={tipodocumentoId}
                    onChange={(e) => setTipodocumentoId(e.target.value)}
                    fullWidth
                    displayEmpty
                    style={{ marginTop: '10px' }}
                >
                    <MenuItem value="" disabled>
                        Seleziona un tipo documento
                    </MenuItem>
                    {tipoDocumentoOptions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.descrizione}
                        </MenuItem>
                    ))}
                </Select>
            )}

            <Button
                onClick={handleClick}
                sx={uploadButtonCss}
                variant="contained"
                color="primary"
                style={{ marginTop: '10px' }}
                disabled={!descrizione || !file} // Disabilita se non c'è descrizione o file
            >
                Esegui upload
            </Button>
        </Box>
    );
};

export default CustomCreateDocumenti;
