import * as React from "react";
import { useState, useEffect } from "react";
import {
    List,
    TextField,
    EditButton,
    ShowButton,
    DatagridConfigurable,
    SelectColumnsButton,
    TopToolbar,
    ExportButton,
    FunctionField,
    WrapperField,
    useNotify,
    useRefresh,
    useDataProvider,
    useListContext
} from 'react-admin';
import { useParams } from 'react-router-dom';
import {
    Card,
    CardContent,
    Dialog,
    Button,
    IconButton,
    InputAdornment,
    TextField as MuiTextField,
    Grid,
    Box,
    Typography,
    Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import ClearIcon from '@mui/icons-material/Clear';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Contacts from '@mui/icons-material/Contacts';
import Chip from '@mui/material/Chip';
import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";

import { editButtonCss, showButtonCss, exportButtonCss, columnsButtonCss } from '../shared/Styles/buttonStyles';
import { listCss } from '../shared/Styles/listStyles';
import { ToBoolean } from '../shared/ToBoolean';

import DescriptionChip from '../anagen/DescriptionChip.js';
import CustomError from "../shared/CustomError/CustomError.js";

// Definisci i campi stringa
const STRING_FIELDS = [
    'gnragnom', 'gnfind', 'gncdiva', 'gncdfisc', 'gncomune',
    'gnemail', 'gntel', 'gncel', 'gnind', 'gnprov',
    'gnnaz', 'gnpfnome', 'gnpfcognome', 'gnpfdtna'
];

// Configurazione campi di ricerca
const SEARCH_FIELDS = [
    { field: 'gnragnom', label: 'Ragione Sociale' },
    { field: 'gnfind', label: 'Titolo' },
    { field: 'gncdiva', label: 'P.IVA' },
    { field: 'gncdfisc', label: 'Cod. Fiscale' },
    { field: 'gncomune', label: 'Comune' }
];

// Dialog per "Nessun risultato"
const NoResultsDialog = ({ open, onClose }) => (
    <Dialog open={open} onClose={onClose}>
        <Box sx={{ p: 3, textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>
                Nessun risultato trovato
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                La ricerca non ha prodotto risultati.
            </Typography>
            <Button onClick={onClose} variant="contained">
                Chiudi
            </Button>
        </Box>
    </Dialog>
);

// Componente per la barra di ricerca
const SearchBar = ({ onSearch, onReset, onOpenAdvanced }) => {
    const [searchValues, setSearchValues] = useState({});

    const handleChange = (field, value) => {
        setSearchValues(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const transformAsterisksToWildcards = (value) => {
        // Sostituisci gli asterischi con %
        return value.replace(/\*/g, '%');
    };

    const handleSearch = () => {
        const filteredValues = Object.entries(searchValues)
            .filter(([_, value]) => value && value.trim() !== '')
            .reduce((acc, [key, value]) => {
                const val = value.trim();
                // Se il valore contiene asterischi, li converto in wildcard riconosciute dal backend
                acc[key] = val.includes('*') ? transformAsterisksToWildcards(val) : val;
                return acc;
            }, {});

        console.log('[SearchBar] filteredValues:', filteredValues); // Debug
        onSearch(filteredValues);
    };

    const handleReset = () => {
        setSearchValues({});
        onReset();
    };

    return (
        <Box>
            <Grid container spacing={2} alignItems="center">
                {/* Campi di ricerca */}
                <Grid container item spacing={2} xs={12} md={9}>
                    {SEARCH_FIELDS.map(({ field, label }) => (
                        <Grid item xs={12} sm={6} md={4} key={field}>
                            <MuiTextField
                                fullWidth
                                size="small"
                                label={label}
                                value={searchValues[field] || ''}
                                onChange={(e) => handleChange(field, e.target.value)}
                                InputProps={{
                                    endAdornment: searchValues[field] ? (
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                onClick={() => handleChange(field, '')}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ) : null
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>

                {/* Bottoni */}
                <Grid container item spacing={2} xs={12} md={3}>
                    <Grid item xs={12} sm={4} md={12}>
                        <Button
                            variant="contained"
                            startIcon={<SearchIcon />}
                            onClick={handleSearch}
                            fullWidth
                        >
                            Cerca
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={12}>
                        <Button
                            variant="outlined"
                            startIcon={<RestartAltIcon />}
                            onClick={handleReset}
                            fullWidth
                            color="secondary"
                        >
                            Reset
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={12}>
                        <Button
                            variant="outlined"
                            startIcon={<TuneIcon />}
                            onClick={onOpenAdvanced}
                            fullWidth
                        >
                            Filtri Avanzati
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

// Lista principale
export const AnagenList = () => {
    const [filters, setFilters] = useState({});
    const [openAdvanced, setOpenAdvanced] = useState(false);
    const [showNoResults, setShowNoResults] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const handleRemoveAdvancedFilter = (key) => {
        const newFilters = { ...filters };
        delete newFilters[key];
        setFilters(newFilters);
        notify('Filtro rimosso', { type: 'info' });
    };

    const handleSearch = async (searchValues) => {
        console.log('[AnagenList] handleSearch searchValues:', searchValues);
        try {
            const { total } = await dataProvider.getList('anagen', {
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'id', order: 'DESC' },
                filter: searchValues
            });

            if (total === 0) {
                setShowNoResults(true);
                return;
            }

            // Unisci i nuovi filtri con quelli esistenti invece di sovrascriverli
            setFilters(prevFilters => ({
                ...prevFilters,
                ...searchValues
            }));
            notify('Ricerca completata', { type: 'success' });

        } catch (error) {
            console.error('Errore durante la ricerca:', error);
            notify('Errore durante la ricerca', { type: 'error' });
        }
    };

    const handleReset = () => {
        setFilters({});
        setShowNoResults(false);
        notify('Filtri resettati', { type: 'info' });
        refresh();
    };

    return (
        <>
            <List
                title={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Contacts sx={{ mr: 1 }} />
                        <Typography variant="h6">Anagrafiche</Typography>
                    </Box>
                }
                actions={
                    <TopToolbar>
                        <ExportButton label="Esporta" sx={exportButtonCss} />
                        <SelectColumnsButton sx={columnsButtonCss} />
                    </TopToolbar>
                }
                perPage={50}
                sort={{ field: 'id', order: 'DESC' }}
                filter={filters}
                empty={false}
            >
                <Card sx={{ mb: 2 }}>
                    <CardContent>
                        <SearchBar
                            onSearch={handleSearch}
                            onReset={handleReset}
                            onOpenAdvanced={() => setOpenAdvanced(true)}
                        />
                        <ActiveAdvancedFilters
                            filters={filters}
                            onRemove={handleRemoveAdvancedFilter}
                        />
                    </CardContent>
                </Card>

                <ListContent />
            </List>

            <NoResultsDialog
                open={showNoResults}
                onClose={() => setShowNoResults(false)}
            />

            <AdvancedFiltersDialog
                open={openAdvanced}
                onClose={() => setOpenAdvanced(false)}
                onApply={handleSearch}
                onReset={handleReset}
                currentFilters={filters}
            />
        </>
    );
};

// Componente per il contenuto della lista
const ListContent = () => {
    const listContext = useListContext();
    const [error, setError] = useState(null);
    const refresh = useRefresh();

    React.useEffect(() => {
        if (listContext.error && !listContext.isLoading) {
            if (listContext.error.status === 403) {
                setError({
                    title: 'Accesso Negato',
                    message: 'Non hai i permessi necessari per visualizzare questa lista.'
                });
            } else {
                setError({
                    title: 'Errore',
                    message: listContext.error.message || 'Si è verificato un errore durante il caricamento dei dati.'
                });
            }
        } else {
            setError(null);
        }
    }, [listContext.error, listContext.isLoading]);

    const handleRetry = () => {
        setError(null);
        refresh();
    };

    if (error) {
        return <CustomError error={error} onRetry={handleRetry} />;
    }

    if (!listContext.data) return null;

    return (
        <DatagridConfigurable
            sx={listCss}
            bulkActionButtons={false}
        >
            <TextField label="ID" source="id" />
            <FunctionField
                source="gnfind"
                label="Titolo Ricerca"
                render={(record) => record?.gnfind}
            />
            <FunctionField
                source="gncdtan"
                label="Tipologia Anagrafica"
                render={(record) => <DescriptionChip gncdtan={record.gncdtan}></DescriptionChip>}
            />
            <TextField label="Ragione Sociale" source="gnragnom" />
            <TextField label="Ragione Sociale 2" source="gnrag2" />
            <TextField label="P.IVA" source="gncdiva" />
            <TextField label="Codice Fiscale" source="gncdfisc" />
            <TextField label="Comune" source="gncomune" />
            <TextField label="Provincia" source="gnprov" />
            <TextField label="Email" source="gnemail" />
            <TextField label="Telefono" source="gntel" />
            <ToBoolean label="Attivo" source="flAttivo" />
            <ToBoolean label="Visibile" source="fiVisibile" />
            <ToBoolean label="Annullato" source="flAnnullato" />
            <EditButton sx={editButtonCss} label="Modifica" />
            <ShowButton sx={showButtonCss} label="Dettaglio" />
            <WrapperField label="Elimina">
                <ConfirmDialogDeleteButtonCustom
                    titolo="Cancella Cliente"
                    messaggio="Sei sicuro di voler cancellare questo cliente?"
                    servizio="anagen"
                />
            </WrapperField>
        </DatagridConfigurable>
    );
};

// Componente per mostrare i filtri avanzati attivi
const ActiveAdvancedFilters = ({ filters, onRemove }) => {
    if (!filters || Object.keys(filters).length === 0) return null;

    // Mappa per i nomi dei campi
    const fieldLabels = {
        id: 'ID',
        gncdtan: 'Codice TAN',
        gncodipc: 'Codice IPC',
        gnpfnome: 'Nome',
        gnpfcognome: 'Cognome',
        gnpfdtna: 'Data Nascita',
        gntel: 'Telefono',
        gncel: 'Cellulare',
        gnemail: 'Email',
        gnind: 'Indirizzo',
        gncap: 'CAP',
        gnprov: 'Provincia',
        gnnaz: 'Nazione'
    };

    return (
        <Box sx={{ mt: 2, p: 2, bgcolor: '#f5f5f5', borderRadius: 1 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
                Filtri Avanzati Attivi:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {Object.entries(filters).map(([key, value]) => (
                    <Chip
                        key={key}
                        label={`${fieldLabels[key] || key}: ${value}`}
                        onDelete={() => onRemove(key)}
                        size="small"
                        color="primary"
                        variant="outlined"
                    />
                ))}
            </Box>
        </Box>
    );
};

// Dialog per i filtri avanzati
const AdvancedFiltersDialog = ({ open, onClose, onApply, onReset, currentFilters }) => {
    const [advancedFilters, setAdvancedFilters] = useState({});

    // Inizializza i filtri avanzati con i valori correnti quando il dialog si apre
    useEffect(() => {
        if (open) {
            setAdvancedFilters(currentFilters || {});
        }
    }, [open, currentFilters]);

    const transformAsterisksToWildcards = (value) => {
        return value.replace(/\*/g, '%');
    };

    const addWildcards = (value) => {
        if (!value.startsWith('*')) {
            value = `*${value}`;
        }
        if (!value.endsWith('*')) {
            value = `${value}*`;
        }
        return value;
    };

    const handleApply = () => {
        const filteredValues = Object.entries(advancedFilters)
            .filter(([_, value]) => value && value.toString().trim() !== '')
            .reduce((acc, [key, value]) => {
                let val = value.toString().trim();
                if (!val.includes('*')) {
                    val = addWildcards(val);
                }
                acc[key] = val.includes('*') ? transformAsterisksToWildcards(val) : val;
                return acc;
            }, {});

        onApply(filteredValues);
        onClose();
    };

    const handleReset = () => {
        setAdvancedFilters({});
        onReset();
        onClose();
    };

    const handleChange = (field, value) => {
        setAdvancedFilters(prev => ({
            ...prev,
            [field]: value
        }));
    };

    // Campi avanzati di esempio (aggiungi tutti i campi necessari)
    const advancedFields = [
        { field: 'id', label: 'ID' },
        { field: 'gncdtan', label: 'Codice TAN' },
        { field: 'gncodipc', label: 'Codice IPC' },
        { field: 'gnpfnome', label: 'Nome' },
        { field: 'gnpfcognome', label: 'Cognome' },
        { field: 'gnpfdtna', label: 'Data Nascita' },
        { field: 'gntel', label: 'Telefono' },
        { field: 'gncel', label: 'Cellulare' },
        { field: 'gnemail', label: 'Email' },
        { field: 'gnind', label: 'Indirizzo' },
        { field: 'gncap', label: 'CAP' },
        { field: 'gnprov', label: 'Provincia' },
        { field: 'gnnaz', label: 'Nazione' }
    ];

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <Box sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Filtri Avanzati
                </Typography>

                <Grid container spacing={2}>
                    {advancedFields.map(({ field, label }) => (
                        <Grid item xs={12} sm={6} md={4} key={field}>
                            <MuiTextField
                                fullWidth
                                size="small"
                                label={label}
                                value={advancedFilters[field] || ''}
                                onChange={(e) => handleChange(field, e.target.value)}
                                InputProps={{
                                    endAdornment: advancedFilters[field] ? (
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                onClick={() => handleChange(field, '')}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ) : null
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button onClick={onClose}>
                        Annulla
                    </Button>
                    <Button
                        onClick={handleReset}
                        color="secondary"
                        startIcon={<RestartAltIcon />}
                    >
                        Reset
                    </Button>
                    <Button
                        onClick={handleApply}
                        variant="contained"
                        startIcon={<SearchIcon />}
                    >
                        Applica Filtri
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default AnagenList;
