import React from 'react';
import { useStore } from 'react-admin';
import ClienteBooking0Show from './cliente/ClienteBooking0Show';
import Booking0Show from './Booking0Show';

export const Booking0ShowWrapper = props => {
    // Recupera l'ID del gruppo dall'utente autenticato
    const [gruppiId] = useStore('profile.gruppiId');

    // Se l'utente è identificato come Cliente (gruppiId === 2), mostra la lista dedicata
    if (gruppiId === 2) {
        return <ClienteBooking0Show gruppiId={gruppiId} {...props} />;
    }

    // Per gli altri utenti, mostra la lista standard
    return <Booking0Show {...props} />;
};