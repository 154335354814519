import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
    required,
    Toolbar,
    SaveButton,
    TopToolbar,
    ShowButton,
    SelectInput,
    useInput
} from 'react-admin';
import {
    Grid,
    Box,
    Typography,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    LinearProgress,
    IconButton
} from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { checkPermission } from '../shared/Permissions/checkPermission';

// Funzione per calcolare la forza della password
const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (password.match(/[a-z]/) && password.match(/[A-Z]/)) strength++;
    if (password.match(/\d/)) strength++;
    if (password.match(/[^a-zA-Z\d]/)) strength++;
    return strength;
};

// Componente personalizzato per il campo password
const PasswordInput = props => {
    const {
        field,
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted }
    } = useInput(props);
    const [strength, setStrength] = React.useState(0);
    const [showPassword, setShowPassword] = React.useState(false);

    const handleChange = (event) => {
        field.onChange(event);
        setStrength(calculatePasswordStrength(event.target.value));
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Box>
            <TextField
                {...field}
                type={showPassword ? "text" : "password"}
                label="Password"
                error={isTouched && invalid}
                helperText={isTouched && invalid ? error : ''}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <LockIcon />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <LinearProgress
                variant="determinate"
                value={strength * 25}
                sx={{
                    marginTop: 1,
                    '& .MuiLinearProgress-bar': {
                        backgroundColor:
                            strength <= 1 ? '#f44336' :
                                strength === 2 ? '#ff9800' :
                                    strength === 3 ? '#4caf50' : '#2196f3'
                    }
                }}
            />
            <Typography variant="caption">
                Forza password: {
                    strength <= 1 ? 'Debole' :
                        strength === 2 ? 'Media' :
                            strength === 3 ? 'Forte' : 'Molto forte'
                }
            </Typography>
        </Box>
    );
};

// Il resto del codice rimane invariato...
const MyToolbarEdit = () => (
    <Toolbar>
        <SaveButton
            sx={{
                borderRadius: '20px',
                backgroundColor: '#01b5d0',
                '&:hover': {
                    backgroundColor: '#0199b0',
                },
            }}
            label="Salva Parametri"
            alwaysEnable
        />
    </Toolbar>
);

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <ShowButton
            sx={{
                marginRight: '10px',
                color: '#4caf50',
                '&:hover': {
                    color: '#388e3c',
                },
            }}
            label="Dettaglio"
        />
    </TopToolbar>
);

const ShowActionsForEdit = () => <ShowTopToolbarDettaglio />;

const UsersEdit = () => {

    const canRead = checkPermission("users", "update");

    if (!canRead) {
        return (
            <div style={{ color: "red", fontWeight: "bold", padding: 20 }}>
                Accesso negato
            </div>
        );
    }

    return (
        <Edit
            title={
                <Box display="flex" alignItems="center">
                    <PeopleIcon color="primary" />
                    <Typography variant="h6" component="span" sx={{ marginLeft: 1 }}>
                        Modifica Record di Users
                    </Typography>
                </Box>
            }
            actions={<ShowActionsForEdit />}
            redirect="show"
        >
            <SimpleForm toolbar={<MyToolbarEdit />}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>User e tipologia</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextInput label="Email" source="email" validate={[required()]} fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PasswordInput source="password" validate={[required()]} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectInput
                                            label="Ruolo"
                                            source="roles"
                                            validate={[required()]}
                                            choices={[
                                                { id: 'admin', name: 'Admin' },
                                                { id: 'gruppi', name: 'Gruppi' }
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>Dettagli di contatto</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextInput label="Nome" source="nome" validate={[required()]} fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextInput label="Cognome" source="cognome" validate={[required()]} fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <NumberInput label="Telefono" source="telefono" validate={[required()]} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
}

export default UsersEdit;