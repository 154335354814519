// utils/createRifornimento.js
import axios from 'axios';
import config from '../../config/config.json';

const host = config.production.host;

export const createRifornimento = async (data) => {
    try {
        const jwt = localStorage.getItem("feathers-jwt");
        const response = await axios.post(
            `${host}/rifornimenti`,
            data,
            {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error(`Errore durante la creazione del rifornimento:`, error);
        throw error;
    }
};
