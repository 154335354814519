import React, { useState, useEffect } from 'react';
import { useGetList } from 'react-admin';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Paper,
    Typography,
    TextField,
    Button,
    Box,
    Checkbox,
    FormControlLabel,
    IconButton,
    Tooltip
} from '@mui/material';
import CalculateIcon from '@mui/icons-material/Calculate';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import _ from 'lodash';
import GetDescrizionGruppoMezzo from './GetDescrizionGruppoMezzo';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DataUsageIcon from '@mui/icons-material/DataUsage';      // Per Dati di Input
import SettingsIcon from '@mui/icons-material/Settings';        // Per Tariffa A 
import TuneIcon from '@mui/icons-material/Tune';               // Per Tariffa B
import { saveButtonCss } from '../../shared/Styles/buttonStyles';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import saveSimulatorData from './saveSimulatorData';
import config from '../../../config/config.json';
import loadSimulatorData from './loadSimulatorData';

const host = config.production.host;

/**
 * Componente per la visualizzazione delle card dei risultati
 */
const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    alert("Importo copiato: " + value);
};

const formatEuro = (value) => {
    return new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 3
    }).format(value);
};

const formatValue = (value, type) => {
    switch (type) {
        case 'euro':
            return new Intl.NumberFormat('it-IT', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 3
            }).format(value);
        case 'percentuale':
            return `${value.toFixed(2)}%`;
        case 'km':
            return `${value.toFixed(2)} km`;
        case 'minuti':
            return `${value} min`;
        case 'ore':
            return `${value} ore`;
        default:
            return value;
    }
};

const ResultCard = ({ title, subtitle, items, total, className }) => {
    // Stato per gestire il popover
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [activeFormula, setActiveFormula] = React.useState('');

    // Gestione apertura/chiusura popover
    const handleInfoClick = (event, formula) => {
        setAnchorEl(event.currentTarget);
        setActiveFormula(formula);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setActiveFormula('');
    };

    const open = Boolean(anchorEl);

    return (
        <Paper className={className} elevation={1} sx={{ p: 3, bgcolor: 'grey.50' }}>
            <Typography variant="h6" sx={{
                color: 'primary.main',
                pb: 1,
                borderBottom: 1,
                borderColor: 'primary.light',
                mb: 2
            }}>
                {title}
                {subtitle && <br />}
                {subtitle}
            </Typography>

            <Grid container spacing={2}>
                {items.map(({ label, value, type, formula }) => (
                    <Grid item xs={6} key={label}>
                        <Typography variant="body2" color="text.secondary">
                            {label}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant="h6" sx={{ mr: 1 }}>
                                {formatValue(value, type)}
                            </Typography>
                            {formula && (
                                <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={(e) => handleInfoClick(e, formula)}
                                >
                                    <InfoIcon fontSize="small" />
                                </IconButton>
                            )}
                            <IconButton
                                size="small"
                                onClick={() => handleCopy(formatValue(value, type))}
                                color="primary"
                            >
                                <ContentCopyIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                ))}
            </Grid>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: {
                        p: 2,
                        maxWidth: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 4,
                        '& pre': {
                            mt: 1,
                            p: 1,
                            bgcolor: 'grey.100',
                            borderRadius: 1,
                            overflow: 'auto',
                            userSelect: 'text',
                            cursor: 'text',
                            fontFamily: 'monospace'
                        }
                    }
                }}
            >
                <Typography variant="subtitle2" color="primary">
                    Formula di Calcolo
                </Typography>
                <pre>
                    {activeFormula}
                </pre>
                <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton
                        size="small"
                        onClick={() => handleCopy(activeFormula)}
                        color="primary"
                    >
                        <ContentCopyIcon fontSize="small" />
                    </IconButton>
                </Box>
            </Popover>

            {total !== undefined && (
                <Box sx={{ pt: 2, mt: 2, borderTop: 1, borderColor: 'primary.light' }}>
                    <Typography variant="h6" sx={{ color: 'primary.main' }}>
                        Totale: {formatValue(total, 'euro')}
                    </Typography>
                    <IconButton
                        size="small"
                        onClick={() => handleCopy(formatValue(total, 'euro'))}
                        color="primary"
                    >
                        <ContentCopyIcon />
                    </IconButton>
                </Box>
            )}
        </Paper>
    );
};

/**
 * Componente principale del simulatore tariffe
 */
const TratteSimulatorDialog = ({
    open,
    onClose,
    booking0AutomezziRecord,
    booking0Record,
    dataTariffeARecord,
    tratte = []
}) => {

    console.log(" TratteSimulatorDialog > dataTariffeARecord : ", dataTariffeARecord);
    // Gestione dello stato per input e risultati
    const [inputs, setInputs] = useState({
        input_km_servizio: 0,
        input_minuti_servizio: 0,
        input_km_vuoto: 0,
        input_minuti_vuoto: 0,
        input_notturno: false,
        input_supplemento_pedaggio: 0,
        input_tipo_sconto: 2, // 1 = percentuale, 2 = valore
        input_valore_sconto: 0,
        input_total_km: 0,      // Nuovo campo per il totale dei KM
        input_total_minuti: 0   // Nuovo campo per il totale dei minuti
    });

    const [results, setResults] = useState({
        results_importo_budget_km: 0,
        results_importo_budget_hh: 0,
        results_importo_totale_suppl_km: 0,
        results_importo_totale_suppl_hh: 0,
        results_km_totali: 0,
        results_minuti_totali: 0,
        results_km_eccedenza: 0,
        results_minuti_eccedenza: 0,
        results_totale_imponibile_di_servizio: 0,
        results_supplemento_notturno: 0,
        results_totale_imponibile_finale: 0,
        sconto: 0,
        totale_imponibile: 0,
        importo_iva: 0,
        totale_preventivo: 0
    });

    // Recupero dati tariffa B
    const { data: tariffeBData } = useGetList('tariffe-b', {
        filter: { id: booking0AutomezziRecord?.tariffeBId }
    });

    console.log("Recupero dati tariffa B - tariffeBData", tariffeBData);


    // Cerca l'ultimo calcolo e popola le variabili oppure Reset del calcolatore all'apertura
    useEffect(() => {
        if (open) {
            const loadData = async () => {
                try {
                    const savedData = await loadSimulatorData(booking0AutomezziRecord.id, host);

                    if (savedData) {
                        // Impostiamo i valori salvati
                        console.log("savedData : ", savedData);

                        setInputs(savedData.inputs);
                        setResults(savedData.results);
                        setTariffeAEditable(savedData.tariffeAEditable);
                        setTariffeBEditable(savedData.tariffeBEditable);
                    } else {
                        // Se non ci sono dati salvati, facciamo il reset normale
                        resetCalculator();
                    }
                } catch (error) {
                    console.error('Error loading saved data:', error);
                    // In caso di errore, facciamo il reset normale
                    resetCalculator();
                }
            };

            loadData();
        }
    }, [open, booking0AutomezziRecord?.id]);

    // Gestione modifiche input
    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setInputs(prev => {
            const newInputs = {
                ...prev,
                [name]: type === 'checkbox' ? checked : (parseFloat(value) || 0)
            };

            // Calcola i totali ogni volta che cambiano gli input
            if (['input_km_servizio', 'input_km_vuoto', 'input_minuti_servizio', 'input_minuti_vuoto'].includes(name)) {
                newInputs.input_total_km = newInputs.input_km_servizio + newInputs.input_km_vuoto;
                newInputs.input_total_minuti = newInputs.input_minuti_servizio + newInputs.input_minuti_vuoto;
            }

            return newInputs;
        });
    };

    /**
     * Calcola tutti i risultati basati su input e tariffe
     */
    const calculateResults = () => {
        // Prendi i valori dallo stato locale modificabile
        const tariffeA = tariffeAEditable;
        const tariffeB = tariffeBEditable;

        // Se mancano i dati base di Tariffa A o Tariffa B, interrompi il calcolo
        if (!tariffeA || !tariffeB) return;

        // 1. Usa i totali direttamente dagli input o la somma dei singoli valori
        const input_km = inputs.input_total_km || (inputs.input_km_servizio + inputs.input_km_vuoto);
        const input_minuti = inputs.input_total_minuti || (inputs.input_minuti_servizio + inputs.input_minuti_vuoto);

        // 2. Calcolo budget in KM
        const results_importo_budget_km =
            input_km > parseFloat(tariffeA.tfa_bdgkm)
                ? (input_km - parseFloat(tariffeA.tfa_bdgkm)) * parseFloat(tariffeB.tfb_ekm) +
                parseFloat(tariffeA.tfa_bdgkm) * parseFloat(tariffeB.tfb_ckm)
                : parseFloat(tariffeA.tfa_bdgkm) * parseFloat(tariffeB.tfb_ckm);

        // 3. Calcolo budget in HH (confrontiamo i minuti totali con tfa_bdghh convertito in minuti)
        const budget_minutes = parseFloat(tariffeA.tfa_bdghh) * 60;
        const results_importo_budget_hh =
            input_minuti > budget_minutes
                ? (input_minuti - budget_minutes) * parseFloat(tariffeB.tfb_ehh)
                : 0;

        // 4. KM e Minuti Totali (usiamo i valori degli input)
        const results_km_totali = input_km;
        const results_minuti_totali = input_minuti;

        // 5. Calcolo Eccedenza
        const results_km_eccedenza = Math.max(0, input_km - parseFloat(tariffeA.tfa_bdgkm));

        // calc_results_minuti_totali=calc_minuti_di_servizio+calc_minuti_a_vuoto_ad+calc_minuti_a_vuoto_nd
        // =SE(calc_results_minuti_totali <= input_minuti + tariffeA.tfa_frgmmec; 0; calc_results_minuti_totali - (input_minuti + tariffeA.tfa_frgmmec))
        // DA impostare la formula per la riga seguente
        // const results_minuti_eccedenza = Math.max(0, input_minuti - budget_minutes);
        // Calcolo minuti eccedenza con franchigia meccanica
        // Debug calcolo minuti eccedenza
        console.log('=== DEBUG CALCOLO MINUTI ECCEDENZA ===');
        console.log('Input iniziali:');
        console.log('- inputs.input_total_minuti:', inputs.input_total_minuti);
        console.log('- inputs.input_minuti_servizio:', inputs.input_minuti_servizio);
        console.log('- inputs.input_minuti_vuoto:', inputs.input_minuti_vuoto);
        console.log('- tariffeA.tfa_frgmmec:', parseFloat(tariffeA.tfa_frgmmec));
        console.log('- input_minuti:', input_minuti);

        const calc_results_minuti_totali = (inputs.input_minuti_servizio + inputs.input_minuti_vuoto);
        console.log('\nCalcolo minuti totali:');
        console.log('calc_results_minuti_totali =', calc_results_minuti_totali, '(usando', inputs.input_total_minuti ? 'input_total_minuti' : 'somma di servizio e vuoto', ')');

        const somma_input_franchigia = input_minuti + parseFloat(tariffeA.tfa_frgmmec);
        console.log('\nCalcolo soglia con franchigia:');
        console.log(`somma_input_franchigia = input_minuti + tfa_frgmmec = ${input_minuti} + ${parseFloat(tariffeA.tfa_frgmmec)} = ${somma_input_franchigia}`);

        console.log('\nVerifica condizione:');
        console.log(`calc_results_minuti_totali (${calc_results_minuti_totali}) <= somma_input_franchigia (${somma_input_franchigia})?`, calc_results_minuti_totali <= somma_input_franchigia);

        const results_minuti_eccedenza = calc_results_minuti_totali <= somma_input_franchigia
            ? 0
            : calc_results_minuti_totali - somma_input_franchigia;

        console.log('\nRisultato finale:');
        console.log('results_minuti_eccedenza =', results_minuti_eccedenza);
        if (results_minuti_eccedenza === 0) {
            console.log('(Nessuna eccedenza perché i minuti totali non superano la soglia con franchigia)');
        } else {
            console.log(`(Eccedenza calcolata come: ${calc_results_minuti_totali} - ${somma_input_franchigia} = ${results_minuti_eccedenza})`);
        }
        console.log('=====================================');

        // 6. Calcoli costi supplementari
        const results_importo_totale_suppl_km =
            input_km > parseFloat(tariffeA.tfa_bdgkm)
                ? (input_km - parseFloat(tariffeA.tfa_bdgkm)) * parseFloat(tariffeB.tfb_ekm)
                : 0;

        const results_importo_totale_suppl_hh = results_minuti_eccedenza * parseFloat(tariffeB.tfb_ehh);

        // 7. Totale imponibile di servizio
        const results_totale_imponibile_di_servizio =
            results_importo_budget_km + results_importo_budget_hh + /* results_importo_totale_suppl_km + */ results_importo_totale_suppl_hh;

        // 8. Supplemento Notturno
        const results_supplemento_notturno = inputs.input_notturno
            ? results_totale_imponibile_di_servizio * (parseFloat(tariffeB.tfb_snotturno || 0) / 100)
            : 0;

        // 9. Totale imponibile finale
        const results_totale_imponibile_finale = results_totale_imponibile_di_servizio + results_supplemento_notturno;

        // 10. Calcolo Sconto
        const sconto = inputs.input_tipo_sconto === 1
            ? (results_totale_imponibile_finale * (parseFloat(inputs.input_valore_sconto || 0) / 100))
            : parseFloat(inputs.input_valore_sconto || 0);

        // 11. Imponibile Scontato
        const totale_imponibile = results_totale_imponibile_finale - sconto;

        // 12. Calcolo IVA e Totale Preventivo
        const iva_percentage = 0.10;
        const importo_iva = totale_imponibile * iva_percentage;
        const totale_preventivo = totale_imponibile + importo_iva;

        // 13. Aggiorna lo stato con tutti i risultati
        setResults({
            results_importo_budget_km,
            results_importo_budget_hh,
            results_importo_totale_suppl_km,
            results_importo_totale_suppl_hh,
            results_km_totali,
            results_minuti_totali,
            results_km_eccedenza,
            results_minuti_eccedenza,
            results_totale_imponibile_di_servizio,
            results_supplemento_notturno,
            results_totale_imponibile_finale,
            sconto,
            totale_imponibile,
            importo_iva,
            totale_preventivo
        });
    };


    /**
     * Reset del calcolatore allo stato iniziale
     */
    const resetCalculator = () => {

        console.log("RESETTTTTO");

        // 1. Re-imposta i parametri di Tariffa A e Tariffa B
        setTariffeAEditable({
            tfa_tipo: dataTariffeARecord?.tfa_tipo || 0,
            tfa_des: dataTariffeARecord?.tfa_des || "",
            tfa_bdgkm: dataTariffeARecord?.tfa_bdgkm || 0,
            tfa_bdghh: dataTariffeARecord?.tfa_bdghh || 0,
            tfa_frgmmec: dataTariffeARecord?.tfa_frgmmec || 0,
            tfa_frgmmnt: dataTariffeARecord?.tfa_frgmmnt || 0
        });

        setTariffeBEditable({
            id: tariffeBData?.[0]?.id || null,
            tfb_des: tariffeBData?.[0]?.tfb_des || "",
            tfb_ckm: tariffeBData?.[0]?.tfb_ckm || 0,
            tfb_chh: tariffeBData?.[0]?.tfb_chh || 0,
            tfb_ekm: tariffeBData?.[0]?.tfb_ekm || 0,
            tfb_ehh: tariffeBData?.[0]?.tfb_ehh || 0,
            tfb_vkmperc: tariffeBData?.[0]?.tfb_vkmperc || 0,
            tfb_vhhperc: tariffeBData?.[0]?.tfb_vhhperc || 0,
            tfb_snotturno: tariffeBData?.[0]?.tfb_snotturno || 0
        });

        // 2. Reset degli input in base alle tratte (come nel tuo codice originale)
        if (tratte?.length > 0) {
            const tratteByType = _.groupBy(tratte, t => t.bk2_tvuoto === "1" ? 'empty' : 'service');

            const serviceTotals = _.reduce(tratteByType.service || [], (acc, t) => ({
                km: acc.km + (parseFloat(t.bk2_pkmt) || 0),
                minutes: acc.minutes + ((parseInt(t.bk2_tempoInSecondi, 10) || 0) / 60)
            }), { km: 0, minutes: 0 });

            const emptyTotals = _.reduce(tratteByType.empty || [], (acc, t) => ({
                km: acc.km + (parseFloat(t.bk2_pkmt) || 0),
                minutes: acc.minutes + ((parseInt(t.bk2_tempoInSecondi, 10) || 0) / 60)
            }), { km: 0, minutes: 0 });

            setInputs({
                input_km_servizio: serviceTotals.km,
                input_minuti_servizio: serviceTotals.minutes,
                input_km_vuoto: emptyTotals.km,
                input_minuti_vuoto: emptyTotals.minutes,
                input_notturno: false,
                input_supplemento_pedaggio: 0,
                input_tipo_sconto: 2,
                input_valore_sconto: 0,
                input_total_km: serviceTotals.km/*  + emptyTotals.km */,
                input_total_minuti: serviceTotals.minutes/*  + emptyTotals.minutes */
            });
        } else {
            setInputs({
                input_km_servizio: 0,
                input_minuti_servizio: 0,
                input_km_vuoto: 0,
                input_minuti_vuoto: 0,
                input_notturno: false,
                input_supplemento_pedaggio: 0,
                input_tipo_sconto: 2,
                input_valore_sconto: 0,
                input_total_km: 0,
                input_total_minuti: 0
            });
        }

        // 3. Reset dei risultati
        setResults({
            results_importo_budget_km: 0,
            results_importo_budget_hh: 0,
            results_importo_totale_suppl_km: 0,
            results_importo_totale_suppl_hh: 0,
            results_km_totali: 0,
            results_minuti_totali: 0,
            results_km_eccedenza: 0,
            results_minuti_eccedenza: 0,
            results_totale_imponibile_di_servizio: 0,
            results_supplemento_notturno: 0,
            results_totale_imponibile_finale: 0,
            sconto: 0,
            totale_imponibile: 0,
            importo_iva: 0,
            totale_preventivo: 0
        });
    };


    /* ****************** JSON generato con tasto SALVA*********** INIZIO */

    const generateFinalJson = () => {
        const {
            id,
            flAttivo,
            flVisibile,
            flAnnullato,
            createdAt,
            updatedAt,
            ...rest
        } = booking0AutomezziRecord;

        const booking0AutomezziRecordMod = {
            ...rest,
            booking0AutomezziId: id
        };

        // Rinominiamo la chiave id in tariffeBEditable
        const {
            id: tariffeBId,
            ...restTariffeB
        } = tariffeBEditable;

        const tariffeBEditableMod = {
            tfb_id: tariffeBId,
            ...restTariffeB
        };

        // Prefissiamo le chiavi desiderate in results
        const fieldsToPrefix = ["sconto", "totale_imponibile", "importo_iva", "totale_preventivo"];
        const prefixedResults = Object.entries(results).reduce((acc, [key, value]) => {
            if (fieldsToPrefix.includes(key)) {
                acc[`results_${key}`] = value;
            } else {
                acc[key] = value;
            }
            return acc;
        }, {});

        // Rinominiamo dataTariffeARecord.id in tfa_id, integrandolo dentro tariffeAEditable
        console.log("tariffeAEditable in generateFinalJson", tariffeAEditable);
        console.log("dopo tariffeAEditable in generateFinalJson", dataTariffeARecord
        );



        const tariffeAEditableMod = {
            ...tariffeAEditable,
            tfa_id: dataTariffeARecord.id
        };

        const finalJson = {
            booking0AutomezziRecordMod,
            // qui usiamo la versione modificata di tariffeAEditable
            tariffeARecord: tariffeAEditableMod,
            tariffeBData: tariffeBEditableMod,
            inputs: {
                ...inputs,
                // Assicuriamoci che i totali siano inclusi
                input_total_km: inputs.input_total_km,
                input_total_minuti: inputs.input_total_minuti
            },
            results: prefixedResults
        };

        console.log("Final JSON:", finalJson);
        return finalJson;
    };



    /* ****************** JSON generato con tasto SALVA*********** FINE */

    // Stato per Tariffa A
    const [tariffeAEditable, setTariffeAEditable] = useState({
        tfa_tipo: dataTariffeARecord?.tfa_tipo || 0,
        tfa_des: dataTariffeARecord?.tfa_des || "",
        tfa_bdgkm: dataTariffeARecord?.tfa_bdgkm || 0,
        tfa_bdghh: dataTariffeARecord?.tfa_bdghh || 0,
        tfa_frgmmec: dataTariffeARecord?.tfa_frgmmec || 0,
        tfa_frgmmnt: dataTariffeARecord?.tfa_frgmmnt || 0
    });

    // Stato per Tariffa B
    const [tariffeBEditable, setTariffeBEditable] = useState({
        id: tariffeBData?.[0]?.id || null,
        tfb_des: tariffeBData?.[0]?.tfb_des || "",
        tfb_ckm: tariffeBData?.[0]?.tfb_ckm || 0,
        tfb_chh: tariffeBData?.[0]?.tfb_chh || 0,
        tfb_ekm: tariffeBData?.[0]?.tfb_ekm || 0,
        tfb_ehh: tariffeBData?.[0]?.tfb_ehh || 0,
        tfb_vkmperc: tariffeBData?.[0]?.tfb_vkmperc || 0,
        tfb_vhhperc: tariffeBData?.[0]?.tfb_vhhperc || 0,
        tfb_snotturno: tariffeBData?.[0]?.tfb_snotturno || 0
    });



    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            PaperProps={{
                sx: (theme) => ({
                    minHeight: '90vh',
                    // width: `calc(${theme.breakpoints.values.lg}px + 115px)`,  // 50px più largo di "lg"
                    width: '95vw',
                    maxWidth: 'none',
                    padding: 2,
                    margin: 'auto'
                })
            }}
        >
            <DialogTitle sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>

                <Box sx={{ display: 'flex', gap: 2, alignItems: 'left' }}>
                    <Button
                        variant="contained"
                        onClick={calculateResults}
                        startIcon={<CalculateIcon />}
                    >
                        Calcola
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={resetCalculator}
                        startIcon={<RestartAltIcon />}
                    >
                        Reset
                    </Button>
                    {/* PRIMA VERSIONE CON SOLO JSON */}
                    {/* <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                            const finalJson = generateFinalJson();
                            alert(JSON.stringify(finalJson, null, 2));
                        }}
                        startIcon={<CalculateIcon />}
                        sx={saveButtonCss}
                    >
                        Salva
                    </Button> */}

                    <Button
                        variant="contained"
                        color="success"
                        onClick={async () => {
                            try {
                                const finalJson = generateFinalJson();
                                await saveSimulatorData(finalJson, host, booking0Record);
                                // Mostra un messaggio di successo
                                alert('Dati salvati con successo!');
                                // Opzionalmente, chiudi il dialog
                                // onClose();
                            } catch (error) {
                                // Gestisci l'errore
                                alert('Errore durante il salvataggio: ' + error.message);
                            }
                        }}
                        startIcon={<CalculateIcon />}
                        sx={saveButtonCss}
                    >
                        Salva Dati
                    </Button>

                    <Typography variant="h5">
                        <Tooltip title={<>gruppoId {booking0AutomezziRecord?.gruppoId} - booking-0-automezzi id: {booking0AutomezziRecord?.id}</>}>
                            <InfoIcon color="action" sx={{ fontSize: 20 }} />
                        </Tooltip>
                        <br></br>
                        <GetDescrizionGruppoMezzo
                            label="Gruppo"
                            id={booking0AutomezziRecord?.gruppoId}
                            reference="tabellealfa"
                        />
                    </Typography>
                </Box>

                <Paper
                    elevation={2}
                    sx={{
                        mt: 3,
                        p: 3,
                        bgcolor: 'primary.main',
                        borderRadius: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <Typography variant="h5" sx={{ color: 'white' }}>
                        Totale Preventivo: {formatEuro(results.totale_preventivo)}
                    </Typography>&nbsp;&nbsp;
                    <IconButton
                        size="small"
                        onClick={() => handleCopy(formatEuro(results.totale_preventivo))}
                        sx={{
                            color: 'white',
                            bgcolor: 'rgba(255, 255, 255, 0.15)',
                            borderRadius: '50%',
                            '&:hover': {
                                bgcolor: 'rgba(255, 255, 255, 0.25)'
                            }
                        }}
                    >
                        <ContentCopyIcon />
                    </IconButton>
                </Paper>

                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>

                    <Button
                        variant="contained"
                        color="warning"
                        onClick={onClose}
                        startIcon={<HighlightOffIcon />}
                    >
                        Chiudi Dialog
                    </Button>

                </Box>
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={3}>
                    {/* Sezione Input */}
                    <Grid item xs={12} md={4}>
                        <Paper elevation={1} sx={{ p: 3, bgcolor: 'grey.100' }}>
                            <Typography variant="h6" color="primary" gutterBottom>
                                <DataUsageIcon />
                                Dati di Input
                            </Typography>

                            <Box sx={{ mt: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Servizio
                                        </Typography>
                                        <TextField
                                            // fullWidth
                                            label="KM Servizio"
                                            name="input_km_servizio"
                                            type="number"
                                            value={inputs.input_km_servizio}
                                            onChange={handleInputChange}
                                            size="small"
                                            sx={{ mb: 2 }}
                                        />
                                        <TextField
                                            // fullWidth
                                            label="Minuti Servizio"
                                            name="input_minuti_servizio"
                                            type="number"
                                            value={inputs.input_minuti_servizio}
                                            onChange={handleInputChange}
                                            size="small"
                                            sx={{ mb: 2 }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            A Vuoto
                                        </Typography>
                                        <TextField
                                            // fullWidth
                                            label="KM Vuoto"
                                            name="input_km_vuoto"
                                            type="number"
                                            value={inputs.input_km_vuoto}
                                            onChange={handleInputChange}
                                            size="small"
                                            sx={{ mb: 2 }}
                                        />
                                        <TextField
                                            // fullWidth
                                            label="Minuti Vuoto"
                                            name="input_minuti_vuoto"
                                            type="number"
                                            value={inputs.input_minuti_vuoto}
                                            onChange={handleInputChange}
                                            size="small"
                                            sx={{ mb: 2 }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Totali {/* DA GESTIRE GLI STATI */}
                                        </Typography>
                                        <TextField
                                            // fullWidth
                                            label="KM"
                                            name="input_total_km"
                                            type="number"
                                            value={inputs.input_total_km}
                                            onChange={handleInputChange}
                                            size="small"
                                            sx={{ mb: 2 }}
                                        />
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                                            <TextField
                                                label="Ore"
                                                name="input_total_ore"
                                                type="number"
                                                value={inputs.input_total_minuti / 60} // SENZA toFixed
                                                onChange={(e) => {
                                                    const ore = parseFloat(e.target.value);
                                                    setInputs((prev) => ({
                                                        ...prev,
                                                        input_total_minuti: !isNaN(ore) ? ore * 60 : 0
                                                    }));
                                                }}
                                                onBlur={() => {
                                                    // Se vuoi arrotondare e poi ridipingere il valore in ore formattate
                                                    setInputs(prev => {
                                                        const ore = prev.input_total_minuti / 60;
                                                        return {
                                                            ...prev,
                                                            input_total_minuti: Math.round(ore * 60), // in minuti, 
                                                        };
                                                    });
                                                }}
                                            />

                                            <span style={{ marginLeft: '8px' }}>
                                                ({inputs.input_total_minuti} minuti)
                                            </span>
                                        </div>

                                    </Grid>
                                </Grid>

                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Supplementi e Sconti
                                    </Typography>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="input_notturno" checked={inputs.input_notturno}
                                                onChange={handleInputChange}
                                            />
                                        }
                                        label="Supplemento Notturno"
                                    />

                                    <Grid container spacing={2} sx={{ mt: 1 }}>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={inputs.input_tipo_sconto === 1}
                                                        onChange={(e) => {
                                                            setInputs(prev => ({
                                                                ...prev,
                                                                input_tipo_sconto: e.target.checked ? 1 : 2
                                                            }));
                                                        }}
                                                    />
                                                }
                                                label="Sconto in percentuale"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                // fullWidth
                                                label={inputs.input_tipo_sconto === 1 ? "Percentuale Sconto" : "Valore Sconto"}
                                                name="input_valore_sconto"
                                                type="number"
                                                value={inputs.input_valore_sconto}
                                                onChange={handleInputChange}
                                                size="small"
                                                InputProps={{
                                                    endAdornment: inputs.input_tipo_sconto === 1 ? "%" : "€"
                                                }}
                                                helperText={inputs.input_tipo_sconto === 1
                                                    ? "Inserire la percentuale di sconto"
                                                    : "Inserire il valore dello sconto"
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>



                            </Box>
                        </Paper>

                        {/* Sezione Tariffe A */}
                        <Paper elevation={1} sx={{ p: 3, bgcolor: '#f5f5ff', mt: 5 }}>
                            <Typography variant="h6" color="primary" sx={{ mb: 3, pb: 1, borderBottom: 1, borderColor: 'primary.light' }}>
                                <SettingsIcon ></SettingsIcon>
                                Modifica Tariffa A
                            </Typography>

                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Box>
                                        <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                                            Descrizione Tariffa A | Tipo {tariffeAEditable.tfa_tipo}
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={4}
                                            placeholder="Inserisci descrizione"
                                            value={tariffeAEditable.tfa_des}
                                            onChange={(e) => setTariffeAEditable(prev => ({ ...prev, tfa_des: e.target.value }))}
                                        />
                                        <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
                                            Campo: tfa_des
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                                        Budget KM
                                    </Typography>
                                    <TextField
                                        // fullWidth
                                        type="number"
                                        placeholder="0"
                                        value={tariffeAEditable.tfa_bdgkm}
                                        onChange={(e) => setTariffeAEditable(prev => ({ ...prev, tfa_bdgkm: parseFloat(e.target.value) || 0 }))}
                                    />
                                    <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
                                        Campo: tfa_bdgkm
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                                        Budget HH
                                    </Typography>
                                    <TextField
                                        // fullWidth
                                        type="number"
                                        placeholder="0"
                                        value={tariffeAEditable.tfa_bdghh}
                                        onChange={(e) => setTariffeAEditable(prev => ({ ...prev, tfa_bdghh: parseFloat(e.target.value) || 0 }))}
                                    />
                                    <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
                                        Campo: tfa_bdghh
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                                        Franchigia Minuti Eccedenza
                                    </Typography>
                                    <TextField
                                        // fullWidth
                                        type="number"
                                        placeholder="0"
                                        value={tariffeAEditable.tfa_frgmmec}
                                        onChange={(e) => setTariffeAEditable(prev => ({ ...prev, tfa_frgmmec: parseFloat(e.target.value) || 0 }))}
                                    />
                                    <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
                                        Campo: tfa_frgmmec
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                                        Franchigia Minuti Notturno
                                    </Typography>
                                    <TextField
                                        // fullWidth
                                        type="number"
                                        placeholder="0"
                                        value={tariffeAEditable.tfa_frgmmnt}
                                        onChange={(e) => setTariffeAEditable(prev => ({ ...prev, tfa_frgmmnt: parseFloat(e.target.value) || 0 }))}
                                    />
                                    <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
                                        Campo: tfa_frgmmnt
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>

                        {/* Sezione Tariffe B */}
                        <Paper elevation={1} sx={{ p: 3, bgcolor: '#fff5f5', mt: 5 }}>
                            <Typography variant="h6" color="primary" sx={{ mb: 3, pb: 1, borderBottom: 1, borderColor: 'primary.light' }}>
                                <TuneIcon></TuneIcon>
                                Modifica Tariffa B
                            </Typography>

                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Box>
                                        <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                                            Descrizione Tariffa B
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={4}
                                            placeholder="Inserisci descrizione"
                                            value={tariffeBEditable.tfb_des}
                                            onChange={(e) => setTariffeBEditable(prev => ({ ...prev, tfb_des: e.target.value }))}
                                        />
                                        <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
                                            Campo: tfb_des
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                                        Costo KM
                                    </Typography>
                                    <TextField
                                        // fullWidth
                                        type="number"
                                        placeholder="0"
                                        value={tariffeBEditable.tfb_ckm}
                                        onChange={(e) => setTariffeBEditable(prev => ({ ...prev, tfb_ckm: parseFloat(e.target.value) || 0 }))}
                                    />
                                    <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
                                        Campo: tfb_ckm
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                                        Costo HH
                                    </Typography>
                                    <TextField
                                        // fullWidth
                                        type="number"
                                        placeholder="0"
                                        value={tariffeBEditable.tfb_chh}
                                        onChange={(e) => setTariffeBEditable(prev => ({ ...prev, tfb_chh: parseFloat(e.target.value) || 0 }))}
                                    />
                                    <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
                                        Campo: tfb_chh
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                                        Costo Extra KM
                                    </Typography>
                                    <TextField
                                        // fullWidth
                                        type="number"
                                        placeholder="0"
                                        value={tariffeBEditable.tfb_ekm}
                                        onChange={(e) => setTariffeBEditable(prev => ({ ...prev, tfb_ekm: parseFloat(e.target.value) || 0 }))}
                                    />
                                    <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
                                        Campo: tfb_ekm
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                                        Costo Extra HH
                                    </Typography>
                                    <TextField
                                        // fullWidth
                                        type="number"
                                        placeholder="0"
                                        value={tariffeBEditable.tfb_ehh}
                                        onChange={(e) => setTariffeBEditable(prev => ({ ...prev, tfb_ehh: parseFloat(e.target.value) || 0 }))}
                                    />
                                    <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
                                        Campo: tfb_ehh
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                                        Vuoto KM %
                                    </Typography>
                                    <TextField
                                        // fullWidth
                                        type="number"
                                        placeholder="0"
                                        value={tariffeBEditable.tfb_vkmperc}
                                        onChange={(e) => setTariffeBEditable(prev => ({ ...prev, tfb_vkmperc: parseFloat(e.target.value) || 0 }))}
                                    />
                                    <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
                                        Campo: tfb_vkmperc
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                                        Vuoto HH %
                                    </Typography>
                                    <TextField
                                        // fullWidth
                                        type="number"
                                        placeholder="0"
                                        value={tariffeBEditable.tfb_vhhperc}
                                        onChange={(e) => setTariffeBEditable(prev => ({ ...prev, tfb_vhhperc: parseFloat(e.target.value) || 0 }))}
                                    />
                                    <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
                                        Campo: tfb_vhhperc
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                                        Supplemento Notturno
                                    </Typography>
                                    <TextField
                                        // fullWidth
                                        type="number"
                                        placeholder="0"
                                        value={tariffeBEditable.tfb_snotturno}
                                        onChange={(e) => setTariffeBEditable(prev => ({ ...prev, tfb_snotturno: parseFloat(e.target.value) || 0 }))}
                                    />
                                    <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
                                        Campo: tfb_snotturno
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* Sezione Risultati */}
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper elevation={1} sx={{ p: 3 }}>
                                    <Typography variant="h6" color="primary" sx={{
                                        mb: 3,
                                        pb: 1,
                                        borderBottom: 1,
                                        borderColor: 'primary.light'
                                    }}>
                                        Riepilogo Calcoli
                                    </Typography>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <ResultCard
                                                title="Calcoli Budget"
                                                items={[
                                                    {
                                                        label: "Importo Budget KM",
                                                        value: results.results_importo_budget_km,
                                                        type: 'euro',
                                                        formula: "Se input_km > tfa_bdgkm:\n(input_km - tfa_bdgkm) × tfb_ekm + tfa_bdgkm × tfb_ckm\nAltrimenti:\ntfa_bdgkm × tfb_ckm"
                                                    },
                                                    {
                                                        label: "Importo Budget HH",
                                                        value: results.results_importo_budget_hh,
                                                        type: 'euro',
                                                        formula: "Se input_minuti > (tfa_bdghh × 60):\n(input_minuti - tfa_bdghh × 60) × tfb_ehh\nAltrimenti: 0"
                                                    },
                                                    {
                                                        label: "KM Totali",
                                                        value: results.results_km_totali,
                                                        type: 'km',
                                                        formula: "input_total_km || (input_km_servizio + input_km_vuoto)"
                                                    },
                                                    {
                                                        label: "Minuti Totali",
                                                        value: results.results_minuti_totali,
                                                        type: 'minuti',
                                                        formula: "input_total_minuti || (input_minuti_servizio + input_minuti_vuoto)"
                                                    }
                                                ]}
                                            />

                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <ResultCard
                                                title="Calcoli Supplementari"
                                                items={[
                                                    {
                                                        label: "KM Eccedenza",
                                                        value: parseFloat(results.results_km_eccedenza || 0),
                                                        type: 'km',
                                                        formula: "max(0, input_km - tfa_bdgkm)"
                                                    },
                                                    {
                                                        label: "Minuti Eccedenza",
                                                        value: parseFloat(results.results_minuti_eccedenza || 0),
                                                        type: 'minuti',
                                                        formula: "max(0, input_minuti - (tfa_bdghh × 60))"
                                                    },
                                                    {
                                                        label: "Suppl. KM",
                                                        value: parseFloat(results.results_importo_totale_suppl_km || 0),
                                                        type: 'euro',
                                                        formula: "Se input_km > tfa_bdgkm:\n(input_km - tfa_bdgkm) × tfb_ekm\nAltrimenti: 0"
                                                    },
                                                    {
                                                        label: "Suppl. HH",
                                                        value: parseFloat(results.results_importo_totale_suppl_hh || 0),
                                                        type: 'euro',
                                                        formula: "results_minuti_eccedenza × tfb_ehh"
                                                    }
                                                ]}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <ResultCard
                                                title="Riepilogo Totali"
                                                items={[
                                                    {
                                                        label: "Imponibile di Servizio",
                                                        value: parseFloat(results.results_totale_imponibile_di_servizio || 0),
                                                        type: 'euro',
                                                        formula: "results_importo_budget_km + results_importo_budget_hh + results_importo_totale_suppl_km + results_importo_totale_suppl_hh"
                                                    },
                                                    {
                                                        label: "Supplemento Notturno",
                                                        value: parseFloat(results.results_supplemento_notturno || 0),
                                                        type: 'euro',
                                                        formula: "Se input_notturno:\nresults_totale_imponibile_di_servizio × (tfb_snotturno / 100)\nAltrimenti: 0"
                                                    },
                                                    {
                                                        label: "Imponibile Finale",
                                                        value: parseFloat(results.results_totale_imponibile_finale || 0),
                                                        type: 'euro',
                                                        formula: "results_totale_imponibile_di_servizio + results_supplemento_notturno"
                                                    },
                                                    {
                                                        label: "Sconto",
                                                        value: parseFloat(results.sconto || 0),
                                                        // Se input_tipo_sconto = 0 → type: "euro"
                                                        //   input_tipo_sconto = 1 → type: "percentuale"
                                                        type: inputs.input_tipo_sconto === 1 ? "percentuale" : "euro",
                                                        formula: `Se input_tipo_sconto === 1:
                                                            results_totale_imponibile_finale × (input_valore_sconto / 100)
                                                            Altrimenti: input_valore_sconto`
                                                    },
                                                    {
                                                        label: "Imponibile Scontato",
                                                        value: parseFloat(results.totale_imponibile || 0),
                                                        type: 'euro',
                                                        formula: "results_totale_imponibile_finale - sconto"
                                                    },
                                                    {
                                                        label: "IVA (10%)",
                                                        value: parseFloat(results.importo_iva || 0),
                                                        type: 'euro',
                                                        formula: "totale_imponibile × 0.10"
                                                    }
                                                ]}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            {/* Sezione Tariffe */}
                            <Grid item xs={12}>
                                <Paper elevation={1} sx={{ p: 3 }}>
                                    <Typography variant="h6" color="primary" sx={{
                                        mb: 3,
                                        pb: 1,
                                        borderBottom: 1,
                                        borderColor: 'primary.light'
                                    }}>
                                        Parametri Tariffe - Base
                                    </Typography>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>

                                            <ResultCard
                                                title={
                                                    "Tariffa A | " +
                                                    "Tipo: " +
                                                    (dataTariffeARecord?.tfa_tipo === 1
                                                        ? "TKm"
                                                        : dataTariffeARecord?.tfa_tipo === 0
                                                            ? "PAX"
                                                            : "ND") +
                                                    " | id:" +
                                                    (dataTariffeARecord?.id || 0)
                                                }
                                                subtitle={dataTariffeARecord?.tfa_des || ""}
                                                items={[
                                                    { label: "Budget KM", value: parseFloat(dataTariffeARecord?.tfa_bdgkm || 0), type: 'km' },
                                                    { label: "Budget HH", value: parseFloat(dataTariffeARecord?.tfa_bdghh || 0), type: 'ore' },
                                                    { label: "Franc Minuti", value: parseFloat(dataTariffeARecord?.tfa_frgmmec || 0), type: 'minuti' },
                                                    { label: "Franc Minuti Notturna", value: parseFloat(dataTariffeARecord?.tfa_frgmmnt || 0), type: 'minuti' }
                                                ]}
                                            />

                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            {tariffeBData?.[0] ? (
                                                <ResultCard
                                                    title={"Tariffa B " + "id:" + (tariffeBData[0]?.id || 0)}
                                                    subtitle={tariffeBData[0]?.tfb_des || ""}
                                                    items={[
                                                        { label: "Costo KM", value: parseFloat(tariffeBData[0]?.tfb_ckm || 0), type: 'euro' },
                                                        { label: "Costo HH", value: parseFloat(tariffeBData[0]?.tfb_chh || 0), type: 'euro' },
                                                        { label: "Costo Extra KM", value: parseFloat(tariffeBData[0]?.tfb_ekm || 0), type: 'euro' },
                                                        { label: "Costo Extra HH", value: parseFloat(tariffeBData[0]?.tfb_ehh || 0), type: 'euro' },
                                                        { label: "Vuoto KM %", value: parseFloat(tariffeBData[0]?.tfb_vkmperc || 0), type: 'percentuale' },
                                                        { label: "Vuoto HH %", value: parseFloat(tariffeBData[0]?.tfb_vhhperc || 0), type: 'percentuale' },
                                                        { label: "Supplemento Notturno", value: parseFloat(tariffeBData[0]?.tfb_snotturno || 0), type: 'euro' }
                                                    ]}
                                                />


                                            ) : (
                                                <Paper sx={{ p: 3, bgcolor: 'grey.50' }}>
                                                    <Typography color="text.secondary">
                                                        Nessuna tariffa B selezionata
                                                    </Typography>
                                                </Paper>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default TratteSimulatorDialog;