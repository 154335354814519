import * as React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title } from 'react-admin';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';

function InCostruzione() {

    return (
        <Card sx={{ mt: 10, textAlign: 'center', backgroundColor: '#f3f6f9' }}>
            <Title title="404 Pagina non trovata" />
            <CardContent>
                <ErrorOutlineIcon sx={{ fontSize: 60, color: blue[500] }} />
                <Typography variant="h4" sx={{ margin: '20px 0', color: '#01b5d0' }}>
                    Funzionalità in costruzione.
                </Typography>
                <Typography variant="subtitle1">
                    La Funzionalità per l'approvazione dei Preventivi sarà attiva nel prossimo Deploy.
                </Typography>
            </CardContent>
        </Card>
    );
}

export default InCostruzione;
