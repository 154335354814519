// src\App.js

import * as React from "react";
import {
  Admin,
  Resource,
  defaultTheme,
  localStorageStore,
  CustomRoutes
} from 'react-admin';

import { Dashboard } from './components/dashboard/Dashboard';

//import { Layout } from 'react-admin';
import { MyLayout } from "./components/shared/Layout/Layout";

// Setup rotte
// import { routesCustom } from './routes/routes';
import { Route } from 'react-router-dom';

///////////////////////////////
import { restClient, authClient } from 'ra-data-feathers';
import feathersClient from './feathersClient';

// Importo il tema
import { myTheme } from "./components/shared/theme";

// Importo tutti i componenti principali
import scadenze from "./components/scadenze";
import pacchetti from './components/pacchetti';
import servpax from './components/servpax';
import tariffea from "./components/tariffea";
import tariffa_servpax from './components/tariffa_servpax';
import users from "./components/users/index";
import gruppi from "./components/gruppi";
import { GruppiList, GruppiEdit, GruppiCreate, GruppiShow } from "./components/gruppi/gruppi";
import booking0 from "./components/booking0";
import { Booking0List } from "./components/booking0/Booking0List";

// Nuovi include
import clienti from "./components/clienti";
import autisti from "./components/autisti";
import automezzi from "./components/automezzi";
import anagen from "./components/anagen";
import { AnagenFilteredList } from "./components/anagen/anagenFilteredList";
import serviziotest from "./components/serviziotest";
import tabellealfa from "./components/tabellealfa";
import preventivi from "./components/preventivi";
import CustomLoading from "./components/shared/CustomLoading/CustomLoading";

// Processo di prenotazione
import PreventiviPrenotazioni from "./components/preventiviPrenotazioni/PreventiviPrenotazioni";
import SimulatoreCliente from './components/preventiviPrenotazioni/SimulatoreCliente/SimulatoreCliente';

/* Login page custom */
import MyLoginPage from './components/loginpage/MyLoginPage';

/* Not Found page custom */
import NotFoundPage from './components/NotFoundPage/NotFoundPage';

// Decoder per il JWT
import decodeJwt from 'jwt-decode';

import {
  useJsApiLoader,
} from '@react-google-maps/api'
import StatisticheBoard from "./components/statistiche/StatisticheBoard";
import SettingsPage from "./components/SettingsPage/SettingsPage";
import CreateAnagraficaPage from "./components/CreateAnagraficaPage/CreateAnagraficaPage";
import MonitorPage from "./components/monitor/MonitorChannels";
import NotesPage from "./components/note/NotesPage";
import DatiPersonali from "./components/SettingsPage/DatiPersonali";
import ResetPasswordPage from "./components/resetPasswordPage/ResetPasswordPage";
import RegisterPage from "./components/registerPage/RegisterPage";
import SimulatoreOperatore from "./components/preventiviPrenotazioni/SimulatoreOperatori/SimulatoreOperatore";
import MiddlewareTabs from "./components/servizi-collegati/MiddlewareTabs";
import BookingDashboard from "./components/booking0/utils/BookingDashboard";
import TariffsDashboard from "./components/tariffea/dashboard-tariffea/TariffsDashboard";
import InCostruzione from "./components/InCostruzione/InCostruzione";
import { Booking0ListWrapper } from "./components/booking0/Booking0ListWrapper";
import AllBookingMessages from "./components/booking0/cliente/AllBookingMessages";

const restClientOptions = {
  id: 'id', // In this example, the database uses '_id' rather than 'id'
  usePatch: true // Use PATCH instead of PUT for updates
};

const authClientOptions = {
  usernameField: 'username', // Our example database might use 'username' rather than 'email'
  permissionsField: 'roles', // Use the 'userroles' field on the JWT as the users role
  redirectTo: '/#/login', // Our example login form might be at '/signin', redirect here if AUTH_CHECK fails
};

const options = {
  id: '_id',
  storageKey: 'feathers-jwt', // The key in localStorage used to store the authentication token
  authenticate: { // Options included in calls to Feathers client.authenticate
    strategy: 'local', // The authentication strategy Feathers should use
  },
  permissionsKey: 'permissions', // The key in localStorage used to store permissions from decoded JWT
  permissionsField: 'roles', // The key in the decoded JWT containing the user's role
  passwordField: 'password', // The key used to provide the password to Feathers client.authenticate
  usernameField: 'email', // The key used to provide the username to Feathers client.authenticate
  redirectTo: '/login', // Redirect to this path if an AUTH_CHECK fails. Uses the react-admin default of '/login' if omitted.
  logoutOnForbidden: false, // Logout when response status code is 403
  /* Allows to use custom query operators from various feathers-database-adapters in GET_MANY calls.
 * Will be merged with the default query operators ['$gt', '$gte', '$lt', '$lte', '$ne', '$sort', '$or', '$nin', '$in']
 */
  customQueryOperators: ['$gt', '$gte', '$lt', '$lte', '$ne', '$sort', '$or', '$nin', '$in']

}

///////////////////////////////


export const theme = {
  ...defaultTheme,
  overrides: {
    /*     RaSidebar: {
          drawerPaper: {
            width: 300,
          },
          fixed: {
            zIndex: 1200,
          },
        }, */
    RaSidebar: {
      fixed: {
        position: 'inherit'
      }
    },
  },
};

// Init store
const storeGenerale = localStorageStore();

const feathersDataProvider = restClient(feathersClient, restClientOptions);

const feathersAuthProvider = authClient(feathersClient, options);
const myAuthProvider = async (verb, resource, params) => {
  const result = await feathersAuthProvider(verb, resource, params);

  if (typeof (resource) != "undefined") {
    try {
      if (typeof (resource['username']) != "undefined") {

        // Reset variabili
        storeGenerale.setItem('profile.name', "");
        storeGenerale.setItem('profile.roles', "");
        storeGenerale.setItem('profile.gruppiId', "");
        storeGenerale.setItem('profile.clientiId', "");
        storeGenerale.setItem('accessibleResources', "");

        // console.log(":::: DEBUG START AUTH ::::");
        // console.log(resource);
        // console.log(resource['username']);
        // TODO: Qui devo decidere se inviare il contenuto verso un endpoints specifico del backend
        var test = localStorage.getItem("feathers-jwt");
        // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> " + test);
        const decodedToken = decodeJwt(test);

        // console.log("---- token decodificato ----");
        // console.log(decodedToken);
        // console.log(resource);
        // console.log(localStorage.getItem('permissions'));
        storeGenerale.setItem('profile.name', decodedToken.username);
        storeGenerale.setItem('profile.email', decodedToken.email);
        storeGenerale.setItem('profile.roles', decodedToken.roles);
        storeGenerale.setItem('profile.gruppiId', decodedToken.gruppiId);
        storeGenerale.setItem('profile.clientiId', decodedToken.clientiId);
        storeGenerale.setItem('profile.idUser', decodedToken.id);
        storeGenerale.setItem('profile.accessibleResources', decodedToken.accessibleResources);
        // console.log(":::: DEBUG STOP AUTH ::::");
      }
    } catch (error) {
      console.log(error);
    }
  }


  return result;
}

/* --- */

const App = () => {

  /*   const { isLoaded } = useJsApiLoader({
      googleMapsApiKey: "AIzaSyDlK_cmPjto-kcZga40cJvqwR7Km4WvmbY",
      libraries: ['places'],
    })
   */

  return (

    /*   <React.Fragment> 
           <div style={{
        position: 'fixed', right: 0, bottom: 0, left: 0, zIndex: 100,
        padding: 6,
        backgroundColor: '#efefef',
        textAlign: 'center',
      }}><strong>HELLO</strong></div>
    </React.Fragment> */
    <Admin
      title='Tuvoni Backoffice - V1.3.14'
      catchAll={NotFoundPage}
      store={storeGenerale}
      layout={MyLayout} // Include il menu
      dashboard={Dashboard}
      loginPage={MyLoginPage}
      theme={myTheme}
      dataProvider={feathersDataProvider}
      authProvider={myAuthProvider}
      loading={CustomLoading}
    >

      {permissions => [
        // Risorse e route per gli admin
        permissions === 'admin' && (
          <>
            <Resource name="pacchetti" {...pacchetti}
              recordRepresentation={(record) => `${record.nome}`}
            ></Resource>
            <Resource name="servpax" {...servpax}
              recordRepresentation={(record) => `${record.descrizione}`}
            />
            <Resource name="tariffa-servpax" {...tariffa_servpax} />
            <Resource name="users"  {...users}
              recordRepresentation={(record) => `${record.email}`}
            />
            <Resource name="clienti" {...clienti} />
            <Resource name="serviziotest" {...serviziotest} />
            <Resource name="autisti" {...autisti} />
            <Resource name="automezzi" {...automezzi} />
            <Resource name="anagen"
              {...anagen}
              recordRepresentation={(record) => `${record.gnfind}`} >
              <Route path=":gncdtan/list" element={<AnagenFilteredList />} />
            </Resource>
            <Resource name="servizi"
              recordRepresentation={(record) => `${record.ss_des}`} />
            <Resource
              name="booking-0"
              {...booking0}
              recordRepresentation={(record) => `${record.bk0_idnumber}`}
            >
              <Route path=":bk0_idstatus/list" element={<Booking0List />} />
            </Resource>
            <CustomRoutes >
              <Route exact path="/bookingdash" element={<BookingDashboard />} />
            </CustomRoutes>
            <CustomRoutes >
              <Route exact path="/tariffedash" element={<TariffsDashboard />} />
            </CustomRoutes>
            <Resource name="booking-2" />
            <Resource name="booking-0-automezzi" />
            <Resource name="note" />
            <Resource name="documenti" />
            <Resource name="tipodocumento" />
            <Resource name="anagen-contatti" />
            <Resource name="anagen-indirizzi" />
            <Resource name="tabellealfa" {...tabellealfa}
              recordRepresentation={(record) => `${record.tbdes}`}
            />
            <Resource name="view-tabellaalfa-grp" />
            <Resource name="view-tabellaalfa-tan" />
            <Resource name="view-tabellaalfa-set" />
            <Resource name="view-tabellaalfa-tia" />
            <Resource name="view-tabellaalfa-trp"
              recordRepresentation={(record) => `${record.descrizione}`}
            />
            <Resource name="tariffe-a" {...tariffea} />
            <Resource name="tariffe-b" />
            <Resource name="scadenze" {...scadenze} />
            <Resource name="gruppi"
              list={GruppiList}
              create={GruppiCreate}
              edit={GruppiEdit}
              {...gruppi}
            />
            <Resource name="users-gruppi" />
            <Resource name="permessi" />
            <Resource name="servicegroup" />
            <Resource name="automezzi-costi-storico" />
            <Resource name="automezzi-costi" />
            <Resource name="view-fornitori-servpax" />
            <Resource name="tiporecord" />
            <Resource name="lista-servizi" />
            <Resource name="rifornimenti" />
            <Resource name="view-booking-2-impegno-non-servizio" />
            <Resource name="view-booking-2-impegno-notturno" />
            <Resource name="view-booking-2-impegno-servizio" />
            <Resource name="view-booking-2-impegno-vuoto" />

            <CustomRoutes /* noLayout */>
              <Route exact path="/prenotazioni" element={<SimulatoreOperatore />} />
            </CustomRoutes>

            <Resource name="preventivi" {...preventivi} />

            <Resource name="booking-0-status"
              recordRepresentation={(record) => `${record.descrizione}`}
            />

            <Resource name="view-booking-0-status-riepilogo"
              recordRepresentation={(record) => `${record.descrizione}`}
            />

            <CustomRoutes >
              <Route exact path="/statistiche" element={<StatisticheBoard />} />
            </CustomRoutes>

            <CustomRoutes >
              <Route exact path="/settingspage" element={<SettingsPage />} />
            </CustomRoutes>

            <CustomRoutes >
              <Route exact path="/anagrafiche/crea" element={<CreateAnagraficaPage />} />
            </CustomRoutes>

            <CustomRoutes >
              <Route exact path="/monitor" element={<MonitorPage />} />
            </CustomRoutes>

            <CustomRoutes >
              <Route exact path="/notelist" element={<NotesPage />} />
            </CustomRoutes>

            {<CustomRoutes >
              <Route exact path="/dati-personali" element={<DatiPersonali />} />
            </CustomRoutes>}

            <Resource name="softwarecollegati" list={MiddlewareTabs} options={{ label: 'Stato Wialon' }} />

            <CustomRoutes >
              <Route exact path="/approva-preventivo" element={<InCostruzione />} />
              <Route exact path="/approva-preventivo/:booking0Id" element={<InCostruzione />} />
            </CustomRoutes>
          </>
        ),

        // Risorse e route per utenti non admin
        permissions && permissions !== 'admin' && (
          <>
            <Resource name="pacchetti" {...pacchetti}
              recordRepresentation={(record) => `${record.nome}`}
            ></Resource>
            <Resource name="servpax" {...servpax}
              recordRepresentation={(record) => `${record.descrizione}`}
            />
            <Resource name="tariffa-servpax" {...tariffa_servpax} />
            <Resource name="users"  {...users}
              recordRepresentation={(record) => `${record.email}`}
            />
            <Resource name="clienti" {...clienti} />
            <Resource name="serviziotest" {...serviziotest} />
            <Resource name="autisti" {...autisti} />
            <Resource name="automezzi" {...automezzi} />
            <Resource name="anagen"
              {...anagen}
              recordRepresentation={(record) => `${record.gnfind}`} >
              <Route path=":gncdtan/list" element={<AnagenFilteredList />} />
            </Resource>
            <Resource name="servizi"
              recordRepresentation={(record) => `${record.ss_des}`} />
            <Resource
              name="booking-0"
              {...booking0}
              recordRepresentation={(record) => `${record.bk0_idnumber}`}
            >
              <Route path=":bk0_idstatus/list" element={<Booking0ListWrapper />} />
            </Resource>
            <CustomRoutes >
              <Route exact path="/bookingdash" element={<BookingDashboard />} />
            </CustomRoutes>
            <CustomRoutes >
              <Route exact path="/tariffedash" element={<TariffsDashboard />} />
            </CustomRoutes>
            <Resource name="booking-2" />
            <Resource name="booking-0-automezzi" />
            <Resource name="note" />
            <Resource name="documenti" />
            <Resource name="tipodocumento" />
            <Resource name="anagen-contatti" />
            <Resource name="anagen-indirizzi" />
            <Resource name="tabellealfa" {...tabellealfa}
              recordRepresentation={(record) => `${record.tbdes}`}
            />
            <Resource name="view-tabellaalfa-grp" />
            <Resource name="view-tabellaalfa-tan" />
            <Resource name="view-tabellaalfa-set" />
            <Resource name="view-tabellaalfa-tia" />
            <Resource name="view-tabellaalfa-trp"
              recordRepresentation={(record) => `${record.descrizione}`}
            />
            <Resource name="tariffe-a" {...tariffea} />
            <Resource name="scadenze" {...scadenze} />
            <Resource name="tariffe-b" />
            <Resource name="gruppi"
              list={GruppiList}
              create={GruppiCreate}
              edit={GruppiEdit}
              {...gruppi}
            />
            <Resource name="users-gruppi" />
            <Resource name="permessi" />
            <Resource name="servicegroup" />
            <Resource name="automezzi-costi-storico" />
            <Resource name="automezzi-costi" />
            <Resource name="view-fornitori-servpax" />
            <Resource name="tiporecord" />
            <Resource name="lista-servizi" />
            <Resource name="rifornimenti" />
            <CustomRoutes /* noLayout */>
              <Route exact path="/prenotazioni" element={<SimulatoreOperatore />} />
            </CustomRoutes>

            <Resource name="preventivi" {...preventivi} />

            <Resource name="booking-0-status"
              recordRepresentation={(record) => `${record.descrizione}`}
            />

            <Resource name="view-booking-0-status-riepilogo"
              recordRepresentation={(record) => `${record.descrizione}`}
            />

            <CustomRoutes >
              <Route exact path="/statistiche" element={<StatisticheBoard />} />
            </CustomRoutes>

            <CustomRoutes >
              <Route exact path="/anagrafiche/crea" element={<CreateAnagraficaPage />} />
            </CustomRoutes>


            <CustomRoutes >
              <Route exact path="/monitor" element={<MonitorPage />} />
            </CustomRoutes>

            <CustomRoutes >
              <Route exact path="/notelist" element={<NotesPage />} />
            </CustomRoutes>

            {<CustomRoutes >
              <Route exact path="/dati-personali" element={<DatiPersonali />} />
            </CustomRoutes>}

            <Resource name="softwarecollegati" list={MiddlewareTabs} options={{ label: 'Stato Wialon' }} />


            {<CustomRoutes /* noLayout */>
              {/* <Route exact path="/prenotazioni" element={<PreventiviPrenotazioni />} /> */}
              <Route exact path="/prenotazioni" element={<SimulatoreCliente />} />
            </CustomRoutes>}

            {<CustomRoutes >
              <Route exact path="/dati-personali" element={<DatiPersonali />} />
            </CustomRoutes>}

            <Resource name="preventivi" {...preventivi} />

            <Resource name="servpax" {...servpax}
              recordRepresentation={(record) => `${record.descrizione}`}
            />

            <CustomRoutes >
              <Route exact path="/notelist" element={<NotesPage />} />
            </CustomRoutes>

            {<CustomRoutes noLayout>
              <Route exact path="/resetpassword/" element={<ResetPasswordPage />} />
              <Route exact path="/resetpassword/:hash" element={<ResetPasswordPage />} />
            </CustomRoutes>}

            <CustomRoutes >
              <Route exact path="/approva-preventivo" element={<InCostruzione />} />
            </CustomRoutes>

            <CustomRoutes>
              <Route path="/messaggi" element={<AllBookingMessages />} />
            </CustomRoutes>


            {/*  {<CustomRoutes>
              <Route exact path="/calcola-preventivo" element={<SimulatoreCliente />} />
            </CustomRoutes>} */}
          </>
        ),

        // Route sempre accessibili, incluse quelle per il reset della password
        <>
          <CustomRoutes noLayout>
            <Route exact path="/resetpassword/" element={<ResetPasswordPage />} />
            <Route exact path="/resetpassword/:hash" element={<ResetPasswordPage />} />
          </CustomRoutes>
          <CustomRoutes noLayout>
            <Route exact path="/register/" element={<RegisterPage />} />
          </CustomRoutes>
        </>

      ]}
    </Admin>
  );
}

export default App;