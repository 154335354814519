import * as React from 'react';
import { DashboardMenuItem, useStore } from 'react-admin';
import { MultiLevelMenu } from "@react-admin/ra-navigation";
import DeterminaMenu from './DeterminaMenu';
import { Box, useTheme } from '@mui/material';
import MyUserMenu from './MyUserMenu';

export const MyMenu = (props) => {
  const [gruppiId] = useStore('profile.gruppiId');
  const theme = useTheme();
  const mainColor = '#01b5d0';
  const darkColor = '#0077be';

  return (
    <Box
      sx={{
        backgroundColor: mainColor,
        color: 'white',
        height: 'calc(100vh - 53px)', // Subtract AppBar height
        boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
        position: 'sticky',
        top: 64, // AppBar height
        overflowY: 'auto',
        width: '100%', // Responsive width
        maxWidth: '250px', // Maximum width
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          borderRadius: '3px',
        },
        '& .RaMenuItemLink-root': {
          color: 'white',
          padding: '12px 16px',
          borderRadius: '4px',
          margin: '2px 8px',
          display: 'flex',
          alignItems: 'center',
          wordWrap: 'break-word', // Permette di andare a capo
          whiteSpace: 'normal', // Consente il wrapping del testo
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.15)',
          },
        },
        '& .MuiListItemText-primary': {
          color: 'white',
          fontSize: '0.95rem',
          fontWeight: 500,
          wordWrap: 'break-word', // Permette il wrapping
          whiteSpace: 'normal', // Consente il wrapping del testo
        },
        '@media (max-width: 768px)': {
          maxWidth: '200px', // Riduci la larghezza su schermi più piccoli
        },
      }}
    >



      <MultiLevelMenu
        sx={{
          width: '250px',
          minHeight: '100%', // Ensure the menu takes full height
          pb: 2, // Add some padding at the bottom
        }}
      >
        <DashboardMenuItem />
        <DeterminaMenu iconColor="white" />
        {(gruppiId === 2) ?
          <MyUserMenu></MyUserMenu> : <></>
        }

      </MultiLevelMenu>
    </Box>
  );
};

export default MyMenu;