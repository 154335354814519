import React, { useState, useEffect, useCallback } from 'react';
import { useDataProvider, useStore } from 'react-admin';
import {
    Box,
    TextField,
    Button,
    List,
    ListItem,
    Typography,
    Paper
} from '@mui/material';
import { GetNameFromResourceById } from '../../shared/CustomFields/GetNameFromResourceById';

const POLL_INTERVAL = 5000; // 5 secondi

const MessagesBookingTab = ({ booking0Id, bk0_idnumber }) => {
    // 1. ID utente
    const [usersId] = useStore('profile.idUser');
    const [email] = useStore('profile.email');

    const [messages, setMessages] = useState([]);
    const [newText, setNewText] = useState('');
    const dataProvider = useDataProvider();

    // 2. fetch dei messaggi
    const fetchMessages = useCallback(async () => {
        if (!booking0Id) return;
        try {
            const { data } = await dataProvider.getList('messaggi-booking-0', {
                filter: { booking0Id },
                sort: { field: 'createdAt', order: 'DESC' },
                pagination: { page: 1, perPage: 100 }
            });
            setMessages(data);
        } catch (error) {
            console.error('Errore fetch messaggi:', error);
        }
    }, [booking0Id, dataProvider]);

    // 3. marcatura come letti
    const setMessagesAsRead = useCallback(async () => {
        if (!booking0Id || !usersId) return;
        try {
            await dataProvider.getList('proc-set-all-messaggi-booking-0-letto', {
                filter: {
                    user_id: usersId,
                    booking0Id: booking0Id
                },
                pagination: { page: 1, perPage: 1 }
            });
        } catch (error) {
            console.error('Errore marcatura messaggi come letti:', error);
        }
    }, [booking0Id, usersId, dataProvider]);

    // 4. Effetto iniziale
    useEffect(() => {
        (async () => {
            // Imposta i messaggi come letti prima
            await setMessagesAsRead();
            // Poi fai la fetch
            await fetchMessages();
        })();

        // Polling ogni 5 secondi
        const intervalId = setInterval(() => {
            fetchMessages();
        }, POLL_INTERVAL);

        return () => clearInterval(intervalId);
    }, [fetchMessages, setMessagesAsRead]);

    // 5. invio di un nuovo messaggio
    const handleSend = async () => {
        if (!newText.trim() || !usersId) return;

        try {
            // 1. Creo il messaggio
            await dataProvider.create('messaggi-booking-0', {
                data: {
                    booking0Id,
                    usersId,
                    testo: newText.trim()
                }
            });

            // 2. Ottengo tutti gli utenti unici che hanno scritto messaggi
            const uniqueUserIds = [...new Set(messages.map(m => m.usersId))];

            // 3. Ottengo le email degli utenti
            const { data: users } = await dataProvider.getMany('users', {
                ids: uniqueUserIds
            });

            // 4. Invio email a tutti gli utenti coinvolti
            const emailPromises = users.map(user =>
                dataProvider.create('generatore-mail', {
                    data: {
                        payload: {
                            to: user.email,
                            tipoServizioMailId: 8,
                            html: {
                                bookingId: booking0Id,
                                testo: newText.trim(),
                                bk0_idnumber: bk0_idnumber,
                                from: `${email} - ${new Date().toLocaleString('it-IT')}`,
                                linkMessaggi: `${window.location.origin}/#/booking-0/${booking0Id}/show/messaggi`
                            }
                        }
                    }
                })
            );

            await Promise.all(emailPromises);

            setNewText('');
            await fetchMessages();
        } catch (error) {
            console.error('Errore invio messaggio:', error);
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ display: 'flex', mt: 1, p: 2, borderTop: '1px solid #ddd' }}>
                <TextField
                    variant="outlined"
                    fullWidth
                    label="Scrivi un messaggio..."
                    value={newText}
                    onChange={(e) => setNewText(e.target.value)}
                />
                <Button
                    variant="contained"
                    sx={{ ml: 2 }}
                    onClick={handleSend}
                >
                    Invia Messaggio
                </Button>
            </Box>
            <List
                sx={{
                    flex: 1,
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2
                }}
            >
                {messages.map((msg) => {
                    const isMyMessage = msg.usersId === usersId;

                    return (
                        <ListItem
                            key={msg.id}
                            disableGutters
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: isMyMessage ? 'flex-end' : 'flex-start'
                            }}
                        >
                            <Paper
                                elevation={2}
                                sx={{
                                    bgcolor: isMyMessage ? 'primary.main' : 'grey.300',
                                    color: isMyMessage ? 'white' : 'black',
                                    p: 1.5,
                                    borderRadius: 2,
                                    mb: 1,
                                    maxWidth: '70%',
                                    opacity: !isMyMessage && !msg.flLetto ? 0.7 : 1
                                }}
                            >
                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                    {isMyMessage
                                        ? 'Tu'
                                        : `Utente #${msg.usersId} `}
                                    <GetNameFromResourceById
                                        id={msg.usersId}
                                        resource="users"
                                        fieldName="email"
                                    />
                                </Typography>
                                <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                                    {msg.testo}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        display: 'block',
                                        textAlign: 'right',
                                        opacity: 0.7,
                                        mt: 0.5
                                    }}
                                >
                                    {new Date(msg.createdAt).toLocaleString()}
                                </Typography>
                            </Paper>
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
};

export default MessagesBookingTab;
