import { Badge, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import MailIcon from '@mui/icons-material/Mail';

const AnimatedBadge = ({ count }) => {
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        setIsAnimating(true);
        const timer = setTimeout(() => setIsAnimating(false), 1000);
        return () => clearTimeout(timer);
    }, [count]);

    return (
        <Box sx={{ display: 'inline-flex' }}>
            <Badge
                badgeContent={count}
                color="error"
                sx={{
                    '& .MuiBadge-badge': {
                        animation: isAnimating ? 'shake 0.5s ease-in-out' : 'none',
                        '@keyframes shake': {
                            '0%': { transform: 'rotate(0deg)' },
                            '25%': { transform: 'rotate(-5deg)' },
                            '75%': { transform: 'rotate(5deg)' },
                            '100%': { transform: 'rotate(0deg)' }
                        }
                    },
                    '& .MuiSvgIcon-root': {
                        color: 'white'
                    }
                }}
            >
                <MailIcon />
            </Badge>
        </Box>
    );
};

export default AnimatedBadge;