import React from 'react';
import { Card, CardContent, Grid, Typography, Box, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import BackpackIcon from '@mui/icons-material/Backpack';

const ActionCard = ({ title, icon, onClick, backgroundColor }) => (
    <Card
        sx={{
            cursor: 'pointer',
            transition: 'transform 0.2s, box-shadow 0.2s',
            height: '100%',
            '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: 3
            }
        }}
        onClick={onClick}
    >
        <CardContent>
            <Box
                sx={{
                    backgroundColor,
                    color: 'white',
                    padding: 2,
                    borderRadius: 1,
                    minHeight: '120px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'
                }}
            >
                {icon}
                <Typography variant="h5" component="div" sx={{ mt: 2 }}>
                    {title}
                </Typography>
            </Box>
        </CardContent>
    </Card>
);

const LinkCard = ({ title, icon, onClick, subtitle }) => (
    <Card
        sx={{
            cursor: 'pointer',
            transition: 'transform 0.2s, box-shadow 0.2s',
            height: '100%',
            '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: 3
            }
        }}
        onClick={onClick}
    >
        <CardContent>
            <Box
                sx={{
                    backgroundColor: '#f5f5f5',
                    padding: 2,
                    borderRadius: 1,
                    minHeight: '120px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'
                }}
            >
                {icon}
                <Typography variant="h6" component="div" sx={{ mt: 2, color: '#333' }}>
                    {title}
                </Typography>
                {subtitle && (
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                        {subtitle}
                    </Typography>
                )}
            </Box>
        </CardContent>
    </Card>
);

const TariffsDashboard = () => {
    const navigate = useNavigate();

    const handleCreateTariffClick = () => {
        navigate('/tariffe-a/create');
    };

    const handleCreatePackageClick = () => {
        navigate('/pacchetti/create');
    };

    const links = [
        {
            title: 'Tariffe',
            path: '/tariffe-a',
            icon: <PriceCheckIcon sx={{ fontSize: 40, color: '#1976d2' }} />,
            subtitle: 'Gestione tariffe principali'
        },
        {
            title: 'Tariffe ServPax',
            path: '/tariffa-servpax',
            icon: <LocalOfferIcon sx={{ fontSize: 40, color: '#1976d2' }} />,
            subtitle: 'Gestione tariffe servizi passeggeri'
        },
        {
            title: 'ServPax',
            path: '/servpax',
            icon: <ListAltIcon sx={{ fontSize: 40, color: '#1976d2' }} />,
            subtitle: 'Gestione servizi passeggeri'
        },
        {
            title: 'Pacchetti',
            path: '/pacchetti',
            icon: <BackpackIcon sx={{ fontSize: 40, color: '#1976d2' }} />,
            subtitle: 'Gestione pacchetti'
        },
    ];

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" gutterBottom sx={{ marginBottom: 4 }}>
                Dashboard Tariffe
            </Typography>

            {/* Sezione Azioni */}
            <Box sx={{ mb: 6 }}>
                <Typography variant="h5" gutterBottom color="primary">
                    Azioni
                </Typography>
                <Divider sx={{ mb: 3 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <ActionCard
                            title="Crea Tariffa"
                            icon={<AddCircleOutlineIcon sx={{ fontSize: 40 }} />}
                            onClick={handleCreateTariffClick}
                            backgroundColor="#2196f3"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <ActionCard
                            title="Crea Pacchetto"
                            icon={<LocalActivityIcon sx={{ fontSize: 40 }} />}
                            onClick={handleCreatePackageClick}
                            backgroundColor="#ff9800"
                        />
                    </Grid>
                </Grid>
            </Box>

            {/* Sezione Link Rapidi */}
            <Box sx={{ mb: 4 }}>
                <Typography variant="h5" gutterBottom color="primary">
                    Link Rapidi
                </Typography>
                <Divider sx={{ mb: 3 }} />
                <Grid container spacing={3}>
                    {links.map((link, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <LinkCard
                                title={link.title}
                                icon={link.icon}
                                onClick={() => navigate(link.path)}
                                subtitle={link.subtitle}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default TariffsDashboard;