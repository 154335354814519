import * as React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    NumberInput,
    SaveButton,
    useRedirect,
    useNotify,
    Button,
    Toolbar,
    BooleanInput,
    ReferenceInput,
    required,
} from 'react-admin';
import {
    Grid,
    Card,
    CardContent,
    ListItem,
    ListItemIcon,
    List as ListMaterial
} from '@mui/material';

import EuroIcon from '@mui/icons-material/Euro';
import NoteIcon from '@mui/icons-material/Note';
import { createButtonCss } from '../shared/Styles/buttonStyles';
import CustomAutocompleInput from "../shared/FormFields/CustomAutocompleInput";

// Componente personalizzato per numeri con virgola
const EuroNumberInput = ({ source, label, ...rest }) => {
    const handleBlur = (event) => {
        let value = event.target.value;
        value = value.replace(',', '.');
        if (!isNaN(value) && value.trim() !== '') {
            value = parseFloat(value);
            value = Math.floor(value * 1000) / 1000;
            value = value.toString().replace('.', ',');
            event.target.value = value;
        }
    };

    return (
        <NumberInput
            min={0.000}
            label={label}
            source={source}
            onBlur={handleBlur}
            {...rest}
        />
    );
};

const TariffeaCreateToolbar = () => {
    const redirect = useRedirect();
    const notify = useNotify();
    return (
        <Toolbar>
            <SaveButton
                sx={createButtonCss}
                label="Crea Tariffea"
                mutationOptions={{
                    onSuccess: (data) => {
                        notify('Tariffea creata con successo', {
                            type: 'info',
                        });
                        redirect('show', 'tariffe-a', data.id);
                    }
                }}
                type="button"
                variant="text"
            />
        </Toolbar>
    );
};

export const TariffeaCreate = (props) => {
    const [selectedClienteData, setSelectedClienteData] = React.useState(null);

    const handleClienteSelect = (record) => {
        if (record) {
            setSelectedClienteData({
                tfa_clienteId: record.id,
                tfa_cliente: record.gncodipc
            });
        }
    };

    const defaultValues = {
        tfa_kmtratta0: 0,
        tfa_kmtratta1: 0,
        tfa_bdgkm: 0,
        tfa_bdghh: 0,
        tfa_maxkmvuoto: 0,
        tfa_maxmmvuoto: 0,
        tfa_frgmmec: 0,
        tfa_frgmmnt: 0,
        tfa_isresday: false,
        tfa_cliente: 0,
        tfa_clienteId: 0,
        ...Array.from({ length: 10 }, (_, i) => ({ [`tfa_maxsc${i + 1}`]: 0 })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
        ...selectedClienteData
    };

    return (
        <Create
            {...props}
            title={<><Button>&nbsp;Nuova Tariffea</Button></>}
            redirect="show"
        >
            <SimpleForm
                toolbar={<TariffeaCreateToolbar />}
                defaultValues={defaultValues}
            >
                <Grid container spacing={2}>
                    {/* Campi standard */}
                    {[
                        {
                            label: "Descrizione",
                            source: "tfa_des",
                            Icon: NoteIcon,
                            component: (props) => (
                                <TextInput
                                    {...props}
                                    label="Descrizione"
                                    validate={required()}
                                    fullWidth
                                />
                            )
                        },
                        {
                            label: "Tipo",
                            source: "tfa_tipo",
                            Icon: NoteIcon,
                            component: (props) => (
                                <SelectInput
                                    {...props}
                                    validate={required()}
                                    choices={[
                                        { id: '1', name: 'Tempo km' },
                                        { id: '2', name: 'A passeggero' },
                                    ]}
                                    fullWidth
                                />
                            )
                        },
                        {
                            label: "Cliente",
                            source: "tfa_clienteId",
                            Icon: NoteIcon,
                            component: (props) => (
                                <CustomAutocompleInput
                                    source="tfa_clienteId"
                                    validate={required()}
                                    reference="anagen"
                                    optionProperty="gnfind"
                                    label="Seleziona Cliente"
                                    onSelect={handleClienteSelect}
                                />
                            )
                        },
                        {
                            label: "Servizio",
                            source: "tfa_servizio",
                            Icon: NoteIcon,
                            component: (props) => (
                                <CustomAutocompleInput
                                    source="tfa_servizio"
                                    reference="servizi"
                                    optionProperty="ss_des"
                                    label="Seleziona Tipo Servizio"
                                    validate={required()}
                                />
                            )
                        },
                        {
                            label: "Resoconto giornaliero",
                            source: "tfa_isresday",
                            Icon: NoteIcon,
                            component: (props) => (
                                <BooleanInput
                                    {...props}
                                    label="Resoconto giornaliero"
                                    fullWidth
                                />
                            )
                        },
                    ].map(({ label, source, Icon, component }, index) => (
                        <Grid item xs={12} sm={6} key={`field-create-${index}`}>
                            <ListItem>
                                <ListItemIcon>
                                    <Icon />
                                </ListItemIcon>
                                {component ?
                                    component({ label, source, fullWidth: true })
                                    : <TextInput label={label} source={source} fullWidth />
                                }
                            </ListItem>
                        </Grid>
                    ))}

                    {/* Km Start e Stop */}
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    {[
                                        { label: "f Km Start", source: "tfa_kmtratta0", Icon: NoteIcon },
                                        { label: "f Km Stop", source: "tfa_kmtratta1", Icon: NoteIcon },
                                    ].map(({ label, source, Icon }, index) => (
                                        <Grid item xs={12} sm={6} key={`km-create-${index}`}>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <Icon />
                                                </ListItemIcon>
                                                <NumberInput min={0} label={label} source={source} fullWidth />
                                            </ListItem>
                                        </Grid>
                                    ))}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Budget e limiti */}
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    {[
                                        { label: "Budget km Totali", source: "tfa_bdgkm", Icon: NoteIcon },
                                        { label: "Budget ore Totali", source: "tfa_bdghh", Icon: NoteIcon },
                                        { label: "Vuoto MAX Km Fatturabili", source: "tfa_maxkmvuoto", Icon: NoteIcon },
                                        { label: "Vuoto MAX minuti Fatturabili", source: "tfa_maxmmvuoto", Icon: NoteIcon },
                                    ].map(({ label, source, Icon }, index) => (
                                        <Grid item xs={12} sm={6} key={`budget-create-${index}`}>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <Icon />
                                                </ListItemIcon>
                                                <NumberInput min={0} label={label} source={source} fullWidth />
                                            </ListItem>
                                        </Grid>
                                    ))}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Sconti */}
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            {Array.from({ length: 10 }, (_, i) => ({
                                                label: `Sconto ${i + 1} (€)`,
                                                source: `tfa_maxsc${i + 1}`,
                                                Icon: EuroIcon,
                                            })).map(({ label, source, Icon }, index) => (
                                                <Grid item xs={6} sm={3} key={`discount-create-${index}`}>
                                                    <ListItem>
                                                        <ListItemIcon><Icon /></ListItemIcon>
                                                        <EuroNumberInput
                                                            label={label}
                                                            source={source}
                                                        />
                                                    </ListItem>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};