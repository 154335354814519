import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    Box,
    Typography,
    Paper,
    Snackbar,
    Alert,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    Card,
    CardContent,
    Divider,
    Button,            // <-- Aggiunto per il bottone "Esporta CSV"
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import WorkIcon from '@mui/icons-material/Work';
import HotelIcon from '@mui/icons-material/Hotel';
import axios from "axios";
import { useAuthenticated } from 'react-admin';
import config from '../../config/config.json';

const DriversReport = ({ id, from, to }) => {
    useAuthenticated();
    const [reportData, setReportData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [driversData, setDriversData] = useState([]);

    const jwt = localStorage.getItem("feathers-jwt");
    const host = config.production.host;

    const performCleanup = async () => {
        try {
            const response = await axios.post(
                `${host}/wialon/report/cleanup_result/`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: jwt
                    }
                }
            );
            if (response.data.error !== 0) {
                throw new Error("Problemi con il cleanup_result di wialon");
            }
            return true;
        } catch (error) {
            console.error("Errore durante il cleanup:", error);
            setErrorMessage("Problemi con il cleanup_result di wialon");
            throw error;
        }
    };

    const processDriverRows = (rowData) => {
        // Gestione del primo livello (totale)
        if (!rowData || !rowData.r) return [];

        const drivers = [];

        // Processo le righe di livello driver (direttamente in rowData.r)
        if (rowData.r) {
            for (const driverRow of rowData.r) {
                if (!driverRow.c) continue;

                const driver = {
                    driverName: driverRow.c[0] || 'N/D', // Nome autista
                    totalDriving: driverRow.c[7] || '0:00:00', // Guida
                    totalWork: driverRow.c[8] || '0:00:00',    // Lavoro
                    totalRest: driverRow.c[9] || '0:00:00',    // Riposo
                    details: []
                };

                // Processo i dettagli giornalieri se presenti
                if (driverRow.r) {
                    for (const dayRow of driverRow.r) {
                        if (!dayRow.c) continue;
                        driver.details.push({
                            date: dayRow.c[0],
                            driving: dayRow.c[7],
                            work: dayRow.c[8],
                            rest: dayRow.c[9],
                            distance: dayRow.c[14]
                        });
                    }
                }

                drivers.push(driver);
            }
        }

        return drivers;
    };

    useEffect(() => {
        const fetchReportData = async () => {
            try {
                setLoading(true);
                setErrorMessage(null);

                await performCleanup();

                // Execute report
                const execReportResponse = await axios.post(
                    `${host}/wialon/report/exec_report/`,
                    {
                        reportResourceId: 27691204,
                        reportTemplateId: 6,
                        reportObjectId: 27691204,
                        reportObjectSecId: id,
                        interval: {
                            flags: 0,
                            from: from,
                            to: to
                        }
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: jwt
                        }
                    }
                );

                const reportResult = execReportResponse.data?.reportResult;
                if (!reportResult || !reportResult.tables || reportResult.tables.length === 0) {
                    throw new Error("Nessun dato disponibile dal report.");
                }

                const tableInfo = reportResult.tables[0];
                if (!tableInfo) {
                    throw new Error("Struttura dati del report non valida.");
                }

                const { rows = 0, level = 0, header = [] } = tableInfo;

                if (rows === 0) {
                    setReportData({
                        reportResult,
                        header,
                        rowsData: []
                    });
                    setDriversData([]);
                    return;
                }

                // Select rows
                const selectRowsResponse = await axios.post(
                    `${host}/wialon/report/select_result_rows`,
                    {
                        tableIndex: 0,
                        config: {
                            type: "range",
                            data: {
                                from: 0,
                                to: rows - 1,
                                level: level,
                                unitInfo: 1
                            }
                        }
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: jwt
                        }
                    }
                );

                const rowsData = selectRowsResponse.data || [];

                // Process driver data
                if (Array.isArray(rowsData) && rowsData.length > 0) {
                    const drivers = processDriverRows(rowsData[0]);
                    setDriversData(drivers);
                }

                setReportData({
                    reportResult,
                    header,
                    rowsData
                });
            } catch (error) {
                console.error("Errore nel caricamento dei dati:", error);
                setErrorMessage(error.message || 'Errore nel caricamento dei dati.');
            } finally {
                setLoading(false);
            }
        };

        fetchReportData();
    }, [id, from, to, jwt, host]);

    const handleCopyJson = () => {
        if (reportData) {
            navigator.clipboard.writeText(JSON.stringify(reportData, null, 2))
                .then(() => {
                    setOpenSnackbar(true);
                })
                .catch((error) => {
                    console.error('Errore durante la copia:', error);
                });
        }
    };

    /**
     * Funzione per esportare in CSV i dati di un singolo driver
     */
    const exportDriverCSV = (driver) => {
        // Prepara le righe del CSV
        const lines = [];
        // Info generali dell'autista
        lines.push(`Autista:;${driver.driverName}`);
        lines.push(`Tempo di Guida:;${driver.totalDriving}`);
        lines.push(`Tempo di Lavoro:;${driver.totalWork}`);
        lines.push(`Tempo di Riposo:;${driver.totalRest}`);
        // Riga vuota
        lines.push(``);
        // Intestazione dettagli giornalieri
        lines.push(`Data;Guida;Lavoro;Riposo;Chilometraggio`);

        // Righe per i dettagli
        driver.details.forEach((detail) => {
            lines.push(
                [
                    detail.date,
                    detail.driving,
                    detail.work,
                    detail.rest,
                    detail.distance
                ].join(';')
            );
        });

        const csvContent = lines.join('\n');
        // Creazione del blob
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        // Creazione di un link 'invisibile' per forzare il download
        const link = document.createElement('a');
        link.href = url;
        // Suggeriamo un filename personalizzato
        link.setAttribute('download', `report_autista_${driver.driverName}.csv`);
        document.body.appendChild(link);
        link.click();

        // Pulizia
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    if (loading) {
        return (
            <Box sx={{ p: 3, textAlign: 'center' }}>
                <CircularProgress />
                <Typography>Caricamento dati attività autisti...</Typography>
            </Box>
        );
    }

    if (errorMessage) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography color="error">{errorMessage}</Typography>
            </Box>
        );
    }

    if (!reportData || driversData.length === 0) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography>Nessun dato disponibile per gli autisti nel periodo selezionato.</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ width: '100%', p: 2 }}>
            <Paper
                elevation={3}
                sx={{
                    p: 3,
                    mb: 3,
                    borderRadius: 2,
                    backgroundColor: 'background.paper'
                }}
            >
                <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
                    Report Attività Badge Autisti
                </Typography>
                <Typography variant="body1">
                    Periodo: {new Date(from * 1000).toLocaleString('it-IT')} - {new Date(to * 1000).toLocaleString('it-IT')}
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                    Autisti trovati: {driversData.length}
                </Typography>
            </Paper>

            <Grid container spacing={3}>
                {driversData.map((driver, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Paper elevation={3} sx={{ p: 2 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                <Typography variant="h6" gutterBottom color="primary">
                                    {driver.driverName}
                                </Typography>
                                {/* Bottone per esportare CSV */}
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => exportDriverCSV(driver)}
                                >
                                    Esporta CSV
                                </Button>
                            </Box>

                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={4}>
                                    <Card raised>
                                        <CardContent>
                                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                                <DriveEtaIcon sx={{ mr: 1, color: 'primary.main' }} />
                                                <Typography variant="subtitle1">Tempo di Guida</Typography>
                                            </Box>
                                            <Typography variant="h5">{driver.totalDriving}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Card raised>
                                        <CardContent>
                                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                                <WorkIcon sx={{ mr: 1, color: 'primary.main' }} />
                                                <Typography variant="subtitle1">Tempo di Lavoro</Typography>
                                            </Box>
                                            <Typography variant="h5">{driver.totalWork}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Card raised>
                                        <CardContent>
                                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                                <HotelIcon sx={{ mr: 1, color: 'primary.main' }} />
                                                <Typography variant="subtitle1">Tempo di Riposo</Typography>
                                            </Box>
                                            <Typography variant="h5">{driver.totalRest}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>

                            {driver.details.length > 0 && (
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Dettagli Giornalieri</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Data</TableCell>
                                                        <TableCell>Guida</TableCell>
                                                        <TableCell>Lavoro</TableCell>
                                                        <TableCell>Riposo</TableCell>
                                                        <TableCell>Chilometraggio</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {driver.details.map((detail, idx) => (
                                                        <TableRow key={idx}>
                                                            <TableCell>{detail.date}</TableCell>
                                                            <TableCell>{detail.driving}</TableCell>
                                                            <TableCell>{detail.work}</TableCell>
                                                            <TableCell>{detail.rest}</TableCell>
                                                            <TableCell>{detail.distance}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            )}

                            {index < driversData.length - 1 && <Divider sx={{ mt: 2 }} />}
                        </Paper>
                    </Grid>
                ))}
            </Grid>

            <Accordion
                sx={{
                    borderRadius: '8px !important',
                    '&:before': {
                        display: 'none',
                    }
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                        backgroundColor: 'background.paper',
                        borderRadius: '8px',
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}>
                        <Typography>Dati JSON Completi</Typography>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCopyJson();
                            }}
                            size="small"
                            sx={{ mr: 2 }}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ bgcolor: 'background.default' }}>
                    <pre style={{
                        overflow: 'auto',
                        maxHeight: '400px',
                        backgroundColor: '#f5f5f5',
                        padding: '16px',
                        borderRadius: '4px',
                        margin: 0
                    }}>
                        {JSON.stringify(reportData, null, 2)}
                    </pre>
                </AccordionDetails>
            </Accordion>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    JSON copiato negli appunti!
                </Alert>
            </Snackbar>
        </Box>
    );
};

DriversReport.propTypes = {
    id: PropTypes.string.isRequired,
    from: PropTypes.number.isRequired,
    to: PropTypes.number.isRequired
};

export default DriversReport;
