import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    Paper,
    Typography,
    Button,
    Alert,
    CircularProgress,
    Tabs,
    Tab,
    Divider,
    LinearProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Tooltip
} from '@mui/material';
import { useNotify } from 'react-admin';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import StopIcon from '@mui/icons-material/Stop';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InstallationGuide from './InstallationAmicoGuide';
import { createRifornimento } from '../../utils/helpers/createRifornimento';

// Funzione per formattare le date nel formato corretto
const formatDate = (date) => {
    if (!date) return null;
    if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
        return date;
    }
    const parts = date.split('/');
    if (parts.length === 3) {
        const [day, month, year] = parts;
        if (!isNaN(Date.parse(`${year}-${month}-${day}`))) {
            return `${year}-${month}-${day}`;
        }
    }
    return null;
};

// Funzione per formattare l'orario nel formato corretto
const formatTime = (ora, minuti) => {
    const parsedOra = parseInt(ora, 10);
    const parsedMinuti = parseInt(minuti, 10);
    if (
        !isNaN(parsedOra) && parsedOra >= 0 && parsedOra < 24 &&
        !isNaN(parsedMinuti) && parsedMinuti >= 0 && parsedMinuti < 60
    ) {
        return `${String(parsedOra).padStart(2, '0')}:${String(parsedMinuti).padStart(2, '0')}:00`;
    }
    return null;
};

// Funzione per formattare la durata in formato leggibile
const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const parts = [];
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);
    parts.push(`${remainingSeconds}s`);

    return parts.join(' ');
};

// Componente Dialog per la conferma dell'importazione
const ConfirmDialog = ({ open, onConfirm, onCancel }) => {
    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogTitle>Conferma Importazione</DialogTitle>
            <DialogContent>
                <Alert severity="info" sx={{ mb: 3 }}>
                    Verranno impostati automaticamente i campi <strong>flImport = 1</strong> e <strong>sourceImport = "AMICODAT"</strong> per tutti i record.
                </Alert>
                <Typography variant="body1">
                    Confermi di voler procedere con l'importazione?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="secondary">
                    Annulla
                </Button>
                <Button onClick={onConfirm} variant="contained" color="primary">
                    Procedi
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// Inizio del componente principale
const SoftwareAmicoImport = () => {
    // Stati base per la gestione dei dati
    const [fileData, setFileData] = useState(null);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [importType, setImportType] = useState('dat');
    const [importedCount, setImportedCount] = useState(0);
    const [importing, setImporting] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [importSummary, setImportSummary] = useState(null);
    const [failedRows, setFailedRows] = useState([]);

    // Stati per la gestione del tempo e dell'interruzione
    const [startTime, setStartTime] = useState(null);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [estimatedTimeRemaining, setEstimatedTimeRemaining] = useState(null);
    const cancelImportRef = useRef(false);
    const timerRef = useRef(null);

    const notify = useNotify();
    // Effect per gestire l'aggiornamento del timer
    useEffect(() => {
        let intervalId;
        if (importing && startTime) {
            intervalId = setInterval(updateTimers, 1000);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [importing, startTime, importedCount]);

    // Funzione per aggiornare i timer durante l'importazione
    const updateTimers = () => {
        if (startTime && importing) {
            const currentTime = new Date();
            const elapsed = Math.floor((currentTime.getTime() - startTime.getTime()) / 1000);
            setElapsedTime(elapsed);

            if (importedCount > 0) {
                const timePerRecord = elapsed / importedCount;
                const remainingRecords = rows.length - importedCount;
                const estimated = Math.floor(timePerRecord * remainingRecords);
                setEstimatedTimeRemaining(estimated);
            }
        }
    };

    // Funzione per copiare il testo negli appunti
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => notify('Copiato negli appunti!', { type: 'success' }))
            .catch(() => notify('Errore durante la copia', { type: 'error' }));
    };

    // Parser per le linee del file DAT
    const parseTxtLine = (line) => {
        const values = line.split(',');
        if (values.length !== 9) return null;
        const rawDate = values[7];
        const formattedDate = formatDate(`${rawDate.substr(6, 2)}/${rawDate.substr(4, 2)}/${rawDate.substr(0, 4)}`);
        return {
            tagAutista: values[0],
            tagAutomezzo: values[1],
            kmDigitati: values[2],
            numeroPompa: values[3],
            litriErogati: Number(values[4]).toFixed(2),
            orario: formatTime(values[5], values[6]),
            data: formattedDate,
            tipoTessera: values[8]
        };
    };

    // Parser per le linee del file CSV
    const parseCsvLine = (line) => {
        const values = line.split(';');
        if (values.length < 15) return null;
        const [F, cm, np, nc, data, ora, auti, mez, km, litri, totalizz, giacl, temp] = values;
        return {
            formato: F,
            codiceMacchina: cm,
            numeroPompa: np,
            numeroCisterna: nc,
            data: formatDate(data),
            orario: ora,
            autista: auti,
            mezzo: mez,
            km: km,
            litriErogati: litri,
            totalizzatore: totalizz,
            giacenzaLogica: giacl,
            temperatura: temp
        };
    };

    // Gestione della conferma di importazione
    const handleConfirm = async () => {
        const updatedRows = rows.map(row => ({
            ...row,
            flImport: 1,
            sourceImport: 'AMICODAT'
        }));
        setOpenDialog(false);
        await startImport(updatedRows);
    };

    const handleCancel = () => {
        setOpenDialog(false);
    };

    // Gestione dell'interruzione dell'importazione
    const handleCancelImport = () => {
        cancelImportRef.current = true;
        notify('Interruzione importazione in corso...', { type: 'warning' });
    };

    // Funzione principale di importazione
    const startImport = async (dataToImport) => {
        if (!dataToImport || dataToImport.length === 0) return;

        const startDateTime = new Date();
        setLoading(true);
        setImporting(true);
        setImportedCount(0);
        setImportSummary(null);
        setStartTime(startDateTime);
        setFailedRows([]);
        cancelImportRef.current = false;

        const totalRecords = dataToImport.length;
        let recordsImported = 0;
        let failedImports = [];

        const timerInterval = setInterval(() => {
            const currentTime = new Date();
            const elapsedSeconds = Math.floor((currentTime - startDateTime) / 1000);
            setElapsedTime(elapsedSeconds);

            if (recordsImported > 0) {
                const timePerRecord = elapsedSeconds / recordsImported;
                const remainingRecords = totalRecords - recordsImported;
                const estimatedRemaining = Math.floor(timePerRecord * remainingRecords);
                setEstimatedTimeRemaining(estimatedRemaining);
            }
        }, 1000);

        timerRef.current = timerInterval;

        try {
            for (let i = 0; i < dataToImport.length; i++) {
                if (cancelImportRef.current) {
                    throw new Error('Importazione interrotta dall\'utente');
                }

                const row = dataToImport[i];
                try {
                    await createRifornimento(row);
                    recordsImported++;
                } catch (rowError) {
                    failedImports.push({
                        row,
                        error: rowError.message,
                        index: i + 1
                    });
                }
                setImportedCount(recordsImported);
            }

            const endDateTime = new Date();
            const totalDuration = (endDateTime - startDateTime) / 1000;
            const failedCount = failedImports.length;

            setFailedRows(failedImports);

            const summaryText = `
                Import completato:
                - Record importati con successo: ${recordsImported}/${totalRecords} (${Math.round((recordsImported / totalRecords) * 100)}%)
                - Record falliti: ${failedCount}
                - Data inizio: ${startDateTime.toLocaleString()}
                - Data fine: ${endDateTime.toLocaleString()}
                - Durata totale: ${formatDuration(totalDuration)}
                - Media: ${(totalDuration / totalRecords).toFixed(2)} secondi per record
                ${failedCount > 0 ? '\nRecord falliti:' : ''}
                ${failedImports.map(f => `\nRiga ${f.index}: ${JSON.stringify(f.row)}\nErrore: ${f.error}`).join('\n')}
            `;

            setImportSummary(summaryText);
            notify(`Import completato. ${failedCount > 0 ? `${failedCount} record falliti.` : 'Tutti i record importati con successo!'}`,
                { type: failedCount > 0 ? 'warning' : 'success' });

        } catch (error) {
            const endDateTime = new Date();
            const totalDuration = (endDateTime - startDateTime) / 1000;
            const percentage = Math.round((recordsImported / totalRecords) * 100);

            const errorMessage = error.message === 'Importazione interrotta dall\'utente'
                ? 'Import interrotto dall\'utente'
                : 'Import terminato con errore';

            setImportSummary(`
                ${errorMessage}:
                - Record importati con successo: ${recordsImported}/${totalRecords} (${percentage}%)
                - Record falliti: ${failedImports.length}
                - Data inizio: ${startDateTime.toLocaleString()}
                - Data fine: ${endDateTime.toLocaleString()}
                - Durata totale: ${formatDuration(totalDuration)}
                - Media: ${(totalDuration / Math.max(recordsImported, 1)).toFixed(2)} secondi per record
                ${failedImports.length > 0 ? '\nRecord falliti:' : ''}
                ${failedImports.map(f => `\nRiga ${f.index}: ${JSON.stringify(f.row)}\nErrore: ${f.error}`).join('\n')}
            `);

            notify(error.message, { type: 'error' });
        } finally {
            if (timerRef.current) {
                clearInterval(timerRef.current);
                timerRef.current = null;
            }
            setLoading(false);
            setImporting(false);
            setStartTime(null);
            setElapsedTime(0);
            setEstimatedTimeRemaining(null);
        }
    };
    // Funzione per gestire il caricamento del file
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const expectedExtension = importType === 'dat' ? '.dat' : '.csv';
        if (!file.name.toLowerCase().endsWith(expectedExtension)) {
            notify(`Per favore carica un file ${expectedExtension}`, { type: 'error' });
            return;
        }

        setLoading(true);
        setError(null);
        const reader = new FileReader();

        reader.onload = (e) => {
            try {
                const text = e.target.result;
                const lines = text.split('\n').filter(line => line.trim());
                const parseFunction = importType === 'dat' ? parseTxtLine : parseCsvLine;
                const parsedRows = lines
                    .map(line => parseFunction(line))
                    .filter(row => row !== null);

                if (parsedRows.length === 0) {
                    throw new Error('Nessun dato valido trovato nel file');
                }

                setFileData(text);
                setRows(parsedRows);
                notify('File caricato con successo', { type: 'success' });
            } catch (err) {
                setError(err.message);
                notify('Errore durante il parsing del file', { type: 'error' });
            } finally {
                setLoading(false);
            }
        };

        reader.onerror = () => {
            setError('Errore durante la lettura del file');
            setLoading(false);
            notify('Errore durante la lettura del file', { type: 'error' });
        };

        reader.readAsText(file);
    };

    // Funzione per gestire un nuovo import
    const handleNewImport = () => {
        setFileData(null);
        setRows([]);
        setError(null);
        setImportedCount(0);
        setImportSummary(null);
        setStartTime(null);
        setElapsedTime(0);
        setEstimatedTimeRemaining(null);
        setLoading(false);
        setImporting(false);
        notify('Pronto per un nuovo import', { type: 'info' });
    };

    // Funzione per cancellare i dati
    const handleClearData = () => {
        setFileData(null);
        setRows([]);
        setError(null);
        setImportedCount(0);
        setImportSummary(null);
        notify('Dati cancellati', { type: 'info' });
    };

    const totalRecords = rows.length;
    const progressPercentage = totalRecords > 0 ? Math.round((importedCount / totalRecords) * 100) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <InstallationGuide />
            <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                <Typography variant="h6" gutterBottom color="primary">
                    Importazione Rifornimenti
                </Typography>

                <Tabs
                    value={importType}
                    onChange={(e, newValue) => {
                        setImportType(newValue);
                        handleClearData();
                    }}
                    sx={{ mb: 3 }}
                >
                    <Tab value="dat" label="Formato DAT (amico.dat)" />
                    {/* <Tab value="csv" label="Formato CSV (amico.csv)" /> */}
                </Tabs>

                <Divider sx={{ mb: 3 }} />

                {!fileData && !importing && !importSummary && (
                    <Box
                        sx={{
                            border: '2px dashed #ccc',
                            borderRadius: 2,
                            p: 3,
                            mb: 3,
                            textAlign: 'center',
                            backgroundColor: '#f8f8f8'
                        }}
                    >
                        <input
                            accept={importType === 'dat' ? '.dat' : '.csv'}
                            style={{ display: 'none' }}
                            id="file-upload"
                            type="file"
                            onChange={handleFileUpload}
                        />
                        <label htmlFor="file-upload">
                            <Button
                                component="span"
                                variant="contained"
                                startIcon={<CloudUploadIcon />}
                                sx={{ mb: 2 }}
                            >
                                Carica File {importType.toUpperCase()}
                            </Button>
                        </label>
                        <Typography variant="body2" color="textSecondary">
                            {importType === 'dat'
                                ? 'Supporta solo file DAT nel formato amico.dat'
                                : 'Supporta solo file CSV nel formato amico.csv'
                            }
                        </Typography>
                    </Box>
                )}

                {loading && !importing && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
                        <CircularProgress />
                    </Box>
                )}

                {error && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                        {error}
                    </Alert>
                )}

                {fileData && !loading && !importing && (
                    <>
                        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6">
                                Numero totale di righe nel file: {rows.length}
                            </Typography>
                            <Box>
                                <Button
                                    variant="outlined"
                                    startIcon={<DeleteIcon />}
                                    onClick={handleClearData}
                                    sx={{ mr: 2 }}
                                >
                                    Annulla
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<SendIcon />}
                                    onClick={() => setOpenDialog(true)}
                                    color="primary"
                                >
                                    Importa
                                </Button>
                            </Box>
                        </Box>
                    </>
                )}

                {importing && (
                    <Box sx={{ width: '100%', mt: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                            <Typography variant="body2">
                                {importedCount} / {totalRecords} record importati ({progressPercentage}%)
                            </Typography>
                            <Button
                                variant="contained"
                                color="error"
                                startIcon={<StopIcon />}
                                onClick={handleCancelImport}
                            >
                                Interrompi
                            </Button>
                        </Box>
                        <LinearProgress variant="determinate" value={progressPercentage} />
                        <Box sx={{ mt: 1 }}>
                            <Typography variant="body2">
                                Tempo trascorso: {formatDuration(elapsedTime)}
                            </Typography>
                            {estimatedTimeRemaining > 0 && (
                                <>
                                    <Typography variant="body2">
                                        Tempo stimato rimanente: {formatDuration(estimatedTimeRemaining)}
                                    </Typography>
                                    <Typography variant="body2">
                                        Completamento stimato: {new Date(Date.now() + (estimatedTimeRemaining * 1000)).toLocaleTimeString()}
                                    </Typography>
                                </>
                            )}
                        </Box>
                    </Box>
                )}

                {importSummary && !importing && (
                    <>
                        <Alert
                            severity={failedRows.length > 0 ? "warning" : "success"}
                            sx={{ mt: 3, whiteSpace: 'pre-line' }}
                            action={
                                <Tooltip title="Copia negli appunti">
                                    <IconButton
                                        color="inherit"
                                        size="small"
                                        onClick={() => copyToClipboard(importSummary)}
                                    >
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        >
                            {importSummary}
                        </Alert>
                        <Box sx={{ mt: 2, textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNewImport}
                                startIcon={<CloudUploadIcon />}
                            >
                                Nuovo Import
                            </Button>
                        </Box>
                    </>
                )}

                <ConfirmDialog
                    open={openDialog}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            </Paper>
        </Box>
    );
};

export default SoftwareAmicoImport;