import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    TopToolbar,
    CreateButton,
    FunctionField,
    Button,
    useNotify,
    useRefresh,
    useDataProvider,
    ReferenceField,
} from 'react-admin';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

// Importo il css per i Bottoni
import { listCss } from '../shared/Styles/listStyles';
import { showButtonCss, createButtonCss } from '../shared/Styles/buttonStyles';

// Importo i componenti dei filtri
import { TariffeaSearchBar } from './TariffeaSearchBar';
import { TariffeaAdvancedFiltersDialog } from './TariffeaAdvancedFiltersDialog';
import { TariffeaActiveFilters } from './TariffeaActiveFilters';
import EmptyListCustomNoCreate from "../shared/emptyPageCustom/empryPageCustomNoCreate";
import { checkPermission } from '../shared/Permissions/checkPermission';

const ListActions = () => (
    <TopToolbar>
        <CreateButton label="Crea" sx={createButtonCss} />
    </TopToolbar>
);

export const TariffeaList = props => {
    const [filters, setFilters] = React.useState({});
    const [openAdvanced, setOpenAdvanced] = React.useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const canRead = checkPermission("tariffe-a", "read");

    if (!canRead) {
        return (
            <div style={{ color: "red", fontWeight: "bold", padding: 20 }}>
                Accesso negato
            </div>
        );
    }

    // Funzione generica per verificare i risultati della ricerca
    const checkSearchResults = async (searchFilters) => {
        try {
            const { total } = await dataProvider.getList("tariffe-a", {
                pagination: { page: 1, perPage: 1 },
                sort: { field: "id", order: "DESC" },
                filter: searchFilters,
            });
            return total > 0;
        } catch (error) {
            console.error("Errore durante la verifica dei risultati:", error);
            return false;
        }
    };

    const handleSearch = async (searchValues) => {
        try {
            const hasResults = await checkSearchResults(searchValues);

            if (!hasResults) {
                notify("Nessun risultato trovato", { type: "warning" });
                return;
            }

            setFilters(searchValues);
            notify("Ricerca completata", { type: "success" });
        } catch (error) {
            console.error("Errore durante la ricerca:", error);
            notify("Errore durante la ricerca", { type: "error" });
        }
    };

    const handleAdvancedSearch = async (advancedFilters) => {
        try {
            const hasResults = await checkSearchResults(advancedFilters);

            if (!hasResults) {
                notify("Nessun risultato trovato per i filtri selezionati", { type: "warning" });
                setOpenAdvanced(false);
                return;
            }

            setFilters(advancedFilters);
            notify("Filtri applicati con successo", { type: "success" });
            setOpenAdvanced(false);
        } catch (error) {
            console.error("Errore durante l'applicazione dei filtri avanzati:", error);
            notify("Errore durante l'applicazione dei filtri", { type: "error" });
        }
    };

    const handleReset = () => {
        setFilters({});
        notify("Filtri resettati", { type: "info" });
        refresh();
    };

    const handleRemoveFilter = (key) => {
        const newFilters = { ...filters };
        delete newFilters[key];
        setFilters(newFilters);
        notify("Filtro rimosso", { type: "info" });
    };

    return (
        <List
            title={<>
                <Button>
                    <MonetizationOnIcon />
                    &nbsp;Lista delle Tariffe
                </Button>
            </>}
            perPage={100}
            sort={{ field: 'id', order: 'DESC' }}
            actions={<ListActions />}
            exporter={false}
            filter={filters}
            empty={<EmptyListCustomNoCreate titolo="Lista" />}
            {...props}
        >
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <TariffeaSearchBar
                        onSearch={handleSearch}
                        onReset={handleReset}
                        onOpenAdvanced={() => setOpenAdvanced(true)}
                    />
                    <TariffeaActiveFilters
                        filters={filters}
                        onRemove={handleRemoveFilter}
                    />
                </CardContent>
            </Card>

            <TariffeaAdvancedFiltersDialog
                open={openAdvanced}
                onClose={() => setOpenAdvanced(false)}
                onApply={handleAdvancedSearch}
                onReset={handleReset}
                currentFilters={filters}
            />

            <Datagrid
                sx={listCss}
                bulkActionButtons={false}
            >
                {<TextField label="Descrizione" source="tfa_des" />}
                {<TextField label="f Km Start" source="tfa_kmtratta0" />}
                {<TextField label="f Km Stop" source="tfa_kmtratta1" />}
                {<FunctionField
                    source="tfa_tipo"
                    label="Tipo"
                    render={element => {
                        return (element.tfa_tipo === 1) ? <strong>TKm</strong> : <strong>PAX</strong>;
                    }}
                />}
                {<FunctionField
                    source="tfa_isresday"
                    label="Resoconto giornaliero"
                    render={record => {
                        return (record.tfa_isresday === 0) ? <strong>NO</strong> : <strong>SI</strong>;
                    }}
                />}
                {<ReferenceField link={false} label="Anagrafica" source="tfa_clienteId" reference="anagen" />}
                {<ReferenceField link={false} label="Servizio" source="tfa_servizio" reference="servizi" />}
                {<TextField label="Budget km Tot" source="tfa_bdgkm" />}
                {<TextField label="Budget hh Tot" source="tfa_bdghh" />}
                {<TextField label="Vuoto MAX Km Fatturabili" source="tfa_maxkmvuoto" />}
                {<TextField label="Vuoto MAX mm Fatturabili" source="tfa_maxmmvuoto" />}
                {<TextField label="Minuti franchigia per supplemento o hh extra budget" source="tfa_frgmmec" />}
                {<TextField label="Minuti franchigia per orario notturno" source="tfa_frgmmnt" />}
                {<TextField label="Sconto 1" source="tfa_maxsc1" />}
                {<TextField label="Sconto 2" source="tfa_maxsc2" />}
                {<TextField label="Sconto 3" source="tfa_maxsc3" />}
                {<TextField label="Sconto 4" source="tfa_maxsc4" />}
                {<TextField label="Sconto 5" source="tfa_maxsc5" />}
                {<TextField label="Sconto 6" source="tfa_maxsc6" />}
                {<TextField label="Sconto 7" source="tfa_maxsc7" />}
                {<TextField label="Sconto 8" source="tfa_maxsc8" />}
                {<TextField label="Sconto 9" source="tfa_maxsc9" />}
                {<TextField label="Sconto 10" source="tfa_maxsc10" />}
                {<ShowButton sx={showButtonCss} label="Dettaglio" />}
            </Datagrid>
        </List>
    );
};