// src/components/automezzi/ActiveFiltersChips.js
import React from 'react';
import { Box, Chip, Typography } from '@mui/material';

const getOperatorLabel = (operator) => {
    const operators = {
        'eq': 'uguale a',
        'gt': 'maggiore di',
        'lt': 'minore di',
        'gte': 'maggiore o uguale a',
        'lte': 'minore o uguale a',
        'like': 'contiene'
    };
    return operators[operator] || operator;
};

const getFieldLabel = (fieldName, searchFields) => {
    const field = searchFields.find(f => f.field === fieldName);
    return field ? field.label : fieldName;
};

const ActiveFiltersChips = ({ filters, onRemoveFilter, searchFields }) => {
    if (!filters || Object.keys(filters).length === 0) {
        return null;
    }

    return (
        <Box sx={{ mt: 2, mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>Filtri attivi:</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {Object.entries(filters).map(([key, value]) => {
                    const [fieldName, operator] = key.split('.');
                    const fieldLabel = getFieldLabel(fieldName, searchFields);
                    const operatorLabel = getOperatorLabel(operator);

                    return (
                        <Chip
                            key={key}
                            label={`${fieldLabel} ${operatorLabel} ${value}`}
                            onDelete={() => onRemoveFilter(key)}
                            color="primary"
                            variant="outlined"
                            size="small"
                        />
                    );
                })}
                {Object.keys(filters).length > 0 && (
                    <Chip
                        label="Rimuovi tutti"
                        onDelete={() => onRemoveFilter('all')}
                        color="secondary"
                        variant="outlined"
                        size="small"
                    />
                )}
            </Box>
        </Box>
    );
};

export default ActiveFiltersChips;