import React from 'react';
import { Toolbar, SaveButton } from 'react-admin';
import { Button } from '@mui/material';

const BookingCreateToolbar = ({ formData, addressPartenza, addressArrivo, submitting, ...props }) => {
    const handleVerifyJSON = () => {
        const finalData = {
            ...formData,
            bk2_partenza: addressPartenza,
            bk2_arrivo: addressArrivo,
        };
        console.log('Dati del form:', finalData);
    };

    return (
        <Toolbar
            {...props}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'transparent',
                marginTop: '20px'
            }}
        >
            <SaveButton
                label="Crea Preventivo"
                type="submit"
                disabled={submitting}
                sx={{
                    padding: '8px 24px',
                    backgroundColor: '#4CAF50',
                    '&:hover': {
                        backgroundColor: '#45a049',
                    },
                    color: 'white',
                    fontWeight: 'bold'
                }}
            />
            <Button
                variant="outlined"
                onClick={handleVerifyJSON}
                disabled={submitting}
                sx={{ ml: 2 }}
            >
                Verifica JSON
            </Button>
        </Toolbar>
    );
};

export default BookingCreateToolbar;