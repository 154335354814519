import React from 'react';
import {
    List,
    DatagridConfigurable,
    TextField,
    EditButton,
    ShowButton,
    CreateButton,
    SelectColumnsButton,
    TopToolbar,
    ExportButton,
    WrapperField
} from 'react-admin';
import { numberFilter, StackedFilters } from '@react-admin/ra-form-layout';
import { ToBoolean } from '../shared/ToBoolean';
import { listCss } from '../shared/Styles/listStyles';
import { editButtonCss, exportButtonCss, columnsButtonCss, filterButtonCss, showButtonCss } from '../shared/Styles/buttonStyles';
import PeopleIcon from '@mui/icons-material/People';
import Button from '@mui/material/Button';
import { ConfirmDialogDeleteButtonCustom } from "../shared/DeleteButtonCustom";

import { checkPermission } from '../shared/Permissions/checkPermission';

const ListActions = () => (
    <TopToolbar>
        <CreateButton label="Crea" sx={exportButtonCss} />
        <ExportButton label="Esporta" sx={exportButtonCss} />
        <SelectColumnsButton sx={columnsButtonCss} />
        <StackedFilters sx={filterButtonCss} config={listFilterstabellealfa} />
    </TopToolbar>
);

const listFilterstabellealfa = {
    'id': numberFilter({ operators: ["eq", "neq", "gt", "lt"] }),
};

export const tabellealfaList = () => {
    const canRead = checkPermission("tabellealfa", "read");

    if (!canRead) {
        return (
            <div style={{ color: "red", fontWeight: "bold", padding: 20 }}>
                Accesso negato
            </div>
        );
    }

    return (
        <List
            mutationMode="pessimistic"
            title={
                <Button>
                    <PeopleIcon />
                    &nbsp;Lista tabellealfa
                </Button>
            }
            perPage={50}
            actions={<ListActions />}
        >
            <DatagridConfigurable bulkActionButtons={false} sx={listCss}>
                <TextField source="tbpref" />
                <TextField source="tbdes" />
                <TextField source="tbmy" />
                <ToBoolean source="flAttivo" />
                <ToBoolean source="flVisibile" />
                <ToBoolean source="flAnnullato" />
                <EditButton sx={editButtonCss} label="Modifica" />
                <ShowButton sx={showButtonCss} label="Dettaglio" />
                <WrapperField label="Elimina">
                    <ConfirmDialogDeleteButtonCustom
                        titolo={"Cancella record"}
                        messaggio={"Sei sicuro di vole cancellare questo record ?"}
                        servizio={"tabellealfa"}
                    />
                </WrapperField>
            </DatagridConfigurable>
        </List>
    );
};
