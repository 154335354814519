import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Divider, Card, CardHeader, CardContent } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import { makeStyles } from '@material-ui/core/styles';
import { Loading, useDataProvider, useStore } from 'react-admin';
import DashboardCliente from './clienti/DashboardCliente';
import DashboardBooking from './booking/DashboardBooking';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const services = [
    { name: 'Preventivi', service: 'preventivi-cliente', icon: PeopleIcon, color: '#00796b' },
];

const DashboardWrapper = ({ props }) => {

    // Recupera l'ID del gruppo dall'utente autenticato
    const [gruppiId] = useStore('profile.gruppiId');
    // Se l'utente è identificato come Cliente (gruppiId === 2), mostra la lista dedicata
    if (gruppiId === 2) {
        return <DashboardCliente gruppiId={gruppiId} {...props} />;
    }

    return <DashboardBooking {...props} />;

};

export default DashboardWrapper;