import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Divider, Card, CardHeader, CardContent } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import { makeStyles } from '@material-ui/core/styles';
import { Loading, useDataProvider } from 'react-admin';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const services = [
    { name: 'Preventivi', service: 'preventivi-cliente', icon: PeopleIcon, color: '#00796b' },
];

const DashboardBooking = () => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [serviceCounts, setServiceCounts] = useState({});
    const [serviceErrors, setServiceErrors] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchServiceData = async () => {
            setLoading(true);
            const promises = services.map(async (service) => {
                try {
                    const result = await dataProvider.getList(service.service, {
                        pagination: { page: 1, perPage: 1 },
                        sort: { field: 'id', order: 'ASC' },
                        filter: {}
                    });
                    return { service: service.service, count: result.total };
                } catch (error) {
                    console.error(`Error fetching data for ${service.name}:`, error);
                    return { service: service.service, error: true };
                }
            });

            const results = await Promise.all(promises);
            const counts = {};
            const errors = {};

            results.forEach((result) => {
                if (result.error) {
                    errors[result.service] = true;
                } else {
                    counts[result.service] = result.count;
                }
            });

            setServiceCounts(counts);
            setServiceErrors(errors);
            setLoading(false);
        };

        fetchServiceData();
    }, [dataProvider]);

    if (loading) return <Loading />;

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom sx={{ marginBottom: 4 }}>
                    Dashboard Wrapper Booking
                </Typography>

                <Box sx={{ mb: 4, mt: 6 }}>
                    <Typography variant="h5" gutterBottom color="primary">
                        Link Rapidi
                    </Typography>
                    <Divider />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardHeader className={classes.paper} title="Lista Preventivi" />
                    <CardContent>
                        <Grid container spacing={2}>

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
};

export default DashboardBooking;