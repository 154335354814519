const formatDateForDisplay = (dateString) => {
    if (!dateString) {
        return "Nessuna data disponibile";
    }

    try {
        // Crea un oggetto Date a partire dalla stringa UTC
        const utcDate = new Date(dateString);

        // Ottiene l'offset del fuso orario del client in minuti
        const timezoneOffset = utcDate.getTimezoneOffset(); // In minuti

        // Converte l'offset in ore e minuti
        const offsetHours = Math.floor(Math.abs(timezoneOffset) / 60);
        const offsetMinutes = Math.abs(timezoneOffset) % 60;

        // Log per debug del fuso orario
        console.log(`Offset del fuso orario del client: ${timezoneOffset} minuti`);
        console.log(`Offset calcolato: ${offsetHours} ore, ${offsetMinutes} minuti`);

        // Converte l'ora UTC in ora locale
        const localDate = new Date(utcDate.getTime() - timezoneOffset * 60000);

        // Formatta la data nel formato "DD/MM/YYYY HH:mm:ss"
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0');
        const day = String(localDate.getDate()).padStart(2, '0');
        const hours = String(localDate.getHours()).padStart(2, '0');
        const minutes = String(localDate.getMinutes()).padStart(2, '0');
        const seconds = String(localDate.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
    } catch (error) {
        console.error("Errore nella formattazione della data:", error.message);
        return "Data non valida";
    }
};

export default formatDateForDisplay;
