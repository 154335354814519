import React from 'react';
// import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Card, CardContent } from '@mui/material';
import { Title } from 'react-admin';
import Plot from 'react-plotly.js';
import StatusPieChartBooking0Status from './StatusPieChartBooking0Status';
import AutomezziStatisticsChart from './AutomezziStatisticsChart';

function StatisticheBoard() {

    return (
        <div>

            {/* <Container component="main" maxWidth="md" sx={{ mt: 10 }}> */}
            <Card sx={{ mt: 10 }}>
                <Title title="STATISTICHE" />
                <CardContent>
                    Pagina delle statistiche.
                </CardContent>
            </Card>


            <StatusPieChartBooking0Status></StatusPieChartBooking0Status>
            <AutomezziStatisticsChart></AutomezziStatisticsChart>

        </div>
    );
}

export default StatisticheBoard;
