// src/components/automezzi/AutomezziSearchBar.js
import React, { useState } from 'react';
import {
    Box, Grid, Button, IconButton, InputAdornment,
    TextField as MuiTextField, MenuItem, Switch, FormControlLabel
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ClearIcon from '@mui/icons-material/Clear';

// Operatori per i campi numerici
const NUMERIC_OPERATORS = [
    { value: 'eq', label: 'Uguale a' },
    { value: 'gt', label: 'Maggiore di' },
    { value: 'lt', label: 'Minore di' },
    { value: 'gte', label: 'Maggiore o uguale a' },
    { value: 'lte', label: 'Minore o uguale a' }
];

// Definizione dei campi di ricerca
export const AUTOMEZZI_SEARCH_FIELDS = [
    { field: 'amid', label: 'ID', type: 'number', allowWildcard: true }, // Aggiunto allowWildcard
    { field: 'amtarga', label: 'Targa', type: 'text' },
    { field: 'ampaxnrdw_s1', label: 'Posti a sedere', type: 'number' },
    { field: 'ampaxnrdw_ass', label: 'Posti Assistente', type: 'number' },
    { field: 'ampaxnrup_s2', label: 'Posti in piedi', type: 'number' },
    { field: 'ampaxnrtot', label: 'Posti Totali', type: 'number' },
    { field: 'ampaxnrdw_tot', label: 'Posti a sedere Totali', type: 'number' },
    {
        field: 'amtgruppo',
        label: 'Gruppo',
        type: 'text',
        isReference: true,
        referenceTable: 'tabellealfa'
    },
    {
        field: 'amtmarca_d1',
        label: 'Marca',
        type: 'text',
        isReference: true,
        referenceTable: 'tabellealfa'
    },
    {
        field: 'amtintest_c2',
        label: 'Organizzazione',
        type: 'text',
        isReference: true,
        referenceTable: 'tabellealfa'
    },
    {
        field: 'amtcategoria_j',
        label: 'Categoria',
        type: 'text',
        isReference: true,
        referenceTable: 'tabellealfa'
    }
];

const AutomezziSearchBar = ({ onSearch, onReset }) => {
    const [searchValues, setSearchValues] = useState({});
    const [numericOperators, setNumericOperators] = useState({});
    const [useWildcard, setUseWildcard] = useState({}); // Per tracciare quali campi usano wildcard

    // Gestisce il cambiamento nei campi di input
    const handleChange = (field, value, type) => {
        if (value.trim() === '') {
            const newValues = { ...searchValues };
            delete newValues[field];
            setSearchValues(newValues);
            return;
        }

        setSearchValues(prev => ({
            ...prev,
            [field]: value
        }));
    };

    // Gestisce il cambiamento degli operatori per i campi numerici
    const handleOperatorChange = (field, operator) => {
        setNumericOperators(prev => ({
            ...prev,
            [field]: operator
        }));
    };

    // Gestisce il toggle della modalità wildcard per i campi che la supportano
    const handleWildcardToggle = (field) => {
        setUseWildcard(prev => ({
            ...prev,
            [field]: !prev[field]
        }));
    };

    // Funzione di ricerca
    const handleSearchClick = () => {
        const filteredValues = {};

        Object.entries(searchValues).forEach(([field, value]) => {
            if (!value || value.toString().trim() === '') return;

            const fieldConfig = AUTOMEZZI_SEARCH_FIELDS.find(f => f.field === field);
            if (!fieldConfig) return;

            // Se è un campo numerico ma ha attivato la wildcard
            if (fieldConfig.type === 'number' && fieldConfig.allowWildcard && useWildcard[field]) {
                if (value.includes('*')) {
                    filteredValues[`${field}.like`] = value;
                } else {
                    filteredValues[`${field}.eq`] = value;
                }
            }
            // Se è numerico normale
            else if (fieldConfig.type === 'number' && !useWildcard[field]) {
                const operator = numericOperators[field] || 'eq';
                filteredValues[`${field}.${operator}`] = value;
            }
            // Se è un campo di riferimento
            else if (fieldConfig.isReference) {
                // Se contiene wildcard, usiamo like
                if (value.includes('*')) {
                    filteredValues[`${field}.like`] = value;
                } else {
                    // Altrimenti usiamo eq per match esatto
                    filteredValues[`${field}.eq`] = value;
                }
            }
            // Se è un campo di testo normale
            else {
                if (value.includes('*')) {
                    filteredValues[`${field}.like`] = value;
                } else {
                    filteredValues[`${field}.eq`] = value;
                }
            }
        });

        console.log('Query costruita:', filteredValues);
        onSearch(filteredValues);
    };

    // Reset dei filtri
    const handleResetClick = () => {
        setSearchValues({});
        setNumericOperators({});
        setUseWildcard({});
        onReset();
    };

    return (
        <Box>
            <Grid container spacing={2} alignItems="center">
                <Grid container item spacing={2} xs={12} md={9}>
                    {AUTOMEZZI_SEARCH_FIELDS.map(({ field, label, type, allowWildcard }) => (
                        <Grid item xs={12} sm={6} md={4} key={field}>
                            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <MuiTextField
                                        fullWidth
                                        size="small"
                                        label={label}
                                        placeholder={type === 'text' || (type === 'number' && allowWildcard && useWildcard[field])
                                            ? 'Usa * come wildcard'
                                            : 'Inserisci valore'}
                                        value={searchValues[field] || ''}
                                        onChange={(e) => handleChange(field, e.target.value, type)}
                                        type={(type === 'number' && !useWildcard[field]) ? 'number' : 'text'}
                                        InputProps={{
                                            endAdornment: searchValues[field] ? (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleChange(field, '', type)}
                                                    >
                                                        <ClearIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ) : null
                                        }}
                                    />
                                    {type === 'number' && !useWildcard[field] && (
                                        <MuiTextField
                                            select
                                            size="small"
                                            value={numericOperators[field] || 'eq'}
                                            onChange={(e) => handleOperatorChange(field, e.target.value)}
                                            sx={{ minWidth: 120 }}
                                        >
                                            {NUMERIC_OPERATORS.map(op => (
                                                <MenuItem key={op.value} value={op.value}>
                                                    {op.label}
                                                </MenuItem>
                                            ))}
                                        </MuiTextField>
                                    )}
                                </Box>
                                {allowWildcard && type === 'number' && (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                size="small"
                                                checked={useWildcard[field] || false}
                                                onChange={() => handleWildcardToggle(field)}
                                            />
                                        }
                                        label="Usa wildcard"
                                    />
                                )}
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                <Grid container item spacing={2} xs={12} md={3}>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            startIcon={<SearchIcon />}
                            onClick={handleSearchClick}
                            fullWidth
                        >
                            Cerca
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            startIcon={<RestartAltIcon />}
                            onClick={handleResetClick}
                            fullWidth
                            color="secondary"
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AutomezziSearchBar;