import React, { useState } from "react";
import { Grid, Box, Button, IconButton, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import ClearIcon from "@mui/icons-material/Clear";

export const TariffeaSearchBar = ({ onSearch, onReset, onOpenAdvanced }) => {
    const [searchValues, setSearchValues] = useState({});

    const handleChange = (field, value) => {
        setSearchValues((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleSearch = () => {
        const formattedValues = {};

        // Aggiungiamo l'operatore like per la ricerca testuale
        if (searchValues["tfa_des"]) {
            formattedValues["tfa_des.like"] = `*${searchValues["tfa_des"]}*`;
        }

        onSearch(formattedValues);
    };

    return (
        <Box>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={8}>
                    <TextField
                        label="Descrizione"
                        value={searchValues["tfa_des"] || ""}
                        onChange={(e) => handleChange("tfa_des", e.target.value)}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => handleChange("tfa_des", "")}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        startIcon={<SearchIcon />}
                        variant="contained"
                        onClick={handleSearch}
                    >
                        Cerca
                    </Button>
                    <Button
                        startIcon={<TuneIcon />}
                        variant="outlined"
                        onClick={onOpenAdvanced}
                        sx={{ ml: 1 }}
                    >
                        Filtri Avanzati
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={onReset} sx={{ ml: 1 }}>
                        Reset
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};