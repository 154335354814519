import React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    BooleanField,
    ReferenceField,
    FunctionField,
    TopToolbar,
    EditButton
} from 'react-admin';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { editButtonCss } from "../shared/Styles/buttonStyles";

const ShowActions = () => (
    <TopToolbar>
        <EditButton sx={editButtonCss} label="Modifica" />
    </TopToolbar>
);


export const ScadenzeShow = props => (
    <Show
        title="Dettaglio Scadenza" {...props}
        actions={<ShowActions />}
    >
        <Card>
            <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                    Dettagli della Scadenza
                </Typography>
                <SimpleShowLayout>
                    {/* Tipo Scadenza */}
                    <ReferenceField source="tipoScadenzaId" reference="tipo-scadenza" label="Tipo">
                        <TextField source="nome" />
                    </ReferenceField>

                    {/* Descrizione */}
                    <TextField source="note" label="Descrizione" />

                    {/* Data Scadenza */}
                    <DateField source="data" label="Data Scadenza" showTime />

                    {/* Stato */}
                    <ReferenceField source="statoScadenzaId" reference="stato-scadenza" label="Stato">
                        <TextField source="nome" />
                    </ReferenceField>

                    {/* Promemoria */}
                    <BooleanField source="flPromemoria" label="Promemoria Abilitato" />

                    {/* Assegnato a */}
                    <ReferenceField link={false} source="userId" reference="users" label="Assegnato a">
                        <TextField source="nome" />
                    </ReferenceField>

                    {/* Campo aggiuntivo: giorni rimanenti */}
                    <FunctionField
                        label="Giorni Rimanenti"
                        render={record => {
                            const today = new Date();
                            const dueDate = new Date(record.data);
                            const diffTime = dueDate - today;
                            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                            return diffDays >= 0
                                ? `${diffDays} giorni rimanenti`
                                : `Scaduto da ${Math.abs(diffDays)} giorni`;
                        }}
                    />
                </SimpleShowLayout>
            </CardContent>
        </Card>
    </Show>
);
