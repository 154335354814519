import { useRecordContext } from 'react-admin';
import DocumentiList from "../documenti/CustomDocumenti";
import CreateDocumentiForm from "../documenti/CustomCreateDocumenti";

const GestioneNote = ({ tipoDocumentoFisso = false, tipodocumentoIdFisso = "0", tiporecordId, refresh }) => {
    const record = useRecordContext();
    const userId = localStorage.getItem("RaStore.profile.idUser");
    const emailUser = localStorage.getItem("RaStore.profile.email");
    if (!record) return null;
    return record ? (
        <>
            <CreateDocumentiForm
                tipoDocumentoFisso={tipoDocumentoFisso} tipodocumentoIdFisso={tipodocumentoIdFisso}
                tiporecordId={tiporecordId} recordId={record.id} userId={userId} refresh={refresh}></CreateDocumentiForm>
            <DocumentiList tiporecordId={tiporecordId} recordId={record.id} emailUser={emailUser}></DocumentiList>
        </>
    ) : null;
};

export default GestioneNote;
