import { localStorageStore } from "react-admin";

// Init store
const localStorage = localStorageStore();


export const checkPermission = (resourceName, action) => {
    // Controlla se l'utente ha il ruolo di admin
    const profileRoles = localStorage.getItem("profile.roles");
    if (profileRoles && profileRoles.includes("admin")) {
        return true;
    }

    const rawResources = localStorage.getItem("accessibleResources");
    if (!rawResources) return false;

    try {
        const resources = JSON.parse(rawResources);
        const foundResource = resources.find((res) => res.name === resourceName);
        return foundResource
            && foundResource.permissions
            && foundResource.permissions[action] === 1;
    } catch (error) {
        console.error("Errore nel parse di accessibleResources:", error);
        return false;
    }
};
