import React from 'react';
import {
    CreateButton,
    DateField,
    DateInput,
    ExportButton,
    List,
    ReferenceField,
    ShowButton,
    TextField,
    TextInput,
    TopToolbar,
    useRefresh,
    useListContext,
    ReferenceInput,
    SelectInput,
    NumberInput,
    Datagrid,
    FunctionField,
    Pagination,
    useNotify
} from "react-admin";

// Parametro necessario per la gestione delle sezioni
import { useParams } from 'react-router-dom';
import { getStatusInfo } from './utils/bookingStatus';

import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

// Importazione componenti custom e stili
import { columnsButtonCss, exportButtonCss, showButtonCss } from "../shared/Styles/buttonStyles";
import { listCss } from "../shared/Styles/listStyles";
import { BookingCreateDialog } from './BookingCreateDialog';
import EmptyListCustom from '../shared/emptyPageCustom/empryPageCustom';
import { BookingFilters } from './BookingFilters';


// Componente per le azioni nella toolbar
const ListActions = ({ onCreateClick }) => {
    const { setFilters } = useListContext();

    // Funzione per resettare i filtri
    const handleResetFilters = () => {
        setFilters({});
    };

    return (
        <TopToolbar>
            <Button
                onClick={handleResetFilters}
                startIcon={<RestartAltIcon />}
                sx={{
                    backgroundColor: '#ff9800',
                    color: 'white',
                    marginRight: '10px',
                    '&:hover': {
                        backgroundColor: '#f57c00',
                    },
                }}
            >
                Reset Filtri
            </Button>
            <ExportButton sx={columnsButtonCss} />
            <Button
                onClick={onCreateClick}
                sx={exportButtonCss}
            >
                Crea Preventivo
            </Button>
        </TopToolbar>
    );
};

// Componente per la paginazione personalizzata
const PostPagination = props => (
    <Pagination
        rowsPerPageOptions={[25, 50, 100]}
        defaultValue={100}
        {...props}
    />
);


export const Booking0List = props => {

    const { bk0_idstatus } = useParams();
    const status = getStatusInfo(bk0_idstatus);
    console.log(`Status corrente: ${status.descrizione} (ID: ${status.id})`);


    // Hooks e state
    const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
    const refresh = useRefresh();
    const notify = useNotify();

    // Gestori eventi
    const handleCreateClick = () => {
        setCreateDialogOpen(true);
    };

    const handleClose = () => {
        setCreateDialogOpen(false);
        refresh();
    };

    // Gestore errori generale
    const handleError = (error) => {
        console.error('Error in Booking0List:', error);

        if (error?.body?.message) {
            // Errori dal backend con messaggio specifico
            notify(error.body.message, { type: 'error', multiLine: true });
        } else if (error?.status === 400) {
            // Errori di validazione
            notify('Errore di validazione. Controlla i dati inseriti.', {
                type: 'warning',
                multiLine: true
            });
        } else if (error?.status === 403) {
            // Errori di autorizzazione
            notify('Non hai i permessi necessari per questa operazione.', {
                type: 'warning'
            });
        } else {
            // Altri errori
            notify('Si è verificato un errore durante il caricamento dei dati.', {
                type: 'error'
            });
        }
    };

    return (
        <>
            <List
                title={
                    <>
                        <InsertInvitationIcon />
                        &nbsp;Booking - Lista Preventivi
                    </>
                }
                filters={BookingFilters(bk0_idstatus)}
                filter={status.id !== -1 ? { bk0_idstatus: status.id } : {}}
                actions={<ListActions onCreateClick={handleCreateClick} />}
                exporter={true}
                pagination={<PostPagination />}
                perPage={100}
                {...props}
                empty={<EmptyListCustom titolo="Lista" />}
                onError={handleError}
                queryOptions={{
                    onError: handleError,
                    retry: false // Disabilita i tentativi automatici di ripetizione della query
                }}
            >
                <Datagrid
                    sx={listCss}
                    bulkActionButtons={false}
                    optimized // Migliora le performance per grandi dataset
                >
                    <TextField source="id" label="id" />
                    <TextField source="bk0_idnumber" label="Numero" />
                    <ReferenceField source="bk0_idstatus" reference="view-booking-0-status-riepilogo" label="Status"></ReferenceField>
                    <ReferenceField
                        link={null}
                        label="Cliente"
                        source="bk0_clienteId"
                        reference="anagen"
                        sortable={true}
                        emptyText="-" // Testo da mostrare quando il valore è vuoto
                    >
                        <TextField source="gnfind" />
                    </ReferenceField>
                    <DateField
                        source="bk0_datada"
                        label="Dal"
                        showTime={false} // Mostra solo la data senza ora
                    />
                    <DateField
                        source="bk0_dataal"
                        label="Al"
                        showTime={false}
                    />
                    <ReferenceField
                        link={null}
                        label="Tipo Servizio"
                        source="bk0_servizio"
                        reference="servizi"
                        sortable={true}
                        emptyText="-"
                    />
                    <ReferenceField
                        link={null}
                        label="Tipo Richiesta"
                        source="bk0_richiestatipo"
                        reference="view-tabellaalfa-trp"
                        sortable={true}
                        emptyText="-"
                    />
                    <TextField label="Note per Booking" source="bk0_ntmemo" emptyText="-" />
                    <TextField label="Nota per Cliente" source="bk0_ntcliente" emptyText="-" />
                    <TextField label="Nota per Autista" source="bk0_ntautista" emptyText="-" />
                    <TextField label="Nota per Responsabile" source="bk0_ntresp" emptyText="-" />
                    <TextField label="Adulti" source="bk0_paxad" emptyText="0" />
                    <TextField label="Bambini" source="bk0_paxch" emptyText="0" />
                    <TextField label="Infanti" source="bk0_paxin" emptyText="0" />
                    <DateField
                        source="createdAt"
                        label="Data Creazione"
                        showTime={true} // Mostra data e ora per la creazione
                    />
                    <FunctionField
                        label="Tratte"
                        render={record => record && (
                            <Button
                                component={Link}
                                to={`/booking-0/${record.id}/show/tratte`}
                                size="small"
                                startIcon={<DirectionsCarIcon />}
                                sx={{
                                    backgroundColor: '#2196f3',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: '#1976d2',
                                    },
                                }}
                            >
                                Tratte
                            </Button>
                        )}
                    />
                    <ShowButton
                        sx={showButtonCss}
                        label="Dettaglio"
                    />
                </Datagrid>
            </List>

            <BookingCreateDialog
                open={createDialogOpen}
                onClose={handleClose}
            />
        </>
    );
};