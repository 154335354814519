import axios from 'axios';

const getOrCreateBookingH = async (booking0Id, host, jwt) => {
    try {
        // Verifica se esiste già un record in booking_h
        const checkResponse = await axios({
            method: 'get',
            url: `${host}/booking-h?booking0Id=${booking0Id}`,
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        });

        if (checkResponse.data.data && checkResponse.data.data.length > 0) {
            console.log("Found existing booking_h:", checkResponse.data.data[0]);
            return checkResponse.data.data[0];
        }

        // Se non esiste, crea un nuovo record con i campi minimi necessari
        const createResponse = await axios({
            method: 'post',
            url: `${host}/booking-h`,
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            data: {
                booking0Id: booking0Id,
                flAttivo: 1,
                flVisibile: 1,
                flAnnullato: 0
            }
        });

        console.log("Created new booking_h:", createResponse.data);
        return createResponse.data;
    } catch (error) {
        console.error('Error in getOrCreateBookingH:', error);
        throw error;
    }
};

const saveSimulatorData = async (finalJson, host, booking0Record) => {
    try {
        const userId = localStorage.getItem("RaStore.profile.idUser");
        const jwt = localStorage.getItem("feathers-jwt");
        console.log("=== DEBUG START ===");
        console.log("record di booking0 : ", booking0Record);

        console.log("1. Input finalJson:", JSON.stringify(finalJson, null, 2));

        // Prima verifichiamo o creiamo il booking_h
        const bookingHRecord = await getOrCreateBookingH(
            finalJson.booking0AutomezziRecordMod.booking0Id,
            host,
            jwt
        );

        console.log("bookingHRecord", bookingHRecord);


        // Prepara i dati per il salvataggio
        const saveData = {
            // Dati booking e IDs
            booking0AutomezziId: Number(finalJson.booking0AutomezziRecordMod.booking0AutomezziId),
            bookingHId: Number(bookingHRecord.id), // Usa l'ID del record booking_h
            booking0Id: Number(finalJson.booking0AutomezziRecordMod.booking0Id),
            gruppoId: Number(finalJson.booking0AutomezziRecordMod.gruppoId),

            // Campi da booking_h
            bkh_idnumber: Number(booking0Record.bk0_idnumber || 0),
            bkh_idserie: Number(booking0Record.bk0_idserie || 0),
            bkh_tipo12: Number(finalJson.tariffeARecord.tfa_tipo || 0),
            bkh_bdgkm: Number(bookingHRecord.results_importo_budget_km || 0),
            bkh_bdghh: Number(bookingHRecord.results_importo_budget_hh || 0),
            bkh_servizio: Number(booking0Record.bk0_servizio || null),
            bkh_gruppo: Number(booking0Record.bk0_gruppo || null),
            bkh_gruppo2: Number(booking0Record.bk0_gruppo2 || null),
            bkh_data: booking0Record.bk0_datada || null,
            bkh_redattore: bookingHRecord.bkh_redattore || null,
            bkh_redattoreId: Number(userId || null),
            bkh_allaca: bookingHRecord.bkh_allaca || null,
            bkh_rifcliente: bookingHRecord.bkh_rifcliente || null,
            bkh_itbreve: bookingHRecord.bkh_itbreve || null,
            bkh_pag: Number(bookingHRecord.bkh_pag || null),
            bkh_tipodoc: bookingHRecord.bkh_tipodoc || null,
            bkh_fattore: Number(bookingHRecord.bkh_fattore || 0),
            bkh_importobase: Number(finalJson.results.results_totale_imponibile),
            bkh_importobase2: Number(bookingHRecord.bkh_importobase2 || 0),
            bkh_importosupp: Number(bookingHRecord.bkh_importosupp || 0),
            bkh_sconto: Number(bookingHRecord.bkh_sconto || 0),
            bkh_scontobit_i: Number(bookingHRecord.bkh_scontobit_i || 0),
            bkh_totaleivato: Number(bookingHRecord.bkh_totaleivato || 0),
            bkh_importonotturno: Number(bookingHRecord.results_supplemento_notturno || 0),
            bkh_nrodoc: Number(bookingHRecord.bkh_nrodoc || 0),
            bkh_incassoautista: Number(bookingHRecord.bkh_incassoautista || 0),
            bkh_hisimpegno: bookingHRecord.bkh_hisimpegno || null,
            bkh_hisservizio: bookingHRecord.bkh_hisservizio || null,

            // Tariffa A
            tfa_id: Number(finalJson.tariffeARecord.tfa_id),
            tfa_tipo: Number(finalJson.tariffeARecord.tfa_tipo),
            tfa_des: String(finalJson.tariffeARecord.tfa_des),
            tfa_bdgkm: Number(finalJson.tariffeARecord.tfa_bdgkm),
            tfa_bdghh: Number(finalJson.tariffeARecord.tfa_bdghh),
            tfa_frgmmec: Number(finalJson.tariffeARecord.tfa_frgmmec),
            tfa_frgmmnt: Number(finalJson.tariffeARecord.tfa_frgmmnt),

            // Tariffa B
            tariffeBId: Number(finalJson.tariffeBData.tfb_id),
            tfb_id: Number(finalJson.tariffeBData.tfb_id),
            tfb_des: String(finalJson.tariffeBData.tfb_des),
            tfb_ckm: Number(finalJson.tariffeBData.tfb_ckm),
            tfb_ekm: Number(finalJson.tariffeBData.tfb_ekm),
            tfb_chh: Number(finalJson.tariffeBData.tfb_chh),
            tfb_ehh: Number(finalJson.tariffeBData.tfb_ehh),
            tfb_vkmperc: Number(finalJson.tariffeBData.tfb_vkmperc),
            tfb_vhhperc: Number(finalJson.tariffeBData.tfb_vhhperc),
            tfb_snotturno: Number(finalJson.tariffeBData.tfb_snotturno),

            // Input values
            input_km_servizio: Number(finalJson.inputs.input_km_servizio),
            input_minuti_servizio: Number(finalJson.inputs.input_minuti_servizio),
            input_km_vuoto: Number(finalJson.inputs.input_km_vuoto),
            input_minuti_vuoto: Number(finalJson.inputs.input_minuti_vuoto),
            input_notturno: Number(finalJson.inputs.input_notturno ? 1 : 0),
            input_supplemento_pedaggio: Number(finalJson.inputs.input_supplemento_pedaggio),
            input_tipo_sconto: Number(finalJson.inputs.input_tipo_sconto),
            input_valore_sconto: Number(finalJson.inputs.input_valore_sconto),
            input_total_km: Number(finalJson.inputs.input_total_km),
            input_total_minuti: Number(finalJson.inputs.input_total_minuti),

            // Results
            results_importo_budget_km: Number(finalJson.results.results_importo_budget_km),
            results_importo_budget_hh: Number(finalJson.results.results_importo_budget_hh),
            results_importo_totale_suppl_km: Number(finalJson.results.results_importo_totale_suppl_km),
            results_importo_totale_suppl_hh: Number(finalJson.results.results_importo_totale_suppl_hh),
            results_km_totali: Number(finalJson.results.results_km_totali),
            results_minuti_totali: Number(finalJson.results.results_minuti_totali),
            results_km_eccedenza: Number(finalJson.results.results_km_eccedenza),
            results_minuti_eccedenza: Number(finalJson.results.results_minuti_eccedenza),
            results_totale_imponibile_di_servizio: Number(finalJson.results.results_totale_imponibile_di_servizio),
            results_supplemento_notturno: Number(finalJson.results.results_supplemento_notturno),
            results_totale_imponibile_finale: Number(finalJson.results.results_totale_imponibile_finale),
            results_sconto: Number(finalJson.results.results_sconto),
            results_totale_imponibile: Number(finalJson.results.results_totale_imponibile),
            results_importo_iva: Number(finalJson.results.results_importo_iva),
            results_totale_preventivo: Number(finalJson.results.results_totale_preventivo),
        };

        console.log("2. Prepared saveData:", JSON.stringify(saveData, null, 2));

        // Verifica esistenza record in booking_h_automezzi
        const checkConfig = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${host}/booking-h-automezzi?booking0AutomezziId=${saveData.booking0AutomezziId}`,
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        };

        const checkResponse = await axios.request(checkConfig);
        console.log("3. Check response:", JSON.stringify(checkResponse.data, null, 2));

        let response;

        if (checkResponse.data.data && checkResponse.data.data.length > 0) {
            const existingRecord = checkResponse.data.data[0];
            console.log("4. Updating existing record:", existingRecord.id);

            response = await axios({
                method: 'patch',
                url: `${host}/booking-h-automezzi/${existingRecord.id}`,
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                data: saveData
            });
        } else {
            console.log("4. Creating new record");
            response = await axios({
                method: 'post',
                url: `${host}/booking-h-automezzi`,
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                data: saveData
            });
        }

        console.log("5. Final response:", JSON.stringify(response.data, null, 2));
        console.log("=== DEBUG END ===");

        return response.data;
    } catch (error) {
        console.error('=== ERROR DETAILS ===');
        if (error.response) {
            console.error('Error response data:', error.response.data);
            console.error('Error response status:', error.response.status);
            console.error('Error response headers:', error.response.headers);
        } else if (error.request) {
            console.error('Error request:', error.request);
        } else {
            console.error('Error message:', error.message);
        }
        console.error('Error config:', error.config);
        console.error('Full error:', error);
        console.error('=== END ERROR DETAILS ===');
        throw error;
    }
};

export default saveSimulatorData;