import { ScadenzeListWithTabs } from './ScadenzeList';
import { ScadenzeCreate } from './ScadenzeCreate';
import { ScadenzeEdit } from './ScadenzeEdit';
import { ScadenzeShow } from './ScadenzeShow';

export default {
    list: ScadenzeListWithTabs,
    create: ScadenzeCreate,
    edit: ScadenzeEdit,
    show: ScadenzeShow,
};