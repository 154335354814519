import * as React from 'react';
import {
    Edit, SimpleForm, Toolbar, TopToolbar, TextInput, BooleanInput,
    SaveButton, ShowButton,
    required
} from 'react-admin';
import { saveButtonCss, showButtonCss } from '../shared/Styles/buttonStyles';
import { formCss } from "../shared/Styles/formCss";
import PeopleIcon from '@mui/icons-material/People';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import CustomAutocompleInput from '../shared/FormFields/CustomAutocompleInput';

import { checkPermission } from '../shared/Permissions/checkPermission';

const MyToolbarEdit = () => (
    <Toolbar>
        <SaveButton sx={saveButtonCss}
            label="Salva parametri" alwaysEnable />
    </Toolbar>
);

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <ShowButton sx={showButtonCss} label="Dettaglio" />
    </TopToolbar>
);

const ShowActionsForEdit = () => (
    <ShowTopToolbarDettaglio></ShowTopToolbarDettaglio>
);


export const tabellealfaEdit = () => {

    const canUpdate = checkPermission("tabellealfa", "update");

    if (!canUpdate) {
        return (
            <div style={{ color: "red", fontWeight: "bold", padding: 20 }}>
                Accesso negato
            </div>
        );
    }

    return (
        <Edit title={<>
            <Button>
                <PeopleIcon></PeopleIcon>
                &nbsp;Modifica record di tabellealfa
            </Button>
        </>}
            actions={<ShowActionsForEdit />}
            redirect="show">
            <SimpleForm sx={formCss} toolbar={<MyToolbarEdit />}>
                <Divider variant="middle" />
                <CustomAutocompleInput source="tbpref" reference="tipotabellealfa" optionProperty="descrizione" label="Tipologia"></CustomAutocompleInput>
                <Divider variant="middle" />
                <TextInput source="tbdes" validate={required()} />
                {/* <TextInput source="tbmy" validate={required()} /> */}
                <BooleanInput source="flAttivo" validate={required()} />
                <BooleanInput source="flVisibile" validate={required()} />
                <BooleanInput source="flAnnullato" validate={required()} />
            </SimpleForm>
        </Edit>
    );
}