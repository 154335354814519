import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox,
    Typography,
    Divider
} from "@mui/material";

export const AdvancedFiltersDialog = ({ open, onClose, onApply, onReset, currentFilters }) => {
    const [filters, setFilters] = useState(currentFilters || {});

    useEffect(() => {
        setFilters(currentFilters || {});
    }, [currentFilters]);

    const handleApply = () => {
        onApply(filters);
        onClose();
    };

    const handleChange = (field, value) => {
        if (value === "") {
            const newFilters = { ...filters };
            delete newFilters[field];
            setFilters(newFilters);
        } else {
            setFilters((prev) => ({ ...prev, [field]: value }));
        }
    };

    const handleCheckboxChange = (field) => (event) => {
        const value = event.target.checked;
        if (!value) {
            const newFilters = { ...filters };
            delete newFilters[field];
            setFilters(newFilters);
        } else {
            setFilters((prev) => ({ ...prev, [field]: value }));
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    maxHeight: '90vh'
                }
            }}
        >
            <DialogTitle>
                Filtri Avanzati
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    {/* Dati Principali */}
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" sx={{ mb: 1 }}>
                            Dati Principali
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="ID"
                            fullWidth
                            value={filters["id"] || ""}
                            onChange={(e) => handleChange("id", e.target.value)}
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Nominativo"
                            fullWidth
                            value={filters["nominativo"] || ""}
                            onChange={(e) => handleChange("nominativo", e.target.value)}
                        />
                    </Grid>

                    {/* Indirizzo */}
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                            Indirizzo
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Indirizzo"
                            fullWidth
                            value={filters["indirizzo"] || ""}
                            onChange={(e) => handleChange("indirizzo", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Città"
                            fullWidth
                            value={filters["città"] || ""}
                            onChange={(e) => handleChange("città", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="CAP"
                            fullWidth
                            value={filters["cap"] || ""}
                            onChange={(e) => handleChange("cap", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Provincia"
                            fullWidth
                            value={filters["prov"] || ""}
                            onChange={(e) => handleChange("prov", e.target.value)}
                        />
                    </Grid>

                    {/* Contatti */}
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                            Contatti
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Telefono"
                            fullWidth
                            value={filters["telefono"] || ""}
                            onChange={(e) => handleChange("telefono", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Cellulare"
                            fullWidth
                            value={filters["cellulare"] || ""}
                            onChange={(e) => handleChange("cellulare", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Telefono Aziendale"
                            fullWidth
                            value={filters["telaziendale"] || ""}
                            onChange={(e) => handleChange("telaziendale", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Email"
                            fullWidth
                            value={filters["email"] || ""}
                            onChange={(e) => handleChange("email", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="PEC"
                            fullWidth
                            value={filters["pec"] || ""}
                            onChange={(e) => handleChange("pec", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Patente"
                            fullWidth
                            value={filters["patente"] || ""}
                            onChange={(e) => handleChange("patente", e.target.value)}
                        />
                    </Grid>

                    {/* Stato */}
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                            Stato
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!filters["flAttivo"]}
                                    onChange={handleCheckboxChange("flAttivo")}
                                />
                            }
                            label="Attivo"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!filters["fiVisibile"]}
                                    onChange={handleCheckboxChange("fiVisibile")}
                                />
                            }
                            label="Visibile"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!filters["flAnnullato"]}
                                    onChange={handleCheckboxChange("flAnnullato")}
                                />
                            }
                            label="Annullato"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button onClick={onReset} color="secondary">
                    Reset
                </Button>
                <Button onClick={onClose} color="primary">
                    Annulla
                </Button>
                <Button onClick={handleApply} variant="contained" color="primary">
                    Applica
                </Button>
            </DialogActions>
        </Dialog>
    );
};