import React from "react";
import { Box, Chip } from "@mui/material";

export const ScadenzeActiveFilters = ({ filters, onRemove }) => {
    if (!filters || Object.keys(filters).length === 0) return null;

    const getFilterLabel = (key, value) => {
        const fieldLabels = {
            note: "Descrizione",
            userId: "Assegnato a",
            tipoScadenzaId: "Tipo Scadenza",
            statoScadenzaId: "Stato Scadenza"
        };
        return `${fieldLabels[key] || key}: ${value}`;
    };

    return (
        <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {Object.entries(filters).map(([key, value]) => (
                <Chip
                    key={key}
                    label={getFilterLabel(key, value)}
                    onDelete={() => onRemove(key)}
                    color="primary"
                    variant="outlined"
                />
            ))}
        </Box>
    );
};
