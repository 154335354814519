import * as React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import { Title, localStorageStore } from 'react-admin';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

// Icons
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import HandshakeIcon from '@mui/icons-material/Handshake';
import BadgeIcon from '@mui/icons-material/Badge';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

// Inizializza lo store
const storeGenerale = localStorageStore();

function ChoicePage() {
    const navigate = useNavigate();

    const createChoiceCard = React.useCallback(({ label, path, urlParam, tabellealfaId, icon, description, color }) => {
        // Funzione per gestire il click del pulsante
        const handleClick = () => {
            storeGenerale.setItem('profile.urlParam', urlParam);
            storeGenerale.setItem('profile.urlParam.id', tabellealfaId);
            navigate(`${path}/${encodeURIComponent(urlParam)}`);
        };

        return (
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card
                    onClick={handleClick}
                    sx={{
                        cursor: 'pointer',
                        height: '100%',
                        transition: 'transform 0.2s, box-shadow 0.2s',
                        '&:hover': {
                            transform: 'scale(1.02)',
                            boxShadow: 3
                        }
                    }}
                >
                    <CardContent>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                                padding: 2
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: `${color}15`,
                                    borderRadius: '50%',
                                    padding: 2,
                                    marginBottom: 2
                                }}
                            >
                                {React.cloneElement(icon, {
                                    sx: {
                                        fontSize: 40,
                                        color: color
                                    }
                                })}
                            </Box>
                            <Typography variant="h6" component="div" gutterBottom>
                                {label}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {description}
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        );
    }, [navigate]);

    const choices = [
        {
            label: 'Clienti',
            path: '/anagen/create',
            urlParam: 'cliente',
            tabellealfaId: '1135',
            icon: <PeopleIcon />,
            description: 'Gestisci l\'anagrafica dei tuoi clienti',
            color: '#2196f3'
        },
        {
            label: 'Fornitori',
            path: '/anagen/create',
            urlParam: 'fornitore',
            tabellealfaId: '1136',
            icon: <BusinessIcon />,
            description: 'Gestisci l\'anagrafica dei fornitori',
            color: '#ff9800'
        },
        {
            label: 'Cliente Fornitore',
            path: '/anagen/create',
            urlParam: 'cliente fornitore',
            tabellealfaId: '1137',
            icon: <HandshakeIcon />,
            description: 'Gestisci clienti che sono anche fornitori',
            color: '#4caf50'
        },
        {
            label: 'Dipendenti e coll.',
            path: '/anagen/create',
            urlParam: 'dipendente',
            tabellealfaId: '1148',
            icon: <BadgeIcon />,
            description: 'Gestisci dipendenti e collaboratori',
            color: '#9c27b0'
        },
        {
            label: 'Parco veicoli',
            path: '/automezzi/create',
            urlParam: 'veicolo',
            tabellealfaId: '',
            icon: <DirectionsCarIcon />,
            description: 'Gestisci il parco veicoli aziendale',
            color: '#f44336'
        },
        {
            label: 'Autisti',
            path: '/autisti/create',
            urlParam: 'autista',
            tabellealfaId: '',
            icon: <PersonIcon />,
            description: 'Gestisci l\'anagrafica degli autisti',
            color: '#009688'
        },
        {
            label: 'Sedi aziendali',
            path: '/x',
            urlParam: 'sede',
            tabellealfaId: '',
            icon: <LocationOnIcon />,
            description: 'Gestisci le sedi aziendali',
            color: '#607d8b'
        },
        {
            label: 'Anagrafica Gen.',
            path: '/anagen/create',
            urlParam: 'generale',
            tabellealfaId: '',
            icon: <AccountBoxIcon />,
            description: 'Gestisci anagrafiche generiche',
            color: '#795548'
        }
    ];

    return (
        <Box sx={{ p: 3 }}>
            <Title title="Crea Anagrafica" />

            <Box sx={{ textAlign: 'center', mb: 6 }}>
                <Typography variant="h4" sx={{ mb: 2, color: '#1976d2' }}>
                    Crea Anagrafica
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                    Seleziona la tipologia di anagrafica che desideri creare
                </Typography>
            </Box>

            <Grid container spacing={3}>
                {choices.map((choice, index) => createChoiceCard(choice))}
            </Grid>
        </Box>
    );
}

export default ChoicePage;