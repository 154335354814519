import * as React from "react";
import { useState } from "react";
import {
    List,
    TextField,
    DatagridConfigurable,
    SelectColumnsButton,
    TopToolbar,
    ExportButton,
    CreateButton,
    useRefresh,
    useDataProvider,
    useNotify,
    EditButton,
    ShowButton,
} from 'react-admin';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import { editButtonCss, showButtonCss, exportButtonCss } from '../shared/Styles/buttonStyles';
import { listCss } from '../shared/Styles/listStyles';
import { ToBoolean } from '../shared/ToBoolean';
import { SearchBar } from "./SearchBar";
import { ActiveAdvancedFilters } from "./ActiveAdvancedFilters";
import { AdvancedFiltersDialog } from "./AdvancedFiltersDialog";

const ListActions = () => (
    <TopToolbar>
        <CreateButton label="Crea" sx={exportButtonCss} />
        <ExportButton label="Esporta" sx={exportButtonCss} />
        <SelectColumnsButton sx={exportButtonCss} />
    </TopToolbar>
);

// Filtro di base per escludere id = 0
const baseFilter = { "id.ne": 0 };

export const AutistiList = props => {
    const [filters, setFilters] = useState({ ...baseFilter });
    const [openAdvanced, setOpenAdvanced] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const sort = { field: 'id', order: 'DESC' };

    const handleSearch = async (searchValues) => {
        try {
            const cleanedValues = Object.fromEntries(
                Object.entries(searchValues).filter(([_, value]) => value !== "" && value != null)
            );

            if (Object.keys(cleanedValues).length === 0) {
                notify("Inserire almeno un criterio di ricerca", { type: "warning" });
                return;
            }

            // Combiniamo i filtri di ricerca con il filtro base
            const combinedFilters = { ...cleanedValues, ...baseFilter };

            const { total } = await dataProvider.getList("autisti", {
                pagination: { page: 1, perPage: 1 },
                sort: { field: "id", order: "DESC" },
                filter: combinedFilters,
            });

            if (total === 0) {
                notify("Nessun risultato trovato", { type: "info" });
                return;
            }

            // Manteniamo il filtro base quando settiamo i nuovi filtri
            setFilters({ ...cleanedValues, ...baseFilter });
            notify("Ricerca completata", { type: "success" });
        } catch (error) {
            console.error("Errore durante la ricerca:", error);
            notify("Errore durante la ricerca", { type: "error" });
        }
    };

    const handleReset = () => {
        // Reset mantenendo il filtro base
        setFilters({ ...baseFilter });
        notify("Filtri resettati", { type: "info" });
        refresh();
    };

    const handleRemoveFilter = (key) => {
        // Non permettiamo la rimozione del filtro id.ne
        if (key === "id.ne") return;

        const newFilters = { ...filters };
        delete newFilters[key];
        setFilters({ ...newFilters, ...baseFilter });
        notify("Filtro rimosso", { type: "info" });
    };

    const handleApplyAdvancedFilters = (advancedFilters) => {
        const cleanedFilters = Object.fromEntries(
            Object.entries(advancedFilters).filter(([_, value]) => value !== "" && value != null)
        );

        // Combiniamo i filtri avanzati con il filtro base
        setFilters(prev => ({
            ...prev,
            ...cleanedFilters,
            ...baseFilter
        }));
        notify("Filtri avanzati applicati", { type: "success" });
    };

    return (
        <List
            title={
                <Button>
                    <AirlineSeatReclineNormalIcon />
                    &nbsp;Anagrafiche Autisti
                </Button>
            }
            sx={listCss}
            perPage={50}
            actions={<ListActions />}
            filter={filters}
            sort={sort}
        >
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <SearchBar
                        onSearch={handleSearch}
                        onReset={handleReset}
                        onOpenAdvanced={() => setOpenAdvanced(true)}
                    />
                    <ActiveAdvancedFilters
                        filters={filters}
                        onRemove={handleRemoveFilter}
                    />
                </CardContent>
            </Card>

            <AdvancedFiltersDialog
                open={openAdvanced}
                onClose={() => setOpenAdvanced(false)}
                onApply={handleApplyAdvancedFilters}
                onReset={handleReset}
                currentFilters={filters}
            />

            <DatagridConfigurable
                omit={['note']}
                bulkActionButtons={false}
            >
                <TextField label="id" source="id" />
                <TextField label="Nominativo" source="nominativo" />
                <TextField label="Indirizzo" source="indirizzo" />
                <TextField label="Città" source="città" />
                <TextField label="Cap" source="cap" />
                <TextField label="Prov" source="prov" />
                <TextField label="Telefono" source="telefono" />
                <TextField label="Cellulare" source="cellulare" />
                <TextField label="Tel. Aziendale" source="telaziendale" />
                <TextField label="Email" source="email" />
                <TextField label="PEC" source="pec" />
                <TextField label="Patente" source="patente" />
                <ToBoolean label="Attivo" source="flAttivo" />
                <ToBoolean label="Visibile" source="fiVisibile" />
                <ToBoolean label="Annullato" source="flAnnullato" />
                <EditButton sx={editButtonCss} label="Modifica" />
                <ShowButton sx={showButtonCss} label="Dettaglio" />
            </DatagridConfigurable>
        </List>
    );
};