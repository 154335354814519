import React from 'react';
import { Loading, useGetList } from 'react-admin';
import { MenuItemNode } from "@react-admin/ra-navigation";
import { Divider, useTheme } from '@mui/material';
import * as Icons from '@mui/icons-material';

const iconMapping = {
    'SettingsIcon': 'Settings',
    'EuroIcon': 'Euro',
    'ManageAccountsIcon': 'ManageAccounts',
    'AddCircleIcon': 'AddCircle',
    'PeopleIcon': 'People',
    'GroupsIcon': 'Groups',
    'DirectionsBusIcon': 'DirectionsBus',
    'AirlineSeatReclineNormalIcon': 'AirlineSeatReclineNormal',
    'BusinessIcon': 'Business',
    'InsertInvitationIcon': 'InsertInvitation',
    'TouchAppIcon': 'TouchApp',
    'LibraryBooksIcon': 'LibraryBooks',
    'TerminalIcon': 'Terminal',
    'LeaderboardIcon': 'Leaderboard',
    'AdminPanelSettingsIcon': 'AdminPanelSettings',
    'Contacts': 'Contacts',
    'CarRentalIcon': 'CarRental',
    'LocalOfferIcon': 'LocalOffer',
    'AlarmIcon': 'Alarm'
    // Aggiungi qui altri mapping se necessario, ricorda che devono corrispondere alle voci sul DB 
};

const DeterminaMenu = ({ iconColor }) => {
    const theme = useTheme();
    const { data, isLoading, error } = useGetList(
        'menuprog',
        {
            pagination: { page: 1, perPage: 1000 },
            filter: { flAttivo: 1, flVisibile: 1 }
        }
    );

    if (isLoading) return <Loading />;
    if (error) return <p>Errore nel caricamento del menu</p>;

    const mainMenuItems = data.filter(item => item.liv01 === 0).sort((a, b) => a.id - b.id);
    const subMenuItems = data.filter(item => item.liv01 !== 0).sort((a, b) => a.id - b.id);

    /* DEBUG */
    // console.log("data menu ", data);


    const getIconComponent = (iconName) => {
        if (!iconName) {
            console.log('Nome icona non definito');
            return null;
        }

        const mappedIconName = iconMapping[iconName] || iconName;
        const IconComponent = Icons[mappedIconName];
        if (!IconComponent) {
            console.log(`Icona non trovata: ${mappedIconName}`);
            return null;
        }

        return (
            <IconComponent
                sx={{
                    color: iconColor,
                    fontSize: { xs: '20px', sm: '24px' }, // Adjust size based on screen width
                }}
            />
        );
    };



    return (
        <>
            {mainMenuItems.map(mainItem => {

                var checkForIsOpen = mainItem.isOpen === "true";

                return (
                    <MenuItemNode
                        key={mainItem.id}
                        isopen={checkForIsOpen.toString()}
                        name={mainItem.descrizione.toLowerCase()}
                        to={`/${mainItem.to.toLowerCase()}`}
                        label={mainItem.descrizione}
                        icon={getIconComponent(mainItem.icon)}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            wordWrap: 'break-word', // Permette di andare a capo
                            whiteSpace: 'normal', // Consente il wrapping
                            lineHeight: '1.2', // Regola lo spazio tra le righe
                        }}
                    >


                        {subMenuItems
                            .filter(subItem => subItem.liv01 === mainItem.id)
                            .map(subItem => (
                                <MenuItemNode
                                    key={subItem.id}
                                    name={subItem.descrizione.toLowerCase()}
                                    to={`/${subItem.to.toLowerCase()}`}
                                    label={subItem.descrizione}
                                    icon={getIconComponent(subItem.icon)}
                                />
                            ))}
                    </MenuItemNode>
                );
            })}
            <Divider sx={{ my: 2 }} />
        </>
    );
};

export default DeterminaMenu;