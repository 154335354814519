import React from 'react';
import {
    useRecordContext,
    Loading,
    useGetOne
} from 'react-admin';
import CustomChip from '../../shared/CustomFields/CustomChip';

const GetDescrizionGruppoMezzo = ({ id, reference }) => {
    const record = useRecordContext();
    const { data: gruppo, isLoading, error } = useGetOne(reference, { id: id });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <CustomChip text={gruppo.tbdes}></CustomChip>;
};

export default GetDescrizionGruppoMezzo;
