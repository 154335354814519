import React, { useState } from "react";
import { Grid, Box, Button, IconButton, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import ClearIcon from "@mui/icons-material/Clear";

export const ScadenzeSearchBar = ({ onSearch, onReset, onOpenAdvanced }) => {
    const [searchValues, setSearchValues] = useState({});

    const handleChange = (field, value) => {
        setSearchValues((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleSearch = () => {
        const formattedValues = {};
        if (searchValues["note"]) {
            formattedValues["note.like"] = `*${searchValues["note"]}*`;
        }
        if (searchValues["data_gte"]) {
            formattedValues["data.gte"] = searchValues["data_gte"];
        }
        if (searchValues["data_lte"]) {
            formattedValues["data.lte"] = searchValues["data_lte"];
        }
        onSearch(formattedValues);
    };

    const handleReset = () => {
        setSearchValues({}); // Reset di tutti i valori
        onReset(); // Esegui anche eventuali logiche aggiuntive nel parent
    };

    return (
        <Box>
            <Grid container spacing={2} alignItems="center">
                {/* Campo descrizione */}
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label="Descrizione"
                        value={searchValues["note"] || ""}
                        onChange={(e) => handleChange("note", e.target.value)}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => handleChange("note", "")}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                {/* Campo data inizio */}
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label="Data Inizio"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={searchValues["data_gte"] || ""}
                        onChange={(e) => handleChange("data_gte", e.target.value)}
                        fullWidth
                    />
                </Grid>

                {/* Campo data fine */}
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label="Data Fine"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={searchValues["data_lte"] || ""}
                        onChange={(e) => handleChange("data_lte", e.target.value)}
                        fullWidth
                    />
                </Grid>

                {/* Pulsanti */}
                <Grid item xs={12}>
                    <Button
                        startIcon={<SearchIcon />}
                        variant="contained"
                        onClick={handleSearch}
                    >
                        Cerca
                    </Button>
                    <Button
                        startIcon={<TuneIcon />}
                        variant="outlined"
                        onClick={onOpenAdvanced}
                        sx={{ ml: 1 }}
                    >
                        Filtri Avanzati
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleReset}
                        sx={{ ml: 1 }}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};
