import React from "react";
import { Box, Chip } from "@mui/material";

export const ActiveAdvancedFilters = ({ filters, onRemove }) => {
    if (!filters || Object.keys(filters).length === 0) return null;

    // Escludiamo il filtro base (id.ne) dai chip
    const displayFilters = Object.entries(filters).filter(([key]) => key !== 'id.ne');

    if (displayFilters.length === 0) return null;

    return (
        <Box sx={{ mt: 2 }}>
            {displayFilters.map(([key, value]) => (
                <Chip
                    key={key}
                    label={`${key}: ${value}`}
                    onDelete={() => onRemove(key)}
                    sx={{ mr: 1, mb: 1 }}
                />
            ))}
        </Box>
    );
};