import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    CircularProgress,
    Alert,
    Typography
} from '@mui/material';
import { FunctionField, ReferenceField, useDataProvider, useNotify } from 'react-admin';
import EmailIcon from '@mui/icons-material/Email';

// Componente Dialog per l'invio dell'email
export const InvioEmailPreventivoDialog = ({
    open,
    onClose,
    bookingId,
    numeroPrev,
    importoTotale,
    dataPrev
}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [note, setNote] = useState('');
    // Nuovo stato per l'indirizzo email da inviare
    const [destEmail, setDestEmail] = useState('');

    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleSend = async () => {
        setLoading(true);
        setError(null);

        // 1) Check campo obbligatorio
        if (!destEmail) {
            setError('Il campo Destinatario è obbligatorio');
            setLoading(false);
            return;
        }

        // 2) Check validità formale dell'indirizzo email (regex semplice)
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(destEmail)) {
            setError('Formato email non valido');
            setLoading(false);
            return;
        }

        try {
            await dataProvider.create('generatore-mail', {
                data: {
                    payload: {
                        // Usiamo la mail inserita dall'utente
                        to: destEmail,
                        tipoServizioMailId: 7,
                        html: {
                            cliente: "Alessandro",
                            numeroPrev: numeroPrev,
                            dataPrev: dataPrev,
                            importoTotale: importoTotale,
                            note: note,
                            email: destEmail, // se vuoi usare la stessa email come mittente (opzionale)
                            azienda: 'Tuvoni Srl',
                            linkApprovazione: `${window.location.origin}/#/approva-preventivo/${bookingId}`
                        }
                    }
                }
            });

            notify('Email inviata con successo', { type: 'success' });
            onClose();
        } catch (err) {
            setError(err.message || 'Errore durante l\'invio dell\'email');
            notify('Errore durante l\'invio dell\'email', { type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor: '#f5f5f5',
                borderBottom: '1px solid #e0e0e0'
            }}>
                <EmailIcon color="primary" />
                <Typography variant="h6">Inoltro Preventivo</Typography>
            </DialogTitle>

            <DialogContent sx={{ pt: 2 }}>
                {/* Alert che spiega funzionalità incompleta, se serve */}
                <Alert severity="error" sx={{ mb: 2 }}>
                    Funzionalità non completa: questa sezione verrà migliorata nel prossimo rilascio.
                    Puoi comunque testare l'inoltro indicando l'email di destinazione.
                </Alert>

                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                <Typography variant="body1" sx={{ mb: 2 }}>
                    Stai per inviare il preventivo a:
                    <ReferenceField
                        source="bk0_clienteId"
                        reference="anagen"
                        link={false}
                    >
                        Anagen ID:
                        <FunctionField
                            label=""
                            render={record => record && record.id}
                        />
                        <ReferenceField
                            source="clientiId"
                            reference="users"
                            link={false}
                        >
                            User ID
                            <FunctionField
                                label=""
                                render={record => record && record.id}
                            />
                        </ReferenceField>
                    </ReferenceField>
                </Typography>

                {/* Campo per l'indirizzo email */}
                <TextField
                    fullWidth
                    label="Indirizzo Email (destinatario)"
                    value={destEmail}
                    onChange={(e) => setDestEmail(e.target.value)}
                    margin="normal"
                    placeholder="Inserisci l'email del destinatario..."
                />

                <TextField
                    fullWidth
                    label="Note Aggiuntive"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    margin="normal"
                    multiline
                    rows={4}
                    placeholder="Inserisci eventuali note da includere nell'email..."
                />
            </DialogContent>

            <DialogActions sx={{
                padding: 2,
                borderTop: '1px solid #e0e0e0',
                backgroundColor: '#f5f5f5'
            }}>
                <Button
                    onClick={onClose}
                    disabled={loading}
                    color="inherit"
                >
                    Annulla
                </Button>
                <Button
                    onClick={handleSend}
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    startIcon={loading ? <CircularProgress size={20} /> : <EmailIcon />}
                >
                    {loading ? 'Invio in corso...' : 'Invia Email'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// Componente Pulsante
export const PreventivoEmailButton = ({ record }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    return (
        <>
            <Button
                onClick={() => setIsDialogOpen(true)}
                startIcon={<EmailIcon />}
                variant="outlined"
                size="small"
                sx={{
                    borderColor: '#1976d2',
                    '&:hover': {
                        borderColor: '#1565c0',
                        backgroundColor: 'rgba(25, 118, 210, 0.04)'
                    }
                }}
            >
                Invia Email per approvazione
            </Button>

            <InvioEmailPreventivoDialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                bookingId={record.id}
                numeroPrev={record.bk0_idnumber}
                importoTotale={record.importoTotale}
                dataPrev={new Date(record.bk0_iddata).toLocaleDateString('it-IT')}
            />
        </>
    );
};
