import * as React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    DateTimeInput,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    useNotify,
    useRedirect,
    Toolbar,
    SaveButton,
    ShowButton,
    TopToolbar
} from "react-admin";
import { saveButtonCss, showButtonCss } from "../shared/Styles/buttonStyles";
import { formCss } from "../shared/Styles/formCss";

const ScadenzeEditToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton sx={saveButtonCss}
            label="Salva parametri" alwaysEnable />
    </Toolbar>
);

const ShowTopToolbarDettaglio = () => (
    <TopToolbar>
        <ShowButton sx={showButtonCss} label="Dettaglio" />
    </TopToolbar>
);

const ShowActionsForEdit = () => (
    <ShowTopToolbarDettaglio></ShowTopToolbarDettaglio>
);

export const ScadenzeEdit = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('Modifica completata con successo', { type: 'success' });
        redirect('show', props.basePath);
    };

    const onFailure = (error) => {
        notify(`Errore durante la modifica: ${error.message}`, { type: 'error' });
    };

    return (
        <Edit
            title="Modifica Scadenza"
            {...props}
            mutationMode="pessimistic"
            onSuccess={onSuccess}
            onFailure={onFailure}
            actions={<ShowActionsForEdit />}
            redirect="show"
        >
            <SimpleForm sx={formCss} toolbar={<ScadenzeEditToolbar />}>
                <ReferenceInput source="tipoScadenzaId" reference="tipo-scadenza">
                    <SelectInput optionText="nome" label="Tipo Scadenza" />
                </ReferenceInput>
                <TextInput source="note" label="Descrizione" fullWidth multiline />
                <DateTimeInput source="data" label="Data Scadenza" />
                <ReferenceInput source="statoScadenzaId" reference="stato-scadenza">
                    <SelectInput optionText="nome" label="Stato Scadenza" />
                </ReferenceInput>
                <BooleanInput source="flPromemoria" label="Promemoria" />
            </SimpleForm>
        </Edit>
    );
};
