import axios from 'axios';

const loadSimulatorData = async (booking0AutomezziId, host) => {
    try {
        const jwt = localStorage.getItem("feathers-jwt");

        // Configurazione della chiamata
        const config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${host}/booking-h-automezzi?booking0AutomezziId=${booking0AutomezziId}&$sort[id]=-1&$limit=1`,
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        };

        console.log("Fetching data with config:", config);

        const response = await axios.request(config);

        if (response.data.data && response.data.data.length > 0) {
            const lastRecord = response.data.data[0];
            console.log("Found last record:", lastRecord);

            // Organizziamo i dati nel formato necessario per la dialog
            return {
                inputs: {
                    input_km_servizio: lastRecord.input_km_servizio || 0,
                    input_minuti_servizio: lastRecord.input_minuti_servizio || 0,
                    input_km_vuoto: lastRecord.input_km_vuoto || 0,
                    input_minuti_vuoto: lastRecord.input_minuti_vuoto || 0,
                    input_notturno: Boolean(lastRecord.input_notturno),
                    input_supplemento_pedaggio: lastRecord.input_supplemento_pedaggio || 0,
                    input_tipo_sconto: lastRecord.input_tipo_sconto || 2,
                    input_valore_sconto: lastRecord.input_valore_sconto || 0,
                    input_total_km: lastRecord.input_total_km || 0,
                    input_total_minuti: lastRecord.input_total_minuti || 0
                },
                results: {
                    results_importo_budget_km: lastRecord.results_importo_budget_km || 0,
                    results_importo_budget_hh: lastRecord.results_importo_budget_hh || 0,
                    results_importo_totale_suppl_km: lastRecord.results_importo_totale_suppl_km || 0,
                    results_importo_totale_suppl_hh: lastRecord.results_importo_totale_suppl_hh || 0,
                    results_km_totali: lastRecord.results_km_totali || 0,
                    results_minuti_totali: lastRecord.results_minuti_totali || 0,
                    results_km_eccedenza: lastRecord.results_km_eccedenza || 0,
                    results_minuti_eccedenza: lastRecord.results_minuti_eccedenza || 0,
                    results_totale_imponibile_di_servizio: lastRecord.results_totale_imponibile_di_servizio || 0,
                    results_supplemento_notturno: lastRecord.results_supplemento_notturno || 0,
                    results_totale_imponibile_finale: lastRecord.results_totale_imponibile_finale || 0,
                    sconto: lastRecord.results_sconto || 0,
                    totale_imponibile: lastRecord.results_totale_imponibile || 0,
                    importo_iva: lastRecord.results_importo_iva || 0,
                    totale_preventivo: lastRecord.results_totale_preventivo || 0
                },
                tariffeAEditable: {
                    tfa_tipo: lastRecord.tfa_tipo || 0,
                    tfa_des: lastRecord.tfa_des || "",
                    tfa_bdgkm: lastRecord.tfa_bdgkm || 0,
                    tfa_bdghh: lastRecord.tfa_bdghh || 0,
                    tfa_frgmmec: lastRecord.tfa_frgmmec || 0,
                    tfa_frgmmnt: lastRecord.tfa_frgmmnt || 0
                },
                tariffeBEditable: {
                    id: lastRecord.tfb_id || null,
                    tfb_des: lastRecord.tfb_des || "",
                    tfb_ckm: lastRecord.tfb_ckm || 0,
                    tfb_chh: lastRecord.tfb_chh || 0,
                    tfb_ekm: lastRecord.tfb_ekm || 0,
                    tfb_ehh: lastRecord.tfb_ehh || 0,
                    tfb_vkmperc: lastRecord.tfb_vkmperc || 0,
                    tfb_vhhperc: lastRecord.tfb_vhhperc || 0,
                    tfb_snotturno: lastRecord.tfb_snotturno || 0
                }
            };
        }

        return null; // Se non ci sono record precedenti
    } catch (error) {
        console.error('Error loading simulator data:', error);
        throw error;
    }
};

export default loadSimulatorData;